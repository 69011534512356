import React from 'react';
import {Row} from "react-bootstrap";
import ShowField from "../form/ShowField";

function ParticipationSettlementBatchFormElements( {settlementBatch} ) {
	return (
        <>
            <Row>
                <ShowField label={'participationSettlementBatch.dateFrom'} object={settlementBatch} property={'dateFrom'} type={'date'} size={6}/>
                <ShowField label={'participationSettlementBatch.dateTo'} object={settlementBatch} property={'dateTo'} type={'date'} size={6}/>
            </Row>
        </>
    )
}

export default ParticipationSettlementBatchFormElements