import React from 'react';
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {policyService} from "../_services/policy.service";
import {Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";

function BillingForPartners() {
	const {t} = useTranslation();
	const useFormObject = useForm();

	const ReportEnum = Object.freeze({
		ASSISTANCE:'ASSISTANCE',
		HAFTPFLICHTPRAEMIEN:'HAFTPFLICHTPRAEMIEN',
		RECHTSSCHUTZ:'RECHTSSCHUTZ'
	});

	const onSubmit = useFormObject.handleSubmit( (data, event) => {
		const report = event.target.name

		policyService.billingForPartners(data.validFrom, data.validTo, data.dataValidOn, data.courtage, data.administrationFee, report)

	} );

	return (
		<>
			<h2>{t('reports.billingForPartners.title')}</h2>

			<FormProvider {...useFormObject}>
				<Form>
					<Card>
						<Card.Header>
							{t('default.filter')}
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="col-md-6" controlId="validFrom">
									<Form.Label>{t('billingForPartners.validFrom.label')}</Form.Label>
									<FormControl
										name={"validFrom"}
										type={"date"}
										required={true}
									/>
								</Form.Group>

								<Form.Group className="col-md-6" controlId="validTo">
									<Form.Label>{t('billingForPartners.validTo.label')}</Form.Label>
									<FormControl
										name={"validTo"}
										type={"date"}
										required={true}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group className="col-md-6" controlId="dataValidOn">
									<Form.Label>{t('billingForPartners.dataValidOn.label')}</Form.Label>
									<FormControl
										name={"dataValidOn"}
										type={"date"}
										required={true}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group className="col-md-6" controlId="courtage">
									<Form.Label>{t('billingForPartners.courtage.label')}</Form.Label>
									<InputGroup>
										<FormControl
											name={"courtage"}
											type={"number"}
											required={true}
										/>
										<InputGroup.Text>%</InputGroup.Text>
									</InputGroup>
								</Form.Group>
								<Form.Group className="col-md-6" controlId="administrationFee">
									<Form.Label>{t('billingForPartners.administrationFee.label')}</Form.Label>
									<InputGroup>
										<FormControl
											name={"administrationFee"}
											type={"number"}
											required={true}
										/>
										<InputGroup.Text>%</InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Row className={"mt-3"}>
						<Col md={12}>
							<Button name={ReportEnum.ASSISTANCE} variant={"primary"} type={"button"} onClick={ onSubmit } >{ t('billingForPartners.button.assistance') }</Button>
							<Button className={"ms-2"} name={ReportEnum.HAFTPFLICHTPRAEMIEN} variant={"primary"} type={"button"} onClick={ onSubmit } >{ t('billingForPartners.button.haftpflichtpraemien') }</Button>
							<Button className={"ms-2"} name={ReportEnum.RECHTSSCHUTZ} variant={"primary"} type={"button"} onClick={ onSubmit } >{ t('billingForPartners.button.rechtsschutz') }</Button>
						</Col>
					</Row>
					<Row className={"mt-3"}>

					</Row>
				</Form>
			</FormProvider>
		</>
	)
}

export default BillingForPartners