import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Button, ButtonGroup, Card, Col, Form, Modal, Table, ToggleButton} from "react-bootstrap";
import {useForm,FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import settingService from "../_services/setting.service";
import restService from "../_services/rest.service";
import {configuratorService} from "../_services/configurator.service";
import textService from "../_services/text.service";
import moment from "moment";

function CreatePolicyAddProductTariffs(props) {
	const { t } = useTranslation()
	const useFormObject = useForm();
	const {appenzellerVersicherungPartnerId} = settingService.useSetting(['appenzellerVersicherungPartnerId']);
	const [appversPartner] = restService.useDomainInstance('partner', appenzellerVersicherungPartnerId);
	const [showAlert, setShowAlert] = useState(false);
	const [parentProductPathAndName, setParentProductPathAndName] = useState("");
	const [brokerTariffsFilter, setBrokerTariffsFilter] = useState(1)
	const detailValues = useMemo( () => ( props.values['detail'] ), [props.values]);
	const policyValues = useMemo( () => ( props.values['policy'] ), [props.values])
	const policyVersionValidFrom = useMemo( () => ( policyValues.validFrom ), [policyValues] );

	const propsDetailTargets = useMemo( () => {
		return (detailValues && detailValues.targets) || []
	}, [detailValues]);

	const addProductConfiguratorValues = useMemo( () => ( props.values['addProductConfigurator'] ), [props.values]);

	const lastTouchesValues = useMemo( () => ( props.values['lastTouches'] ), [props.values]);

	const addProductBuildingValues = useMemo( () => ( props.values['addProductBuilding'] ), [props.values]);

	const propsConfiguratorTargets = useMemo( () => {
		return addProductConfiguratorValues && addProductConfiguratorValues.targets;
	}, [addProductConfiguratorValues]);

	const propsConfiguratorValues = useMemo( () => {
		return addProductConfiguratorValues && addProductConfiguratorValues.values
	}, [addProductConfiguratorValues]);

	const propsConfiguratorBuilding = useMemo( () => {
		return addProductBuildingValues && addProductBuildingValues.building
	}, [addProductBuildingValues]);

	const propsConfiguratorPlaceOfInsuranceStreet = useMemo( () => {
		return addProductBuildingValues && addProductBuildingValues.placeOfInsuranceStreet
	}, [addProductBuildingValues]);

	const propsConfiguratorPlaceOfInsuranceZip = useMemo( () => {
		return addProductBuildingValues && addProductBuildingValues.placeOfInsuranceZip
	}, [addProductBuildingValues]);

	const getMaxId = (targets) => {
		let maxId
		if (targets && targets.length>0) {
			maxId = Math.max.apply( Math, targets.map( function ( o ) {
				return o.key;
			} ) );
		}
		else {
			maxId = 0;
		}
		return maxId;
	};

	const prepareTargets = useCallback( () => {
		let result
		if ( propsConfiguratorTargets ) {
			result = []
			let maxId=getMaxId(propsDetailTargets);
			const isSelected = propsConfiguratorTargets.length === 1;
			propsConfiguratorTargets.forEach( (target) => {
				let targetCopy = {...target};
				targetCopy.isSelected = isSelected;
				targetCopy.key = ++maxId;
				result.push(targetCopy);
			} );
		}
		return result;
	}, [propsConfiguratorTargets, propsDetailTargets] );

	const [configuratorTargets, setConfiguratorTargets] = useState(prepareTargets(propsConfiguratorTargets));
	const [configuratorValues, setConfiguratorValues] = useState(propsConfiguratorValues);
	const [building, setBuilding] = useState(propsConfiguratorBuilding);
    const brokerTariffInList = useMemo( () => {
        return configuratorTargets && configuratorTargets.some( (ct) => ct.broker )
    }, [configuratorTargets])

	const visibleTargetsFilter = useCallback((ct) => {
		switch ( brokerTariffsFilter ) {
			case 1:
				return !!ct.broker || !configuratorTargets.some( (ct1) => ct1.parentConfiguratorTarget && ct1.parentConfiguratorTarget.id === ct.id )
			case 2:
				return !ct.broker
			case 3:
				return true //display all
			default:
				throw new Error('Unknown brokerTariffsFilter')
		}
	}, [brokerTariffsFilter, configuratorTargets])


	useEffect( ()=> {
		setConfiguratorTargets( prepareTargets(propsConfiguratorTargets) );
		setConfiguratorValues( propsConfiguratorValues );
		setBuilding( propsConfiguratorBuilding );
	}, [propsConfiguratorTargets, propsConfiguratorValues, propsConfiguratorBuilding, prepareTargets])

	const handleClickPrevious = () => {
		props.previousStep();
	}

	const onSubmit = (data) => {
		const selectedTargets = configuratorTargets.filter( (target) => target.isSelected ).sort( (a,b) => {
				/*
					We want configuratorTargetDependentProducts at the end of the list. Why? When the selected targets are
					added to the policy, the amount of the targets can be predefined => dependent products should come at the end.

					Known problem: It could happen, that some configuratorTargetDependentProduct could be dependent from another
					configuratorTargetDependentProduct. In such a case, this sorting must not be sufficient and we should use
					much more complicated sorting by hierarchy. But such situation should happen no more than once in 10 years,
					so we don't waste time now.
				 */
				if ( (['configuratorTargetDependentProduct', 'configuratorTargetDependentProductBroker'].includes(a.targetClassName) &&  ['configuratorTargetDependentProduct', 'configuratorTargetDependentProductBroker'].includes(b.targetClassName) )
					 || (!['configuratorTargetDependentProduct', 'configuratorTargetDependentProductBroker'].includes(a.targetClassName) &&  !['configuratorTargetDependentProduct', 'configuratorTargetDependentProductBroker'].includes(b.targetClassName)) ) {
					return 0; //keep original order of a and b
				}
				else if ( a.targetClassName === 'configuratorTargetDependentProduct' || a.targetClassName === 'configuratorTargetDependentProductBroker' ) {
					return 1 //sort a after b
				}
				else {
					return -1; //sort a before b
				}
			});

		selectedTargets.forEach( (target) => {
			if ( propsConfiguratorPlaceOfInsuranceStreet ) {
				target.placeOfInsuranceStreet = propsConfiguratorPlaceOfInsuranceStreet;
			}
			if ( propsConfiguratorPlaceOfInsuranceZip ) {
				target.placeOfInsuranceZip = propsConfiguratorPlaceOfInsuranceZip;
			}
			let lastIndividualTextOrdering = 0
			target.individualTexts = [];
			target.configuratorTargetIndividualTexts.forEach( (ctit) => {
				target.individualTexts.push( {
					"action": "create",
					"individualText": { "id": ctit.individualText.id, "label": ctit.individualText.label, "classSimpleName":"individualText" },
					"ordering": ++lastIndividualTextOrdering
				} );
			} )
		})
		const allTargets = propsDetailTargets.concat( selectedTargets );

		selectedTargets.forEach( (target) => {
			switch(target.targetClassName) {
				case 'configuratorTargetBase':
				case 'configuratorTargetBaseBroker':
					const correspondingTargets = detailValues.getCorrespondingTargets(allTargets, target, 0).sort((a,b) => {
						//base targets should have priority before dependent targets when amount is predefined
						let aOrder = 0;
						let bOrder = 0;
						if (a.targetClassName === 'configuratorTargetBase' || a.targetClassName === 'configuratorTargetBaseBroker') {
							aOrder = -1
						}
						if (b.targetClassName === 'configuratorTargetBase' || b.targetClassName === 'configuratorTargetBaseBroker') {
							bOrder = -1
						}
						return ( aOrder < bOrder ) ? -1 : ( ( bOrder < aOrder ) ? 1 : 0 )
					})
					const correspondingTarget = correspondingTargets[0]
					if ( correspondingTarget ) {
						if (correspondingTarget.targetClassName === 'configuratorTargetDependentProduct' || correspondingTarget.targetClassName === 'configuratorTargetDependentProductBroker') {
							target.amount = Math.round(correspondingTarget.amount / correspondingTarget.amountDependencyFactor)
						} else {
							target.amount = correspondingTarget.amount;
						}
					}
					break;
				case 'configuratorTargetDependentProduct':
				case 'configuratorTargetDependentProductBroker':
					const parentTarget = detailValues.getParentTarget(allTargets, target);
					if ( parentTarget ) {
						target.amount = Math.round(parentTarget.amount * target.amountDependencyFactor / target.roundAmountToMultiple) * target.roundAmountToMultiple
					}
					break;
				default:
				//skip
			}

			//If there is just one available amount for this target, we predefine it.
			if ( !target.amount ) {
				if ( target.availableInsuranceAmount.intervals && target.availableInsuranceAmount.intervals.length === 1 && target.availableInsuranceAmount.intervals[0].from === target.availableInsuranceAmount.intervals[0].to ) {
					target.amount = target.availableInsuranceAmount.intervals[0].from
				}
			}
		})

		props.handleUpdate( 'detail', { ...detailValues, ...{targets: allTargets} } );
		props.handleUpdate( 'addProductConfigurator', {...addProductConfiguratorValues, targets: []} ); //clear the temporary targets

		let standardTexts = [...(lastTouchesValues.standardTexts || [])];
		let lastStandardTextOrdering = standardTexts.length;
		selectedTargets.forEach( (target) => {
			target.configuratorTargetStandardTexts.forEach( (ctst) => {
				if ( ctst.standardText.active && !standardTexts.some( (st) => parseInt(st.standardText.id) === parseInt(ctst.standardText.id) ) ) {
					standardTexts.push( {"action":"create","standardText":{"id": `${ctst.standardText.id}`},"ordering": ++lastStandardTextOrdering} );
				}
			})
		});
		props.handleUpdate( 'lastTouches', {...lastTouchesValues, standardTexts: standardTexts} );
		props.goToNamedStep( 'detail' );

		if ( selectedTargets && selectedTargets.length > 0 ) {
			configuratorService.expandAndScrollToConfiguratorHeader(selectedTargets[0].key);
		}
	}

	const _handleUpdate = props.handleUpdate;
	const _goToNamedStep = props.goToNamedStep;

	const enterAddressOrBuilding = useCallback( (what) => {
		_handleUpdate( 'addProductBuilding', { ...addProductBuildingValues, ...{addressOrBuilding: what} } );
		_goToNamedStep( 'addProductBuilding' );
	}, [_handleUpdate, _goToNamedStep, addProductBuildingValues]);

	const handleTargetRowChanged = useCallback( (key, selected) => {
		if (configuratorTargets) {
			let target = configuratorTargets.find( (t) => (t.key === key) );

			if ( selected && ( target.dependsOn || ( target.broker && target.parentConfiguratorTarget.dependsOn ) ) ) {
				let parentTarget = detailValues.getParentTarget( detailValues.targets, target )
				if ( !parentTarget ) {
					const filteredTargets = configuratorTargets.filter(visibleTargetsFilter)
					const parentTarget = detailValues.getParentTarget( filteredTargets, target )
					if (parentTarget) {
						parentTarget.isSelected = true;
					}
					else {
						setParentProductPathAndName(target.dependsOn.label);
						setShowAlert(true);
						selected = false;
					}
				}
			}
			target.isSelected = selected;
			setConfiguratorTargets([...configuratorTargets]);
		}
	}, [configuratorTargets, detailValues, visibleTargetsFilter] );

	const _appversPartnerId = appversPartner && appversPartner.id
	const _appversPartnerLabel = appversPartner && appversPartner.label

	const selectedTargets = useMemo( () => {
		let result = []

		if ( configuratorTargets ) {
			result = configuratorTargets.filter( visibleTargetsFilter ).map( ( target ) => {
				target.configuratorValues = configuratorValues;
				if ( building ) {
					target.building = { id: building.id, label: building.label };
				}
				target.participation = [ {partner: {id:_appversPartnerId, label:_appversPartnerLabel}, percent: 100, action: 'create'} ];
				target.action = 'create'
				return <TargetRow key={target.key} targetKey={target.key} targetId={target.targetId} onChange={handleTargetRowChanged} target={target} isSelected={target.isSelected} isAddressEntered={!!propsConfiguratorPlaceOfInsuranceStreet && !!propsConfiguratorPlaceOfInsuranceZip} isBuildingEntered={!!propsConfiguratorBuilding} enterAddressOrBuilding={enterAddressOrBuilding} isBrokerTarget={!!target.broker} policyVersionValidFrom={policyVersionValidFrom}/>
			} )
		}

		return result;
	}, [configuratorTargets, configuratorValues, handleTargetRowChanged, building, propsConfiguratorBuilding, propsConfiguratorPlaceOfInsuranceStreet, propsConfiguratorPlaceOfInsuranceZip, enterAddressOrBuilding, visibleTargetsFilter, _appversPartnerId, _appversPartnerLabel])



	return (
		// todo: translate
		<>
			<Modal show={showAlert} onHide={() => setShowAlert(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Abhängiges Produkt</Modal.Title>
				</Modal.Header>
				<Modal.Body>Zuerst müssen Sie das Produkt <strong>{parentProductPathAndName}</strong> anfügen.</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setShowAlert(false)}>
						Ich verstehe
					</Button>
				</Modal.Footer>
			</Modal>
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Col className={"tour-configurator-form-configuratorTargets"} md={"8"}>
						<Card className={"mb-2"}>
							<Card.Header>
								<h5 className={"float-start"}>{t('availableTargets.card.header')}</h5>
                                { brokerTariffInList && <span className={"float-end"}>
									<ButtonGroup>
										{[{id: 'broker', value:1, text:'Makler Tarife'},
					                        {id: 'common', value:2, text:'Allgemeine Tarife'},
					                        {id: 'all', value:3, text:'Alle Tarife'}].map( (button, idx) => {
					                        return (
					                            <ToggleButton
						                            size={"sm"}
					                                key={button.id}
					                                id={`radio-${idx}`}
					                                type={"radio"}
					                                name={"radio"}
					                                value={button.value}
					                                variant={'light'}
					                                checked={brokerTariffsFilter === button.value}
					                                onChange={(e) => {
					                                    setBrokerTariffsFilter(parseInt(e.currentTarget.value))
					                                }}
					                            >
					                                {button.text}
					                            </ToggleButton>
					                        )
					                    })}
									</ButtonGroup>
								</span>
								}
							</Card.Header>
							<Card.Body>
								<Table>
									<tbody>
										{selectedTargets}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>

					<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
					<Button type={"submit"} className='btn btn-default'>{t('default.next')}</Button>
				</Form>
			</FormProvider>
		</>
	);
}

function TargetRow({targetKey, onChange, target, isSelected, isAddressEntered, isBuildingEntered, enterAddressOrBuilding, isBrokerTarget, policyVersionValidFrom}) {
	const { t } = useTranslation()

	const handleClick = useCallback( () => {
		onChange(targetKey, !isSelected);
	}, [isSelected, onChange, targetKey]);

	const handleEnterAddress = useCallback ( () => {
		enterAddressOrBuilding('ADDRESS');
	}, [enterAddressOrBuilding]);

	const handleEnterBuilding = useCallback ( () => {
		enterAddressOrBuilding('BUILDING');
	}, [enterAddressOrBuilding]);

	const targetIsAddressObligatory = target.isAddressObligatory;
	const targetIsBuildingObligatory = target.isBuildingObligatory;

	const button = useMemo( () => {
		if ( targetIsBuildingObligatory && !isBuildingEntered ) {
			return <Button className={"float-end"} variant={ "warning" } onClick={ handleEnterBuilding }>{t('createPolicyAddProductTariffs.isBuildingObligatory.label')}</Button>
		}
		else if ( targetIsAddressObligatory && !isAddressEntered && !isBuildingEntered ) {
			return <Button className={"float-end"} variant={ "warning" } onClick={ handleEnterAddress }>{t('createPolicyAddProductTariffs.isAddressObligatory.label')}</Button>
		}
		else {
			return <Button className={"float-end"} variant={ isSelected ? "success" : "secondary" } onClick={ handleClick }>{ isSelected ? t( 'default.remove' ) : t( 'default.add' ) }</Button>
		}
	}, [isSelected, isAddressEntered, isBuildingEntered, handleClick, handleEnterAddress, handleEnterBuilding, t, targetIsAddressObligatory, targetIsBuildingObligatory])

	const validity = configuratorService.useConfiguratorValidity( target.validFrom, target.validTo, false, policyVersionValidFrom )

	return (
		<tr>
			<td className={isBrokerTarget ? 'text-success' : ''}>
				{ target.pathAndNameAsText }
				{validity}
			</td>
			<td>{ button }</td>
		</tr>
	)
}


export { CreatePolicyAddProductTariffs };
