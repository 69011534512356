import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('damageType.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="6" controlId="formGroupDamageTypeName">
							<Form.Label>{t('damageType.name')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value={domainInstance.name || (defaultValues && defaultValues.name)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="6" controlId="formGroupDamageTypeDescription">
							<Form.Label>{t('damageType.edCause')}</Form.Label>
							<RestAsyncSelectControl
								noSelection={{label: t('damageType.edCause.noSelection'), id: ""}}
								placeholder={t('damageType.edCause.noSelection')}
								domainName={"elementalDamageCause"}
								sort={"description_sort"}
								value={ domainInstance && domainInstance.edCause && domainInstance.edCause.id }
								label={ domainInstance && domainInstance.edCause && domainInstance.edCause.label }
								name={'edCause'}
								// createable={{formElements: <ElementalDamageCause/>}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
