import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";

function ElementalDamageCause({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			text: t('elementalDamageCause.id'),
			hidden: true
		},
		{
			dataField: 'causeCode',
			text: t('elementalDamageCause.causeCode'),
			sortField: 'causeCode',
			sort: true
		},
		{
			dataField: 'description',
			text: t( 'elementalDamageCause.description' ),
			sortField: 'description_sort',
			sort: true
		},
	];

	return (
		<Index
			path={match.path}
			domainName={ 'elementalDamageCause' }
			columns={ columns }
			toString={ (object) => object.description }
			formElements={ <FormElements/> }
			sortField={'causeCode'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { ElementalDamageCause };
