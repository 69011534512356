import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";
import CreateOtherLeadershipDiscountSettlementBatch from "./CreateOtherLeadershipDiscountSettlementBatch";
import PreviewOtherLeadershipDiscountSettlementBatch from "./PreviewOtherLeadershipDiscountSettlementBatch";

function SettlementBatchCommission({match}) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('default.id'),
		hidden: true
	}, {
		dataField: 'year',
		text: t('otherLeadershipDiscountSettlementBatch.year.label'),
		sort: true,
	}, {
		dataField: 'percent',
		text: t('otherLeadershipDiscountSettlementBatch.percent.label'),
		sort: false,
		formatter: (cellContent, row) => bootstrapTableService.formatterPercent(cellContent)
	}, {
		dataField: 'createdBy',
		text: t( 'settlementBatch.createdBy.label' ),
		sort: true
	}, {
		dataField: 'createdOn',
		text: t( 'settlementBatch.createdOn.label' ),
		formatter: (cellContent, row) => bootstrapTableService.formatterDate(cellContent),
		sort: true
	}, {
		dataField: 'settlementsCount',
		text: t( 'settlementBatch.settlementsCount.label'),
		sort: true
	},
	];

	return (
		<Index
			path={match.path}
			domainName={'otherLeadershipDiscountSettlementBatch'}
			columns={columns}
			toString={['name']}
			createElement={<CreateOtherLeadershipDiscountSettlementBatch/>}
			showElement={<PreviewOtherLeadershipDiscountSettlementBatch/>}
			sortField={'createdOn'}
			sortOrder={'desc'}
			allowEdits={false}
			allowDeletions={false}
			allowShow={true}
		/>
	);
}

export default SettlementBatchCommission;
