import i18n from "i18next";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useState} from "react";
import {TariffContent} from "./TariffContent";
import {ConfiguratorEditContext} from "./contexts";
import {configuratorService} from "../_services/configurator.service";


function ConfiguratorTargetBaseContent({holderId, configuratorTarget, displayPartOfNameWarning, isBrokerTariff }) {
    const [tariffsCount] = useState(configuratorTarget.content.tariffs.length);
    const [obsoleteClass] = useState(configuratorTarget.content.obsolete ? 'obsolete-tariff' : '');
    const {showNodeDomainHolderId} = useContext(ConfiguratorEditContext)

    const [tariffs] = useState(configuratorTarget.content.tariffs.map( (tariff, index) => {
        return (
            <span className={(tariffsCount===1?"tariff-single":"tariff-multiple") + ' ' + obsoleteClass} key={"tariff-" + index}>
                <TariffContent tariff={tariff} index={index}/>
            </span>)
    }));

    const singleTariffElement = () => {
        return <span><span className={`configurator-target-content-text ${obsoleteClass}`}>{configuratorTarget.content.text}:&nbsp;</span>{tariffs}</span>
    }

    const multipleTariffsElement = () => {
        return (
            <>
                <span className={`configurator-target-content-text ${obsoleteClass}`}>{configuratorTarget.content.text}:&nbsp;</span><br/>
                <div className={`tariff-mulitiple-wrapper ${obsoleteClass}`}>{tariffs}<span className={"method-remark"}>{i18n.t('appvers.enums.ConfiguratorTargetMethod.' + configuratorTarget.content.method.name + '.tooltip')}</span></div>
            </>
        )
    }

    const noTariffElement = () => {
        return (
            <>
                <span className={`configurator-target-content-text ${obsoleteClass}`}>{configuratorTarget.content.text}:&nbsp;</span><br/>
                <span className={`tariff-mulitiple-wrapper ${obsoleteClass}`}>
                    {i18n.t('default.dependsOn')} {configuratorTarget.content.dependsOnText}
                    <span className={`${configuratorTarget.content.dependsOnInDifferentBranch ? 'text-warning' : ''}`}>
                        {configuratorTarget.content.dependsOnInDifferentBranch && <> ({i18n.t('configuratorTargetBaseContent.dependsOnInDifferentBranch.message')}) </> }
                    </span><br/>
                    {i18n.t('configuratorTarget.tariff.label')}&times;{configuratorTarget.content.ansatzDependencyFactor}<br/>
                    {i18n.t('createPolicy.detail.products.header.amount')}&times;{configuratorTarget.content.amountDependencyFactor}&nbsp;({i18n.t('configuratorNodeEditor.roundAmountToMultiple.label')}&nbsp;{configuratorTarget.content.roundAmountToMultiple})
            </span><br/>
            </>
        )
    }

    const validity = configuratorService.useConfiguratorValidity( configuratorTarget.content.validFrom, configuratorTarget.content.validTo, true )

    return (
        <div className={`configurator-target-content ${isBrokerTariff?'broker-tariff':'other-tariff'}`} key={"configurator-target-content-" + configuratorTarget.id}>
            <div>
                {showNodeDomainHolderId &&<Badge className="me-1" bg="info">{holderId}</Badge>}
               <Badge bg="dark">{configuratorTarget.id}</Badge>
            </div>
            <div>
                {displayPartOfNameWarning ? (<><FontAwesomeIcon icon={faExclamationCircle} color={"red"}/> <span className={"font-italic"} style={{color: 'red'}}>{i18n.t('configuratorNodeEditor.isPartOfTariffName.warning', {what: i18n.t('configuratorNodeEditor.isPartOfTariffName.onlabel')})}</span></>) : undefined }
            </div>
            <div>
                {tariffsCount===1?singleTariffElement():tariffsCount>0?multipleTariffsElement():noTariffElement()}
            </div>
            <div className={"mb-2"}>
                {validity}
            </div>
        </div>
    );
}

export { ConfiguratorTargetBaseContent }
