import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import restService from "../_services/rest.service";
import {SERVER_URL} from "../config";
import {alertService} from "../_services";
import headers from "../security/headers";
import {withRouter} from "react-router-dom";
import {policyService} from "../_services/policy.service";
import SubmitButton from "../_components/SubmitButton";

function CreatePolicyReview( props ) {
	const { t } = useTranslation()
	const useFormObject = useForm();
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [print, setPrint] = useState(false)

	useEffect(() => {
		if ( isSubmitting ) {
			save()
				.then( (id) => {
					if ( print ) {
						policyService.print(id)
							.then( () => {
								redirectToShow(id)
							} )
					}
					else {
						redirectToShow(id)
					}
					setIsSubmitting(false)
				} )
				.catch( error => {
					alertService.error( error )
					setIsSubmitting(false)
				} )
		}
	}, [isSubmitting]);

	const handleClickPrevious = () => {
		props.goToNamedStep('lastTouches');
	}

	const save = () => {
		return new Promise( (resolve, reject) => {
			const h = headers();
			const params = { data: JSON.stringify( props.values ) }
			fetch( `${ SERVER_URL }/api/policy/saveOrUpdateOffer`, {
				method: 'POST',
				headers: h,
				body: JSON.stringify( params )
			} )
				.then( r => restService.handleServerResponse( r.clone() ) )
				.then( json => resolve(json.policyVersionId) )
				.catch( reject );
		});
	}

	const redirectToShow = (id) => {
		console.log('/policy/show/'+id)
		props.history.push('/policy/show/'+id)
		alertService.success( t( 'default.updated', { what: t( 'policy.label' ) } ), { keepAfterRouteChange: true } );
	}

	const handleSave = () => {
		setIsSubmitting(true) //causes redraw and calls useEffect depending on isSubmitting
	}

	const handleSaveAndPrint = () => {
		setIsSubmitting(true) //causes redraw and calls useEffect depending on isSubmitting
		setPrint(true)
	}

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form>
					<Alert variant={"success"}>{t('createPolicy.review.success')}</Alert>
					<div className="create-policy-footer p-3">
						<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
						<SubmitButton isSubmitting={isSubmitting} type={"button"} onClick={handleSave} className='btn btn-default me-1'>{t('default.save')}</SubmitButton>
						<SubmitButton isSubmitting={isSubmitting} type={"button"} onClick={handleSaveAndPrint} className='btn btn-default'>{t('default.saveAndPrint')}</SubmitButton>
					</div>
				</Form>
			</FormProvider>
		</div>
	);
}

export default withRouter(CreatePolicyReview);
