import * as XLSX from 'xlsx';

const xlsxService = {
	createSheet,
	getColLetter,
}

function getColLetter(columns, columnName) {
	function colName(n) {
		var ordA = 'A'.charCodeAt(0);
		var ordZ = 'Z'.charCodeAt(0);
		var len = ordZ - ordA + 1;

		var s = "";
		while(n >= 0) {
			s = String.fromCharCode(n % len + ordA) + s;
			n = Math.floor(n / len) - 1;
		}
		return s;
	}

	return colName( columns.indexOf(columnName) )
}

function createSheet(data, fileName, totalColumns) {
	if ( data.length > 0 ) {
		const array = [];

		//column headers
		const columns = Object.keys( data[0] )
		array.push( columns )

		//data
		data.forEach( (row) => {
			array.push( Object.values( row ) )
		})

		const lastRowNr = data.length+1

		if ( totalColumns && totalColumns.length > 0 ) {
			const totals = []
			columns.forEach( column => {
				if ( totalColumns.includes(column) ) {
					const colLetter = getColLetter( columns, column )
					totals.push( { f: `SUBTOTAL(9, ${colLetter}2:${colLetter}${lastRowNr})`} )
				}
				else {
					totals.push( '' )
				}
			})
			array.push(totals)
		}

		const lastColLetter = getColLetter( columns, columns[columns.length-1])
		const ws = XLSX.utils.aoa_to_sheet(array);
		ws['!autofilter'] = { ref: `A1:${lastColLetter}${lastRowNr}` }

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, `${fileName}.xlsx`);
	}
}

export default xlsxService