import React from 'react';

function FormattedText({text}) {
    if ( text ) {
        return (
            <div>
                {text.split("\n").map((line, key) => {
                    return <div key={key}>{line}</div>
                })}
            </div>
        )
    }
    else {
        return ''
    }
}

export default FormattedText
