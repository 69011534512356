import React from 'react';
import {createContext, useState, useRef, useEffect} from "react";
import SockJS from "sockjs-client"
import {SERVER_URL} from "../config";
import * as StompJs from "@stomp/stompjs";
import store from "../store";

export const WebsocketContext = createContext(false, null, () => {});

export const WebsocketProvider = ({ children }) => {
	const [isReady, setIsReady] = useState(false);

	const ws = useRef(null);

	useEffect(() => {
		const auth = store.getState().security.auth;
		const stompClient = new StompJs.Client( {
			webSocketFactory: function () {
				return new SockJS(`${ SERVER_URL }/stomp`);
			},
			connectHeaders: {
				token: auth && auth.access_token,
			},
			debug: function (str) {
				console.log(str);
			},
			reconnectDelay: 5000,
			heartbeatIncoming: 4000,
			heartbeatOutgoing: 4000,
		})

		const disconnect = () => {
			setIsReady(false);
			stompClient.deactivate();
		}

		stompClient.onStompError = function (frame) {
			// Will be invoked in case of error encountered at Broker
			// Bad login/passcode typically will cause an error
			// Complaint brokers will set `message` header with a brief message. Body may contain details.
			// Compliant brokers will terminate the connection after any error
			console.log('Broker reported error: ' + frame.headers['message']);
			console.log('Additional details: ' + frame.body);
			disconnect();
		};

		stompClient.onConnect = function (frame) {
			setIsReady(true);
		};

		stompClient.activate();

		ws.current = stompClient;

		return () => {
			disconnect();
		};
	}, []);

	const ret = [isReady, ws.current];
	//(message) => ws.current?.publish({destination: "/app/hello", body: message})

	return (
		<WebsocketContext.Provider value={ret}>
			{children}
		</WebsocketContext.Provider>
	);
};
