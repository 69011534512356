import i18n from "i18next";

const reactHookFormService = {
	getValidationMessage
};

function getValidationMessage(fieldState, validationMessages) {
	let result = '';
	if (fieldState.error) {
		if (validationMessages && validationMessages[fieldState.error.type] ) {
			result = validationMessages[fieldState.error.type];
		}
		else {
			const keyPrefix = 'default.validationMessage'
			const key = keyPrefix + '.' + fieldState.error.type;
			result = i18n.t(key);
			if ( result === key ) {
				result = i18n.t(keyPrefix);
			}
		}
	}
	return result;
}

export default reactHookFormService