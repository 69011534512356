import React, {useState} from 'react';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tour from "reactour";
import { useSelector } from 'react-redux'
import {Parser} from 'html-to-react'

/**
 * Button starting react tour. See useTour hook for more information how to add/remove steps to/from the tour
 * @return {JSX.Element}
 * @constructor
 */
const PageTour = () => {
	const steps = useSelector((state) => {
		//by default we use only top level steps (levels are used for modal forms)

		let result = [];
		let maxLevel = 0;
		for (const id in state.tour.steps) {
			const _steps = state.tour.steps[id];
			if ( _steps.level > maxLevel) {
				result = [];
				maxLevel = _steps.level;
			}
			result = result.concat(_steps.steps);
		}

		//we want only unique steps (by 'selector' property)
		result = result.filter((v,i,a)=>a.findIndex(t=>(t.selector === v.selector))===i)

		var htmlToReactParser = new Parser();
		result = result.map( (step) => {
			return { ...step, content: htmlToReactParser.parse(step.content) };
		} );

		return result;
	})
	const [isTourOpen, setIsTourOpen] = useState(false);

	const handleTourOpen = (value) => {
		setIsTourOpen(value);
	}

	return (
		<>
			{steps.length>0 &&
			<>
				<Tour
					startAt={0} // The Tour remembers last step as 'current step'. When the amount of steps decreases below the current step, then an exception occurs during next tour. The property startAt sets the current step always to the first step.
					steps={ steps }
					isOpen={isTourOpen}
					onRequestClose={() => handleTourOpen(false)}
				/>
				<span className={"page-tour"} onClick={() => handleTourOpen(true)}><FontAwesomeIcon icon={faQuestionCircle}/></span>
			</>
			}
		</>
	);
};

export default PageTour;

