import React from 'react';
import ShowField from "../form/ShowField";
import {Row} from "react-bootstrap";

function FormElementsOtherLeadershipDiscount({settlement, onRefreshSettlement}) {
	return (
		<Row>
			<ShowField label={'creditNoteOtherLeadershipDiscount.year'} object={settlement} property={'year'} type={'text'} size={4}/>
			<ShowField label={'creditNoteOtherLeadershipDiscount.percent'} object={settlement} property={'percent'} type={'number'} appendix={' %'} size={4}/>
		</Row>
	)
}

export default FormElementsOtherLeadershipDiscount