import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import paginationFactory from "react-bootstrap-table2-paginator";
import restService from "../_services/rest.service";
import TableSearch from "../form/TableSearch";
import {domainTableService} from "../_services/domainTable.service";
import websocketService from "../_services/websocket.service";

const DomainTable = React.forwardRef(({
										  id,
										  columns,
										  getData,
										  domainName,
										  namedCriteria,
										  rowClasses,
										  sortField,
										  sortOrder,
										  sortType,
										  sizePerPage,
										  searchText,
										  searchOptions,
										  showSearchbar,
										  showPagination,
										  page,
										  onTableStateChanged,
										  expandRow,
										  rowStyle,
										  templateSuffix
	}, ref) => {
	const thisRef = useRef();
	const [_sortField, set_sortField] = useState( sortField )
	const [_sortOrder, set_sortOrder] = useState( sortOrder );
	const [_searchText, set_searchText] = useState( searchText );
	const [_searchOptions, set_searchOptions] = useState();
	const [previousSearchOptions, setPreviousSearchOptions] = useState();
	const [_sizePerPage, set_sizePerPage] = useState( sizePerPage );
	const [_page, set_Page] = useState( page );
	const internalSearchOptions = useMemo( () => {
		if ( !previousSearchOptions ) {
			setPreviousSearchOptions( searchOptions )
			return searchOptions
		}
		else if ( JSON.stringify( previousSearchOptions ) !== JSON.stringify( searchOptions ) ) {
			setPreviousSearchOptions( searchOptions )
			set_Page(1)
			return searchOptions
		}
		else if ( _searchOptions ) {
			return _searchOptions
		}
		else {
			return searchOptions
		}
	},[_searchOptions, searchOptions, previousSearchOptions, setPreviousSearchOptions, set_Page])
	const [data, setData] = useState([]);
	const [totalSize, setTotalSize] = useState(0);
	const [reloadTimeoutId, setReloadTimeoutId] = useState(undefined);

	useImperativeHandle(
		ref,
		() => ({
			onRowChanged(row) {
				const idx = data.findIndex( (r) => r.id === row.id )
				if ( idx >= 0 ) {
					data.splice( idx, 1, row )
					setData( [...data] );
				}
			},
			onRowDeleted(row) {
				const idx = data.findIndex( (r) => r.id === row.id )
				if ( idx >= 0 ) {
					loadData()
				}
			},
		}),
	)

	const loadData = useCallback( () => {
			const controller = new AbortController();

			function _getData() {
				const sfColumn = columns.find((column) => column.dataField === _sortField);
				let sf = sfColumn ? sfColumn.sortField : undefined;
				let st = sfColumn ? sfColumn.sortType : undefined;
				if (undefined === sf && sfColumn && sfColumn.sort) {
					sf = sfColumn.dataField;
				}
				let sort = undefined
				if ( sf ) {
					sort = `${ st ? ( '[' + st + ']' ) : '' }${ sf }${ _sortOrder ? ( ' ' + _sortOrder ) : '' }`
				}
				if (getData) {
					return getData(_page, _sizePerPage, sort, _searchText, internalSearchOptions, controller.signal)
				} else {
					return restService.getDomainInstancesList(domainName, _page, _sizePerPage, sort, _searchText, internalSearchOptions, namedCriteria, templateSuffix, controller.signal)
				}
			}

			_getData().then((result) => {
				setTotalSize(result.totalSize);
				setData(result.data);
			}).catch((error) => restService.handleServerErrors(error, controller.signal));

			return function cleanup() {
				controller.abort();
			}
		}, [ domainName, _page, _sizePerPage, _sortField, _sortOrder, namedCriteria, _searchText, internalSearchOptions, columns, getData, templateSuffix ]
	)

	//EXPERIMENTAL:
	// const observeIds = useMemo( () => {
	// 	return data.map( ( d ) => d.id );
	// }, [data] ) -> no observeIds in order to observe creates as well
	//timeout is here because, when for example monthly invoicing will be running, then someone, who has
	//opened list of invoices or commissions in his browser, would have received lots of changes.
	websocketService.useAfterUpdateSubscription( domainName, undefined, () => {
		clearTimeout(reloadTimeoutId);
		setReloadTimeoutId( setTimeout(loadData, 500) );
	} );

	useEffect(() => {
		thisRef.current = {refresh: loadData}
		domainTableService.register( id, thisRef );
		loadData()
		return () => {
			domainTableService.unregister( id )
		}
	}, [ loadData, id ]);

	const paginationOptions = {
		page: _page,
		sizePerPage: _sizePerPage,
		totalSize: totalSize,
	};

	const handleTableChange = (type, newState) => {
		if ( newState.searchText && _searchText !== newState.searchText ) {
			set_Page(1)
		}
		else {
			set_Page(newState.page);
		}
		set_sizePerPage(newState.sizePerPage);
		set_sortField(newState.sortField);
		set_sortOrder(newState.sortOrder);
		set_searchText(newState.searchText);
		if ( !['search','pagination', 'sort'].includes(type) ) {
			set_searchOptions(newState.searchOptions);
		}
		if ( onTableStateChanged ) {
			onTableStateChanged(newState);
		}
	}

	return (
		<ToolkitProvider
			keyField="id"
			data={ data }
			columns={ columns }
			search = { {
				defaultSearch: _searchText
			} }
		>
			{
				toolkitprops => [
					showSearchbar && <TableSearch key={"searchBar"}  {...toolkitprops.searchProps}  />,
					<BootstrapTable
						key={"table"}
						bootstrap4
						remote
						{ ...toolkitprops.baseProps }
						pagination={ showPagination && paginationFactory(paginationOptions) }
						onTableChange={ handleTableChange }
						defaultSorted={ _sortField ?
							[{
								dataField: _sortField,
								order: _sortOrder ? _sortOrder : 'desc',
							}] : undefined}
						rowClasses={ rowClasses }
						expandRow={ expandRow }
						rowStyle={ rowStyle }
					/>
				]
			}
		</ToolkitProvider>
	)
})

DomainTable.propTypes = {
	columns: PropTypes.array.isRequired,
	domainName: PropTypes.string.isRequired,
	getData: PropTypes.func,
	namedCriteria: PropTypes.object,
	rowClasses:  PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	sortField: PropTypes.string,
	sortOrder: PropTypes.string,
	sizePerPage: PropTypes.number,
	searchText: PropTypes.string,
	searchOptions: PropTypes.object,
	page: PropTypes.number,
	onTableStateChanged: PropTypes.func,
	expandRow: PropTypes.object,
	rowStyle: PropTypes.object,
	showSearchbar: PropTypes.bool,
	showPagination: PropTypes.bool,
}

DomainTable.defaultProps = {
	sizePerPage: 10,
	page: 1,
	showSearchbar: true,
	showPagination: true,
}

export default DomainTable;
