import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";

function PreviewPolicyVersionMemo( {policyVersion} ) {
	const {t} = useTranslation();
	const [memo, setMemo] = useState(policyVersion.memo);
	const [additionalText, setAdditionalText] = useState(policyVersion.additionalText);
	const [isMemoDirty, setIsMemoDirty] = useState(false);
	const [isAdditionalTextDirty, setIsAdditionalTextDirty] = useState(false);

	useEffect( () => {
		setMemo(policyVersion.memo);
		setAdditionalText(policyVersion.additionalText)
	}, [policyVersion.memo, policyVersion.additionalText, setMemo, setAdditionalText]);

	const handleMemoChanged = (value) => {
		setMemo(value);
		setIsMemoDirty(policyVersion.memo !== value);
	}

	const handleAdditionalTextChanged = (value) => {
		setAdditionalText(value);
		setIsAdditionalTextDirty(policyVersion.additionalText !== value)
	}

	const handleSave = () => {
		let data = {}

		if ( isMemoDirty ) {
			data.memo = memo;
		}

		if ( isAdditionalTextDirty ) {
			data.additionalText = additionalText;
		}

		if ( Object.keys(data).length > 0 ) {
			restService.updateDomainInstance( "policyVersion", policyVersion.id, {version: policyVersion.version, ...data} )
				.then( () => {
					setIsMemoDirty( false );
					setIsAdditionalTextDirty( false );
				} )
		}
	}

	const handleBlur = (e) => {
		/**
		 * Known problem: When a message is displayed in onBlur (even if it is just alert()) then the process of klicking
		 * is damaged. For example - Steps to reproduce:
		 * 1) change memo
		 * 2) Click on Policies in the menu => message appears
		 * 3) Click "No" => expected behaviour is, that we go to policies index. But nothing happens.
		 */

		if ( (isAdditionalTextDirty || isMemoDirty) && (!e.relatedTarget || (e.relatedTarget.name !== "saveMemoAndAdditionalText" && e.relatedTarget.name !== "memo" && e.relatedTarget.name !== "additionalText"))) {
			messageBoxService.display(t('previewPolicyVersionMemo.memoNotSaved.warning'), t('previewPolicyVersion.tab.memo'), [MessageBoxButtons.YES, MessageBoxButtons.NO])
				.then( (button) => {
					if ( button === MessageBoxButtons.YES ) {
						handleSave();
					}
					else {
						handleMemoChanged(policyVersion.memo);
						handleAdditionalTextChanged(policyVersion.additionalText);
					}
				});
		}
	}

	return (
		<>
			<Row>
				<Col md={6}>
					<Form.Group className={"mt-3"} controlId="memo">
						<Form.Label>{t("policyVersion.memo.label")}</Form.Label>
						<Form.Control
							name={"memo"}
							as="textarea" rows={10}
							value={memo || ''}
							onChange={(e) => handleMemoChanged(e.target.value)}
							onBlur={handleBlur}
						/>
					</Form.Group>
				</Col>
				<Col md={6}>
					<Form.Group className={"mt-3"} controlId="additionalText">
						<Form.Label>{t("policyVersion.additionalText.label")}</Form.Label>
						<Form.Control
							name={"additionalText"}
							as="textarea" rows={10}
							value={additionalText || ''}
							onChange={(e) => handleAdditionalTextChanged(e.target.value)}
							onBlur={handleBlur}
						/>
					</Form.Group>
				</Col>
			</Row>
			{ ( isMemoDirty || additionalText ) &&
				<Row className={ "mt-2" }>
					<Col md={ 2 }>
						<Button name={"saveMemoAndAdditionalText"} type="button" variant={ "primary" }
						        onClick={ handleSave }>{ t( 'default.save' ) }</Button>
					</Col>
				</Row>
			}
		</>
	)
}

export default PreviewPolicyVersionMemo
