import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import textService from "../_services/text.service";
import CreateSettlementBatch from "../_components/_settlementBatch/CreateSettlementBatch";
import ProfitParticipationSettlementBatchFilter from "./ProfitParticipationSettlementBatchFilter";
import SimpleTable from "../_components/SimpleTable";
import {Accordion, Card} from "react-bootstrap";
import {profitParticipationService} from "../_services/profitParticipation.service";

function CreateProfitParticipationSettlementBatch( { routeParams } ) {
	const { t } = useTranslation();
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [profitParticipationSettlementBatch] = restService.useDomainInstance('profitParticipationSettlementBatch', id, true)

	const getParticipantAccordionElement  = useCallback( (row) => {
		const getPolicyTotal = (policy) => {
			let total = policy.years.reduce((accumulator, year) => accumulator + year.amount, 0)
			if ( total < 0 ) {
				total = 0
			}
			return total
		}

		const total = row.policies.reduce((accumulator, policy) => accumulator + getPolicyTotal(policy), 0)

		const getParticipantPolicyElement = (partnerId, policy) => {
			const total = getPolicyTotal(policy);

			return <Card className={"mt-3"}>
				<Card.Header>
					<div className={ "w-100" }>
						<div className={ "float-start" }>{ policy.policy.label }</div>
						<div className={ "float-end" }>CHF { textService.formatNumber( total ) }</div>
					</div>
				</Card.Header>
				<Card.Body>
					<SimpleTable key={ `${partnerId}-${policy.policy.id}` } columns={ profitParticipationService.getSettlementColumns(true) } rows={ policy.years }/>
				</Card.Body>
			</Card>
		}

		return <Accordion.Item key={row.partner.id} eventKey={row.partner.id}>
			<Accordion.Header>
				<div className={"w-100"}>
					<div className={"float-start"}>{row.partner.label}</div>
					<div className={"float-end"}>CHF {textService.formatNumber( total )}</div>
				</div>
			</Accordion.Header>
			<Accordion.Body>
				{
					row.policies.sort( (a,b) => {
						const policyA = a.policy.label;
						const policyB = b.policy.label;

						return ( policyA < policyB ) ? -1 : ( ( policyB < policyA ) ? 1 : 0 )
					}).map( (policy) => {
						return getParticipantPolicyElement(row.partner.id, policy)
					})
				}
			</Accordion.Body>
		</Accordion.Item>
	}, []);

	const getPolicyAccordionElement = useCallback( (row) => {
		let total = row.years.reduce((accumulator, year) => accumulator + year.amount, 0)
		if ( total < 0 ) {
			total = 0
		}

		let details = []
		row.years.forEach ( (y) => {
			y.settlements.forEach( (s) => {
				details.push({
					year: y.year,
					settlement: s,
				})
			})
		} )

		return <Accordion.Item key={row.policy.id} eventKey={row.policy.id}>
			<Accordion.Header>
				<div className={"w-100"}>
					<div className={"float-start"}>{row.policy.label}</div>
					<div className={"float-end"}>CHF {textService.formatNumber( total )}</div>
				</div>
			</Accordion.Header>
			<Accordion.Body>

				<SimpleTable key={row.policy.id} columns={profitParticipationService.getSettlementColumns()} rows={row.years}/>

				<Accordion>
					<Accordion.Item  eventKey={`details-${row.policy.id}`}>
						<Accordion.Header>
							{t('tabContentProfitParticipationDetails.tab.label')}
						</Accordion.Header>
						<Accordion.Body>
							<SimpleTable key={row.policy.id} columns={profitParticipationService.getDetailsColumns()} rows={details}/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Accordion.Body>
		</Accordion.Item>
	}, [t])

	const getTable = useCallback( (rows) => {
		const policies = rows.filter( (row) => row.classSimpleName === 'profitParticipationPerPolicy' ).sort( (a,b) => {
			const policyA = a.policy.label;
			const policyB = b.policy.label;

			return ( policyA < policyB ) ? -1 : ( ( policyB < policyA ) ? 1 : 0 )
		})

		const participants = rows.filter( (row) => row.classSimpleName === 'profitParticipationPerParticipant' ).sort( (a,b) => {
			const partnerLabelA = a.partner.label;
			const partnerLabelB = b.partner.label;

			return ( partnerLabelA < partnerLabelB ) ? -1 : ( ( partnerLabelB < partnerLabelA ) ? 1 : 0 )
		})

		const policiesAccordionElements = policies.map( (row) => {
			return getPolicyAccordionElement(row)
		})

		const participantsAccordionElements = participants.map( (row) => {
			return getParticipantAccordionElement(row)
		})

		return {
			element: <>
				{policiesAccordionElements.length > 0 &&
					<Card>
						<Card.Header>
							{t('createProfitParticipationSettlementBatch.policiesCustomers')}
						</Card.Header>
						<Card.Body>
							<Accordion> { policiesAccordionElements } </Accordion>
						</Card.Body>
					</Card>}
				{participantsAccordionElements.length > 0 &&
					<Card className={"mt-3"}>
						<Card.Header>
							{t('createProfitParticipationSettlementBatch.policiesParticipants')}
						</Card.Header>
						<Card.Body>
							<Accordion> { participantsAccordionElements } </Accordion>
						</Card.Body>
					</Card>
				}
				</>,
			size: rows.length,
		}
	}, [getPolicyAccordionElement, getParticipantAccordionElement, t])

	return (
		<CreateSettlementBatch
			settlementBatch={profitParticipationSettlementBatch}
			filter={<ProfitParticipationSettlementBatchFilter />}
			getInvoicesTable={getTable}
		/>
	);
}

export default CreateProfitParticipationSettlementBatch