import React, {useCallback} from 'react';
import restService from "../_services/rest.service";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import moment from "moment/moment";

function PrintInvoicePolicyVersionWithSettlementBatch({settlement, onRefreshSettlement}) {
    const {t} = useTranslation();

    const handlePrintWithSettlementBatchOnChange =  useCallback( (value) => {
        restService.updateDomainInstance('invoicePolicyVersion', settlement.id, {printWithSettlementBatch: value})
            .catch( error => {
                restService.handleServerErrors( error )
                onRefreshSettlement()
            })
    }, [settlement.id, onRefreshSettlement])

    const onlabel = t( `printWithSettlementBatch.onlabel`, {month: moment(settlement.billingFrom).format('MMMM YYYY')} );
    const offlabel = t( `printWithSettlementBatch.offlabel` );
    const labelLength = Math.max( onlabel.length, offlabel.length );
    const isPartOfSettlementBatch = settlement.settlementBatch && settlement.settlementBatch.id != null

	return (
        <>
            {!isPartOfSettlementBatch && <Row>
                <Col>
                    <BootstrapSwitchButton
                        width={ labelLength * 10 }
                        key={ 'printWithSettlementBatch' }
                        onlabel={ onlabel }
                        offlabel={ offlabel }
                        checked={ settlement.printWithSettlementBatch }
                        size={ 'sm' }
                        onChange={ ( value ) => {
                            handlePrintWithSettlementBatchOnChange(value)
                        } }
                    />
                </Col>
            </Row>
            }
        </>
	)
}

export default PrintInvoicePolicyVersionWithSettlementBatch