import React, { useEffect, useMemo, useReducer } from 'react';
import StepWizard from "react-step-wizard";
import {useTranslation} from "react-i18next";
import {BaseData} from "./stepWizard/BaseData";
import {Details} from "./stepWizard/Details";
import damageReducer from "./damageReducer";
import moment from "moment/moment";
import restService from "../_services/rest.service";

function CreateEditDamage ( {routeParams} ) {
    const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
    const [damage] = restService.useDomainInstance('damage', id);
    const searchOptions = useMemo( () => ( {queryStrings: [{field: 'damage.id', text: `${id}`}]} ), [id] );
    const [details] = restService.useDomainInstancesList('damageDetail', undefined, undefined, undefined, undefined, searchOptions)

    const defaultDamage = {
        id: undefined,
        dateOfDamage: moment().format("YYYY-MM-DD"),
        description: '',
        policyVersion: {
            id: undefined,
            label: '',
        },
        damageEvent: {
            id: undefined,
            label: '',
        },
        damageType: {
            id: undefined,
            label: '',
        },
        // costRate: 0,
    }

    const [damageState, dispatchDamage] = useReducer(damageReducer, {
        baseData: defaultDamage,
        details: [],
        fileChanges: [],
    })

    useEffect( ()=>{
        dispatchDamage({type: 'BASE_DATA_CHANGE', data: damage});
    }, [damage])

    useEffect( ()=>{
        dispatchDamage({type: 'DETAILS_CHANGE', data: details});
    }, [details])

    const onStepChange = (stats) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const printDamageLabel = () => {
        if ( id ) {
            return t('damage.withNr.label', {number: id});
        }
        else {
            return t('damage.label');
        }
    }

    const {t} = useTranslation();
    return (
        <div>
            <h4>{printDamageLabel()}</h4>
            {<StepWizard onStepChange={onStepChange}>
                {damageState.baseData && <BaseData damageData={damageState} dispatch={dispatchDamage}/>}
                {<Details damageData={damageState} dispatch={dispatchDamage}/>}
            </StepWizard>}
        </div>
    );
}

export {CreateEditDamage}
