import React, {useMemo} from 'react';
import PreviewSettlementBatch from "../_components/_settlementBatch/PreviewSettlementBatch";
import CommissionSettlementBatchFormElements from "./CommissionSettlementBatchFormElements";

function PreviewCommissionSettlementBatch( { id } ) {
	const hiddenFields = useMemo(() => ['policyNumber', 'detail'], [])

	return (
		<PreviewSettlementBatch
			className={'commissionSettlementBatch'}
			id={ parseInt(id) }
			settlementBatchFormElements={<CommissionSettlementBatchFormElements />}
			hiddenFields={hiddenFields}
		/>
	);
}

export default PreviewCommissionSettlementBatch
