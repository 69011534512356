import React from 'react';
import {Row} from "react-bootstrap";
import ShowField from "../form/ShowField";

function OtherLeadershipDiscountSettlementBatchFormElements( {settlementBatch} ) {
	return (
        <>
            <Row>
                <ShowField label={'otherLeadershipDiscountSettlementBatch.year.label'} object={settlementBatch} property={'year'} type={'number'} size={4}/>
            </Row>
            <Row>
                <ShowField label={'otherLeadershipDiscountSettlementBatch.percent.label'} object={settlementBatch} property={'percent'} type={'number'} size={4}/>
            </Row>
        </>
    )
}

export default OtherLeadershipDiscountSettlementBatchFormElements