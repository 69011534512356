import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import {fileService} from "./file.service";
import {useEffect, useState} from "react";

export const accountingService = {
	downloadTafFile,
	useAccountingDocuments,
	getAccountingDocuments
}

function downloadTafFile(id) {
	const h = headers();
	const controller = new AbortController();
	const params = {
		id: id
	}
	fetch( `${ SERVER_URL }/api/accountingTafFile/downloadTafFile`, {
		method: 'POST',
		headers: h,
		body: JSON.stringify( params ),
		responseType: 'blob',
		signal: controller.signal,
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}

function useAccountingDocuments(ownerClassName, ownerId) {
	const [ accountingDocuments, setAccountingDocuments ] = useState([]);
	const [ accountingDocumentsLoaded, setAccountingDocumentsLoaded ] = useState(false);

	useEffect(() => {
		const controller = new AbortController();

		if ( ownerClassName && ownerId ) {
			setAccountingDocumentsLoaded( false );
			getAccountingDocuments(ownerClassName, ownerId, controller.signal)
				.then( (result) => {
					setAccountingDocuments( result )
					setAccountingDocumentsLoaded( true );
				})
				.catch( error => restService.handleServerErrors( error, controller.signal ) )
		}

		return function cleanup() {
			controller.abort();
		}
	}, [ownerClassName, ownerId]);

	return { accountingDocuments: accountingDocuments, setAccountingDocuments: setAccountingDocuments, accountingDocumentsLoaded: accountingDocumentsLoaded };
}

function getAccountingDocuments(ownerClassName, ownerId, signal) {
	return new Promise( (resolve, reject) => {
		const h = headers();

		const params = {
			ownerClassName: ownerClassName,
			ownerId: ownerId,
		}

		fetch( `${ SERVER_URL }/api/accountingTafFile/getAccountingDocuments?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: signal
		} )
			.then( r => r.json() )
			.then( result => {
				resolve( result.data );
			} )
			.catch( reject );
	})
}