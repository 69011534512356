import i18n from "i18next";
import textService from "../_services/text.service";

const editableTableService = {
	getValue: getValue,
	getSum: getSum,
	parseInputTypeValue: parseInputTypeValue
};

function parseInputTypeValue( inputType, value ) {
	let error = undefined;
	let regex = '';
	let patternError = '';
	let convertedValue = undefined;
	let val;

	switch ( inputType ) {
		case "integer":
			regex = /^[0-9]*$/;
			patternError = i18n.t( "input.integer.validation.error" ); //"Integer number expected."
			val = String(value)
			break;
		case "double":
			regex = /^[0-9]+([\\.,][0-9]+)?$/;
			patternError = i18n.t( "input.double.validation.error" ); //"Decimal number expected."
			if ( typeof value === 'number' ) {
				val = textService.numberToString( value )
			}
			else {
				val = value
			}
			break;
		default:
			val = value;
			break;
	}

	if ( regex ) {
		if ( val && !regex.test( val ) ) {
			error = patternError;
		}
		else {
			switch ( inputType ) {
				case "integer":
					convertedValue = parseInt(val);
					break;
				case "double":
					if ( typeof val === 'string' ) {
						val = val.replace(',', '.');
					}
					convertedValue = parseFloat(val);
					break;
				default:
					break;
			}
		}
	}

	return {error: error, convertedValue: (convertedValue===undefined)?val:convertedValue, stringValue: val};
}

function getValue(column, rawValue) {
	let value;
	let error;
	let rw = (rawValue===''?null:rawValue);
	switch ( column.input.tag ) {
		case 'input':
		case 'label':
		case 'typeahead':
		case 'numericFormat':
			const validationResult = parseInputTypeValue( column.input.type, rw )
			if (!validationResult.error) {
				value = validationResult.convertedValue;
			}
			else {
				error = validationResult.error;
				value = rw;
			}
			break;
		case 'check':
			value = !!rw;
			break;
		case 'select':
			if ( column.input.enumType ) {
				value = { enumType: column.input.enumType, name: rw };
			}
			else {
				value = column.input.values.find( (item) => String(item.id) === rw );
			}
			break;
		default:
			break;
	}
	return {error: error, value: value};
}

function getSum(data, columns, columnId) {
	const column = getColumn(columns, columnId);
	return data
		.filter((row) => !(row.deleted===true))
		.reduce( (sum, row) => (sum + editableTableService.getValue( column, row[columnId] ).value), 0 );
}

function getColumn(columns, id) {
	return columns.find( (column) => column.id === id );
}

export default editableTableService;
