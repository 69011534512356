import React from 'react';
import {Table} from "react-bootstrap";
import AccountingRecord from "./AccountingRecord";
import {useTranslation} from "react-i18next";
import textService from "../_services/text.service";
import {Link} from "react-router-dom";

function AccountingDocument( {accountingDocument} ) {
	const {t} = useTranslation()

	return (
        <Table className={'accounting-document-table'}>
	        <thead>
	            <tr>
		            <th>{t('accountingDocument.accountingTafFile.label')}</th>
		            <th>{t('accountingDocument.event.label')}</th>
		            <th>{t('accountingDocument.origin.label')}</th>
		            <th>{t('accountingDocument.nr.label')}</th>
		            <th>{t('accountingDocument.documentDate.label')}</th>
	            </tr>
	        </thead>
	        <tbody>
		        <tr>
			        <td>{ accountingDocument.accountingTafFile ?
				        <Link to={`/accountingTafFile/show/${accountingDocument.accountingTafFile.id}`} target={"_blank"} rel={"noopener noreferrer"}>
					        { textService.format(accountingDocument.accountingTafFile.createdOn, 'datetime') }
				        </Link>
				        : 'Noch nicht exportiert' }</td>
					<td>{ textService.format( accountingDocument.event, 'appvers.enums.AccountingDocumentEvent' ) }</td>
					<td>{ textService.format( accountingDocument.origin, 'appvers.enums.AccountingDocumentOrigin' ) }</td>
					<td>{ accountingDocument.nr }</td>
					<td>{ textService.format( accountingDocument.documentDate, 'date' ) }</td>
		        </tr>
		        <tr>
			        <td colSpan={4}>
				        <Table className={"ms-3 accounting-record-table"}>
					        <thead>
					        <tr>
						        <th>{t('accountingRecord.date.label')}</th>
						        <th>{t('accountingRecord.account.label')}</th>
						        <th>{t('accountingRecord.type.label')}</th>
						        <th>{t('accountingRecord.amount.label')}</th>
						        <th>{t('accountingRecord.text.label')}</th>
					        </tr>
					        </thead>
					        <tbody>
			                    {accountingDocument.accountingRecords.map( (accountingRecord) => <AccountingRecord key={accountingRecord.id} accountingRecord={accountingRecord} /> )}
					        </tbody>
				        </Table>
			        </td>
		        </tr>
	        </tbody>
        </Table>
	)
}

export default AccountingDocument