import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import Attachments from "../_components/Attachments";
import FileUploader from "../_components/FileUploader";
import {getEnumType, UserAttachmentType} from "../_enum/enum";

function FormElements({domainInstance, isAddMode}) {
	const { t } = useTranslation();
	const [attachmentsData, setAttachmentsData] = useState([])
	const [fileChanges, setFileChanges] = useState({});

	useEffect( () => {
		const getAttachmentsData = () => {
			if ( domainInstance && domainInstance.attachments ) {
				return domainInstance.attachments.map((attachmentsData) => {
					return attachmentsData
				})
			}
			else {
				return []
			}
		}

		setAttachmentsData( getAttachmentsData() );
	},[domainInstance]);

	const filesCount = useMemo( ()=> {
		return attachmentsData.length - Object.values(fileChanges).filter( (fc) => {
			return fc.action === 'delete'
		}).length
	}, [attachmentsData, fileChanges])

	const onFileChange = useCallback( (_fileChanges) => {
		setFileChanges(_fileChanges)
	}, [setFileChanges])
	
	return (
		<>
			<Card className="mb-3">
				<Card.Header>{t('user.card.common')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group controlId="formGroupUserUsername">
							<Form.Label>{t('user.username.label')}</Form.Label>
							<FormControl
								name={"username"}
								type={"text"}
								value= {domainInstance.username}
								rules={{
									required: true
								}}
								autoComplete={"off"}
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group controlId="formGroupUserName">
							<Form.Label>{t('user.nameOfUser.label')}</Form.Label>
							<FormControl
								name={"nameOfUser"}
								type={"text"}
								value= {domainInstance.nameOfUser}
								autoComplete={"off"}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group controlId="formGroupPassword">
							<Form.Label>{t('user.password.label')}</Form.Label>
							<FormControl
								autoComplete={"new-password"}
								name={"password"}
								type={"password"}
								value= {domainInstance.password}
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.enabled}
								offlabel={t("user.enabled.offlabel")}
								onlabel={t("user.enabled.onlabel")}
								onstyle={'success'}
								offstyle={'danger'}
								name={"enabled"}
							/>
						</Col>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.accountExpired}
								offlabel={t("user.accountExpired.offlabel")}
								onlabel={t("user.accountExpired.onlabel")}
								onstyle={'danger'}
								offstyle={'success'}
								name={"accountExpired"}
							/>
						</Col>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.accountLocked}
								offlabel={t("user.accountLocked.offlabel")}
								onlabel={t("user.accountLocked.onlabel")}
								onstyle={'danger'}
								offstyle={'success'}
								name={"accountLocked"}
							/>
						</Col>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.passwordExpired}
								offlabel={t("user.passwordExpired.offlabel")}
								onlabel={t("user.passwordExpired.onlabel")}
								onstyle={'warning'}
								name={"passwordExpired"}
							/>
						</Col>
					</Row>
					<Card className={"mt-3"}>
						<Card.Header>
							{t('user.signature')}
						</Card.Header>
						<Card.Body>
						<Row className={"ps-3 pe-3"}>
							<Attachments attachments={attachmentsData} changesPropertyName={'fileChanges'} showImages={true} onFileChange={onFileChange}/>
						</Row>
						{ filesCount === 0 &&
							<Row>
								<Form.Group as={ Col } md="12" controlId="groupFileChanges">
									<Form.Label>{ t( 'uploadFiles.label' ) }</Form.Label>
									<FileUploader changesPropertyName={ 'fileChanges' } maxFiles={1} additionalFileProperties={{userAttachmentType: getEnumType( "appvers.enums.UserAttachmentType", UserAttachmentType.SIGNATURE )}}/>
								</Form.Group>
							</Row>
						}
						</Card.Body>
					</Card>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
