import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('damageEvent.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="6" controlId="formGroupEventDateTime">
							<Form.Label>{t('damageEvent.eventDate')}</Form.Label>
							<FormControl
								name={"eventDate"}
								type={"date"}
								value={domainInstance.eventDate || (defaultValues && defaultValues.eventDate)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="6" controlId="formGroupEventName">
							<Form.Label>{t('damageEvent.eventName')}</Form.Label>
							<FormControl
								name={"eventName"}
								type={"text"}
								value={domainInstance.eventName || (defaultValues && defaultValues.eventName)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="12" controlId="formGroupDescription">
							<Form.Label>{t('damageEvent.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								as="textarea"
								value={domainInstance.description || (defaultValues && defaultValues.description)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
