import React from 'react';
import ShowField from "../form/ShowField";
import {Row} from "react-bootstrap";

function FormElementsDamagePayment({settlement, onRefreshSettlement}) {
	return (
        <>
            <Row>
	            <ShowField
		            label={'damage.label'}
		            object={settlement}
		            property={'damage.label'}
		            type={'text'}
		            link={{domain: 'damage', action: 'show', newTab:true}}
	            />
            </Row>
        </>
	)
}

export default FormElementsDamagePayment