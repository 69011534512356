import React from 'react'
import {useForm, FormProvider} from "react-hook-form";
import {FormElements} from "../FormElements";
import {Navigation} from "./Navigation";
import {Form} from "react-bootstrap";


function BaseData( {damagePaymentInfoData, dispatch, ...props } ) {
    const methods = useForm();

    const handleFormSubmit = (data) => {
        console.log('Base form submit', data);
        dispatch({type: 'BASE_DATA_CHANGE', data});
        props.nextStep();
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <FormElements domainInstance={ damagePaymentInfoData.baseData }/>
                <Navigation damagePaymentInfoData={damagePaymentInfoData} {...props}/>
            </Form>
        </FormProvider>
    )
}

export {BaseData}
