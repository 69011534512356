import React, {useMemo} from "react";
import {Settlements} from "../settlement/Settlements";

function PreviewPolicyVersionPolicyVersionSettlements( {policyVersion}) {
	const searchOptions = useMemo( () => {
		return (
			{
				queryStrings: [{field: 'policyVersion.id', text: `${policyVersion.id}`}],
				targetGroup: 'PolicyVersionSettlement'
			}
		)}, [policyVersion] );

	return (
		<>
			<div  className={'mt-3'}>
				{(policyVersion && policyVersion.id) ? <Settlements searchOptions={searchOptions} hiddenFields={['policyNumber']}/> : null}
			</div>
		</>

	)
}

export default PreviewPolicyVersionPolicyVersionSettlements
