import React, {useMemo} from 'react'
import {PolicyTarget} from "./PolicyTarget";
import {Badge, Card, Col, Row} from "react-bootstrap";
import {t} from "i18next";
import textService from "../_services/text.service";
import {policyService} from "../_services/policy.service";

function PolicyTargets({targets, onChanged}) {
    const feeTotal = useMemo(() => {
        let result = 0
        targets.forEach((target) => {
            result += target.indexedFeeTotalCurrentYear
        })
        return policyService.round20(result);
    }, [targets])

    const getFeeTotalElement = () => {
        return (
            <Row>
                <Col md={12}>
                    <h5>
                       <Badge pill bg="success">
                           Total { textService.formatNumber(feeTotal) } CHF
                        </Badge>
                    </h5>
                </Col>
            </Row>
        )
    }

    return (
        <Card className={"mb-2"}>
            <Card.Header>{t('createPolicy.detail.products.header')} {getFeeTotalElement()}</Card.Header>
            <Card.Body>
                <Row>
                    <Col md={12}>
                        <div>
                            {targets.map( (target) => {
                                return <PolicyTarget key={target.key} target={target} onChanged={onChanged}/>
                            })}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export {PolicyTargets}
