import React, {useEffect, useMemo, useReducer} from 'react';
import restService from "../_services/rest.service";
import moment from "moment";
import {useTranslation} from "react-i18next";
import StepWizard from "react-step-wizard";
import damagePaymentInfoReducer from "./damagePaymentInfoReducer";
import {BaseData} from "./stepWizard/BaseData";
import {Details} from "./stepWizard/Details";

function CreateEditDamagePaymentInfo({damageId, id}) {
    // const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
    const [damage] = restService.useDomainInstance('damage', damageId);
    const [damagePaymentInfo] = restService.useDomainInstance('damagePaymentInfo', id);

    const searchOptions = useMemo( () => ( {queryStrings: [{field: 'damagePaymentInfo.id', text: `${id}`}]} ), [id] );
    const [details] = restService.useDomainInstancesList('damagePaymentInfoDetail', undefined, undefined, undefined, undefined, searchOptions)

    const defaultDamagePaymentInfo = {
        id: undefined,
        damage: {
            id: damageId,
        },
        partner: {
            id: undefined,
            label: '',
        },
        paymentInfoDate: moment().format("YYYY-MM-DD"),
        description: '',
        // costRate: 0,
    }

    const [damagePaymentInfoState, dispatchDamagePaymentInfo] = useReducer(damagePaymentInfoReducer, {
        baseData: defaultDamagePaymentInfo,
        details: [],
    })


    useEffect( ()=>{
        if ( Object.keys(damagePaymentInfo).length > 0 ) {
            dispatchDamagePaymentInfo({
                type: 'BASE_DATA_CHANGE', data: damagePaymentInfo
            });
        }
        if ( Object.keys(damage).lenght > 0 ) {
            dispatchDamagePaymentInfo({
                type: 'BASE_DATA_CHANGE', data: {damage: damage}
            });
        }
    }, [damage, damagePaymentInfo])


    useEffect( ()=>{
        dispatchDamagePaymentInfo({type: 'DETAILS_CHANGE', data: details});
    }, [details])

    const onStepChange = (stats) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const printLabel = () => {
        let whatId;
        if ( damagePaymentInfo && damagePaymentInfo.damage && damagePaymentInfo.damage.id ) {
            whatId = damagePaymentInfo.damage.id
        }
        else if ( damageId ) {
            whatId = damageId
        }
        return t('damagePaymentInfo.withDamage.label', {what: whatId});
    }

    const {t} = useTranslation();

    return (
        <div>
            <h4>{printLabel()}</h4>
            {<StepWizard onStepChange={onStepChange}>
                {damagePaymentInfoState.baseData && <BaseData damagePaymentInfoData={damagePaymentInfoState} dispatch={dispatchDamagePaymentInfo}/>}
                {<Details damagePaymentInfoData={damagePaymentInfoState} dispatch={dispatchDamagePaymentInfo}/>}
            </StepWizard>}
        </div>
    );

}

export default CreateEditDamagePaymentInfo
