import React, {useMemo} from 'react';
import {DamageSettlements} from "./DamageSettlements";

function ShowDamagePaymentInvoices({damage}) {
    const searchOptions = useMemo( () => {
        return {
            queryStrings: [{ field: 'damage.id', text: `${ damage.id }` }],
            targetGroup: 'DamageSettlement',
        }
    }, [damage.id] );

    return (
        <>
            <div>
                {(damage && damage.id) ? <DamageSettlements searchOptions={searchOptions}/> : null}
            </div>
        </>
    )
}

export default ShowDamagePaymentInvoices
