import {useEffect, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";

const settingService = {
	useSetting
}

function useSetting( settingNames ) {
	const [values, setValues] = useState( {} );
	const [_settingNames] = useState(settingNames); //workaround for solving of an infinite loop in useEffect(..., [settingNames])

	useEffect(() => {
		const controller = new AbortController();
		const h = headers();

		const params = {
			settingNames: JSON.stringify(_settingNames)
		}

		fetch( `${ SERVER_URL }/api/setting/getSettings?` + new URLSearchParams(params), {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( r => r.json() )
			.then( values => {
				setValues( values );
			} )
			.catch( error => restService.handleServerErrors( error, controller.signal ));
		return function cleanup() {
			controller.abort();
		}
	}, [_settingNames]);

	return values;
}

export default settingService
