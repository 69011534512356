import React from 'react';
import {Col, Form} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as ZipFormElements} from "../zip/FormElements";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

function Address( {namePrefix, defaultStreet, defaultZip} ) {
    const {t} = useTranslation()
    const useFormObject = useForm();

	return (
        <>
            <Form.Group as={Col} md="12" controlId={`group${namePrefix}Street`} className={"mb-3"}>
                <Form.Label>
                    {t('address.street')}
                </Form.Label>
                <FormControl
                    name={`${namePrefix}Street`}
                    type={"text"}
                    value={defaultStreet}
                    validationMessages={{
                        incompleteAddress: t('default.validationMessage.incompleteAddress'),
                    }}
                    rules={{
                        validate: {
                            incompleteAddress: (v) => {
                                const street = !!v;
                                const zip = !!useFormObject.getValues(`${namePrefix}Zip`);
                                return street || (zip && street) || (!zip && !street);
                            }
                        }
                    }}
                />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId={`group${namePrefix}Zip`}>
                <Form.Label>
                    {t('address.zip')}
                </Form.Label>
                <RestAsyncSelectControl
                    domainName={"zip"}
                    sort={"place_sort"}
                    name={`${namePrefix}Zip`}
                    value={defaultZip && defaultZip.id}
                    label={defaultZip && defaultZip.label}
                    createable={{formElements: <ZipFormElements/>}}
                    validationMessages={{
                        incompleteAddress: t('default.validationMessage.incompleteAddress'),
                    }}
                    rules={{
                        validate: {
                            incompleteAddress: (v) => {
                                const street = !!useFormObject.getValues(`${namePrefix}Street`);
                                const zip = !!v;
                                return zip || (zip && street) || (!zip && !street);
                            }
                        }
                    }}
                />
            </Form.Group>
        </>
	)
}

Address.defaultProps = {
    namePrefix: ''
}

export default Address