import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LogoAppvers from "./_components/LogoAppvers";

const Home = () => {
	const { t } = useTranslation()

	return (
		<>
			{/*<LogoAppvers greyscale={false}/>*/}
			<Row>
				<Col>
					<h1 className="display-4">{t('login.jumbotron.title')}</h1>
					<p className="lead">{t('login.jumbotron.intro')}</p>
				</Col>
			</Row>
		</>
	);
};

export default Home;

