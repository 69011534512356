import React, {useCallback} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

function InvoiceAdditionalInfoEditor({show, setShow, rabatt, setRabatt, rabattText1, setRabattText1, rabattText2, setRabattText2, additionalInfo, setAdditionalInfo, onSubmit}) {
	const {t} = useTranslation();

	const handleSubmit = useCallback((event) => {
		event.preventDefault();
		setShow(false)
		onSubmit();
	}, [setShow, onSubmit])

	const handleOnCancel = useCallback(() => {
		setShow(false);
	}, [setShow]);

	return (
		<Modal show={show} >
			<Modal.Header>
				{t('policyVersionSettlementBatch.additionalInfo.label')}
			</Modal.Header>
			<Modal.Body>
				<Form className="login-form" onSubmit={handleSubmit}>
					<Row>
						<Form.Group as={ Col } controlId="rabatt">
							<Form.Label>{t("policyVersionSettlementBatch.rabatt.label")}</Form.Label>
							<InputGroup>
								<Form.Control
									name="rabatt"
									type="number"
									value={ rabatt || '' }
									onChange={ (e) => setRabatt( parseInt(e.target.value) ) }
								/>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group as={ Col } controlId="rabattText1">
							<Form.Label>{t("policyVersionSettlementBatch.rabattText1.label")}</Form.Label>
							<Form.Control
								name="rabattText1"
								type="text"
								value={ rabattText1  || '' }
								onChange={ (e) => setRabattText1( e.target.value ) }
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group as={ Col } controlId="rabattText2">
							<Form.Label>{t("policyVersionSettlementBatch.rabattText2.label")}</Form.Label>
							<Form.Control
								name="rabattText2"
								type="text"
								value={ rabattText2  || '' }
								onChange={ (e) => setRabattText2( e.target.value ) }
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group as={ Col } controlId="additionalInfo">
							<Form.Label>{t("policyVersionSettlementBatch.additionalInfo.label")}</Form.Label>
							<Form.Control
								name="additionalInfo"
								type="text"
								value={ additionalInfo  || '' }
								onChange={ (e) => setAdditionalInfo( e.target.value ) }
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-5"}>
						<Row>
							<Col>
								<Button variant={'primary'} type={'submit'} >{t('default.update')}</Button>
								<Button className={'ms-2'} variant={'secondary'} onClick={ handleOnCancel }>{t('default.cancel')}</Button>
							</Col>
						</Row>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	)
}

export default InvoiceAdditionalInfoEditor

InvoiceAdditionalInfoEditor.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	rabatt: PropTypes.number,
	setRabatt: PropTypes.func.isRequired,
	rabattText1: PropTypes.string,
	setRabattText1: PropTypes.func.isRequired,
	rabattText2: PropTypes.string,
	setRabattText2: PropTypes.func.isRequired,
	additionalInfo: PropTypes.string,
	setAdditionalInfo: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}