let refMessageBox = null;
let isDisplayed = false;

export const messageBoxService = {
	register,
	display
}

function register(ref) {
	refMessageBox = ref;
}

/**
 * Displays a messagebox
 * @param content text or element to be shown in the messagebox
 * @param content title of the messagebox
 * @param buttons Array of MessageBoxButtons to be shown
 * @param options Object containing additional options: headerClassName = className parameter of Modal.Header
 * @return Promise to be used .then((button) => {...}) where button is one of MessageBoxButtons
 */
function display( content, title, buttons, options = {} ) {
	if ( isDisplayed ) {
		throw new Error('Trying to display MessageBox before closing previous one.');
	}
	return new Promise ((resolve) => {
		const callback = (button) => {
			isDisplayed = false;
			resolve(button)
		}
		isDisplayed = true;
		refMessageBox.current.display(content, buttons, title, options.headerClassName, callback);
	})
}