import React from 'react';


function FormElements({domainInstance}) {
	return (
		<>
			<p> Daten zum Buchen werden hier angezeigt. Vielleicht einige optionen? </p>
		</>
	);
}

export { FormElements };
