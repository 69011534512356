import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";

function Zip({ match }) {
	const { t } = useTranslation();
	const columns = [
		{
			dataField: 'id',
			text: t('zip.id'),
			hidden: true
		}, {
			dataField: 'zip',
			text: t('zip.zip'),
			sort: true
		}, {
			dataField: 'place',
			text: t('zip.place'),
			sortField: 'place_sort',
			sort: true
		},
		{
			dataField: 'country',
			text: t('zip.country'),
			sort: true,
			sortField: 'country.name_sort',
			formatter: (cell, row) => cell['label'],
		},
		{
			dataField: 'county',
			text: t('zip.county'),
			sort: true,
			sortField: 'county.name',
			formatter: (cell, row) => cell['label']
		}
	];

	return (
		<Index
			path={match.path}
			domainName={'zip'}
			columns={columns}
			toString={['zip', 'county.name', 'country.name']}
			formElements={<FormElements/>}
			sortField={'zip'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { Zip };
