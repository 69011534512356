import React from 'react';
import ShowField from "../form/ShowField";
import {Row} from "react-bootstrap";

function FormElementsPolicyVersion({settlement, onRefreshSettlement}) {
	return (
		<Row>
			<ShowField label={'creditNoteCommission.year'} object={settlement} property={'year'} type={'text'} size={4}/>
		</Row>
	)
}

export default FormElementsPolicyVersion