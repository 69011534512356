import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {configuratorService} from "../_services/configurator.service";
import {alertService} from "../_services";
import {useTranslation} from "react-i18next";
import useTour from "../tour/useTour";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SERVER_URL} from "../config";
import headers from "../security/headers";
import {saveAs} from "file-saver";
import moment from "moment";
import {ChildrenNodesElements} from "./ChildrenNodesElements";
import {ConfiguratorEditContext} from "./contexts";
import settingService from "../_services/setting.service";
import restService from "../_services/rest.service";

function ConfiguratorEdit() {
    const [configuratorNodesData, setConfiguratorNodesData] = useState(undefined);
    const {t} = useTranslation()
    const [ showConfiguratorNodeId, setShowConfiguratorNodeId ] = useState(false)
    const [ showNodeDomainHolderId, setShowNodeDomainHolderId ] = useState(false)
    const [ showDomainId, setShowDomainId ] = useState(false)
    const {_showConfiguratorEditorDebug} = settingService.useSetting(['_showConfiguratorEditorDebug'] )

    useTour([
        {selector: ".tour-configurator-edit-header", content: t("tour.configurator-edit.header")}
    ])

    const onShowConfiguratorNodeIdChange = (e) => {
        setShowConfiguratorNodeId(e.target.checked);
    }

    const onShowNodeDomainHolderIdChange = (e) => {
        setShowNodeDomainHolderId(e.target.checked);
    }

    const onShowDomainIdChange = (e) => {
        setShowDomainId(e.target.checked);
    }

    useEffect(() => {
        const controller = new AbortController()
        configuratorService.getConfiguratorNodesData(null, controller.signal).then(nodes => {
            setConfiguratorNodesData(nodes);
        }).catch(errMsg => {
            if (!controller.signal.aborted) {
                alertService.error(errMsg);
            }
        });
        return function cleanup() {
            controller.abort();
        }
    }, []);

    const handlePrint = (event) => {
        const h = headers();
        h['Content-Type'] = 'application/pdf';

        fetch(`${SERVER_URL}/api/configurator/getTariffsReport`, {
            method: 'GET',
            headers: h,
            responseType: 'blob'
        })
            .then( response => restService.handleServerResponse( response ) )
            .then(blob => saveAs(blob, `tariffs-${moment().format('YYYY-MM-DD-HH-mm')}.pdf`))
            .catch( (error) => restService.handleServerErrors( error ) );
    }

    return (
        <ConfiguratorEditContext.Provider value={{
            showConfiguratorNodeId: showConfiguratorNodeId,
            showNodeDomainHolderId: showNodeDomainHolderId,
            showDomainId: showDomainId
        }}>
            <Row>
                <Col>
                    <Card className={"mb-2 tour-configurator-edit-header"}>
                        <Card.Header>
                            <span className="me-2">
                                <Button size={"sm"} variant={'dark'}
                                        onClick={handlePrint}><FontAwesomeIcon icon={faPrint}/></Button>
                            </span>
                            <span>{t('configurator.editor.card.header')}</span>
                            <div>
                            {_showConfiguratorEditorDebug === true && <Form className="float-end">
                                <Form.Check inline type="switch"
                                            checked={showConfiguratorNodeId}
                                            id="showConfiguratorNodeId"
                                            onChange={onShowConfiguratorNodeIdChange}
                                            label={t('configurator.editor.switch.showConfiguratorNodeId')}/>
                                <Form.Check inline type="switch"
                                            checked={showNodeDomainHolderId}
                                            id="showNodeDomainHolderId"
                                            onChange={onShowNodeDomainHolderIdChange}
                                            label={t('configurator.editor.switch.showNodeDomainHolderId')}/>
                                <Form.Check inline type="switch"
                                            checked={showDomainId}
                                            id="showDomainId"
                                            onChange={onShowDomainIdChange}
                                            label={t('configurator.editor.switch.showDomainId')}/>
                                </Form>}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <ChildrenNodesElements childrenData={configuratorNodesData}
                                                   parentLoeschfuenferRelevant={false}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ConfiguratorEditContext.Provider>
    );
}

export {ConfiguratorEdit};
