import React, {useCallback, useMemo} from 'react';
import PropTypes from "prop-types";
import {Col, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import appService from "../_services";
import textService from "../_services/text.service";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";
import Editable from "../_components/Editable";

function ShowField({label, labelAppendix, object, property, size, value, type, appendix, link, children, className, editable}) {
	const {t} = useTranslation();

	const _value = useMemo( () => {
		let result = '';

		if ( children ) {
			result = children;
		}
		else {
			if (value) {
				result = value;
			} else {
				const val = appService.nullSafeGet(object, property);
				if (undefined !== val && null !== val) {
					result = textService.format(val, type)
				} else {
					result = '-';
				}
			}
			if ( appendix ) {
				result = result + appendix;
			}
		}
		return result
	}, [value, object, property, type, appendix, children])

	const linked = useCallback( (content) => {
		if ( link ) {
			let linkParams = {}
			if ( link.newTab ) {
				linkParams.target = "_blank"
				linkParams.rel = "noopener noreferrer"
			}

			var to = undefined;

			if ( link.to ) {
				to = link.to
			}
			else if ( link.domain && link.action ) {
				to = restService.getTo( appService.nullSafeGet( object, link.domain ), link.action )
			}

			if ( to ) {
				return <Link to={ to } { ...linkParams }>
					{ content }
				</Link>
			}
			else {
				return content
			}
		}
		else {
			return content
		}
	}, [link, object])

	const element = useMemo(() => {
		if ( editable && object && object.id ) {
			return (
				<Editable editFormElement={editable && editable.editFormElement} onSave={editable && editable.onSave}>
					{ linked( _value ) }
				</Editable>
			)
		}
		else {
			return linked( _value )
		}
	}, [ editable, _value, linked ])

	return (
		<Form.Group as={Col} md={size} className={className ? className : 'mb-3'}>
			<div>{t(label)}{labelAppendix?labelAppendix:''}</div>
			<div className={'fw-bold'}>
				{element}
			</div>
		</Form.Group>
	)
}

ShowField.propTypes = {
	label: PropTypes.string,
	object: PropTypes.object,
	property: PropTypes.string,
	value: PropTypes.oneOfType( [PropTypes.string, PropTypes.element] ),
	type: function(props, propName, componentName) {
		if ( ( props['value'] === undefined && props['children'] === undefined ) && !/text|date|datetime|boolean|number|appvers\.enums.\.*/.test(props[propName])) {
			return new Error(
				'Invalid prop `' + propName + '` (' + props[propName] + ') supplied to' +
				' `' + componentName + '`. Validation failed.'
			)
		}
	},
	labelAppendix: PropTypes.oneOfType( [PropTypes.string, PropTypes.element] ),
	appendix: PropTypes.string,
	size: PropTypes.number,
	link: PropTypes.object,
	editable: PropTypes.shape({
		editFormElement: PropTypes.element.isRequired,
		onSave: PropTypes.func.isRequired,
	})
};

ShowField.defaultProps = {
	size: 12
};

export default ShowField
