import React from "react";
import MenuItem from "./MenuItem";

export default function MenuItems({level, routes}) {
    const getLevelClasses = () => {
        if ( level === undefined ) {
            return "nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
        }
        else {
            return `nav flex-column ms-${level}`
        }
    }

    return (
        <ul className={getLevelClasses()} id="menu">
            {routes.map( (route, index ) => (
                <MenuItem level={level? level: 0} key={index} route={route}/>
            ))}
        </ul>
    )
}
