import React, {useMemo} from 'react'
import {Badge} from "react-bootstrap";

function ChangelogItem( {issue} ) {
	const stateClass = useMemo( () => {
		switch( issue.state ) {
			case 'To do':
				return 'todo'
			case 'In progress':
				return 'in-progress'
			case 'To be α-tested':
				return 'to-be-alpha-tested'
			case 'To be merged':
				return 'to-be-merged'
			case 'To be β-tested':
				return 'to-be-beta-tested'
			case 'Resolved':
				return 'resolved'
			default:
		}
	}, [issue.state])

	return (
		<>
			<tr>
				<td className={"small"} style={{width:'110px'}}><a href={`https://youtrack.bsa-soft.cz/issue/${issue.id}`} target={"_blank"} rel={"noreferrer"}>{issue.id}</a></td>
				<td className={"small"}>{issue.assignee}<br/><Badge className={`changelog-item-state ${stateClass}`}>{issue.state}</Badge></td>
				<td className={"small"}>{issue.summary}</td>
			</tr>
		</>
	)
}

export default ChangelogItem