import React, {useMemo} from 'react';
import {Row} from "react-bootstrap";
import ShowField from "../form/ShowField";

function DiscountSurcharge({policyTarget}) {

    const discountSurcharge = useMemo( () => {
        let result = []
        if ( policyTarget && policyTarget.discountSurcharge ) {
            for( const dsKey in policyTarget.discountSurcharge ) {
                const dsItem = policyTarget.discountSurcharge[dsKey];
                result.push(
                    (<Row key={dsItem.name}>
                        <ShowField label={dsItem.name} object={{listBoxLabel: dsItem.listBoxLabel}}
                                   property={'listBoxLabel'}
                                   type={'text'} size={12}/>
                    </Row>)
                )
            }
        }
        return result;
    }, [policyTarget]);

    return (
        <>
            {discountSurcharge}
        </>
    )
}

export default DiscountSurcharge
