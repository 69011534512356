import {Button, useAccordionButton} from "react-bootstrap";

export default function CustomPanelToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {

        }
    );

    return (
        <Button variant={"link"}
            onClick={decoratedOnClick}
        >
            {children}
        </Button>
    );
}
