import {Badge} from "react-bootstrap";
import React, {useContext} from "react";
import {TariffContent} from "./TariffContent";
import {ConfiguratorEditContext} from "./contexts";
import {configuratorService} from "../_services/configurator.service";


function ConfiguratorTargetDecIncContent({holderId, domain}) {
    const {showNodeDomainHolderId, showDomainId} = useContext(ConfiguratorEditContext)


    const getTariffContent = () => {
        let tariffs = domain.content.tariffs.map( (tariff, index) => {
                return <TariffContent tariff={tariff} index={index} decInc={true}/>
            });
        return tariffs
    }

    const validity = configuratorService.useConfiguratorValidity( domain.validFrom, domain.validTo, true )

    return (
        <div className={ "configurator-target-content" } key={ "configurator-target-content-" + domain.id }>
            { showNodeDomainHolderId && <Badge bg="info">{ holderId }</Badge> }
            { showDomainId && <Badge bg="warning">{ domain.id }</Badge> }
            { getTariffContent() }
            <div className={ "mb-2" }>
                { validity }
            </div>
        </div>
    );
}

export {ConfiguratorTargetDecIncContent}
