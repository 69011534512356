import React, {useMemo} from 'react';
import DamagePaymentInfoDetails from "../damagePaymentInfoDetail/DamagePaymentInfoDetails";

function ShowDamagePaymentInfoDetails({damagePaymentInfo}) {
    const searchOptions = useMemo( () => ( {queryStrings: [{field: 'damagePaymentInfo.id', text: `${damagePaymentInfo.id}`}]} ), [damagePaymentInfo.id] );

    return (
        <>
            <div>
                {(damagePaymentInfo && damagePaymentInfo.id) ? <DamagePaymentInfoDetails searchOptions={searchOptions}/> : null}
            </div>
        </>
    )
}

export default ShowDamagePaymentInfoDetails
