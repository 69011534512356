import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddEditForm from "../form/AddEditForm";
import ListForm from "./ListForm";
import textService from "../_services/text.service";

function Index(props) {
	const path = props.path;

	function AddEdit(props) {
		return (
			<AddEditForm
				domainName={props.domainName}
				instanceToString={(data) => props.instanceToString(data, props.domainName, props.toString)}
				beforeSubmit={props.beforeSubmit}
				routeParams={props.routeParams}
			>
				{ props.formElements }
			</AddEditForm>
		)
	}

	function List(props) {
		return (
			<>
				<ListForm
					instanceToString={(data) => props.instanceToString(data, props.domainName, props.toString)}
					allowEdits={props.allowEdits}
					allowDeletions={props.allowDeletions}
					allowShow={props.allowShow}
					actionIdColumn={props.actionIdColumn}
					additionalButtons={props.additionalButtons}
					columns={props.columns}
					domainName={props.domainName}
					sortField={props.sortField}
					sortOrder={props.sortOrder}
					addButtons={props.addButtons}
					getEditUrlParams={props.getEditUrlParams}
					expandRow={props.expandRow}
					rowStyle={props.rowStyle}
					getIndexData={props.getIndexData}
					pagination={props.pagination}
				/>
			</>
		);
	}

	const createElement = (routeComponentProps) => {
		if ( props.createElement ) {
			return React.cloneElement(props.createElement, {routeParams: routeComponentProps.match.params })
		}
		else {
			return (<AddEdit domainName={props.domainName} formElements={props.formElements}
							 instanceToString={textService.objectToString} toString={props.toString}
							 beforeSubmit={props.beforeSubmit} routeParams={routeComponentProps.match.params}/>)
		}
	}

	const editElement = (routeComponentProps) => {
		if ( props.editElement ) {
			return React.cloneElement(props.editElement, {routeParams: routeComponentProps.match.params })
		}
		else {
			return (<AddEdit domainName={props.domainName} formElements={props.formElements}
							 instanceToString={textService.objectToString} toString={props.toString}
							 beforeSubmit={props.beforeSubmit} routeParams={routeComponentProps.match.params}/>)
		}
	}

	const showElement = (routeComponentProps) => {
		if ( props.showElement ) {
			return React.cloneElement(props.showElement, {id: routeComponentProps.match.params.id})
		}
		else {
			throw new Error('form/Index.jsx::showElmement() doesn\'t support common show yet - you can implement it ;-).')
		}
	}

	return (
		<Switch>
			<Route exact path={path} render={() => (
				<List domainName={props.domainName} columns={props.columns} actionIdColumn={props.actionIdColumn}
					  instanceToString={textService.objectToString} toString={ props.toString } sortField={props.sortField}
					  sortOrder={props.sortOrder} allowEdits={props.allowEdits} allowDeletions={props.allowDeletions}
					  allowShow={props.allowShow} addButtons={props.addButtons}
					  additionalButtons={props.additionalButtons} getEditUrlParams={props.getEditUrlParams}
					  expandRow={props.expandRow} rowStyle={props.rowStyle} getIndexData={props.getIndexData} pagination={props.pagination}/>
			)}/>
			<Route path={`${path}/create/:parentId?`} render={(routeComponentProps) => (
				createElement(routeComponentProps)
			)}/>
			<Route path={`${path}/edit/:id`} render={(routeComponentProps) => (
				editElement(routeComponentProps)
			)}/>
			<Route path={`${path}/show/:id`} render={(routeComponentProps) => (
				showElement(routeComponentProps)
			)}/>
		</Switch>
	);
}

export default Index;
