import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const alertSubject = new Subject();
const defaultId = 'default-alert';

export const alertService = {
	onAlert,
	success,
	error,
	info,
	warn,
	alert,
	clear
};

export const AlertType = {
	Success: 'Success',
	Error: 'Error',
	Info: 'Info',
	Warning: 'Warning'
};

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
	return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

// convenience methods
function processMessage( message ) {
	let result = "";

	const processErrors = (errors) => {
		for (let err in errors) {
			let error = errors[err];
			if (error && error.message) {
				result += ('<li>' + error.message + '</li>')
			}
		}
	}
	if ( message !== null && typeof message === 'object' ) {
		result = '<ul style="margin-bottom: 0;">';
		if ( message._embedded && message._embedded.errors ) {
			processErrors( message._embedded.errors )
		}
		else if ( message.errors ) {
			processErrors( message.errors )
		}
		else if ( message.message ) {
			result += ('<li>' + message.message + '</li>' );
		}
		result += '</ul>';
	}
	else if ( message.message ) {
		result = message.message;
	}
	else {
		result = message
	}
	return result;
}

function success(message, options) {
	alert({ ...options, type: AlertType.Success, message: processMessage(message) });
}

function error(message, options) {
	alert({ ...options, type: AlertType.Error, message: processMessage(message) });
}

function info(message, options) {
	alert({ ...options, type: AlertType.Info, message: processMessage(message) });
}

function warn(message, options) {
	alert({ ...options, type: AlertType.Warning, message: processMessage(message) });
}

// core alert method
function alert(alert) {
	alert.id = alert.id || defaultId;
	alert.autoClose = (alert.autoClose === undefined ? true : alert.autoClose);
	alert.scrollIntoView = (alert.scrollIntoView === undefined ? true : alert.scrollIntoView);
	alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
	alertSubject.next({ id });
}
