import React, {useCallback, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap'
import {alertService} from "../_services";
import { withRouter } from 'react-router-dom';
import restService from "../_services/rest.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faPencilAlt, faEye} from "@fortawesome/free-solid-svg-icons";
import DomainTable from "../_components/DomainTable";
import AddButtons from "./AddButtons";
import DeleteConfirmDialog from "../_components/DeleteConfirmDialog";

function ListForm(props) {
	const domainTableRef = useRef();
	const instanceToString = props.instanceToString;
	const allowEdits = props.allowEdits;
	const allowDeletions = props.allowDeletions;
	const allowShow = props.allowShow;
	const columns = props.columns;
	const { t } = useTranslation();
	const domainName = props.domainName;
	const { path } = props.match;
	const actionIdColumn = props.actionIdColumn ? props.actionIdColumn : 'id';
	const queryParams = new URLSearchParams(window.location.search)
	const [page, setPage] = useState( () => {
		const pageParam = parseInt( queryParams.get("page") )
		if ( isNaN(pageParam) ) {
			return 1
		}
		else {
			return pageParam
		}
	});
	const [sizePerPage, setSizePerPage] = useState( () => {
		const sizePerPage = parseInt( queryParams.get("sizePerPage") )
		if ( isNaN(sizePerPage) ) {
			return 10
		}
		else {
			return sizePerPage
		}
	});
	const [sortField, setSortField] = useState( () => {
		const sortFieldParam = queryParams.get("sortField")
		return sortFieldParam || props.sortField
	});
	const [sortOrder, setSortOrder] = useState( () => {
		const sortOrderParam = queryParams.get("sortOrder")
		return sortOrderParam || props.sortOrder
	});
	const [searchText, setSearchText] = useState( () => {
		return queryParams.get("searchText")
	});
	const [rowToBeDeleted, setRowToBeDeleted] = useState({});
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

	const handleTableStateChanged = (newState) => {
		let params = {};
		if ( newState.page ) {
			params['page'] = newState.page;
			setPage(newState.page)
		}
		if ( newState.sortField ) {
			params['sortField'] = newState.sortField;
			setSortField(newState.sortField)
		}
		if ( newState.sortOrder ) {
			params['sortOrder'] = newState.sortOrder;
			setSortOrder(newState.sortOrder)
		}
		if ( newState.searchText ) {
			params['searchText'] = newState.searchText;
			setSearchText(newState.searchText)
		}
		if ( newState.sizePerPage ) {
			params['sizePerPage'] = newState.sizePerPage;
			setSizePerPage(newState.sizePerPage)
		}
		console.log(`${props.match.url}?${new URLSearchParams( params )}`)
		window.history.pushState(null, null, `${props.match.url}?${new URLSearchParams( params )}`);
	}

	function deleteDomainInstance() {
		try {
			let row = rowToBeDeleted;
			restService.deleteDomainInstance( domainName, row.id )
				.then( () => {
					domainTableRef.current.onRowDeleted( row );
					alertService.success(t("default.deleted", {what: instanceToString(row)}), { keepAfterRouteChange: true });
				})
				.catch( (error, signal) => {
					restService.handleServerErrors(error, signal);
				})

		}
		catch (e) {
			alertService.error(e);
		}
	}

	const getActionDomainInstance = useCallback( (row, action, className, variant, icon) => {
		let urlParams = '';
		if ( props.getEditUrlParams ) {
			urlParams = props.getEditUrlParams(row)
		}
		return (
			<Link className={`${className} btn btn-sm btn-${variant}`} to={`${props.match.url}${urlParams}/${action}/${row[actionIdColumn]}`}>
				<FontAwesomeIcon icon={icon}/>
			</Link>
		)
	}, [actionIdColumn, props]);

	const columnsWithActions = useMemo( () => {
		let result = columns.slice(0); //clone columns

		let actions = {
			dataField: '',
			text: t( 'default.actions' ),
			align: 'center',
			headerStyle: ( column, colIndex ) => {
				return { width: '150px', textAlign: 'center' };
			},
			formatter: ( cellContent, row ) => {
				const allowEditsValue = (typeof allowEdits === 'function' ? allowEdits(row) : allowEdits )
				const allowDeletionsValue = (typeof allowDeletions === 'function' ? allowDeletions(row) : allowDeletions )
				const allowShowValue = (typeof allowShow === 'function' ? allowShow(row) : allowShow )

				return (
					<>
						{ ( allowShowValue ? (
							getActionDomainInstance( row, 'show', '', 'primary', faEye)
						) : '' ) }
						{ allowEditsValue ? (
							getActionDomainInstance( row, 'edit', 'me-1', 'warning', faPencilAlt)
						) : '' }
						{ ( allowDeletionsValue ? (
							<Button
								variant={ 'danger' }
								size={ 'sm' }
								onClick={ ( event ) => {
									event.preventDefault();
									event.stopPropagation();
									setRowToBeDeleted( row );
									setShowDeleteConfirm( true );
								} }>
								<FontAwesomeIcon icon={ faTrashAlt }/>
							</Button>
						) : '' ) }
						{ ( props.additionalButtons ? (
							props.additionalButtons(row)
						) : '' )}
					</>
				)
			}
		}

		result.push(actions);

		return result;
	}, [columns, allowDeletions, allowEdits, allowShow, getActionDomainInstance, props, t]);

	return (
		<>
			<div className={"float-start mb-2 w-50"}>
				<AddButtons addButtons={props.addButtons} path={path} domainName={domainName}/>
			</div>

            <DomainTable getData={props.getIndexData} columns={columnsWithActions} domainName={domainName}
                         ref={domainTableRef}
                         sortField={sortField} sortOrder={sortOrder} searchText={searchText} sizePerPage={sizePerPage}
                         page={page} onTableStateChanged={handleTableStateChanged} expandRow={props.expandRow}
                         rowStyle={props.rowStyle} pagination={props.pagination}/>
			<DeleteConfirmDialog
				show={showDeleteConfirm}
				onHide={handleCloseDeleteConfirm}
				bodyText={t('deleteConfirm.body', {name: instanceToString(rowToBeDeleted)})}
				onDelete={deleteDomainInstance}
			/>
		</>
	);
}

export default withRouter(ListForm);
