import React, {useMemo} from "react";
import PreviewPolicyVersionBase from "./PreviewPolicyVersionBase";
import {Alert, Button, ButtonGroup, Card, Col, Dropdown, Row, Tab, Tabs} from "react-bootstrap";
import appService from "../_services";
import ShowField from "../form/ShowField";
import restService from "../_services/rest.service";
import {useTranslation} from "react-i18next";
import {policyService} from "../_services/policy.service";
import PreviewPolicyVersionDamages from "./PreviewPolicyVersionDamages";
import PreviewPolicyVersionMemo from "./PreviewPolicyVersionMemo";
import {ChonkyFileBrowser} from "../_components/ChonkyFileBrowser";
import {PolicyVersionStatus} from "../_enum/enum";
import PreviewCommissions from "./PreviewCommissions";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import DropdownItemWithOptions from "../_components/DropdownItemWithOptions";
import Envers from "../_components/Envers";
import PreviewPolicyVersionSettlements from "./PreviewPolicyVersionSettlements";
import textService from "../_services/text.service";
import moment from "moment/moment";
import {Link} from "react-router-dom";

function PreviewPolicyVersion(props) {
	const [policyVersion, setPolicyVersion] = restService.useDomainInstance('policyVersion', props.id, false);
	const {t} = useTranslation();
	const nextPeriodFrom = policyVersion?.nextInvoicePeriodFrom ? textService.formatDateTime(moment(appService.nullSafeGet( policyVersion, 'nextInvoicePeriodFrom' )),{dateStyle: 'medium'}) : undefined;
	const nextPeriodTo = policyVersion?.nextInvoicePeriodTo ? textService.formatDateTime(moment(appService.nullSafeGet( policyVersion, 'nextInvoicePeriodTo' )),{dateStyle: 'medium'}) : undefined

	const hsNamedCriteriaBuildingManager = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "buildingManager",
					params: {}
				}
		}
	}, [])

	const getDamageRendementHref = () => {
		const params = {
			defaultPolicies: JSON.stringify([{id: policyVersion.policy.id, label: policyVersion.policy.label}])
		}

		return `/damageRendement?${new URLSearchParams( params )}`
	}
	const getPrintButtons = () => {
		if ( policyVersion && policyVersion.id ) {
			return (
				<Dropdown as={ ButtonGroup } size="sm" className={ "float-end" }>
					<Button variant="primary"
					        onClick = { ( event ) => {
							event.preventDefault();
							event.stopPropagation();
							policyService.print( policyVersion.id );
						} }>{t(`previewPolicyVersion.createPolicyPdf.${policyVersion.state === PolicyVersionStatus.IN_PROCESS ? 'offer' : 'policy'}.label`)}</Button>

					<Dropdown.Toggle split variant="primary" id="dropdown-split-basic"/>

					<Dropdown.Menu>
						<DropdownItemWithOptions
							label={t(`previewPolicyVersion.createPolicyPdf.${policyVersion.state === PolicyVersionStatus.IN_PROCESS ? 'offer' : 'policy'}.label`) }
							options={[{name:'withLogo', checked:false, control: 'switchbutton'}, {name:'hangingFileTitle', checked:true, control: 'switchbutton'}]}
							onClick={ (values) => {
								policyService.print( policyVersion.id, values.withLogo, values.hangingFileTitle );
							}}
						/>
						{ policyVersion.policy &&
							<Dropdown.Item rel={"noopener noreferrer"} target={"_blank"} href={getDamageRendementHref()}>
								{t("reports.damageRendement.title")}
							</Dropdown.Item>
						}
						{ policyVersion.hasAssistanceProduct &&
							<Dropdown.Item onClick = { ( event ) => {
								policyService.assistanceFormHelvetia( policyVersion.id );
							}}>{t("previewPolicyVersion.assistanceFormHelvetia.label")}</Dropdown.Item>
						}
						{ policyVersion.hasAssistanceProduct && policyVersion.state === PolicyVersionStatus.ACTIVE &&
							<Dropdown.Item onClick = { ( event ) => {
								policyService.assistanceSosCardHelvetia( policyVersion.id );
							}}>{t("previewPolicyVersion.assistanceSosCardHelvetia.label")}</Dropdown.Item>
						}
						{
							policyVersion.assistanceCancelled &&
							<Dropdown.Item onClick = { ( event ) => {
								policyService.assistanceFormHelvetia( policyVersion.id, true );
							}}>{t("previewPolicyVersion.assistanceCancelledFormHelvetia.label")}</Dropdown.Item>
						}
						{
							policyVersion.previousPolicyVersionAssistanceCancelled &&
							<Dropdown.Item onClick = { ( event ) => {
								policyService.assistanceFormHelvetia( policyVersion.previousPolicyVersionId, true );
							}}>{t("previewPolicyVersion.assistanceCancelledFormHelvetia.previousPolicyVersion.label")}</Dropdown.Item>
						}
					</Dropdown.Menu>
				</Dropdown>
			)
		}
	}

	const savePolicyVersionValue = (data) => {
		return new Promise( (resolve, reject) => {
			restService.updateDomainInstance('policyVersion', policyVersion.id, { version: policyVersion.version, ...data })
				.then( (domain) => {
					setPolicyVersion( domain )
					resolve();
				})
				.catch( (error) => reject(error) )
		})
	}

	const getBaseData = () => {
		return (
			<Card className={"mb-2"}>
				<Card.Header className={"mb-0"}>
					<h4 className={"mb-0"}>
						{appService.nullSafeGet( policyVersion, 'policyNumber' )}
						{(policyVersion.unpaidInvoices !== undefined && policyVersion.unpaidInvoices.length>0)
							?
							(<Card border="danger" className={"mt-3 mb-3"}>
								<Card.Header className={"bg-danger"}>
									{t('previewPolicyVersion.unpaidInvoices.header')}
								</Card.Header>
								<Card.Body>
									{policyVersion.unpaidInvoices.map((invoice) => <div className={'unpaid-invoice'}> <Link to={restService.getTo( invoice, 'show' ) || ''} target={"_blank"} rel={"noopener noreferrer"}>{invoice.toStringUnpaid}</Link><br/> </div>)}
								</Card.Body>
							</Card>)
							:
							''
						}
						{getPrintButtons()}
					</h4>
				</Card.Header>
				<Card.Body>
					<Envers domain={policyVersion}/>
					{policyVersion && policyVersion.policy && policyVersion.policy.policyCancellationReason &&
						<Alert variant={"danger"}>
							<h3>{t('default.cancelled')}</h3>
							<Row>
								<ShowField label={'policy.policyCancellationReason'} object={policyVersion} property={'policy.policyCancellationReason.label'} type={'text'} size={4}/>
							</Row>
						</Alert>
					}
					<Row>
						<ShowField label={'createPolicy.version.policyHolder.label'} object={policyVersion} property={'policyHolder.label'} type={'text'} link={{domain: 'policyHolder', action: 'edit', newTab:true}}/>
					</Row>
					<Row>
						<ShowField
							label={'createPolicy.version.insured.label'}
							object={policyVersion}
							property={'insured.label'}
							type={'text'}
							link={{domain: 'insured', action: 'edit', newTab:true}}
							editable={{
								editFormElement:
									<RestAsyncSelectControl
										domainName={"partner"}
										sort={"fullName_sort"}
										value={policyVersion.insured && policyVersion.insured.id}
										label={policyVersion.insured && policyVersion.insured.label}
										name={'insured'}
										rules={{
											required: true
										}}
									/>,
								onSave: (formData) => savePolicyVersionValue({insured: formData.insured.id})
							}}
							/>
					</Row>
					<Row>
						<ShowField
							label={'createPolicy.version.invoiceTo.label'}
							object={policyVersion}
							property={'invoiceTo.label'}
							type={'text'}
							link={{domain: 'invoiceTo', action: 'edit', newTab:true}}
							editable={{
								editFormElement:
									<RestAsyncSelectControl
										domainName={"partner"}
										sort={"fullName_sort"}
										value={policyVersion.invoiceTo && policyVersion.invoiceTo.id}
										label={policyVersion.invoiceTo && policyVersion.invoiceTo.label}
										name={'invoiceTo'}
										rules={{
											required: true
										}}
									/>,
								onSave: (formData) => savePolicyVersionValue({invoiceTo: formData.invoiceTo.id})
							}}
							/>
					</Row>
					<Row>
						<ShowField
							label={'createPolicy.version.buildingManager.label'}
							object={policyVersion}
							property={'buildingManager.label'}
							type={'text'}
							link={{domain: 'buildingManager', action: 'edit', newTab:true}}
							editable={{
								editFormElement:
									<RestAsyncSelectControl
										domainName={"partner"}
										sort={"fullName_sort"}
										value={policyVersion.buildingManager && policyVersion.buildingManager.id}
										label={policyVersion.buildingManager && policyVersion.buildingManager.label}
										name={'buildingManager'}
										searchOptions={ hsNamedCriteriaBuildingManager }
										noSelection={true}
									/>,
								onSave: (formData) => savePolicyVersionValue({buildingManager: formData.buildingManager.id})
							}}
						/>
					</Row>
					<hr/>
					<Row>
						<ShowField label={'createPolicy.policy.contractStart.label'} object={policyVersion} property={'policy.contractStart'} type={'date'} size={4}/>
						<ShowField label={'createPolicy.version.contractValidityPeriod.label'} object={policyVersion} property={'contractValidityPeriod'} type={'text'} appendix={` ${t('default.year' + (appService.nullSafeGet(policyVersion, 'policy.contractValidityPeriod')==="1"?'':'s'))}`} size={4}/>
						<ShowField label={'createPolicy.policy.contractExpiry.label'} object={policyVersion} property={'policy.contractExpiry'} type={'date'} size={4}/>
					</Row>
					<Row>
						<ShowField label={'createPolicy.version.dueMonth.label'} value={'1.'+appService.nullSafeGet(policyVersion, 'dueMonth')+'.'} size={4}/>
						<ShowField label={'createPolicy.version.invoiceCycle.label'} object={policyVersion} property={'invoiceCycle'} type={'appvers.enums.InvoiceCycle'} size={4}/>
					</Row>
					<Row>
						<Col>
							<span><em>
								{ !policyVersion.otherLeadership ?
									t('policyVersion.nextInvoicePeriod', {nextPeriodFrom: nextPeriodFrom, nextPeriodTo: nextPeriodTo})
									:
									t('policyVersion.nextInvoicePeriodOtherLeadership', {otherLeadershipInsurance: policyVersion.leadingCompany.label})
								}
							</em></span>
						</Col>
					</Row>
				</Card.Body>
				{/*<div>{JSON.stringify(policyVersion)}</div>*/}
			</Card>
		)
	}

	const setUpdatedPolicyVersion = (pv) => {
		setPolicyVersion(pv);
	}

	const getAttachmentsData = () => {
		if ( undefined === policyVersion || !policyVersion.policy ) return;
		return (
			<Card className={"mb-2"}>
				<Card.Header>{t('default.files')}</Card.Header>
				<Card.Body>
					<Row>
						<ChonkyFileBrowser holderType={'policy'} holderId={policyVersion.policy.id}/>
					</Row>
				</Card.Body>
			</Card>
		)
	}


	return (
		<>
			{getBaseData()}
				<Tabs defaultActiveKey="base" id="uncontrolled-tab-example">
					<Tab eventKey="base" title={t('previewPolicyVersion.tab.version')}>
						<div className={"border-left border-right border-bottom rounded-bottom p-3"}>
							<PreviewPolicyVersionBase policyVersion={policyVersion} setUpdatedPolicyVersion={setUpdatedPolicyVersion} setPolicyVersion={setPolicyVersion} savePolicyVersionValue={savePolicyVersionValue}/>
						</div>
					</Tab>
					<Tab eventKey="memo" title={t('previewPolicyVersion.tab.memo')}>
						<PreviewPolicyVersionMemo policyVersion={policyVersion || {}}/>
					</Tab>
					<Tab eventKey="attachments" title={t('previewPolicyVersion.tab.attachments')} mountOnEnter={true}>
						{getAttachmentsData()}
					</Tab>
					<Tab eventKey="settlements" title={t('previewPolicyVersion.tab.settlements')} mountOnEnter={true}>
						<PreviewPolicyVersionSettlements policyVersion={policyVersion}/>
					</Tab>
					<Tab eventKey="damages" title={t('previewPolicyVersion.tab.harmfulEvents')} mountOnEnter={true}>
						<PreviewPolicyVersionDamages policyVersion={policyVersion}/>
					</Tab>
					<Tab eventKey="commissions" title={t('previewPolicyVersion.tab.commissions')} mountOnEnter={true}>
						<PreviewCommissions policyVersion={policyVersion || {}}/>
					</Tab>
				</Tabs>
		</>
	)
}

export default PreviewPolicyVersion
