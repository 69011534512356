import React from 'react';
import PreviewSettlementBatch from "../_components/_settlementBatch/PreviewSettlementBatch";
import ProfitParticipationSettlementBatchFormElements from "./ProfitParticipationSettlementBatchFormElements";

function PreviewProfitParticipationSettlementBatch( { id } ) {
	//const hiddenFields = useMemo(() => ['policyNumber', 'detail'], [])

	return (
		<PreviewSettlementBatch
			className={'profitParticipationSettlementBatch'}
			id={ parseInt(id) }
			settlementBatchFormElements={<ProfitParticipationSettlementBatchFormElements />}
			//hiddenFields={hiddenFields}
		/>
	);
}

export default PreviewProfitParticipationSettlementBatch
