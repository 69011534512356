import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";
import bootstrapTableService from "../_components/bootstrapTable.service";

function County({ match }) {
	const { t } = useTranslation();
	const columns = [
		{
			dataField: 'id',
			text: t('county.id'),
			hidden: true
		}, {
			dataField: 'code',
			text: t('county.code'),
			sort: true
		}, {
			dataField: 'name',
			text: t('county.name'),
			sort: true
		},
		{
			dataField: 'country.name',
			sortField: 'country.name_sort',
			text: t('county.country'),
			sort: true
		},
		{
			dataField: 'hasLoeschfuenfer',
			text: t('county.hasLoeschfuenfer'),
			formatter: bootstrapTableService.formatterBoolean,
			sort: false
		}
	];

	return (
		<Index
			path={match.path}
			domainName={'county'}
			columns={columns}
			toString={['name']}
			formElements={<FormElements/>}
			sortField={'code'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { County };
