import React, {useEffect} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {Dropdown} from "../_components/Dropdown";
import {Controller, useFormContext} from "react-hook-form";

function FormElements({domainInstance}) {
	const { t } = useTranslation();
	const { reset, getValues, control } = useFormContext();

	useEffect(() => {
		const val = {...getValues(), 'valueType': domainInstance.valueType === undefined ? '' : domainInstance.valueType};
		reset( val ); //value is normally set only once. In case of asynchronous loading of the form is undefined loaded first and later is loaded the correct value. reset() must be called for such case.
	}, [domainInstance.valueType, getValues, reset]);

	const getOptions = () => {
		const options = [
			{id:'STRING', name: 'STRING'},
			{id:'INTEGER', name: 'INTEGER'},
			{id:'BIGDECIMAL', name: 'BIGDECIMAL'},
			{id:'JSON', name: 'JSON'},
		]
		return options
	}

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('setting.card.common')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="4" controlId="formGroupSettingName">
							<Form.Label>{t('setting.name')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value= {domainInstance.name}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="formGroupSettingValue">
							<Form.Label>{t('setting.value')}</Form.Label>
							<FormControl
								name={"value"}
								type={"text"}
								value= {domainInstance.value}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="formGroupSettingValueType">
							<Form.Label>{t('setting.valueType')}</Form.Label>
							<Controller
								name={ 'valueType' }
								control={ control }
								defaultValue={ domainInstance.valueType }
								render={ ({ field }) => {
									return (
									<>
										<Dropdown
											name={ field.name }
											options={getOptions()}
											keyPropName={'id'}
											valuePropName={'name'}
											onChange={ (e) => {
												field.onChange( e )
											}}
											value={ field.value }
										/>
									</>
									)
								}
							    }
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupSettingDescription">
							<Form.Label>{t('setting.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value= {domainInstance.description}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
