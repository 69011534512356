import React from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {messagePanelService} from "../../_services/message.panel.service";
import restService from "../../_services/rest.service";

function SettlementPrintedPrintWithSettlementBatchMessage(params) {
	const {t} = useTranslation();

	const handleNoOnClick = () => {
		restService.updateDomainInstance('invoicePolicyVersion', params.id, {printWithSettlementBatch: false})
		messagePanelService.update( undefined, [params.uuid] );
	}

	const handleYesOnClick = () => {
		messagePanelService.update( undefined, [params.uuid] );
	}

	return (
		<>
			<p>{params.message}</p>
			<Button variant={'primary'} onClick={handleYesOnClick}>{t('default.yes')}</Button>
			<Button className={"ms-2"} variant={'secondary'} onClick={handleNoOnClick}>{t('default.no')}</Button>
		</>
	)
}

export default SettlementPrintedPrintWithSettlementBatchMessage
