import { createSlice } from '@reduxjs/toolkit'

export const securitySlice = createSlice({
	name: 'security',
	initialState: {
		auth: undefined,
	},
	reducers: {
		setAuth: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			if ( action.payload === "" ) {
				state.auth = undefined;
			}
			else {
				state.auth = JSON.parse( action.payload );
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const { setAuth } = securitySlice.actions

export default securitySlice.reducer
