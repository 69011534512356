import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";
import bootstrapFormatService from "../_components/bootstrapTable.service";

function DamageEvent({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			text: t('damageEvent.id'),
			hidden: true
		},
		{
			dataField: 'eventDate',
			text: t('damageEvent.eventDate'),
			sortField: 'eventDateTime',
			sort: true,
			formatter: bootstrapFormatService.formatterDate
		},
		{
			dataField: 'eventName',
			text: t('damageEvent.eventName'),
			sortField: 'eventName_sort',
			sort: true
		},
		{
			dataField: 'description',
			text: t( 'damageEvent.description' ),
			sortField: 'description_sort',
			sort: true
		},
	];

	return (
		<Index
			path={match.path}
			domainName={ 'damageEvent' }
			columns={ columns }
			toString={ (object) => object.eventName }
			formElements={ <FormElements/> }
			sortField={'eventDateTime'}
			sortOrder={'desc'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { DamageEvent };
