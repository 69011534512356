import React from 'react';
import serviceService from "../_services/service.service";
import {withRouter} from "react-router-dom";

function ReindexAll( {history} ) {
	serviceService.reindexAll()
	history.goBack()

	return (
		<>
		</>
	)
}

export default withRouter(ReindexAll)