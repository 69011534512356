import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";

function IndividualText({match}) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('individualText.id'),
		hidden: true
	}, {
		dataField: 'text',
		text: t('individualText.text'),
		sortField: 'text_sort',
		sort: true
	}, {
		dataField: 'active',
		text: t('individualText.active'),
		sort: true,
		headerAlign: 'center',
		align: 'center',
		headerStyle: (column, colIndex) => {
			return { width: '60px' };
		},
		formatter: bootstrapTableService.formatterBoolean,
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'individualText'}
			columns={columns}
			toString={['text']}
			formElements={<FormElements/>}
			sortField={'text_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { IndividualText };
