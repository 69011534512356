import {useCallback, useEffect, useMemo, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import moment from "moment";
import settingService from "./setting.service";
import appService from "./index";
import {PolicyVersionStatus} from "../_enum/enum";
import {fileService} from "./file.service";
import {messageBoxService} from "./messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";

export const policyService = {
	usePolicyOffer,
	print,
	printSettlement,
	cancelSettlement,
	round20: round20,
	getPolicyStateClass,
	activateDeactivate,
	revertPolicyVersionActivation,
	revertPolicyVersionDeactivation,
	useLastSettlement,
	useValidToCalculated,
	assistanceFormHelvetia,
	assistanceSosCardHelvetia,
	getTotalFeePerYear,
	useOtherLeadershipChecks,
	saveOrUpdateOtherLeadershipCheck,
	printExpiringPolicyVersions,
	checkMandatoryText,
	policiesExport,
	billingForPartners,
	inventoryExport,
	invoicedAppversPart,
	deletePolicyVersion,
	exportCommissions,
}

function useValidToCalculated( validFrom, contractValidityPeriod, dueMonth ) {
	const [validToCalculated, setValidToCalculated] = useState(undefined);

	useEffect( () => {
		const h = headers();
		const controller = new AbortController();
		const params = {
			validFrom: validFrom,
			contractValidityPeriod: contractValidityPeriod,
			dueMonth: dueMonth,
		}
		fetch( `${ SERVER_URL }/api/policy/getValidToCalculated?${new URLSearchParams( params )}`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( json => {
				setValidToCalculated(json.validToCalculated);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, [validFrom, contractValidityPeriod, dueMonth])

	return validToCalculated
}

function useLastSettlement( policyVersionId ) {
	const [ lastSettlement, setLastSettlement ] = useState({})

	useEffect( () => {
		const h = headers();
		const controller = new AbortController();
		const params = {
			policyVersionId: policyVersionId
		}
		fetch( `${ SERVER_URL }/api/policy/getLastSettlement?${new URLSearchParams( params )}`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( json => {
				setLastSettlement(json);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, [policyVersionId]);

	return lastSettlement;
}

function deletePolicyVersion( id ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/policy/deletePolicyVersion/${id}`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( () => {
				resolve();
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function activateDeactivate( action, params ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/policy/${ action }PolicyVersion?${new URLSearchParams( params )}`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function revertPolicyVersionActivation( policyVersionId ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/policy/revertPolicyVersionActivation?${new URLSearchParams( { policyVersionId: policyVersionId } )}`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function revertPolicyVersionDeactivation( policyVersionId ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/policy/revertPolicyVersionDeactivation?${new URLSearchParams( { policyVersionId: policyVersionId } )}`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function print(id, withLogo = true, hangingFileTitle = false) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController();

		const params = {
			withLogo: withLogo,
			hangingFileTitle: hangingFileTitle,
		}

		fetch( `${ SERVER_URL }/api/policy/report/${ id }?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal,
		} )
			.then( (response) => {
				console.log("then")
				fileService.saveAttachmentWithContentDispositionFileName(response)
					.then(() => {
						console.log("saveATt then")
						resolve()
					})
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function cancelSettlement(id, settlementCancelReason, settlementCancelNote) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController()
		const params = {
			id: id,
			settlementCancelReason: settlementCancelReason,
			settlementCancelNote: settlementCancelNote
		}
		fetch( `${ SERVER_URL }/api/policy/cancelSettlement`, {
			method: 'POST',
			headers: h,
			signal: controller.signal,
			body: JSON.stringify( params )
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( result => {
				resolve(result);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printSettlement(id, number, policyNumber, partner, className, withLogo = true) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/policy/printSettlement/${ id }?withLogo=${withLogo}`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( (response) => {
				fileService.saveAttachmentWithContentDispositionFileName(response)
					.then(resolve)
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function useDefaultPartnerConsultantScope() {
	const [ defaultPartnerConsultantScope, setDefaultPartnerConsultantScope ] = useState(undefined);

	useEffect( () => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/policy/getDefaultPartnerConsultantScope`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponse( response ) )
			.then( json => {
				setDefaultPartnerConsultantScope(json);
			} )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, []);

	return defaultPartnerConsultantScope;
}

function usePolicyOffer(id, policyId) {
	const { defaultContractValidityPeriod, defaultNewPolicyOfferValidityMonths, defaultReinsurancePartnerId } = settingService.useSetting(['defaultContractValidityPeriod', 'defaultNewPolicyOfferValidityMonths', 'defaultReinsurancePartnerId'])
	const [ defaultReinsurancePartner ] = restService.useDomainInstance('partner', defaultReinsurancePartnerId);
	const defaultPartnerConsultantScope = useDefaultPartnerConsultantScope();

	const getMaxId = function () {
		let maxId
		if ( this.targets && this.targets.length > 0 ) {
			maxId = Math.max.apply( Math, this.targets.map( function ( o ) {
				return o.key;
			} ) );
		} else {
			maxId = 0;
		}
		return maxId;
	}

	const sameBuilding = (t1, t2) => {
		const getBuildingId = (t) => t.building ? t.building.id : undefined;
		return getBuildingId(t1) === getBuildingId(t2);
	}

	const getParentTarget = useCallback( (targets, dependentTarget) => {
		if ( dependentTarget.dependsOn && targets ) {
			return targets.find( ( parentTarget ) => {
				return (
					( parentTarget.action !== 'delete' && dependentTarget.dependsOn.id === parentTarget.targetId)
						||
					( parentTarget.action !== 'delete' && parentTarget.broker && dependentTarget.dependsOn.id === parentTarget.parentConfiguratorTarget.id )
				)
			} )

		}
		else {
			return null;
		}
	}, [])

	const getDependentTargets = useCallback( (targets, parentTarget) => {
		return targets.filter( (dependentTarget) => {
			return ( dependentTarget.action !== 'delete'
				&& dependentTarget.dependsOn
				&& dependentTarget.dependsOn.id === parentTarget.targetId )
			||
				( dependentTarget.action !== 'delete'
					&& dependentTarget.broker
					&& dependentTarget.parentConfiguratorTarget.dependsOn
					&& dependentTarget.parentConfiguratorTarget.dependsOn.id === parentTarget.targetId )
		} )
	}, [])

	const getCorrespondingTargets = useCallback( (targets, target, amount) => {
		const getSimilarity = (t) => {
			if ( t ) {
				return [...t.jsPathToRoot].reverse().find( ( node ) => node.isPartOfTariffName );
			}
			else {
				return undefined;
			}
		};
		const targetSimilarity = getSimilarity(target);
		if (targets) {
			return targets.filter( ( t ) => {
				const similarity1 = getSimilarity( t );
				return t.action !== 'delete'
					&& t.key !== target.key
					&& appService.compareObjectsByValue( similarity1, targetSimilarity )
					&& ( target.key !== t.key )
					&& ( t.amount !== amount )
					&& sameBuilding( target, t )
					&& ( t.targetClassName === 'configuratorTargetBase'
						||
						t.targetClassName === 'configuratorTargetBaseBroker'
						||
						( (t.targetClassName === 'configuratorTargetDependentProduct' || t.targetClassName === 'configuratorTargetDependentProductBroker') && t.dependsOn.id === target.targetId )
						||
						( (t.targetClassName === 'configuratorTargetDependentProduct' || t.targetClassName === 'configuratorTargetDependentProductBroker') && target.parentConfiguratorTarget && t.dependsOn.id === target.parentConfiguratorTarget.id )
					)
			} )
		}
		else {
			return [];
		}
	}, []);

	const defaultPolicyOffer = useMemo( () => {
		//we want to return only fully loaded values. Otherwise we return null.
		if ( defaultReinsurancePartner && defaultContractValidityPeriod && defaultNewPolicyOfferValidityMonths && defaultPartnerConsultantScope !== undefined) {
			return {
				policy: {
					validFrom: moment().format( 'YYYY-MM-DD' ),
					offerValidTo: moment().add( defaultNewPolicyOfferValidityMonths, 'month' ).format( 'YYYY-MM-DD' ),
					contractValidityPeriod: defaultContractValidityPeriod,
					isFirstPolicyVersion: true,
					partnerConsultantScope: defaultPartnerConsultantScope,
				},
				detail: {
					getMaxId: getMaxId,
					getCorrespondingTargets: getCorrespondingTargets,
					getDependentTargets: getDependentTargets,
					getParentTarget: getParentTarget,
				},
				addProductBuilding: {},
				addProductConfigurator: {},
				addProductTariffs: {},
				lastTouches: {
					reinsuranceCompany: { id: defaultReinsurancePartner.id, label: defaultReinsurancePartner.label },
				},
			}
		}
		else {
			return null;
		}
	}, [defaultNewPolicyOfferValidityMonths, defaultContractValidityPeriod, defaultReinsurancePartner, getCorrespondingTargets, getDependentTargets, getParentTarget, defaultPartnerConsultantScope]);

	const [ policyOffer, setPolicyOffer ] = useState( null );

	useEffect( () => {
		const controller = new AbortController();
		if( id || policyId ) {
			const h = headers();
			const params = {}
			var action
			if ( id ) {
				params.id = id
				action = 'editPolicyOffer'
			}
			else if ( policyId ) {
				params.policyId = policyId
				action = 'createPolicyOffer'
			}
			fetch( `${ SERVER_URL }/api/policy/${action}?` + new URLSearchParams( params ), {
				method: 'GET',
				headers: h,
				signal: controller.signal
			} )
				.then( ( response ) => ( restService.handleServerResponse( response ) ) )
				.then( ( json ) => {
					json.detail.getMaxId = getMaxId
					json.detail.getCorrespondingTargets = getCorrespondingTargets
					json.detail.getDependentTargets = getDependentTargets
					json.detail.getParentTarget = getParentTarget
					setPolicyOffer( json )
				} )
				.catch( (error) => restService.handleServerErrors( error, controller.signal ) );
		}
		else {
			setPolicyOffer( defaultPolicyOffer );
		}

		return function cleanup() {
			controller.abort()
		}
	}, [id, policyId, defaultPolicyOffer, getCorrespondingTargets, getDependentTargets, getParentTarget])

	return policyOffer;
}

function round20( number) {
	let sgn = Math.sign(number)
	if ( sgn === 0 ) {
		sgn = 1
	}
	const factor = (sgn * 20)
	return Math.round(number * factor) / factor
}

function getPolicyStateClass(state) {
	switch ( state ) {
		case PolicyVersionStatus.IN_PROCESS:
			return "text-primary"
		case PolicyVersionStatus.ACTIVE:
			return "text-success"
		default:
			return "text-danger"
	}
}

function assistanceFormHelvetia(policyVersionId, cancellation = false) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		const params = {
			policyVersionId: policyVersionId,
			cancellation: cancellation,
		}
		fetch( `${ SERVER_URL }/api/policyVersion/assistanceFormHelvetia?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function assistanceSosCardHelvetia(policyVersionId) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/policyVersion/assistanceSosCardHelvetia/${ policyVersionId }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function getTotalFeePerYear( years ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController()
		const params = {
			years: JSON.stringify(years),
		}
		fetch( `${ SERVER_URL }/api/policy/getTotalFeePerYear?${new URLSearchParams( params )}`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => {
				resolve(json);
			})
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printExpiringPolicyVersions(dateFrom, dateTo, partnerConsultantScopes, brokers) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const controller = new AbortController()
		const params = {
			dateFrom: dateFrom,
			dateTo: dateTo,
			partnerConsultantScopes: JSON.stringify(partnerConsultantScopes),
			brokers: JSON.stringify(brokers),
		}
		fetch( `${ SERVER_URL }/api/policy/printExpiringPolicyVersions/?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function exportCommissions(dateFrom, dateTo, partners) {
	const h = headers();
	h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const controller = new AbortController()
	const params = {
		dateFrom: dateFrom,
		dateTo: dateTo,
		partners: JSON.stringify(partners),
	}
	fetch( `${ SERVER_URL }/api/policy/exportCommissions/?` + new URLSearchParams( params ), {
		method: 'GET',
		headers: h,
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}

function policiesExport(validFrom, validTo, dataValidOn, products) {
	const h = headers();
	h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const controller = new AbortController()
	const params = {
		validFrom: validFrom,
		validTo: validTo,
		dataValidOn: dataValidOn,
		products: JSON.stringify(products),
	}
	fetch( `${ SERVER_URL }/api/policy/policiesExport/?` + new URLSearchParams( params ), {
		method: 'GET',
		headers: h,
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}

function billingForPartners(validFrom, validTo, dataValidOn, courtage, administrationFee, report) {
	const h = headers();
	h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const controller = new AbortController()
	const params = {
		validFrom: validFrom,
		validTo: validTo,
		dataValidOn: dataValidOn,
		courtage: courtage,
		administrationFee: administrationFee,
		report: report,
	}
	fetch( `${ SERVER_URL }/api/policy/billingForPartners/?` + new URLSearchParams( params ), {
		method: 'GET',
		headers: h,
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}


function invoicedAppversPart(dateFrom, dateTo) {
	const h = headers();
	h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const controller = new AbortController()
	const params = {
		dateFrom: dateFrom,
		dateTo: dateTo,
	}
	fetch( `${ SERVER_URL }/api/policy/invoicedAppversPart/?` + new URLSearchParams( params ), {
		method: 'GET',
		headers: h,
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}


function inventoryExport(dataValidOn) {
	const h = headers();
	h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const controller = new AbortController()
	const params = {
		dataValidOn: dataValidOn,
	}
	fetch( `${ SERVER_URL }/api/policy/inventoryExport/?` + new URLSearchParams( params ), {
		method: 'GET',
		headers: h,
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}

/**
 * @param textId
 * @param currentTexts
 * @param targets
 * @param textClass
 * @returns if the text is mandatory, then product name of a product is returned, where the text is mandatory
 */
function checkMandatoryText(textId, currentTexts, targets, textClass) {
	for( const target of targets ) {
		for ( const ctst of target[`configuratorTarget${appService.capitalize(textClass)}s`] ) {
			if ( ctst.mandatory && parseInt(ctst[textClass].id) === parseInt( textId ) ) {
				const sameTexts = currentTexts.filter( (st) => !st.deleted && typeof(st[textClass]) === 'object' && parseInt(st[textClass].id) === parseInt(textId) ).length
				if ( sameTexts === 1 ) {
					messageBoxService.display(
						i18n.t( 'createPolicy.mandatoryText.cant.delete.message', { product: target.text } ),
						i18n.t( 'default.mandatoryText' ),
						[MessageBoxButtons.OK]
					);
					return false;
				}
			}
		}
	}

	return true;
}

function useOtherLeadershipChecks(year) {
	const [rows, setRows] = useState([]);

	useEffect( () => {
		const h = headers();
		const controller = new AbortController()
		const params = {
			year: year,
		}
		fetch( `${ SERVER_URL }/api/policy/getOtherLeadershipChecks?${ new URLSearchParams( params ) }`, {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => {
				setRows( json );
			} )
			.catch( ( error ) => {
				setRows( [] );
				restService.handleServerErrors( error, controller.signal );
			} );
	}, [year])

	return [rows, setRows];
}

function saveOrUpdateOtherLeadershipCheck(year, policyVersionId, data) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController()
		const params = {
			year: year,
			policyVersionId: policyVersionId,
			data: JSON.stringify(data),
		}
		fetch( `${ SERVER_URL }/api/policy/saveOrUpdateOtherLeadershipCheck?${new URLSearchParams( params )}`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => {
				resolve(json);
			})
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}