import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as AvbDescriptionFormElements} from "../avbDescription/FormElements";

function FormElements({domainInstance}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('avb.card.common.header')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="2" controlId="formGroupAvbYear">
							<Form.Label>{t('avb.year.label')}</Form.Label>
							<FormControl
								name={"year"}
								type={"text"}
								value= {domainInstance.year}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="formGroupEsAvbCode">
							<Form.Label>{t('avb.esAvbCode.label')}</Form.Label>
							<FormControl
								name={"esAvbCode"}
								type={"text"}
								value= {domainInstance.esAvbCode}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="12" controlId="formGroupAvbDescription">
							<Form.Label>{t('avb.avbDescription.label')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"avbDescription"}
								sort={"description_sort"}
								value={domainInstance && domainInstance.avbDescription && domainInstance.avbDescription.id}
								label={domainInstance && domainInstance.avbDescription && domainInstance.avbDescription.label}
								name={'avbDescription'}
								createable={{formElements: <AvbDescriptionFormElements/>, target:'description'}}
							/>
						</Form.Group>
					</Row>
					<Card className={"mb-2"}>
						<Card.Header>{t('avb.card.availableForNewPolicy.header')}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="col-md-5 col-lg-4" controlId="formAvailableFromForNewPolicyAvbYear">
									<Form.Label>{t('avb.availableFromForNewPolicy.label')}</Form.Label>
									<FormControl
										name={"availableFromForNewPolicy"}
										type={"date"}
										value= {domainInstance.availableFromForNewPolicy}
									/>
								</Form.Group>
								<Form.Group className="col-md-5 col-lg-4" controlId="formAvailableToForNewPolicyAvbYear">
									<Form.Label>{t('avb.availableToForNewPolicy.label')}</Form.Label>
									<FormControl
										name={"availableToForNewPolicy"}
										type={"date"}
										value= {domainInstance.availableToForNewPolicy}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
