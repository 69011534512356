import React from 'react';
import {Alert} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {faPersonDigging} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function UnderConstruction({what}) {
	const {t} = useTranslation();

	return (
		<Alert variant={"warning"} className={"text-center"}>
			<div><FontAwesomeIcon icon={faPersonDigging} size={"5x"} color={"orange"}/></div>
			<h4 className={"mt-3"}>{what ? t('default.underConstructionWhat', {what: what}) : t('default.underConstruction')}</h4>
		</Alert>
	)
}

export default UnderConstruction