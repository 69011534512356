import textService from "../_services/text.service";

const bootstrapTableService = {
	formatterBoolean,
	formatterDate,
	formatterDateTime,
	formatterAmount,
	formatterPercent
};

function formatterBoolean(value) {
	return textService.format(value, 'boolean')
}

function formatterDate(value) {
	return textService.formatDateTime(Date.parse(value),{dateStyle: 'medium'})
}

function formatterDateTime(value) {
	return textService.formatDateTime(Date.parse(value),{dateStyle: 'medium', timeStyle: 'short' })
}

function formatterAmount(value) {
	return textService.formatNumber(value)
}

function formatterPercent(value) {
	return textService.formatNumber(value) + ' %'
}

export default bootstrapTableService
