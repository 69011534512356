import React, {useMemo} from 'react';
import SimpleTable from "../_components/SimpleTable";
import {profitParticipationService} from "../_services/profitParticipation.service";

function TabContentProfitParticipationDetails({settlement}) {
	const rows = useMemo( () => {
		let result = []
		settlement.settlementItems.forEach( (settlementItem) => {
			result.push.apply( result, settlementItem.settlementItemProfitParticipationSettlements );
		})

		return result.sort( (a,b) => a.year - b.year );
	}, [settlement])



	return (
		<>
            <SimpleTable columns={profitParticipationService.getDetailsColumns()} rows={rows}/>
		</>
	)
}

export default TabContentProfitParticipationDetails