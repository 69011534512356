import React from 'react';
import {Button} from "react-bootstrap";

function SubmitButton( {isSubmitting, ...props} ) {
    return (
        <>
            <Button disabled={isSubmitting} {...props}>
                {isSubmitting && <span><span className="spinner-border spinner-border-sm mr-1"></span>&nbsp;</span>}
                {props.children}
            </Button>
        </>
    )
}

export default SubmitButton