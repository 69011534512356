import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import PreviewPolicyVersionSettlementBatch from "./PreviewPolicyVersionSettlementBatch";
import bootstrapTableService from "../_components/bootstrapTable.service";
import CreatePolicyVersionSettlementBatch from "./CreatePolicyVersionSettlementBatch";

function PolicyVersionSettlementBatch( {match}) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('default.id'),
		hidden: true
	}, {
		dataField: 'dateFrom',
		text: t('settlementBatch.dateFrom.label'),
		sort: true,
		formatter: (cellContent, row) => bootstrapTableService.formatterDate(cellContent)
	}, {
		dataField: 'dateTo',
		text: t('settlementBatch.dateTo.label'),
		sort: true,
		formatter: (cellContent, row) => bootstrapTableService.formatterDate(cellContent)
	}, {
		dataField: 'createdOn',
		text: t('settlementBatch.createdOn.label'),
		sort: true,
		formatter: (cellContent, row) => bootstrapTableService.formatterDateTime(cellContent)
	}, {
		dataField: 'createdBy',
		text: t( 'settlementBatch.createdBy.label' ),
		sort: true
	}, {
		dataField: 'settlementsCount',
		text: t( 'settlementBatch.settlementsCount.label'),
	},
	];

	return (
		<Index
			path={match.path}
			domainName={'policyVersionSettlementBatch'}
			columns={columns}
			toString={['name']}
			createElement={<CreatePolicyVersionSettlementBatch/>}
			showElement={<PreviewPolicyVersionSettlementBatch/>}
			sortField={'dateTo'}
			sortOrder={'desc'}
			allowEdits={false}
			allowDeletions={false}
			allowShow={true}
		/>
	);
}

export default PolicyVersionSettlementBatch;
