import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function AvbDescription({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('avbDescription.id.label'),
		hidden: true
	},
	{
		dataField: 'description',
		sortField: 'description_sort',
		text: t('avbDescription.description.label'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'avbDescription'}
			columns={columns}
			toString={ (object) => ( object.description ) }
			formElements={<FormElements/>}
			sortField={'description'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { AvbDescription };
