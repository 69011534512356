let refs = {};

export const domainTableService = {
    register,
    unregister,
    refresh
}

function register(id, ref) {
    if( id ) {
        refs[id] = ref;
    }
}

function unregister(id) {
    if ( id ) {
        delete refs[id];
    }
}


function refresh(id) {
    if ( id ) {
        const ref = refs[id];
        if ( ref ) {
            ref.current.refresh();
        }
    }
}
