import React, {useCallback, useMemo, useState} from 'react';
import {policyService} from "../_services/policy.service";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import textService from "../_services/text.service";
import restService from "../_services/rest.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import moment from "moment";
import xlsxService from "../_services/xlsx.service";

function FeePerYear() {
	const {t} = useTranslation();
	const [fees, setFees] = useState([]);
	const useFormObject = useForm();
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

	const onSubmit = (formData) => {
		let years = []
		for( let i = parseInt(formData.yearFrom); i <= parseInt(formData.yearTo); i++ ) {
			years.push(i)
		}

		setSubmitButtonDisabled(true);
		policyService.getTotalFeePerYear(years)
			.then( (result) => {
				setFees(result);
				setSubmitButtonDisabled(false);
			} )
			.catch( () => setSubmitButtonDisabled(false))
	};

	const exportToExcel = useCallback( (year) => {
		const columns = {
			POLICY: t( 'policy.label' ),
			VALID_FROM: t('createPolicy.version.validFrom'),
			VALID_TO: t('createPolicy.version.validTo'),
			POLICY_VERSION: t('policyVersion.label'),
			PRODUCT: t('product.label'),
			PRODUCT_TYPE: t('productType.label'),
			TARIFF: t('configuratorTargetTariff.tariff.label'),
			INDEXED: t('configuratorTarget.indexed.onlabel'),
			FEE_PER_YEAR: `${t('configuratorTarget.fee.label')} ${t('default.per')} ${t('default.year')}`,
			DAYS: t('default.days'),
			FEE_PER_YEAR_DAYS: t('configuratorTarget.fee.label') + ' ' + year,
			APPVERS_PART: t('feePerYear.appversParticipationPercent'),
			APPVERS_FEE: t('feePerYear.appversFeePerIntersection'),
			APPVERS_FEE_INDEXED: t('feePerYear.appversFeePerIntersectionIndexed'),
		}

		const col = (columnName) => {
			return xlsxService.getColLetter( Object.values(columns), columnName)
		}

		//rows
		const data = []
		let row = 2
		fees[year].details.forEach( (detail) => {
			data.push( {
				[columns.POLICY]: { l: { Target: `${ window.location.protocol }/${ window.location.hostname }${ window.location.port ? ':' + window.location.port : '' }${ restService.getTo( {
						classSimpleName: 'policyVersion',
						id: detail.policyVersionId
					}, 'show' ) }`}, v: detail.policyNumber },
				[columns.VALID_FROM]: moment(detail.validFrom).toDate(),
				[columns.VALID_TO]: detail.validTo !== null ? moment(detail.validTo).toDate() : '',
				[columns.POLICY_VERSION]: detail.policyNumber,
				[columns.PRODUCT]: detail.product,
				[columns.PRODUCT_TYPE]: detail.productType,
				[columns.TARIFF]: detail.tariff,
				[columns.INDEXED]: detail.indexed,
				[columns.FEE_PER_YEAR]: policyService.round20( detail.feePerYear ),
				[columns.DAYS]: detail.intersectionDays,
				[columns.FEE_PER_YEAR_DAYS]: { f: `${col(columns.FEE_PER_YEAR)}${ row }*${col(columns.DAYS)}${ row }/360` },
				[columns.APPVERS_PART]: detail.appversParticipationPercent,
 				[columns.APPVERS_FEE]: { f: `${col(columns.FEE_PER_YEAR_DAYS)}${ row }*${col(columns.APPVERS_PART)}${ row }/100` },
				[columns.APPVERS_FEE_INDEXED]: { f: `IF(${col(columns.INDEXED)}${ row },${col(columns.APPVERS_FEE)}${ row },0)` },
			});
			row++
		})

		xlsxService.createSheet( data, `Auswertung Indexierung pro Jahr ${year}`, [columns.FEE_PER_YEAR, columns.FEE_PER_YEAR_DAYS, columns.APPVERS_FEE, columns.APPVERS_FEE_INDEXED] )
	}, [fees, t])

	const feeRows = useMemo(() => {
		let prevFee = undefined
		let prevIndexedAppversFee = undefined
		return Object.keys(fees).map( (year) => {
			const totalFee = fees[year].totalFee
			const totalIndexedAppversFee = fees[year].totalIndexedAppversFee
			const diff = ( prevFee !== undefined ? totalFee - prevFee : undefined );
			const diffIndexed = ( prevIndexedAppversFee !== undefined ? totalIndexedAppversFee - prevIndexedAppversFee : undefined );
			let diffText = undefined
			if ( diff !== undefined ) {
				diffText = `${diff>0 ? '+' : ''}${ textService.formatNumber( Math.round(diff), {thousandSeparator: true, minimumFractionDigits: 0}) } CHF`
			}
			let diffTextIndexedAppvers = undefined
			if ( diffIndexed !== undefined ) {
				diffTextIndexedAppvers = `${diffIndexed>0 ? '+' : ''}${ textService.formatNumber( Math.round(diffIndexed), {thousandSeparator: true, minimumFractionDigits: 0}) } CHF`
			}
			prevFee = totalFee;
			prevIndexedAppversFee = totalIndexedAppversFee;

			return <tr key={year}>
				<td><Button type={'button'} onClick={() => exportToExcel(year)}><FontAwesomeIcon icon={faFileExcel}/></Button></td>
				<td className="text-end border-end">{ year }</td>
				<td className="text-end">{ textService.formatNumber( Math.round(totalIndexedAppversFee), {thousandSeparator: true, minimumFractionDigits: 0} ) } CHF</td>
				<td className="text-end border-end">{ diffTextIndexedAppvers }</td>
				<td className="text-end">{ textService.formatNumber( Math.round(totalFee), {thousandSeparator: true, minimumFractionDigits: 0} ) } CHF</td>
				<td className="text-end">{ diffText }</td>
			</tr>
		})
	}, [fees, exportToExcel])

	return (
		<>
			<h2>{t('reports.feePerYear.title')}</h2>

			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Card>
						<Card.Header>
							{t('default.filter')}
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="col-md-6" controlId="yearFrom">
									<Form.Label>{t('damageRendement.yearFrom.label')}</Form.Label>
									<FormControl
										name={"yearFrom"}
										type={"number"}
										value={moment().year()}
									/>
								</Form.Group>
								<Form.Group className="col-md-6" controlId="yearTo">
									<Form.Label>{t('damageRendement.yearTo.label')}</Form.Label>
									<FormControl
										name={"yearTo"}
										type={"number"}
										value={moment().year()+1}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Row className={"mt-3"}>
						<Col md={2}>
							<Button type={"submit"} disabled={submitButtonDisabled}>{ t('default.calculate') }</Button>
						</Col>
					</Row>
				</Form>
			</FormProvider>

			{ feeRows.length > 0 &&
				<Card className={"mt-3"}>
					<Card.Header>
						{t('feePerYear.results')}
					</Card.Header>
					<Card.Body>
						<Row>
							<Table>
								<thead className={'fw-bold'}>
									<tr>
										<td></td>
										<td className="text-end border-end" style={{width:'50px'}}>{t('default.year')}</td>
										<td className="text-end w-25">{t('feePerYear.appversFeePerIntersectionIndexed')}</td>
										<td className="text-end w-25 border-end">{t('default.difference')}</td>
										<td className="text-end w-25">{t('feePerYear.fee')}</td>
										<td className="text-end w-25">{t('default.difference')}</td>
									</tr>
								</thead>
								<tbody>
									{feeRows}
								</tbody>
							</Table>
						</Row>
					</Card.Body>
				</Card>
			}
		</>
	)
}

export default FeePerYear