import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";

function FormElements({domainInstance}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('roofType.card.common.header')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupName">
							<Form.Label>{t('roofType.name.label')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value= {domainInstance.name}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
