import React from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import { useHistory } from 'react-router-dom';
import reportMessageService from "../_services/reportMessage.service";
import {FormProvider, useForm} from "react-hook-form";

function EditReportMessage( {routeParams} ) {
	const { t } = useTranslation();
	const useFormObject = useForm();
	const code = routeParams.id
	const composedReportMessage = reportMessageService.useComposedReportMessage(code)
	const history = useHistory();

	const onSubmit = (data) => {
		reportMessageService.saveComposedReportMessage( data );
		history.goBack();
	}

	return (
		<>
			<FormProvider {...useFormObject}>
				<FormControl type={"text"} name={'code'} hidden={true} value={composedReportMessage.code}/>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Card className={"mb-2"}>
						<Card.Header><strong>{code}</strong></Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="12" controlId="formGroupName">
									<Form.Label>Deutsch</Form.Label>
									<FormControl
										name={"message_de"}
										type={"text"}
										as="textarea" rows={10}
										value= { composedReportMessage.message_de }
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-4"}>
								<Form.Group as={Col} md="12" controlId="formGroupName">
									<Form.Label>English</Form.Label>
									<FormControl
										name={"message_en"}
										type={"text"}
										as="textarea" rows={10}
										value= { composedReportMessage.message_en }
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Button type={"submit"} className='btn btn-default me-1'>{t('default.save')}</Button>
					<Button type={"button"} variant={"secondary"} className='btn btn-default me-1' onClick={() => history.goBack()}>{t('default.cancel')}</Button>
				</Form>
			</FormProvider>
		</>
	);
}

export { EditReportMessage };
