import React, {useMemo} from 'react';
import {Alert, Col, Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import InvoiceAdditionalInfo from "../settlement/InvoiceAdditionalInfo";
import websocketService from "../_services/websocket.service";
import {useTranslation} from "react-i18next";
import Trans from "../_components/Trans";
import moment from "moment";

function PolicyVersionSettlementBatchFormElements( {settlementBatch} ) {
    const {t} = useTranslation();
    const [settlementBatchUpdateState] = websocketService.useWebSocketSubscription(`/topic/settlementBatch-${ settlementBatch.id }/update`, {})

    const updatingAdditionalInfo = useMemo( () => {
        return Object.keys(settlementBatchUpdateState).length > 0 && !settlementBatchUpdateState.finished
    }, [settlementBatchUpdateState])

    const additionalInfoButtonText = useMemo( () => {
        if ( updatingAdditionalInfo ) {
            return <span className={'p-4'}>{settlementBatchUpdateState.currentIdx} / {settlementBatchUpdateState.totalCount}</span>
        }
        else {
            return t( 'default.edit' )
        }
    }, [settlementBatchUpdateState, t, updatingAdditionalInfo])

	return (
        <>
            <Row>
                <ShowField label={'settlementBatch.dateFrom.label'} object={settlementBatch} property={'dateFrom'} type={'date'} size={4}/>
                <ShowField label={'settlementBatch.dateTo.label'} object={settlementBatch} property={'dateTo'} type={'date'} size={4}/>
            </Row>
            <InvoiceAdditionalInfo clazzName={settlementBatch.className} additionalInfoButtonText={additionalInfoButtonText} updatingAdditionalInfo={updatingAdditionalInfo} instance={settlementBatch} />
            { settlementBatch.printWithSettlementBatchInvoicesCount > 0 && <Row className={"mt-3"}>
                <Col>
                    <Alert variant={"info"}>
                        <Trans i18nKey={'policyVersionSettlementBatch.printWithSettlementBatch.tooltip'} values={{count: settlementBatch.printWithSettlementBatchInvoicesCount, href: `/invoicePolicyVersion?billingFrom=AT_LEAST(${moment(settlementBatch.dateFrom).format("d.M.yyyy")})&billingFrom=AT_MOST(${moment(settlementBatch.dateTo).format("d.M.yyyy")})&printWithSettlementBatch=true`}}/>
                    </Alert>
                </Col>
            </Row> }
        </>
    )
}

export default PolicyVersionSettlementBatchFormElements