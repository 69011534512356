import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import appService from "./index";
import {saveAs} from "file-saver";

export const fileService = {
	downloadAttachment,
	attachmentsInfo,
	thumbnail,
	saveAttachmentsInfo,
	saveAttachmentWithContentDispositionFileName: saveAttachmentWithContentDispositionFileName
}

function downloadAttachment(id) {
	return new Promise( (resolve, reject) => {
		const { Authorization } = headers();
		const h = { Authorization };
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/files/download/${ id }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( response => response.blob() )
			.then( blob => resolve( blob ) )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	})
};

function attachmentsInfo(type, id) {
	return new Promise( (resolve, reject) => {
		const { Authorization } = headers();
		const h = { Authorization };
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/files/attachmentsInfo/${type}/${ id }`, {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( ( response ) => restService.handleServerResponse( response ) )
			.then( data => resolve(data) )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	})
};

function thumbnail(url, id) {
	return new Promise( (resolve, reject) => {
		const { Authorization } = headers();
		const h = { Authorization };
		const controller = new AbortController()
		fetch( `${ SERVER_URL }${url}/${ id }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( response => response.blob() )
			.then( blob => resolve( blob ) )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	})
};

function saveAttachmentsInfo( type, id, data ) {
	return new Promise( (resolve, reject) => {
		const { Authorization } = headers();
		const h = { Authorization };
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/files/saveAttachmentsInfo/${type}/${ id }`, {
			method: 'PUT',
			headers: h,
			signal: controller.signal,
			body: JSON.stringify(data)
		} )
			.then( ( response ) => restService.handleServerResponse( response ) )
			.then( data => resolve(data) )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	})
}

function saveAttachmentWithContentDispositionFileName( response) {
	return new Promise( (resolve, reject) => {
		try {
			restService.handleServerResponse( response )
				.then( ( content ) => {
					const contentDisposition = response.headers.get( "content-disposition" );
					const filename = contentDisposition.split( 'filename=' )[1]
					if ( !(content instanceof Blob) ) {
						content = new Blob([content], {type: "text/plain"});
					}

					saveAs( content, appService.fixFileName( filename ) );
					resolve();
				} )
				.catch( reject )
		}
		catch(error) {
			reject(error);
		}
	});
}