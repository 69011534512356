import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function Setting({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('setting.id'),
		hidden: true
	}, {
		dataField: 'name',
		text: t('setting.name'),
		sort: true
	}, {
		dataField: 'value',
		text: t('setting.value'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'setting'}
			columns={columns}
			toString={ (object) => ( object.name ) }
			formElements={<FormElements/>}
			sortField={'name'}
		/>
	);
}

export { Setting };
