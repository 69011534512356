import React from 'react';
import restService from "../_services/rest.service";
import {Card, Col, Row} from "react-bootstrap";
import appService from "../_services";
import ShowField from "../form/ShowField";

function ShowDamagePaymentInfoDetail(props) {
    const [damagePaymentInfoDetail] = restService.useDomainInstance( 'damagePaymentInfoDetail', props.id, false );

    const showBaseData = () => {
        return (
            <Card className='mb-2'>
                <Card.Header><h4>{appService.nullSafeGet( damagePaymentInfoDetail, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfoDetail.damageDetail'} object={damagePaymentInfoDetail} property={'damageDetail.label'} type={'text'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.amount'} object={damagePaymentInfoDetail} property={'amount'} type={'text'}/>
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfoDetail.participation'} object={damagePaymentInfoDetail} property={'participation'} type={'boolean'} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            {showBaseData()}
        </>
    )
}

export default ShowDamagePaymentInfoDetail
