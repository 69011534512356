import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import EditableTableRow from "./EditableTableRow";
import {Table, Alert, Button} from "react-bootstrap";
import i18n from 'i18next';
import editableTableService from "./editableTable.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpAZ} from "@fortawesome/free-solid-svg-icons";

const ERRORS_CELLS = 'cells';
const ERROR_GLOBAL = 'global';
export const ACTION_CREATE = "create";
export const ACTION_UPDATE = "update";
export const ACTION_DELETE = "delete";

function EditableTable(props) {
	const data = props.data;

	const refreshCallbackRefs = useRef({});

	const reorder = () => {
		const oldData = [...data];
		const sortedData = data.sort(props.autoOrderFn);
		sortedData.forEach( (row, index) => {
			row.ordering = index+1;
			if ( row["action"] !== ACTION_CREATE ) {
				row["action"] = ACTION_UPDATE;
			}
		})
		onChange(sortedData);
		refreshCells(oldData, sortedData)
	};

	useEffect( () => {
		if ( props.columns.includes('action') || props.columns.includes('ordering') ) {
			console.warn('Reserved column name(s) used EditableTable');
		}
	}, [props.columns])

	const getErrors = useCallback((type) => {
		if (props.validationErrors && props.validationErrors[type] ) {
			return props.validationErrors[type]
		}
		else {
			return type===ERRORS_CELLS?{}:undefined;
		}
	}, [props.validationErrors]);

	const getTableHeader = () => {
		let tableHead = props.columns.map( (column) => {
			let props = {key: column.id}
			if ( column.className ) {
				props.className = column.className
			}
			return <th {...props}>{column.label}</th>;
		});
		if ( props.allowDeletions ) {
			tableHead.push( <th style={ { width: "1%" } } key={ props.columns.length }/> ); //delete column
		}
		if( props.orderable ) {
			tableHead.push( <th style={{width: "1%"}} key={ props.columns.length + 1 }>
				{ props.autoOrderFn && <Button variant={"light"} className={"float-end"} onClick={reorder}>
					<FontAwesomeIcon icon={ faArrowUpAZ }/>
				</Button>}
			</th> ); //ordering column
		}
		return tableHead;
	};

	const refreshCells = useCallback((oldData, newData) => {
		//here we check, whether some data was changed. If yes, we rerender corresponding cells
		for ( let rowIndex in newData ) {
			props.columns.forEach( (column) => {
				if ( refreshCallbackRefs.current.hasOwnProperty(rowIndex) ) {
					refreshCallbackRefs.current[rowIndex](column.id, newData[rowIndex]);
				}
			})
		}
	}, [props, refreshCallbackRefs])

	const onChange = useCallback((newData) => {
		props.onChange( props.normalizeData ? normalizeData(newData) : newData );
	}, [props]);

	const handleRowChanged = useCallback((columnId, dataItem, rowId) => {
		if ( props.orderable ) {
			if ( !dataItem.ordering ) {
				dataItem.ordering = data.length + 1
			}
		}
		let actRow = data[rowId]
		let originalRow
		if ( actRow ) {
			originalRow = JSON.parse(JSON.stringify(data[rowId]))
		}
		data[rowId] = dataItem;
		let newData = [...data]

		const column = props.columns.find( (column) => column.id === columnId );
		if ( column && column.afterUpdate ) {
			//handleRowChanged is called also after changes, that doesn't concern specific column (e.g. during delete)
			//afterUpdate function must return a Promise!!
			column.afterUpdate(rowId, newData, originalRow)
				.then((newData) => {
					refreshCells( data, newData );
				})
		}

		onChange(newData);
	}, [data, props, refreshCells, onChange])

	const handleMoveUp = useCallback((rowId) => {
		let prevId = rowId-1;
		while ( prevId <= data.length ) {
			if ( prevId === -1 ) {
				//we went through whole list - break and unset nextId
				prevId = undefined;
				break;
			}
			if ( !data[prevId].deleted ) {
				//we have found next undeleted item - break
				break;
			}
			prevId--
		}

		if ( undefined !== prevId ) {
			const oldData = [...data];
			[data[prevId], data[rowId]] = [data[rowId], data[prevId]];
			[data[prevId].ordering, data[rowId].ordering] = [data[rowId].ordering, data[prevId].ordering];
			if (data[prevId]["action"] !== ACTION_CREATE) {
				data[prevId]["action"] = ACTION_UPDATE;
			}
			if (data[rowId]["action"] !== ACTION_CREATE ) {
				data[rowId]["action"] = ACTION_UPDATE;
			}
			const newData = [...data]
			onChange(newData);
		 	refreshCells(oldData, newData)
		}
	}, [data, refreshCells, onChange])

	const handleMoveDown = useCallback((rowId) => {
		let nextId = rowId+1;
		while ( nextId <= data.length ) {
			if ( nextId === data.length ) {
				//we went through whole list - break and unset nextId
				nextId = undefined;
				break;
			}
			if ( !data[nextId].deleted ) {
				//we have found next undeleted item - break
				break;
			}
			nextId++
		}

		if ( undefined !== nextId ) {
			const oldData = [...data];
			[data[rowId], data[nextId]] = [data[nextId], data[rowId]];
			[data[rowId].ordering, data[nextId].ordering] = [data[nextId].ordering, data[rowId].ordering];
			if ( data[rowId]["action"] !== ACTION_CREATE ) {
				data[rowId]["action"] = ACTION_UPDATE
			}
			if ( data[nextId]["action"] !== ACTION_CREATE ) {
				data[nextId]["action"] = ACTION_UPDATE
			}
			const newData = [...data]
			onChange(newData);
			refreshCells(oldData, newData)
		}
	}, [data, refreshCells, onChange])

	const tableBody = useMemo( () => {
		let rows = data.map( (rowData, index) => {
			if ( !rowData.deleted ) {
				return (
					<EditableTableRow onRef={(callback) => {refreshCallbackRefs.current[index] = callback}}
					                  key={ index } columns={ props.columns } rowId={ index } rowData={ rowData }
					                  onChange={ handleRowChanged }
					                  validationErrors={ getErrors( ERRORS_CELLS )[index] } moveUp={ handleMoveUp }
					                  moveDown={ handleMoveDown } orderable={ props.orderable } allowDeletions={ props.allowDeletions } beforeDelete={props.beforeDelete} beforeChange={props.beforeChange}/>
				)
			}
			else {
				return undefined;
			}
		});
		if ( props.allowAdditions ) {
			rows.push(
				<EditableTableRow key={ data.length } columns={ props.columns } rowId={ data.length } rowData={ {} }
				                  isEmptyRow={ true } onChange={ handleRowChanged } moveUp={ handleMoveUp }
				                  moveDown={ handleMoveDown } orderable={ false }/>
			)
		}
		return rows;
	}, [data, props, getErrors, handleMoveUp, handleMoveDown, handleRowChanged])

	const getGlobalErrorAlert = () => {
		const globalError = getErrors( ERROR_GLOBAL );

		if ( globalError ) {
			return (<Alert variant={ 'danger' }>{ globalError }</Alert>)
		}
		else {
			return undefined;
		}
	}

	return (
		<div>
			{ getGlobalErrorAlert() }
			<Table responsive="sm">
				<thead>
					<tr>
						{getTableHeader()}
					</tr>
				</thead>
				<tbody>
					{tableBody}
				</tbody>
			</Table>
		</div>
	);
}

export function validateTable(data, columns, globalValidator) {
	let cellsValidationErrors = {};
	let globalValidationError = undefined;

	if (data) {
		data.forEach( ( row, rowIndex ) => {
			columns.forEach( ( column ) => {
				let error = '';

				//check required
				if ( column.input.required === true ) {
					if ( !row[column.id] ) {
						error = i18n.t( "input.required.validation.error" );
					}
				}
				//check input type
				if ( !error ) {
					error = editableTableService.getValue( column, row[column.id] ).error;
				}

				//check validator
				if ( !error ) {
					if ( column.validator ) {
						error = column.validator( row[column.id], data, rowIndex );
					}
				}

				if ( error ) {
					if ( !cellsValidationErrors[rowIndex] ) {
						cellsValidationErrors[rowIndex] = {}
					}
					cellsValidationErrors[rowIndex][column.id] = error;
				}
			} )
		} )

		if ( globalValidator ) {
			globalValidationError = globalValidator(data);
		}
	}

	if ( Object.keys(cellsValidationErrors).length === 0 && !globalValidationError ) {
		return null;
	}
	else {
		return {[ERRORS_CELLS]: cellsValidationErrors, [ERROR_GLOBAL]: globalValidationError};
	}
}

export function getValue(column, rawValue) {
	let result;
	switch ( column.input.tag ) {
		case 'input':
		case 'label':
		case 'check':
		case 'numericFormat':
			result = {value: rawValue};
			break;
		case 'select':
			result = {value: ((rawValue && rawValue.id) ? rawValue.id : rawValue) || ''};
			break;
		case 'typeahead':
			result = {
				defaultSelected: ( rawValue === undefined || rawValue === null ) ? [] : [rawValue],
				selected: ( rawValue === undefined || rawValue === null ) ? [] : [rawValue],
				value: rawValue || ''
			};
			break;
		default:
			throw new Error('unknown column.input.tag ' + column.input.tag);
	}
	return result;
}

export function normalizeData(data) {
	if ( data ) {
		return data.filter( ( row ) => !row.deleted ).map( ( row ) => {
			delete ( row.action );
			return row
		} )
	}
	else {
		return data;
	}
}

EditableTable.defaultProps = {
	orderable: false,
	allowDeletions: true,
	allowAdditions: true
}

export default EditableTable;
