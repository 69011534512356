import React from 'react';
import ShowField from "../form/ShowField";
import {Row} from "react-bootstrap";

function FormElementsParticipation({settlement, onRefreshSettlement}) {
	return (
		<Row>
			<ShowField label={'creditNoteParticipation.dateFrom'} object={settlement} property={'dateFrom'} type={'date'} size={4}/>
			<ShowField label={'creditNoteParticipation.dateTo'} object={settlement} property={'dateTo'} type={'date'} size={4}/>
		</Row>
	)
}

export default FormElementsParticipation