import React, {useMemo} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as CountryFormElements} from "../country/FormElements"
import EditableTableControl from "../form/EditableTableControl";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();

	const columns = useMemo( () => [
		{id: "validFrom", label: t("loeschfuenferRate.validFrom"), input: {tag: "input", type: "date"}, validator: (value,data,rowIndex) => { return (value > data[rowIndex].validTo)?t('default.fromCantBeAfterTo.message'):undefined}},
		{id: "rate", label: t("loeschfuenferRate.rate"), input: {tag: "input", type: "double"}}//, required: true
	], [t] )

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('county.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupCountyCode">
							<Form.Label>{t('county.code')}</Form.Label>
							<FormControl
								name={"code"}
								type={"text"}
								value={domainInstance.code || (defaultValues && defaultValues.code)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupCountyName">
							<Form.Label>{t('county.name')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value={domainInstance.name || (defaultValues && defaultValues.name) }
								rules={ {required: true} }
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="4" controlId="formGroupCountyCountry">
							<Form.Label>{t('county.country')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"country"}
								sort={"name"}
								value={ domainInstance && domainInstance.country && domainInstance.country.id }
								label={ domainInstance && domainInstance.country && domainInstance.country.name }
								name={'country'}
								createable={{formElements: <CountryFormElements/>}}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('loeschfuenferRate.card.info')}</Card.Header>
				<Card.Body>
					<Row>
						<EditableTableControl name={"loeschfuenferRates"} columns={columns} data={domainInstance.loeschfuenferRates || []}/>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
