import {Button} from "react-bootstrap";

function ConfiguratorTargetMessage( {text, buttons} ) {
	return (
		<div>
			<p>{ text }</p>
			<div>
				{ buttons.map( (button, idx) => <Button key={idx} variant={button.variant} onClick={button.onClick} className={"me-1"}>{button.text}</Button> )}
			</div>
		</div>
	)
}

export default ConfiguratorTargetMessage

