import React, {useCallback, useMemo, useState, useEffect, useRef} from 'react';
import {Alert, Badge, Button, Card, Col, Form, Row} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ConfiguratorTargetList} from "./ConfiguratorTargetList";
import textService from "../_services/text.service";
import {configuratorService} from "../_services/configurator.service";
import appService from "../_services";
import {messageBoxService} from "../_services/messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";

function CreatePolicyDetail(props) {
	const { t } = useTranslation()
	const ref = useRef(null);
	const useFormObject = useForm();
	const stepValues = useMemo( () => (props.values[props.stepName]), [props.values, props.stepName] );
	const policyValues = useMemo( () => (props.values['policy']), [props.values] );
	const validFrom = useMemo( () => (policyValues.validFrom), [policyValues] );
	const [feeTotal, setFeeTotal] = useState(undefined);
	const [activeConfiguratorTargetKey, setActiveConfiguratorTargetKey] = useState(0);
	const obsoleteTargets = useMemo( () => (props.values.obsoleteTargets || []), [props.values] );

	const targets = useMemo( () => ( stepValues.targets ? stepValues.targets : [] ), [stepValues.targets] );

	const handleConfiguratorTargetHeaderClick = useCallback( (eventKey) => {
		configuratorService.expandAndScrollToConfiguratorHeader(eventKey);
	}, []);

	const expandConfiguratorTarget = useCallback( (key) => {
		if ( activeConfiguratorTargetKey === key  ) {
			setActiveConfiguratorTargetKey( 0 );
		}
		else {
			setActiveConfiguratorTargetKey( parseInt( key ) );
		}
	}, [setActiveConfiguratorTargetKey, activeConfiguratorTargetKey])

	useEffect( () => {
		ref.current = {expandConfiguratorTarget: expandConfiguratorTarget}
		configuratorService.registerConfiguratorAccordeon( ref );
	}, [expandConfiguratorTarget, ref]);

	const handleClickPrevious = () => {
		props.previousStep();
	}

	const onError = useCallback((errors, e) => {
		const firstErrorKey = Object.keys(errors)[0].match(/.*-(\d+)/)[1];
		handleConfiguratorTargetHeaderClick( String( firstErrorKey ), null );
	}, [handleConfiguratorTargetHeaderClick]);

	const onSubmit = useCallback((data) => {
		const targetsParticipations = targets.map( target => {
			return {target: target.target, participations: data[`participation-${target.key}`].sort( (a,b) => a.partner.id - b.partner.id).map( p => {
					return  `${p.partner.id}-${p.percent}`
				} ).join(';')}
		})

		const grouppedParticipations =  appService.groupBy(targetsParticipations, t => t.participations );

		const submit = () => {
			//read values from ConfiguratorTarget elements and copy them to targets
			targets.forEach( (target) => {
				let changed = false;
				[
					'targetId',
					'amount',
					'policyType',
					'avb',
					'zip',
					'firstLossRisk',
					'participation',
					'individualTexts',
					'tariff',
					'indexed',
					'additionalDiscountSurchargePercent',
					'additionalDiscountSurchargeValue',
					'discountSurchargeNames',
					'discountSurcharge',
					'useStampTax',
					'useLoeschfuenfer',
					'fee',
					'tariffUnit',
					'isManualTariff'
				].forEach( (property) => {
					let newValue = data[property+'-'+target.key];
					if ( target[property] !== newValue) {
						changed = true
						target[property] = newValue;
					}
				});
				if (changed && !target.action) {
					target.action = 'update';
				}
			});
			props.handleUpdate(props.stepName, {...stepValues, ...{targets: targets}});
			props.goToNamedStep('lastTouches');
		}

		if ( Object.keys(grouppedParticipations).length > 1 ) {
			let exit = false;
			messageBoxService.display( i18n.t( 'createPolicy.detail.products.differentParticipation.content' ), i18n.t( 'createPolicy.detail.products.differentParticipation.title' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
				.then( ( button ) => {
					if ( button === MessageBoxButtons.YES ) {
						submit()
					}
				})
		}
		else {
			submit()
		}

	}, [targets, props, stepValues]);

	const handleAddTarget = () => {
		props.goToNamedStep('addProductBuilding');
	}

	const handleOnConfiguratorTargetClose = (key, _targets = null) => {
		if ( !_targets ) {
			//_targets is used for recursive call of this method. If the targets are not passed as param in this case,
			//then variable targets is used, but it contains still closed targets from previous calls of handleOnConfiguratorTargetClose
			_targets = targets
		}
		let closedTarget = _targets.find( (target) => target.key === key );
		let newTargets;
		if ( closedTarget.action === 'create' ) {
			newTargets = _targets.filter( (target) => target.key !== key );
		}
		else if ( !closedTarget.action || closedTarget.action === 'update' ) {
			closedTarget.action = 'delete';
			newTargets = _targets;
		}
		props.handleUpdate(props.stepName, {...stepValues, ...{targets: newTargets}});

		// const dependentTargets = stepValues.getDependentTargets(targets, closedTarget)
		// dependentTargets.forEach( (dependentTarget) => {
		// 	handleOnConfiguratorTargetClose(dependentTarget.key, newTargets);
		// })
	}

	const handleOnFeeTotalChanged = useCallback( (feeTotal) => {
		setFeeTotal(feeTotal)
	}, [setFeeTotal])

	const feeTotalElement = useMemo( () => {
		if ( feeTotal ) {
			return (
				<Row>
					<Col md={12}>
						<h5>
							<Badge pill variant="success">
								Total { textService.formatNumber(feeTotal) } CHF
							</Badge>
						</h5>
					</Col>
				</Row>
			)
		}
		else {
			return undefined;
		}
	}, [feeTotal])

	const obsoleteTargetsElement = useMemo(() => {
		if ( obsoleteTargets.length > 0 ) {
			return (
				<Alert variant={ "warning" }>
					{ t( 'createPolicy.detail.obsoleteTargets' ) }
					<ul className={ "mb-0" }>
						{ obsoleteTargets.map( ( obsoleteTarget ) => {
							return <li>{ obsoleteTarget }</li>
						} ) }
					</ul>
				</Alert>
			)
		}
		else {
			return undefined;
		}
	}, [obsoleteTargets, t])

	return (
		<div>
			{ obsoleteTargetsElement }
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit, onError)}>
					<Card className={"mb-2"}>
						<Card.Header>
							<div>{t('createPolicy.detail.products.header')}</div>
							{feeTotalElement}
						</Card.Header>
						<Card.Body>
							<Row>
								<Col md={12}>
									<ConfiguratorTargetList
										onConfiguratorTargetHeaderClick={handleConfiguratorTargetHeaderClick}
										activeConfiguratorTargetKey={activeConfiguratorTargetKey}
										targets={targets}
										onClose={handleOnConfiguratorTargetClose}
										validFrom={validFrom}
										feeTotalChanged={handleOnFeeTotalChanged}
										getCorrespondingTargets={stepValues.getCorrespondingTargets}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					<div className="create-policy-footer p-3">
						<Button className='btn btn-success me-1' onClick={handleAddTarget}>{t('createPolicy.detail.addProduct.label')}</Button>
						<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
						{ targets.filter( (target) => !['delete'].includes(target.action) ).length > 0 && <Button type={"submit"} className='btn btn-default'>{t('default.next')}</Button>}
					</div>
				</Form>
			</FormProvider>
		</div>
	);
}

export { CreatePolicyDetail };
