import React, {useMemo, useRef} from 'react';
import PropTypes from "prop-types";
import DomainTable from "../_components/DomainTable";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";

function DamageShares( {namedCriteria, searchOptions, hiddenFields}) {
    const {t} = useTranslation();
    const domainTableRef = useRef()

    // const handlePrintSettlement = useCallback( (row) => {
    //     policyService.printSettlement( row.id, row.number, row.policyNumber, row.partner.label, row.className )
    //         .then( () => { /*invoicePolicyVersion printed*/} )
    // }, []);

    const columns = useMemo(() => {
        const _columns = [
            {
                dataField: 'id',
                text: t('damageShare.id'),
                hidden: true,
            },
            {
                dataField: 'company',
                formatter: (cellContent, row) => <Link to={ restService.getTo( cellContent, 'edit' ) } target = "_blank" rel = "noopener noreferrer" >
                    { cellContent.label }
                </Link>,
                text: t('damageShare.company'),
            },
            {
                dataField: 'percentShare',
                text: t('damageShare.percentShare'),
            },
            // {
            //     dataField: '',
            //     text: '',
            //     sort: false,
            //     classes: (cell, row, rowIndex, colIndex) => 'text-end',
            //     formatter: (cellContent, row) => {
            //         return (
            //             <>
            //                 {/*<Button variant={'primary'} size={'sm'} onClick={ () => handlePrintSettlement(row) }>*/}
            //                 {/*    <FontAwesomeIcon icon={faFilePdf}/>*/}
            //                 {/*</Button>*/}
            //                 <Link className={`btn btn-sm btn-primary ms-1`} to={`/damageDetail/show/${row.id}`} target={"_blank"} rel={"noopener noreferrer"}>
            //                     <FontAwesomeIcon icon={faEye}/>
            //                 </Link>
            //                 {/*{ row.cancelledWhy === SettlementCancelReason.NOT_CANCELLED &&*/}
            //                 {/*    <Button className={ "ms-1" } variant={ 'danger' } size={ 'sm' }*/}
            //                 {/*            onClick={ () => handleCancelSettlementQuestion( row ) }>*/}
            //                 {/*        <FontAwesomeIcon icon={ faFileCircleXmark }/>*/}
            //                 {/*    </Button>*/}
            //                 {/*}*/}
            //             </>
            //         )
            //     },
            //     headerStyle: (column, colIndex) => {
            //         return { width: '10%' };
            //     },
            // }
        ];

        return _columns.filter( (column) => !hiddenFields.includes( column.dataField ) );
    }, [hiddenFields, t])

    return (
        <>
            <DomainTable
                ref={domainTableRef}
                domainName={'damageShare'}
                columns={columns}
                namedCriteria={namedCriteria}
                searchOptions={searchOptions}
                // rowClasses={rowClasses}
                // sortField={'billingFrom'}
                // sortOrder={'desc'}
            />
        </>
    )
}


DamageShares.defaultProps = {
    hiddenFields: []
};

DamageShares.propTypes = {
    namedCriteria: PropTypes.object,
    hiddenFields: PropTypes.array
};

export { DamageShares };
