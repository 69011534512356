import React, {useState} from 'react';
import OtherLeadershipChecks from "./OtherLeadershipChecks";
import moment from "moment";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";

function OtherLeadershipCheck(){
    const [year, setYear] = useState(moment().year());

    return (
        <>
            <Row>
                <Col md={2}>
                    <InputGroup className="mb-3">
                        <Button variant="outline-secondary" onClick={ () => setYear(year-1) }>-</Button>
                        <Form.Control
                            className={"text-center fw-bolder"}
                            value={year}
                            onChange={ (e) => setYear(parseInt(e.target.value)) }
                        />
                        <Button variant="outline-secondary" onClick={ () => setYear(year+1) }>+</Button>
                    </InputGroup>
                </Col>
            </Row>
            <OtherLeadershipChecks year={year} />
        </>
    );
}

export default OtherLeadershipCheck