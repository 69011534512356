import React, {useContext, useState} from "react";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import {faChevronCircleRight} from "@fortawesome/free-solid-svg-icons";
import {ConfiguratorTargetBaseContent} from "./ConfiguratorTargetBaseContent";
import {ConfiguratorTargetDecIncContent} from "./ConfiguratorTargetDecIncContent";
import {ConfiguratorEditContext} from "./contexts";


function ConfiguratorNodeContent ({configuratorNodeDomainHolder, isPartOfTariffName = false, displayPartOfNameWarning = false, active}) {
    const [domain] = useState(configuratorNodeDomainHolder.domain)
    const {showNodeDomainHolderId, showDomainId} = useContext(ConfiguratorEditContext)

    const getDefaultContent = ( holderId, id, content, isPartOfTariffName, domainClassName, active ) => {
        return (
            <span key={id} className={`default-content ${domainClassName}-content ${ active ? '' : 'inactive-node'}` }>
                {showNodeDomainHolderId &&<Badge  className="me-1" bg="info">{holderId}</Badge>}
                {showDomainId &&<Badge  className="me-1" bg="warning">{id}</Badge>}
                {content}{isPartOfTariffName && <>&nbsp;<FontAwesomeIcon title={i18n.t('configuratorNodeEditor.isPartOfTariffName.onlabel')} size={'xs'} icon={faChevronCircleRight} /></>}
            </span>
        )
    }

    const getContent = () => {
        switch(domain.className) {
            case 'configuratorTargetBase':
            case 'configuratorTargetAdditionalProducts':
            case 'configuratorTargetDependentProduct':
            case 'configuratorTargetBaseBroker':
            case 'configuratorTargetAdditionalProductsBroker':
            case 'configuratorTargetDependentProductBroker':
                return <ConfiguratorTargetBaseContent  holderId={configuratorNodeDomainHolder.id} configuratorTarget={domain} displayPartOfNameWarning={displayPartOfNameWarning} isBrokerTariff={domain.className.substring(domain.className.length - 6) === 'Broker'}/>
            case 'configuratorTargetDecInc':
                return <ConfiguratorTargetDecIncContent holderId={configuratorNodeDomainHolder.id} domain={domain}/>
            case 'productType':
            case 'product':
                return getDefaultContent( configuratorNodeDomainHolder.id, domain.id, domain.content.name, isPartOfTariffName, domain.className, active );
            default:
                return getDefaultContent( configuratorNodeDomainHolder.id, domain.id, domain.content, isPartOfTariffName, domain.className, active );
        }
    }

    return (
        <>
            { getContent() }
        </>
    )
}
export { ConfiguratorNodeContent }
