import {Col, Form, InputGroup, OverlayTrigger, Popover, Table} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import textService from "../_services/text.service";

function ReinsuranceRow( { id, description, expectedHarm, reinsuranceAnsatz, defaultReinsuranceRates } ) {
	const { t } = useTranslation()
	const [expectedHarmVal, setExpectedHarmVal] = useState(expectedHarm)
	const [reinsuranceAnsatzVal, setReinsuranceAnsatzVal] = useState(reinsuranceAnsatz)

	const defaultReinsuranceRate = useMemo( () => {
		if ( defaultReinsuranceRates ) {
			const rate = defaultReinsuranceRates.find( ( item ) => (item.from===null || item.from <= expectedHarmVal) && (item.to===null || expectedHarmVal <= item.to ));
			if ( rate ) {
				return rate.percent;
			}
		}
		return undefined;
	}, [defaultReinsuranceRates, expectedHarmVal] );

	const renderPopover = (
		<Popover id="popover-contained">
			<Popover.Body>
				<Table size="sm">
					<tbody>
					{ defaultReinsuranceRates && defaultReinsuranceRates.map( ( rate, idx ) => {
						let style = {}
						if ( rate.percent === defaultReinsuranceRate ) {
							style['color'] = 'blue';
						}
						return <tr key={ idx }
						           style={ style }>
							<td className={"text-end"}>{ ( rate.from !== undefined && rate.from !== null ) ? textService.formatNumber(rate.from, {thousandSeparator: true, minimumFractionDigits: 0}) : '-'}</td>
							<td className={"text-center"}>-</td>
							<td className={"text-end"}>{ ( rate.to !== undefined && rate.to !== null ) ? textService.formatNumber(rate.to, {thousandSeparator: true, minimumFractionDigits: 0}) : '-' }</td>
							<td className={"text-end fw-bold"}>{ rate.percent } %</td>
						</tr>
					} ) }
					</tbody>
				</Table>
			</Popover.Body>
		</Popover>
	)

	const getDefaultReinsuranceExpectedValueElement = () => {
		let result = undefined;
		if ( defaultReinsuranceRate ) {
			let style = {cursor: "pointer"}
			if ( defaultReinsuranceRate !== reinsuranceAnsatzVal ) {
				style['color'] = 'red';

				result =
					<OverlayTrigger
						delay={ { show: 250, hide: 400 } }
						overlay={ renderPopover }
					>
						<span style={ style }
						      size={ "sm" }>&nbsp;{ t( 'createPolicy.policy.reinsuranceAnsatz.expectedValue', { expectedValue: defaultReinsuranceRate } ) }</span>
					</OverlayTrigger>
			}
		}
		return result;
	}

	return (
		<>
			<Form.Group as={ Col } md="6" controlId="groupExpectedHarm">
				<Form.Label>{ t( 'createPolicy.policy.expectedHarm.label' ) } {description}</Form.Label>
				<FormControl
					type={ "number" }
					name={ "expectedHarm-" + id }
					value={ expectedHarmVal }
					thousandSeparator={ true }
					onChange={ (value) => setExpectedHarmVal(value) }
					rules={{
						required: true,
					}}
				/>
			</Form.Group>
			<Form.Group as={ Col } md="6" controlId="groupReinsuranceAnsatz">
				<Form.Label>{ t( 'createPolicy.policy.reinsuranceAnsatz.label' ) }  {description}</Form.Label>
				<InputGroup>
					<FormControl
						type={ "number" }
						name={ "reinsuranceAnsatz-" + id }
						value={ reinsuranceAnsatzVal }
						onChange={ (value) => setReinsuranceAnsatzVal(value.target.value === "" ? undefined : parseInt(value.target.value)) }
						validationMessages={{
							range: t('default.validationMessage.range', {from:"1", to:"100"}),
						}}
						rules={{
							validate: {
								range: (v) => v===undefined || v==="" || (1<=v && v<=100)
							},
							required:true,
						}}
					/>
					<InputGroup.Text>&#37; { getDefaultReinsuranceExpectedValueElement() }</InputGroup.Text>
				</InputGroup>
			</Form.Group>
		</>
	)
}

ReinsuranceRow.propTypes = {
	id: PropTypes.number.isRequired,
	description: PropTypes.string,
	expectedHarm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	reinsuranceAnsatz: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultReinsuranceRates: PropTypes.array
}

export default ReinsuranceRow
