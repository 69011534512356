import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import EditableTableControl from "../form/EditableTableControl";
import restService from "../_services/rest.service";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import ReinsuranceRow from "./ReinsuranceRow";
import {policyService} from "../_services/policy.service";

function CreatePolicyLastTouches( props ) {
	const { t } = useTranslation()
	const useFormObject = useForm();
	const detailValues = useMemo( () => (props.values['detail']), [props.values] );
	const usedProductTypeIds = useMemo( () => {
			if ( detailValues && detailValues.targets && detailValues.targets.length > 0 ) {
				const ids = detailValues.targets
					.filter( (target) => target.action !== 'delete' && target.configuratorValues.productType )
					.map( (target) => target.configuratorValues.productType.value );
				return [...new Set(ids)];
			}
			return [];
		}, [detailValues] );
	const stepValues = useMemo( () => (props.values[props.stepName]), [props.values, props.stepName] );
	const standardTexts = useMemo( () => stepValues.standardTexts || [], [stepValues.standardTexts]);
	const targets = useMemo( () => ( detailValues.targets ? detailValues.targets : [] ), [detailValues.targets] );
	const configuratorNodeIds = useMemo( () => {
		var result = []
		for( var t in targets ) {
			const target =  targets[t];
			for ( var n in target.jsPathToRoot ) {
				const node = target.jsPathToRoot[n];
				result.push(node.id);
			}
		}
		return result;
	}, [targets] )
	const availableStandardTextsHsNamedRestriction = useMemo( () => ( { namedRestriction: { queryName: "availableStandardTexts", params: { configuratorNodeIds: configuratorNodeIds } } }), [configuratorNodeIds] );
	const [availableStandardTexts] = restService.useDomainInstancesList('standardText', undefined, undefined, 'theme_sort', undefined, availableStandardTextsHsNamedRestriction, undefined, "select")
	const [productTypes] = restService.useDomainInstancesList('productType');

	const reinsuranceRates = useMemo( () => {
		let result = {};

		if ( productTypes &&  productTypes.length > 0 ) {
			productTypes.forEach( (productType) => {
				if ( productType.separateReinsurance ) {
					result[ productType.id ] = productType.reinsuranceRates;
				}
			});
		}

		return result;
	}, [productTypes]);

	const descriptions = useMemo( () => {
		let result = {};

		if ( productTypes &&  productTypes.length > 0 ) {
			productTypes.forEach( (productType) => {
				result[productType.id] = productType.name;
			});
		}

		return result;
	}, [productTypes]);

	const reinsurance = useMemo( ()=> {
		let result
		if ( !stepValues.reinsurance || stepValues.reinsurance.some( (r) => r.id === 0) ) {
			result = [ { id: 0 } ] //default reinsurance
		}
		else {
			result = stepValues.reinsurance
		}
		return result;
	}, [stepValues.reinsurance]);

	const handleClickPrevious = () => {
		props.goToNamedStep('detail');
	}

	const onSubmit = (data) => {
		data.reinsurance = Object.keys(reinsuranceRates).map( (productTypeId) => {
			return {
				id: productTypeId,
				expectedHarm: data['expectedHarm-' + productTypeId],
				reinsuranceAnsatz: data['reinsuranceAnsatz-' + productTypeId]
			}
		});
		props.handleUpdate(props.stepName, data );
		props.goToNamedStep('review');
	}

	const columns = [
		{id: "standardText", label: t('createPolicy.standardText.theme.label') , input: {tag: "select", values: availableStandardTexts, required: true, nullable: true }},
		//{id: "standardText.autoOrderIndex", label: t('createPolicy.autoOrderIndex.theme.label') , input: {tag: "label"}},
	]

	const reinsuranceRows = useMemo(() => {
		return Object.keys(reinsuranceRates)
			.filter ( (ptId) => {
				return usedProductTypeIds.some( (id) => parseInt(id) === parseInt(ptId) ) || parseInt(ptId) === 0
			})
			.map( (ptId) => {
				const productTypeId = parseInt(ptId)
				const r = reinsurance.find( ( r ) => productTypeId === parseInt(r.id) );
				return <ReinsuranceRow
					key={ productTypeId }
					id={ productTypeId }
					description={ descriptions[productTypeId] }
					expectedHarm={ r && r.expectedHarm }
					reinsuranceAnsatz={ r && r.reinsuranceAnsatz }
					defaultReinsuranceRates={ reinsuranceRates[productTypeId] || [] }
			/>
		});
	}, [descriptions, reinsurance, reinsuranceRates, usedProductTypeIds]);

	const beforeChangeStandardText = useCallback( (rowData, column, newValue) => {
		if ( column === 'standardText') {
			return policyService.checkMandatoryText( rowData.standardText.id, standardTexts, targets, 'standardText' )
		}

		return true;
	}, [standardTexts, targets])

	const beforeDeleteStandardText = useCallback((rowData) => {
		return policyService.checkMandatoryText( rowData.standardText.id, standardTexts, targets, 'standardText' )
	}, [standardTexts, targets]);

	const insurancesHsNamedRestriction = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "insurance",
					params: {}
				}
		}
	}, [])

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Card className={"mb-2"}>
						<Card.Header>{t('createPolicy.standardText.card.header')}</Card.Header>
						<Card.Body>
							<Row>
								<EditableTableControl
									autoOrderFn={ (a,b) => {
										let result = 0;
										if (!result) {
											result = a.standardText.autoOrderIndex - b.standardText.autoOrderIndex;
										}
										if (!result) {
											result = a.standardText.label.localeCompare(b.standardText.label);
										}
										return result;
									} }
									name={"standardTexts"}
									columns={columns}
									data={standardTexts || []}
									orderable={true}
									onChange={ (value) => {
										props.handleUpdate( 'lastTouches', {...stepValues, standardTexts: value} );
									} }
									beforeDelete={beforeDeleteStandardText}
									beforeChange={beforeChangeStandardText}
								/>
							</Row>
						</Card.Body>
					</Card>

					<Card className={"mb-2"}>
						<Card.Header>{t('createPolicy.version.reinsurance.header')}</Card.Header>
						<Card.Body>
							<Row className={"mb-3"}>
								<Form.Group as={ Col } md="12" controlId="groupReinsuranceCompany">
									<Form.Label>{ t( 'createPolicy.version.reinsuranceCompany.label' ) }</Form.Label>
									<RestAsyncSelectControl
										noSelection={true}
										domainName={ "partner" }
										sort={ "name" }
										value={ stepValues.reinsuranceCompany ? stepValues.reinsuranceCompany.id : null }
										label={ stepValues.reinsuranceCompany ? stepValues.reinsuranceCompany.label : null }
										name={ 'reinsuranceCompany' }
										searchOptions={ insurancesHsNamedRestriction }
										disabled={true}
									/>
								</Form.Group>
							</Row>
							<Row>
								{reinsuranceRows}
							</Row>
						</Card.Body>
					</Card>

					<div className="create-policy-footer p-3">
						<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
						<Button type={"submit"} className='btn btn-default'>{t('default.next')}</Button>
					</div>
				</Form>
			</FormProvider>
		</div>
	);
}

export { CreatePolicyLastTouches };
