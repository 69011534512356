import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import {useEffect, useState} from "react";

const serviceService = {
    usePoliciesVersionsWithIncompleteRV,
    reindexAll,
};

function usePoliciesVersionsWithIncompleteRV() {
    const [ policiesVersionsWithIncompleteRV, setPoliciesVersionsWithIncompleteRV ] = useState([])

    useEffect( () => {
        const h = headers();
        const controller = new AbortController();
        fetch(`${ SERVER_URL }/api/service/showPoliciesWithMissingRV`, {
            method: 'GET',
            headers: h,
            responseType: 'blob',
            signal: controller.signal
        })
            .then( response => restService.handleServerResponse( response ) )
            .then( json => {
                setPoliciesVersionsWithIncompleteRV(json.items);
            } )
            .catch( (error) => {
                restService.handleServerErrors( error, controller.signal );
            } );

        return function cleanup() {
            controller.abort()
        }
    }, []);

    return policiesVersionsWithIncompleteRV;
}

function reindexAll() {
    return new Promise((resolve, reject) => {
        const h = headers();
        const controller = new AbortController();
        fetch( `${ SERVER_URL }/api/service/reindexAll`, {
            method: 'GET',
            headers: h,
            signal: controller.signal,
        } )
            .then( ( response ) => {
                return restService.handleServerResponse( response )
            } )
            .then( successJSON => resolve( successJSON ) )
            .catch( error => reject( error, controller.signal ) );
    })
}

export default serviceService;
