import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function Salutation({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('salutation.id'),
		hidden: true
	}, {
		dataField: 'description',
		sortField: 'description_sort',
		text: t('salutation.description'),
		sort: true
	}, {
		dataField: 'salutation',
		sortField: 'salutation_sort',
		text: t('salutation.salutation'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'salutation'}
			columns={columns}
			toString={ (object) => ( object.description ? object.description : object.salutation ) }
			formElements={<FormElements/>}
			sortField={'salutation_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { Salutation };
