import React, {useState} from 'react';
import {Dropdown, Form} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

function DropdownItemWithOptions({label, options, onClick}) {
    const {t} = useTranslation()
    const [values, setValues] = useState( (() => {
        let result = {};
        options.forEach( (option) => {
            result[option.name] = option.checked;
        })
        return result;
    })());

	return (
        <Dropdown.Item onClick = { ( event ) => {
            onClick(values);
        }}>
            {label}
            <span
                onClick={ (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}>
                { options.map( (option, index) => {
                        switch ( option.control ) {
                            case 'switchbutton':
                                const onlabel = t( `dropdownItemWithOptions.${ option.name }.onlabel` );
                                const offlabel = t( `dropdownItemWithOptions.${ option.name }.offlabel` );
                                const labelLength = Math.max( onlabel.length, offlabel.length );

                                return (
                                    <span key={ index } className={ "ms-2" }>
                                        <BootstrapSwitchButton
                                            width={ labelLength * 10 }
                                            key={ option }
                                            onlabel={ onlabel }
                                            offlabel={ offlabel }
                                            checked={ values[option.name] }
                                            size={ 'xs' }
                                            onChange={ ( value ) => {
                                                setValues( { ...values, ...{ [option.name]: value } } )
                                            } }
                                        />
                                    </span>
                                )
                            case 'input':
                                return (
                                    <span key={ index } className={ "ms-2" }
                                          onKeyDown={ (event) => {
                                              event.stopPropagation();
                                              if ( event.key === 'Enter' ) {
                                                  event.preventDefault()
                                                  onClick(values);
                                              }
                                          }}
                                    >
                                        <Form.Control
                                            placeholder={option.placeholder}
                                            className={'d-inline-block w-auto'}
                                            size={"sm"}
                                            type="text"
                                            onChange={ ( event ) => {
                                                setValues( { ...values, ...{ [option.name]: event.target.value } } )
                                            } }
                                        />
                                    </span>
                                )
                            default:
                                return <></>
                        }
                    }
                )}
            </span>
        </Dropdown.Item>
	)
}

DropdownItemWithOptions.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array,
    onClick: PropTypes.func,
}

export default DropdownItemWithOptions