import React, {useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import appService, {alertService} from "../_services";
import ShowField from "../form/ShowField";
import restService from "../_services/rest.service";
import {useTranslation} from "react-i18next";
import PaymentStateBadge from "../payment/PaymentStateBadge";
import FormattedText from "../_components/FormatedText";
import {Link, useHistory} from "react-router-dom";
import DeleteConfirmDialog from "../_components/DeleteConfirmDialog";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ShowManualPayment(props ) {
    const [manualPayment] = restService.useDomainInstance( 'manualPayment', props.id, false );
    const { t } = useTranslation();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const history = useHistory();
    const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

    const deletePayment = () => {
        try {
            restService.deleteDomainInstance( 'manualPayment', manualPayment.id )
                .then( () => {
                    alertService.success(t("default.deleted", {what: `${t('manualPayment.label')} ${manualPayment.id}`}), { keepAfterRouteChange: true });
                    history.push('/manualPayment')
                })
                .catch( (error, signal) => {
                    restService.handleServerErrors(error, signal);
                })

        }
        catch (e) {
            alertService.error(e);
        }
    }

    return (
        <>
            <Card className='mb-2'>
                <Card.Header><h4>{t('manualPayment.label') + ' ' + appService.nullSafeGet( manualPayment, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <ShowField label={'payment.amountDate'} object={manualPayment} property={'amountDate'} type={'date'}/>
                        </Col>
                        <Col>
                            <ShowField label={'payment.refNr'} object={manualPayment} property={'refNr'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'payment.amount'} object={manualPayment} property={'amount'} type={'text'}/>
                        </Col>
                        <Col>
                            <ShowField label={'bankPayment.invoice.total'} object={manualPayment} property={'invoice.total'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'manualPayment.note'} object={manualPayment} property={'note'}>
                                <FormattedText text={manualPayment.note}/>
                            </ShowField>
                        </Col>
                        <Col>
                            <ShowField label={'manualPayment.bankPayment'} object={manualPayment} property={'bankPayment.label'} type={'text'}
                                       link={manualPayment.bankPayment && manualPayment.bankPayment.id && {to: `/bankPayment/show/${manualPayment.bankPayment.id}`}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'payment.invoice'} object={manualPayment} property={'invoice.invoiceNr'} type={'text'} link={manualPayment.invoice && {to: `/settlement/show/${manualPayment.invoice.id}`}}/>
                        </Col>
                        <Col>
                            <ShowField label={'bankPayment.invoice.paymentState'} object={manualPayment} property={'invoice.paymentState'} type={'text'}>
                                {manualPayment.invoice && <PaymentStateBadge paymentState={manualPayment.invoice.paymentState}/>}
                            </ShowField>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
            <Link to={ `/manualPayment/edit/${props.id}` } className="btn btn-sm btn-primary me-2">{t('default.edit')}</Link>
            <Button
                variant={ 'danger' }
                size={ 'sm' }
                onClick={ ( event ) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDeleteConfirm( true );
                } }>
                <FontAwesomeIcon icon={ faTrashAlt }/>
            </Button>
            <DeleteConfirmDialog
                show={showDeleteConfirm}
                onHide={handleCloseDeleteConfirm}
                bodyText={t('deleteConfirm.body', {name: t('manualPayment.label')})}
                onDelete={deletePayment}
            />
        </>
)
}

export default ShowManualPayment
