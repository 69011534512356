import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {Accordion, Button, Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {damageService} from "../_services/damage.service";
import reportService from "../_services/report.service";
import appService from "../_services";

function Reports() {
	const {t} = useTranslation();
	const [report] = useState(undefined);

	const reports = [
		{
			title: t('reports.debtorsList.title'),
			description: t('reports.debtorsList.description'),
			to: '/debtorsList',
			group: 'accounting',
		},
		{
			title: t('reports.damageRendement.title'),
			description: t('reports.damageRendement.description'),
			to: '/damageRendement',
			group: 'damage',
		},
		{
			title: t('reports.damageReserves.title'),
			description: t('reports.damageReserves.description'),
			buttons: [{title: t('default.export'), onClick: () => damageService.printDamageReserves()}],
			group: 'damage',
		},
		{
			title: t('reports.feePerYear.title'),
			description: t('reports.feePerYear.description'),
			to: '/feePerYear',
			group: 'policy',
		},
		{
			title: t('reports.expiringPolicyVersions.title'),
			description: t('reports.expiringPolicyVersions.description'),
			to: '/expiringPolicyVersions',
			group: 'policy',
		},
		{
			title: t('reports.policiesExport.title'),
			description: t('reports.policiesExport.description'),
			to: '/policiesExport',
			group: 'policy',
		},
		{
			title: t('reports.inventoryExport.title'),
			description: t('reports.inventoryExport.description'),
			to: '/inventoryExport',
			group: 'policy',
		},
		{
			title: t('reports.profitParticipationSummary.title'),
			description: t('reports.profitParticipationSummary.description'),
			to: '/profitParticipationSummary',
			group: 'policy',
		},
		{
			title: t('reports.invoicedAppversPart.title'),
			description: t('reports.invoicedAppversPart.description'),
			to: '/invoicedAppversPart',
			group: 'policy',
		},
		{
			title: t('reports.commissionsExport.title'),
			description: t('reports.commissionsExport.description'),
			to: '/commissionsExport',
			group: 'policy',
		},
		{
			title: t('reports.damagePayments.title'),
			description: t('reports.damagePayments.description'),
			to: '/damagePayments',
			group: 'damage',
		},
		{
			title: t('reports.activePartners.title'),
			description: t('reports.activePartners.description'),
			buttons: [{title: t('default.export'), onClick: () => reportService.printActivePartner()}],
			group: 'partner',
		},
		{
			title: t('reports.interimBillingDamages.title'),
			description: t('reports.interimBillingDamages.description'),
			to: '/interimBillingDamages',
			group: 'damage',
		},
		{
			title: t('reports.billingForPartners.title'),
			description: t('reports.billingForPartners.description'),
			to: '/billingForPartners',
			group: 'policy',
		},
	]

	const reportElement = (report, key) => {
		return (
			<Col key={key} >
				<Card variant={"secondary"}>
					<Card.Header>
						{report.title}
					</Card.Header>
					<Card.Body>
						<Card.Text>
							{report.description}
						</Card.Text>
						{ report.to &&
							<Link className={'btn btn-primary'} to={report.to}>{t('default.open')}</Link>
						}
						{ report.buttons &&
							report.buttons.map( (button, index) => {
								return <Button key={index} className={ 'btn btn-primary me-2' } onClick={ button.onClick }>{button.title}</Button>
							})
						}
					</Card.Body>
				</Card>
			</Col>
		)
	}

	const reportsElement = () => {
		const reportGroups = appService.groupBy( reports, (report) => report.group );
		return <Accordion>
			{Object.keys(reportGroups).map( (group) => {
				return (
					<Accordion.Item eventKey={group}>
						<Accordion.Header>{t(`reports.${group}`)}</Accordion.Header>
						<Accordion.Body>
							<Row xs={1} sm={2} md={3} lg={4} className="g-4">
							{reportGroups[group].map( ( report, index ) => {
								return reportElement( report, index );
							} )}
							</Row>
						</Accordion.Body>
					</Accordion.Item>
				)
			} ) }
		</Accordion>
	}

	return (
		<div>{ report ? report : reportsElement() } </div>
	)

}

export default Reports