import React from 'react';
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {accountingService} from "../_services/accounting.service";

function ShowAccountingTafFile(props) {
	const {t} = useTranslation();
	const [accountingTafFile] = restService.useDomainInstance( 'accountingTafFile', props.id );

	const handleDownloadTafFile = () => {
		accountingService.downloadTafFile( props.id )
	}

	return (
		<>
			<div>
				<ShowField label={'accountingTafFile.id.label'} object={accountingTafFile} property={'id'} type={'number'}/>
				<ShowField label={'accountingTafFile.createdOn.label'} object={accountingTafFile} property={'createdOn'} type={'datetime'}/>
				<ShowField label={'accountingTafFile.createdBy.label'} object={accountingTafFile} property={'createdBy'} type={'text'}/>
			</div>
			<div>
				<Button type={"button"} onClick={handleDownloadTafFile}>{t('default.download')}</Button>
			</div>
		</>
	)
}

export default ShowAccountingTafFile