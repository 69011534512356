import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";

function FormElements({domainInstance}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('partnerConsultantScope.label')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupName">
							<Form.Label>{t('partnerConsultantScope.name.label')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value= {domainInstance.name}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupUser">
							<Form.Label>{t('partnerConsultantScope.name.label')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"user"}
								sort={"username"}
								value={ domainInstance && domainInstance.user && domainInstance.user.id }
								label={ domainInstance && domainInstance.user && domainInstance.user.label }
								name={'user'}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupUser">
							<Form.Label>{t('partnerConsultantScope.partner.label')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"partner"}
								sort={"fullName_sort"}
								value={ domainInstance && domainInstance.partner && domainInstance.partner.id }
								label={ domainInstance && domainInstance.partner && domainInstance.partner.label }
								name={'partner'}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
