import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';

function TypeaheadControl( { name, rules, validationMessages, value, placeholder, onChange, disabled, className, options }) {
	const { reset, getValues, control } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);

	useEffect(() => {
		if ( getValues && reset ) {
			const newValue = defVal;
			setCurrentValue(newValue);
			const val = { ...getValues(), [name]: newValue };
			reset( val );
		}
	}, [value, getValues, name, reset, defVal]);

	return (
		<>
		<Controller
			control={ control }
			name={ name }
			rules={ rules }
			defaultValue={ currentValue }
			render={ ( { field, fieldState } ) =>
				<>
					<Typeahead
						id={name}
						onChange={ ( selection ) => {
							setCurrentValue( selection[0] );
							field.onChange( selection[0] );
							if ( onChange ) {
								onChange( selection[0] );
							}
						} }
						onInputChange={ (input) => {
							setCurrentValue( input );
							field.onChange( input );
							if ( onChange ) {
								onChange( input );
							}
						} }
						onBlur={ field.onBlur }
						defaultSelected={ [value] }
						value={ field.value }
						name={ field.name }
						ref={ field.ref }
						options={options}
						className={ (className ? `${className} ` : '') + (!!fieldState.error ? 'is-invalid' : '') }
						isInvalid = {!!fieldState.error}
						placeholder={ placeholder }
						disabled={ disabled }
					/>
					<Form.Control.Feedback type="invalid">
						{ reactHookFormService.getValidationMessage(fieldState, validationMessages) }
					</Form.Control.Feedback>
				</>
			}
		/>
		</>
	)
}

TypeaheadControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	options: PropTypes.array,
};

export default TypeaheadControl;
