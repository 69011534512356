import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import bootstrapTableService from "../_components/bootstrapTable.service";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";
import PaymentStateBadge from "../payment/PaymentStateBadge";
import DomainTable from "../_components/DomainTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import {SettlementCancelReason} from "../_enum/enum";

function ShowSimilarInvoices({bankPaymentId} ) {
    const getData = useCallback((page, sizePerPage, sort, searchText, searchOptions, signal) => {
        return new Promise( (resolve, reject) => {
            const h = headers();
            const params = {
                page: page,
                sizePerPage: sizePerPage,
                sort: sort,
                searchText: searchText,
                searchOptions: searchOptions,
            }
            fetch( `${ SERVER_URL }/api/bankPayment/showSimilar/${bankPaymentId}?${ new URLSearchParams( params )}`, {
                method: 'GET',
                headers: h,
                signal: signal
            } )
                .then( r => r.json() )
                .then( json => {
                    resolve( {totalSize: json.count, data: json.data } );
                } )
                .catch( reject )
        })
    }, [bankPaymentId] );


    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'id',
            text: t('default.id'),
            hidden: true,
        },
        {
            dataField: 'number',
            text: t('settlement.number'),
            // sort: true,
            headerStyle: (column, colIndex) => {
                return { width: '8%', textAlign: 'right' };
            },
            formatter: (cellContent, row) => {
                return (
                    <Link to={ restService.getTo( row, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
                       { cellContent }
                    </Link>
                )
            },
            classes: (cell, row, rowIndex, colIndex) => 'text-end',
        },
        {
            dataField: 'createdOn',
            text: t('default.createdOn'),
            formatter: (cellContent, row) => {
                if ( cellContent ) return  bootstrapTableService.formatterDate(cellContent)
                else {
                    return ''
                }
            },
            // sort: true,
        },
        {
            dataField: 'partner',
            text: t('settlement.partner'),
            sortField: 'partner.label_sort',
            formatter: (cellContent, row) => {
                if ( cellContent ) {
                    return <Link to={ restService.getTo( row, 'edit' ) } target = "_blank" rel = "noopener noreferrer" >
                        { cellContent.label }
                    </Link>
                }
                else {
                    return ('')
                }
            },
            // sort: true
        },
        {
            dataField: 'type',
            text: t('settlement.type'),
            sortField: 'type_sort',
            // sort: true,
            headerStyle: (column, colIndex) => {
                return { width: '15%' };
            },
        },
        {
            dataField: 'paymentState',
            text: t('invoice.paymentState'),
            // sort: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Link className={`btn btn-sm btn-primary`} to={`/manualPayment/bankPaymentCorrection/${bankPaymentId}/${row.id}`}>
                            <FontAwesomeIcon icon={faLink}/> {t('bankPayment.pair.with')}
                        </Link>
                        <br/>
                        <PaymentStateBadge paymentState={cellContent}/>
                    </>
                )
            },
        },
        {
            dataField: 'total',
            text: t('settlement.total'),
            // sort: true,
            formatter: (cellContent, row) => bootstrapTableService.formatterAmount(cellContent),
            headerStyle: (column, colIndex) => {
                return { width: '12%', textAlign: 'right' };
            },
            classes: (cell, row, rowIndex, colIndex) => 'text-end'
        }
    ];

    const rowClasses = (row, rowIndex) => {
        if ( row.cancelledWhy === SettlementCancelReason.NOT_CANCELLED ) {
            return ''
        }
        else {
            return 'line-through'
        }
    }

    return (
        <>
            <DomainTable
                domainName={'invoice'}
                columns={columns}
                showSearchbar={false}
                showPagination={false}
                rowClasses={rowClasses}
                getData={getData}
            />
        </>
    )
}

export { ShowSimilarInvoices };
