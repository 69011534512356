import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function PartnerConsultantScope({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('partnerConsultantScope.id.label'),
		hidden: true
	}, {
		dataField: 'name',
		sortField: 'name_sort',
		text: t('partnerConsultantScope.name.label'),
		sort: true
	}, {
		dataField: 'user.label',
		sortField: 'user.username',
		text: t('partnerConsultantScope.user.label'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'partnerConsultantScope'}
			columns={columns}
			toString={ (object) => object.name }
			formElements={<FormElements/>}
			sortField={'name_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { PartnerConsultantScope };
