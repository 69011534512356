import React, {useEffect, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import i18n from "i18next";
import moment from "moment/moment";
import textService from "./text.service";
import {Badge} from "react-bootstrap";

let configuratorHeaderRefs = {};
let configuratorAccordeonRef = null;

export const configuratorService = {
	getConfiguratorNodesData,
	useConfiguratorOptions,
	getFee,
	useFee,
	useConfiguratorNodeClassNames,
	saveConfiguratorNode,
	deleteConfiguratorNode,
	getAllowedChildrenClasses,
	useConfiguratorNodes,
	registerConfiguratorHeader,
	unregisterConfiguratorHeader,
	expandAndScrollToConfiguratorHeader,
	registerConfiguratorAccordeon,
	createBrokerTariff,
	useConfiguratorValidity,
	isObsolete,
};

function isObsolete(validFrom, validTo, date) {
	return (validFrom && moment(date) < moment(validFrom) ) || ( validTo && moment(validTo) < moment(date) )
}

function useConfiguratorValidity( validFrom, validTo, alwaysVisible = false, relatedDate = undefined ) {
	const [element, setElement] = useState( <></> );

	useEffect(() => {
		let validity = undefined

		const _relatedDate = relatedDate ? relatedDate : moment()
		var durationValidFrom = Math.abs(moment.duration(moment(_relatedDate).diff(moment(validFrom))).asDays());
		var durationValidTo = Math.abs(moment.duration(moment(validTo).diff(moment(_relatedDate))).asDays());

		const VISIBILITY_DAYS = 60

		if ( ( validFrom && 0 < durationValidFrom && ( alwaysVisible || durationValidFrom < VISIBILITY_DAYS )) && ( validTo && 0 < durationValidTo && ( alwaysVisible || durationValidTo < VISIBILITY_DAYS ) ) ) {
			validity = i18n.t('configuratorTarget.validFromTo', {from: textService.formatDateTime( moment(validFrom), { dateStyle: 'medium' } ), to: textService.formatDateTime( moment(validTo), { dateStyle: 'medium' } )})
		}
		else if ( validFrom && 0 < durationValidFrom && ( alwaysVisible || durationValidFrom < VISIBILITY_DAYS ) ) {
			validity = i18n.t('configuratorTarget.validFrom', {from: textService.formatDateTime( moment(validFrom), { dateStyle: 'medium' } ) } )
		}
		else if ( validTo && 0 < durationValidTo && ( alwaysVisible || durationValidTo < VISIBILITY_DAYS ) ) {
			validity = i18n.t('configuratorTarget.validTo', {to: textService.formatDateTime( moment(validTo), { dateStyle: 'medium' } ) })
		}

		const obsolete = configuratorService.isObsolete( validFrom, validTo, _relatedDate )
		const bg = obsolete ? "danger" : "warning"

		setElement( validity ? <div><Badge bg={bg} text="dark">{validity}</Badge></div> : <></> )

	}, [alwaysVisible, relatedDate, validFrom, validTo]); //sameBuildingTargets causes infinite loop, but they aren't necessary

	return [element];
}

function registerConfiguratorHeader(key, ref) {
	configuratorHeaderRefs[key] = ref;
}

function unregisterConfiguratorHeader(key) {
	delete configuratorHeaderRefs[key];
}

function expandAndScrollToConfiguratorHeader(key) {
	configuratorAccordeonRef.current.expandConfiguratorTarget(key);

	const ref = configuratorHeaderRefs[key];

	if ( ref ) {
		setTimeout( () => {
			if ( ref && ref.current ) {
				ref.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
			}
		}, 10 )
	}
}

function registerConfiguratorAccordeon(ref) {
	configuratorAccordeonRef = ref;
}

function getConfiguratorNodesData(parentId, signal) {
	return new Promise ((resolve, reject) => {
		const h = headers();

		const params = {
			parentId: parentId
		}

		fetch( `${ SERVER_URL }/api/configuratorNode?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: signal
		} )
			.then( r => r.json() )
			.then( json => {
				const nodes = json.data;
				resolve(nodes);
			} )
			.catch( error => {
				reject('Error retrieving data: ' + error);
			} );
	});
}

function useConfiguratorOptions( values, defaultValues, validOn ) {
	const [result, setResult] = useState( { configuratorOptions:[], mergedValues:{} });

	useEffect(() => {
		const controller = new AbortController();
		const h = headers();
		const params = {
			values: JSON.stringify(values),
			defaultValues: JSON.stringify(defaultValues),
			validOn: validOn
		}
		fetch( `${ SERVER_URL }/api/configurator/getConfiguratorOptions?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( r => r.json() )
			.then( data => {
				let mv = {}
				data.configuratorOptions.forEach( (configuratorOption, index) => {
					const defVal = defaultValues[configuratorOption.className];
					const value = ( values && values[configuratorOption.className] ) ? values[configuratorOption.className].value : undefined;
					if ( defVal ) {
						mv[configuratorOption.className] = { value: defVal.id, order: index + 1, isDefaultValue: true }
					}
					else {
						mv[configuratorOption.className] = { value: value, order: index + 1, isDefaultValue: false }
					}
				});

				setResult( {
					configuratorOptions: data.configuratorOptions,
					mergedValues: mv
				});
			} )
			.catch( error => restService.handleServerErrors( error, controller.signal ) );
		return function cleanup() {
			controller.abort()
		}
	}, [values, defaultValues]);

	return result;
}


function useConfiguratorNodes( maxDepth ) {
	const [configuratorNodes, setConfiguratorNodes] = useState( [] );

	useEffect(() => {
		const controller = new AbortController();
		const h = headers();
		const params = {
			maxDepth: maxDepth
		}
		fetch( `${ SERVER_URL }/api/configurator/getConfiguratorNodes?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( r => r.json() )
			.then( data => {
				setConfiguratorNodes(data.configuratorNodes);
			} )
			.catch( error => restService.handleServerErrors( error, controller.signal ) );
		return function cleanup() {
			controller.abort()
		}
	}, [maxDepth]);

	return configuratorNodes;
}

function getFee( id, amount, values, sameBuildingTargets, signal ) {
	return new Promise( (resolve, reject) => {
		if( amount>0 && id ) {
			const params = {
				id: id,
				value: amount,
				values: JSON.stringify( values ),
				sameBuildingTargets: JSON.stringify( sameBuildingTargets ),
			}
			const h = headers();
			fetch( `${ SERVER_URL }/api/configurator/getFee?` + new URLSearchParams( params ), {
				method: 'GET',
				headers: h,
				signal: signal
			} )
				.then( r => r.json() )
				.then( data => {
					resolve(data)
				} )
				.catch( error => reject(error) );
		}
	})
}

function useFee( id, amount, values, sameBuildingTargets ) {
	const [fee, setFee] = useState( undefined );

	useEffect(() => {
		const controller = new AbortController();

		getFee( id, amount, values, sameBuildingTargets, controller.signal )
			.then( data => setFee(data) )
			.catch( error => restService.handleServerErrors( error, controller.signal ) )

		return function cleanup() {
			controller.abort()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, amount, values]); //sameBuildingTargets causes infinite loop, but they aren't necessary

	return [fee, setFee];
}

function useConfiguratorNodeClassNames(nonTarget, target) {
	const [configuratorNodeClassNames, setConfiguratorNodeClassNames] = useState( undefined );

	useEffect(() => {
		const controller = new AbortController();
		const h = headers();
		const params = {
			target: target,
			nonTarget: nonTarget
		}
		fetch( `${ SERVER_URL }/api/configurator/getAvailableConfiguratorNodeClassNames?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( r => r.json() )
			.then( data => {
				setConfiguratorNodeClassNames( data.classNames.map( (className) => {
					return {id: className, name: i18n.t(className + ".label") }
				}));
			} )
			.catch( error => restService.handleServerErrors( error, controller.signal ) );
		return function cleanup() {
			controller.abort()
		}
	}, [nonTarget, target]);

	return [configuratorNodeClassNames];
}

function saveConfiguratorNode(data) {
	return new Promise ((resolve, reject) => {
		const h = headers();

		const params = {
			data: JSON.stringify(data)
		}

		fetch( `${ SERVER_URL }/api/configurator/saveConfiguratorNode?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
		} )
			.then( r => restService.handleServerResponse(r.clone()) )
			.then( json => resolve(json) )
			.catch( error => {
				if ( typeof error === 'object' ) {
					reject(error);
				}
				else {
				 	reject(
						{ message: error }
					);
				}
			});
	});
}

// function saveConfiguratorTarget(data) {
// 	return new Promise( (resolve, reject) => {
// 		const h = headers();
//
// 		const bodyData = JSON.stringify({
// 			data: data
// 		});
//
// 		fetch(
// 			`${ SERVER_URL }/api/configuratorTargetTariff/persistAll`,
// 			{
// 				method: 'POST',
// 				headers: h,
// 				body: bodyData
// 			}
// 		)
// 			.then( response => {
// 				resolve(response);
// 			})
// 	});
// }

function deleteConfiguratorNode(data) {
	return new Promise ( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController()
		const params = {
			data: JSON.stringify(data)
		}

		fetch( `${ SERVER_URL }/api/configurator/deleteConfiguratorNode?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: controller.signal
		})
			.then( r => {
				return restService.handleServerResponse(r)
			} )
			.then( response => resolve( response.status ) )
			.catch( error => {
				return restService.handleServerErrors( error, controller.signal )
			} );
	})
}

function getAllowedChildrenClasses(nodeId) {
	return new Promise ( (resolve, reject) => {
		const h = headers();

		const params = {
			nodeId: nodeId
		}

		fetch( `${ SERVER_URL }/api/configurator/getAllowedChildrenClasses?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
		})
			.then( r => restService.handleServerResponse(r.clone()) )
			.then( json => {
				resolve(json.allowedChildrenClasses);
			} )
			.catch( error => {
				if ( typeof error === 'object' ) {
					reject(error);
				}
				else {
					reject(
						{ message: 'Error retrieving data: ' + error }
					);
				}
			});
	})
}

function createBrokerTariff(nodeId, brokerId) {
	return new Promise ( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController()
		const params = {
			nodeId: nodeId,
			brokerId: brokerId,
		}

		fetch( `${ SERVER_URL }/api/configurator/createBrokerTariff?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: controller.signal
		})
			.then( r => {
				return restService.handleServerResponse(r)
			} )
			.then( response => resolve( response ) )
			.catch( error => {
				return restService.handleServerErrors( error, controller.signal )
			} );
	})
}
