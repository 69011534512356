import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	Accordion, Alert,
	Button,
	ButtonGroup,
	Card,
	CloseButton,
	Col,
	Form,
	InputGroup,
	Row,
	Table,
	ToggleButton
} from "react-bootstrap";
import {
	ConfiguratorTargetType,
} from "../_enum/enum";
import {configuratorService} from "../_services/configurator.service";
import {useTranslation} from "react-i18next";
import useTour from "../tour/useTour";
import FormControl from "../_components/FormControl";
import TypeaheadControl from "../_components/TypeaheadControl";
import usePolicyTypes from "../configurator/usePolicyTypes";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import { NumericFormat } from "react-number-format";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import EditableTableControl from "../form/EditableTableControl";
import restService from "../_services/rest.service";
import editableTableService from "../form/editableTable.service";
import settingService from "../_services/setting.service";
import {ACTION_CREATE, ACTION_UPDATE} from "../form/EditableTable";
import {ConfiguratorTargetHeader} from "./ConfiguratorTargetHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import textService from "../_services/text.service";
import DiscountSurchargeDropdown from "./DiscountSurchargeDropdown";
import DiscountSurchargeFeeElementTr from "./DiscountSurchargeFeeElementTr";
import {policyService} from "../_services/policy.service";
import CustomPanelToggle from "../_components/CustomPanelToggle";
import moment from "moment";

function ConfiguratorTarget( { targetKey, targetId, sameBuildingTargets, target, dependsOnTarget, building, onConfiguratorTargetHeaderClick,
	                             configuratorValues, availableInsuranceAmount, configuratorTargetType, onClose, policyValidFrom,
	                             tariffValidFrom, tariffValidTo,
	                             onFeeChanged, onAmountChanged, policyType, avb,
	                             discountSurchargeNames, discountSurchargeLabels, discountSurchargeValues,
	                             defaultParticipation, defaultAmount, previousAmount, previousNextInvoicePeriodFrom, indexYear, amountIndexedForIndexYearPlus1,
	                             defaultIndividualTexts, defaultIndexed, defaultPlaceOfInsuranceStreet, defaultPlaceOfInsuranceZip, defaultFirstLossRisk,
	                             defaultDiscountSurcharge, defaultAdditionalDiscountSurchargePercent, defaultAdditionalDiscountSurchargeValue,
	                             defaultTariff, defaultIsManualTariff, defaultUseStampTax, defaultStampTaxPercent, defaultUseLoeschfuenfer, defaultLoeschfuenferRate, indexable,
	                             configuratorTargetIndividualTexts} ) {
	const [amount, setAmount] = useState(defaultAmount);
	const [manualTariff, setManualTariff] = useState( (defaultTariff && defaultTariff.length===1) ? (defaultTariff[0].tariff * 1000) : null );
	const [isManualTariff, setIsManualTariff] = useState( defaultIsManualTariff );
	const [fee, setFee] = configuratorService.useFee(targetId, amount, configuratorValues, sameBuildingTargets);
	const {t} = useTranslation()
	const [policyTypes] = usePolicyTypes()
	const [tariff, setTariff] = useState({})
	const insurancesHsNamedRestriction = useMemo( ()=> {
		return {
			namedRestriction:
				{
					queryName: "insurance",
					params: {}
				}
		}
	}, [] );
	const [insurances] = restService.useDomainInstancesList('partner', 1, 100, undefined, undefined, insurancesHsNamedRestriction)
	const active = useMemo( () => ({ queryName: "active", params: {} }), [] );
	const [allIndividualTexts] = restService.useDomainInstancesList('individualText', undefined, undefined, 'text_sort', undefined, active, undefined, "select")
	const [participation, setParticipation] = useState(defaultParticipation.sort( (a,b) => b.percent - a.percent ) || []);
	const [individualTexts, setIndividualTexts] = useState(defaultIndividualTexts || []);
	const {appenzellerVersicherungPartnerId} = settingService.useSetting(['appenzellerVersicherungPartnerId']);
	const [additionalDiscountSurchargePercent, setAdditionalDiscountSurchargePercent] = useState(defaultAdditionalDiscountSurchargePercent);
	const [additionalDiscountOrSurcharge, setAdditionalDiscountOrSurcharge] = useState(defaultAdditionalDiscountSurchargeValue > 0 ? 'SURCHARGE' : 'DISCOUNT');
	const [amountTimeoutHandle, setAmountTimeoutHandle] = useState(undefined);
	const [stampTaxPercent] = useState(defaultStampTaxPercent);
	const [useStampTax] = useState(defaultUseStampTax);
	const [loeschfuenferRate] = useState(defaultLoeschfuenferRate);
	const [useLoeschfuenfer] = useState(defaultUseLoeschfuenfer)
	const [participationValidationErrors, setParticipationValidationErrors] = useState(null);
	const [participationOpened, setParticipationOpened] = useState(false)
	const [indexed, setIndexed] = useState(defaultIndexed)

	const dependsOnTargetTariff = dependsOnTarget && dependsOnTarget.tariff

	const refreshFee = useCallback( () => {
		configuratorService.getFee(targetId, amount, configuratorValues, sameBuildingTargets)
			.then( data => setFee(data) )
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetId, amount, configuratorValues, setFee])

	useEffect( () => {
		refreshFee()
	}, [dependsOnTargetTariff, refreshFee] ) //when dependsOnTargetTariff changes, we need to refresh the fee

	const feeTariffExists = useMemo(() => {
		return !!(fee && fee.tariff && fee.tariff.length>0)
	}, [fee])

	const displayManualTariff = useMemo(() => {
		return isManualTariff || !feeTariffExists
	}, [isManualTariff, feeTariffExists])

    const getTariffValue = ( t ) => {
        switch( t.configuratorTarget ) {
            case 'configuratorTargetDecInc':
                return Math.round( t.value * t.tariff * 100 ) / 100 //(decInc tariff is in %, thus we have to divide the result by 100)
            default:
                return Math.round( t.value * t.tariff * 100 ) / 100 //(other tariffs are promille, thus we have to divide the result by 1000)
        }
    }

	const feeBrutto = useMemo( () => {
		let result;
 		if ( displayManualTariff ) {
			 if ( typeof(tariff) === "object" && tariff.constructor.name === "Array" ) {
                 const f = tariff.reduce((accumulator, t) => {
                     return accumulator + getTariffValue(t)
                 }, 0);
                 result = policyService.round20(f);
             }
			 else {
                 result = 0;
             }
		}
		else {
			result = fee.error.length > 0 ? undefined : policyService.round20(fee.fee)
		}
		return result;
	}, [displayManualTariff, tariff, fee])

	const getDiscountSurchargeListBoxValues = (ds) => {
		let result = {}
		if ( ds ) {
			Object.keys(ds).forEach( (idx) => {
				result[idx] = ds[idx].listBoxValue;
			})
		}
		return result;
	}

	const loeschfuenferValue = useMemo( () => {
		let loeschfuenfer = 0;
		if ( useLoeschfuenfer ) {
			if ( configuratorTargetType.name === ConfiguratorTargetType.VALUE ) {
				loeschfuenfer = amount / 1000 * loeschfuenferRate;
			}
			else {
				console.error(`Löschfünfer can't be calculated. Configuration type is not ${ConfiguratorTargetType.VALUE}`);
			}
		}
		return policyService.round20( loeschfuenfer );
	}, [loeschfuenferRate, amount, useLoeschfuenfer, configuratorTargetType.name]);

	const feeNetto = useMemo( () => {
		let result;
		result = feeBrutto - loeschfuenferValue
		return result;
	}, [feeBrutto, loeschfuenferValue])

	const [discountSurchargeListBoxValues, setDiscountSurchargeListBoxValues] = useState( getDiscountSurchargeListBoxValues(defaultDiscountSurcharge) );

	const discountSurcharge = useMemo( () => {
		let result = {}

		if ( discountSurchargeNames ) {
			const getDiscountSurchargeRate = ( idx, listBoxValue ) => {
				const fieldValues = discountSurchargeValues[idx];
				return ( fieldValues[listBoxValue] || 0 );
			}

			const getDiscountSurchargeLabel = ( idx, listBoxValue ) => {
				const fieldLabels = discountSurchargeLabels[idx];
				return ( fieldLabels[listBoxValue] || 0 );
			}

			let discountSurchargeSoFar = 0
			if (discountSurchargeNames) {
				discountSurchargeNames.forEach( ( name, idx ) => {
					const listBoxValue = discountSurchargeListBoxValues[idx] || 0
					const rate = getDiscountSurchargeRate( idx, listBoxValue );
					const listBoxLabel = getDiscountSurchargeLabel( idx, listBoxValue )
					let value;
					switch ( rate.unit ) {
						case '%':
							value = ( feeNetto + discountSurchargeSoFar ) * rate.rate;
							break;
						case 'CHF':
							if ( rate.rate < 0 ) {
								if ( feeNetto - discountSurchargeSoFar < -rate.rate ) {
									value = -(feeNetto - discountSurchargeSoFar);
								}
								else {
									value = rate.rate;
								}
							}
							else {
								value = rate.rate;
							}
							break;
						default:
							throw new Error('Unknown discount surcharge rate unit');
					}

					discountSurchargeSoFar += value;
					result[idx] = {
						listBoxValue: listBoxValue,
						listBoxLabel: listBoxLabel,
						name: name,
						rate: rate,
						value: policyService.round20( value )
					}
				}, 1 );
			}
		}

		return result
	}, [discountSurchargeNames, discountSurchargeValues, discountSurchargeLabels, feeNetto, discountSurchargeListBoxValues])

	const discountSurchargeValue = useMemo( () => {
		let discountSurchargeSum = 0;
		Object.keys(discountSurcharge).forEach( (key) => {
			discountSurchargeSum += discountSurcharge[key].value;
		} )
		return policyService.round20( discountSurchargeSum );
	}, [discountSurcharge]);

	const additionalDiscountSurchargeValue = useMemo( () => {
		let additionalDiscountSurchargeFactor = 0
		switch(additionalDiscountOrSurcharge){
			case 'DISCOUNT':
				additionalDiscountSurchargeFactor = -1
				break;
			case 'SURCHARGE':
				additionalDiscountSurchargeFactor = 1;
				break;
			default:
				throw new Error("Undefined additionalDiscountOrSurcharge")
		}
		return policyService.round20(((feeNetto + discountSurchargeValue) * ((additionalDiscountSurchargePercent || 0)/100))) * additionalDiscountSurchargeFactor;
	}, [feeNetto, additionalDiscountSurchargePercent, discountSurchargeValue, additionalDiscountOrSurcharge]);

	const stampTaxValue = useMemo( () => {
		let stampTax = 0;
		if ( useStampTax ) {
			let feeTotal = feeNetto + discountSurchargeValue + additionalDiscountSurchargeValue;
			stampTax = feeTotal * stampTaxPercent / 100;
		}
		return Math.max(policyService.round20( stampTax ), 0);
	}, [useStampTax, feeNetto, discountSurchargeValue, additionalDiscountSurchargeValue, stampTaxPercent]);

	const total = useMemo( () => {
		//loeschfuenferValue seems to be part of feeBrutto already, see excel in APPVERS-157 Kantonale Abgabe Feuerlöschkosten (Löschfünfer)/Prämie mit Stempelsteuer
		return policyService.round20(feeBrutto + discountSurchargeValue + additionalDiscountSurchargeValue + stampTaxValue);
	}, [feeBrutto, discountSurchargeValue, additionalDiscountSurchargeValue, stampTaxValue])

	useTour([
		{selector: ".tour-configurator-target-insurance-amount", content: t("tour.configurator-target.insurance-amount")},
	])

	useEffect( () => {
		onFeeChanged(targetKey, total, tariff, displayManualTariff);
	}, [onFeeChanged, targetKey, total, tariff, displayManualTariff]);

	useEffect( () => {
		setAmount(defaultAmount);
	}, [setAmount, defaultAmount])

	const totalElement = useMemo(() => {
		if ( total ) {
			return (
				<span><NumericFormat value={ total } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/> CHF</span>
			)
		}
		else {
			return undefined;
		}
	}, [total, t]);

	const feeTariff = useMemo( () => {
		return fee && fee.tariff;
	})

	const feeTariffUnit = useMemo( () => {
		return fee && fee.tariffUnit;
	})

	const feeElement = useMemo(() => {
		if ( amount > 0 ) {
			const discountSurchargeFeeElement = () => {
				return Object.keys(discountSurcharge).filter( (idx) => discountSurcharge[idx].rate.rate !== 0 ).map( (idx) => {
					const ds = discountSurcharge[idx];
					return <DiscountSurchargeFeeElementTr key={idx} name={ds.name} listBoxLabel={ds.listBoxLabel} rate={ ds.rate.rate } unit={ds.rate.unit} value={ ds.value }/>
				})
			}

			const tariffExplanationElement = (tariff) => {
                return tariff.map( ( trf, index ) => {
                    var tariffValue
                    switch ( trf.configuratorTarget ) {
                        case 'configuratorTargetDecInc':
                            tariffValue = textService.formatNumber(trf.tariff * 100) + ' %'
                            break;
                        default:
                            tariffValue = textService.formatNumber(trf.tariff * 1000)
                            break;
                    }
                    return (
                        <span key={ index }>{ tariffValue } (&times; <NumericFormat value={ trf.value }
                                                                                    displayType={ 'text' }
                                                                                    thousandSeparator={ t('thousandSeparator') }/>)</span>
                    )
                }).reduce( ( accu, elem ) => {
                    return accu === null ? [elem] : [...accu, <> &amp; </>, elem]
                }, null ).map((element, index) => {
                    //Avoiding of
                    //Warning: Each child in a list should have a unique "key" prop
                    return React.cloneElement(element, {...{ key: index }})
                })
			}

			const getTariffs = () => {
				if ( !displayManualTariff ) {
					if ( fee.error.length > 0 ) {
						setTariff({});
						return undefined;
					} else if ( feeTariff.length === 1 ) {
						const _tariff = feeTariff
						setTariff(_tariff);
						return  (
							<>
								<FormControl type={"text"} name={'tariff-' + targetKey} hidden={true} value={_tariff}/>
								<NumericFormat value={ feeTariff[0].tariff * 1000 }
											   displayType={ 'text' }
											   thousandSeparator={ t('thousandSeparator') }/>
								<FormControl type={"text"} name={'tariffUnit-' + targetKey} hidden={true} value={feeTariffUnit}/>
							</>
						)
					} else {
						const _tariff = feeTariff
						setTariff(_tariff);
						return (
							<>
								<FormControl type={"text"} name={'tariff-' + targetKey} hidden={true} value={_tariff}/>
								{ tariffExplanationElement(feeTariff) }
								<FormControl type={"text"} name={'tariffUnit-' + targetKey} hidden={true} value={feeTariffUnit}/>
							</>
						)
					}
				} else {
					const _manualTariff = (manualTariff == null ? 0 : parseFloat(manualTariff)) / 1000
					let _tariff = []
					let lastTariff = { value: amount, tariff: _manualTariff }
					_tariff.push( lastTariff )
					let displayExplanation = false;
					if ( typeof(_tariff) === "object" && _tariff.constructor.name === "Array" ) {
						const decIncTariffs = _tariff.filter( ( t ) => t.configuratorTarget === "configuratorTargetDecInc" )
						decIncTariffs.forEach( ( t ) => {
                            displayExplanation = true
							t.value = getTariffValue( lastTariff )
							lastTariff = t
							_tariff.push( t )
						} )
						setTariff( _tariff );
					}
					return  (
						<>
							<FormControl type={"text"} name={'tariff-' + targetKey} hidden={true} value={_tariff}/>
							<FormControl
								size={"sm"}
								name={ 'manualTariff-' + targetKey }
								type="number"
								value={ manualTariff }
								onChange={ ( event ) => {
									setManualTariff( event.target.value )
								} }
								className={ "text-right configurator-target-manual-tariff float-start" }
								step={"any"}
								rules={{
									required: true
								}}
							/>
							{ displayExplanation && <div className={"float-end"}>{tariffExplanationElement(_tariff)}</div> }
						</>
					)
				}
			}

			const getFee = () => {
				return (
					<>
						<div className={"float-start"}><NumericFormat value={ feeBrutto } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/> CHF</div>
						{ indexable &&
							<div className={"indexed-wrapper float-end"}><SwitchButtonControl offlabel={t("configuratorTarget.indexed.offlabel")} onlabel={t("configuratorTarget.indexed.onlabel")} name={'indexed-' + targetKey} size={"xs"} value={defaultIndexed} onChange={ (value) => setIndexed(value) } /></div>
						}
					</>
				)
			}

			const getIsManualTariffButton = () => {
				return ( feeTariffExists &&
					<>
					<ButtonGroup className="mb-2">
					<ToggleButton
						id={'isManualTariff-'+targetKey}
						type="checkbox"
						variant={isManualTariff ? "danger" : "light"}
						checked={isManualTariff}
						value="1"
						onChange={(e) => {
							if ( e.currentTarget.checked ) {
								setManualTariff(fee && feeTariff && feeTariff.length>0 && feeTariff[0].tariff * 1000)
							}
							else {
								setManualTariff(null)
							}
							setIsManualTariff(e.currentTarget.checked)
						}}
						className={"manual-tariff-button "}
						size={"xs"}
					>
						{isManualTariff ? <FontAwesomeIcon icon={faUnlock}/> : <FontAwesomeIcon icon={faLock}/> }
					</ToggleButton>
					</ButtonGroup>
					</>)
			}

			const getStampTaxElement = () => {
				return (useStampTax &&
					<tr>
						<td>{ t( 'configuratorTarget.stampTaxPercent.label', {percent: stampTaxPercent}) }</td>
						<td className="text-right">
							<NumericFormat value={ stampTaxValue } displayType={ 'text' }
										   thousandSeparator={ t('thousandSeparator') }/> CHF
						</td>
					</tr>
				)
			}

			const getLoeschfuenferElement = () => {
				if ( loeschfuenferRate > 0 ) {
					return (
						<tr>
							<td>{t('configuratorTarget.loeschfuenfer.label')}</td>
							<td className="text-right">
								<NumericFormat value={loeschfuenferValue} displayType={'text'}
											   thousandSeparator={t('thousandSeparator')}/> CHF
							</td>
						</tr>
					)
				}
			}

			return (
				<Card className={"mb-2" }>
					<Card.Header>{ t( "configuratorTarget.card.header" ) } { totalElement }</Card.Header>
					<Card.Body>
						<Table size="sm">
							<tbody>
							<tr>
								<td>{ t( 'configuratorTarget.tariff.label') } { getIsManualTariffButton() }</td>
								<td className="text-right">
									{ getTariffs() }
								</td>
							</tr>
							<tr>
								<td>{ t( 'configuratorTarget.feeBrutto.label') }</td>
								<td className="text-right">{ getFee() }</td>
							</tr>
							{ discountSurchargeFeeElement() }
							<tr>
								<td>
									<div style={ { width: "6rem", float: "left", marginRight: "1em" } }>
										<SwitchButtonControl name={"additionalDiscountOrSurcharge-" + targetKey} onlabel={t("default.discount")} onstyle={"light"} offlabel={t("default.surcharge")} offstyle={"light"} size={"sm"} value={additionalDiscountOrSurcharge === 'DISCOUNT'} onChange={
											(value) => setAdditionalDiscountOrSurcharge( value ? 'DISCOUNT' : 'SURCHARGE' ) }
										/>
									</div>
									<div style={ { float: "left" } }>
										<InputGroup size={ 'sm' }>
											<FormControl
												name={ 'additionalDiscountSurchargePercent-' + targetKey }
												type="number"
												value={ additionalDiscountSurchargePercent }
												onChange={ ( event ) => setAdditionalDiscountSurchargePercent( event.target.value ) }
												className={ "text-right configurator-target-discount" }
											/>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup>
									</div>
								</td>
								<td className="text-right">
									<FormControl type={"text"} name={'additionalDiscountSurchargeValue-' + targetKey} hidden={true} value={additionalDiscountSurchargeValue}/>
									<NumericFormat value={ additionalDiscountSurchargeValue } displayType={ 'text' }
												   thousandSeparator={ t('thousandSeparator') }/> CHF
								</td>
							</tr>
							{getLoeschfuenferElement()}
							{getStampTaxElement()}
							</tbody>
						</Table>
						<FormControl type={"text"} name={'loeschfuenferRate-' + targetKey} hidden={true} value={loeschfuenferRate}/>
						<FormControl type={"text"} name={'loeschfuenferValue-' + targetKey} hidden={true} value={loeschfuenferValue}/>
						<FormControl type={"text"} name={'stampTaxValue-' + targetKey} hidden={true} value={stampTaxValue}/>
						<FormControl type={"text"} name={'stampTaxPercent-' + targetKey} hidden={true} value={stampTaxPercent}/>
					</Card.Body>
				</Card>
			)
		}
		else {
			return undefined;
		}
	}, [fee, t, additionalDiscountSurchargeValue, additionalDiscountSurchargePercent, amount, feeBrutto, manualTariff, defaultIndexed, isManualTariff, displayManualTariff, additionalDiscountOrSurcharge, discountSurcharge, loeschfuenferRate, loeschfuenferValue, stampTaxPercent, stampTaxValue, useStampTax, indexable, feeTariff, feeTariffUnit]);

	const individualTextsElement = useMemo( () => {
		const columns = [
			{id: "individualText", label: t('configuratorTarget.individualTexts.text.label') , input: {tag: "typeahead", id: "individualText", options: allIndividualTexts, required: true }},
		]

		const beforeChangeIndividualText = (rowData, column, newValue) => {
			if ( column === 'standardText') {
				return policyService.checkMandatoryText( rowData.individualText.id, individualTexts, [{configuratorTargetIndividualTexts: configuratorTargetIndividualTexts, text: target}], 'individualText' )
			}

			return true;
		}

		const beforeDeleteIndividualText = (rowData) => {
			return policyService.checkMandatoryText( rowData.individualText.id, individualTexts, [{configuratorTargetIndividualTexts: configuratorTargetIndividualTexts, text: target}], 'individualText' )
		}

		return (
			<Accordion defaultActiveKey={"0"}>
				<Card className={"mb-2"} style={{overflow: "inherit"}}> {/*Dropdown list of the <Typeahead/> in <EditableTableControl/> is not fully visible without 'overflow: inherit' */}
					<Card.Header>
						<CustomPanelToggle as={Button} variant="link" eventKey="0">
							{t('configuratorTarget.individualTexts.card.header')}
						</CustomPanelToggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<EditableTableControl
								name={"individualTexts-"+ targetKey}
								columns={columns}
								data={individualTexts}
								orderable={true}
								onChange={(value) => setIndividualTexts(value)}
								beforeDelete={beforeDeleteIndividualText}
								beforeChange={beforeChangeIndividualText}
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}, [allIndividualTexts, t, targetKey, individualTexts, configuratorTargetIndividualTexts, target]);

	const participationElement = useMemo( () => {
		async function handleAfterPercentUpdate(rowId, data) {
			let sum = 0;
			let leadingRowIdx = data.findIndex( (row) => ( !row.deleted && row.partner && row.partner.id === appenzellerVersicherungPartnerId ));
			if ( leadingRowIdx !== rowId ) {
				for ( let rowIdx in data ) {
					if ( data[rowIdx].partner && data[rowIdx].partner.id !== appenzellerVersicherungPartnerId && parseInt(rowIdx) !== rowId && !data[rowIdx].deleted ) {
						sum += data[rowIdx].percent;
					}
				}
				const recalculatedLeadingPercent = 100 - ( sum + data[rowId].percent );
				data[leadingRowIdx] = { ...data[leadingRowIdx], percent: recalculatedLeadingPercent };
				if ( data[leadingRowIdx]["action"] !== ACTION_CREATE ) {
					data[leadingRowIdx]["action"] = ACTION_UPDATE
				}
			}
			return data;
		}

		const columns = [
			{id: "partner", label: t('configuratorTarget.participation.partner.label') , input: {tag: "select", values: insurances, required: true, nullable:true }},
			{id: "percent", label: t('configuratorTarget.participation.percent.label'), input: {tag: "input", type: "integer", required: true}, afterUpdate: handleAfterPercentUpdate},
		]

		return (
			<Accordion activeKey={ (participationOpened || participation.filter((p)=>!p.deleted).length > 1 || participationValidationErrors !== null ) ? "0" : "" }>
				<Card className={"mb-2"}>
					<Card.Header
						onClick={ () => {
							setParticipationOpened(!participationOpened)
						} }
					>
						<CustomPanelToggle as={Button} variant="link" eventKey="0">
							{t('configuratorTarget.participation.card.header')}
						</CustomPanelToggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<EditableTableControl
								name={"participation-"+ targetKey}
								columns={columns}
								data={participation}
								globalValidator={ (data) => {
									const totalPercent = editableTableService.getSum(data, columns, 'percent');
									if ( totalPercent !== 100 ) {
										return t('configuratorTarget.participation.totalMustBe100' , {what: t('configuratorTarget.participation.percent.label')})
									}
								}}
								onChange={(value) => setParticipation(value)}
								onValidationErrorsChange={(value) => setParticipationValidationErrors(value)}
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}, [insurances, t, targetKey, participation, appenzellerVersicherungPartnerId, participationValidationErrors, participationOpened, setParticipationOpened]);

	const onDiscountSurchargeKeyChanged = useCallback((idx, listBoxValue) => {
		discountSurchargeListBoxValues[idx] = listBoxValue
		setDiscountSurchargeListBoxValues( {...discountSurchargeListBoxValues} );
	}, [setDiscountSurchargeListBoxValues, discountSurchargeListBoxValues])

	const discountSurchargeElement = useMemo( () => {
		if ( discountSurcharge ) {
			return (
				<Row className={"mb-3"}>
					<FormControl type={"text"} name={'discountSurcharge-' + targetKey} hidden={true} value={discountSurcharge}/>
					<FormControl type={"text"} name={'discountSurchargeNames-' + targetKey} hidden={true} value={discountSurchargeNames}/>
					{Object.keys(discountSurcharge).map( (idx) => {
						return <DiscountSurchargeDropdown
							key={ idx }
							idx={ idx }
							targetKey={ targetKey }
							name={ discountSurcharge[idx].name }
							labels={ discountSurchargeLabels[idx] }
							defaultValue = { discountSurcharge[idx].listBoxValue || 0 }
							onDiscountSurchargeKeyChanged={ onDiscountSurchargeKeyChanged }
						/>
					})}
				</Row>
			)
		}
		else {
			return undefined;
		}
	}, [targetKey, discountSurcharge, onDiscountSurchargeKeyChanged, discountSurchargeLabels, discountSurchargeNames])

	const insuranceAmountProps = useMemo( () => {
		let label = ''
		let validator = function(amount) { return amount>0 }; //default validator

		if ( availableInsuranceAmount ) {
			let intervalsTexts = [];
			availableInsuranceAmount.intervals.forEach( ( interval ) => {
				if ( interval.from === interval.to ) {
					intervalsTexts.push(`${textService.formatNumber(interval.from)}`) ;
				}
				else {
					intervalsTexts.push(`${textService.formatNumber(interval.from)}-${textService.formatNumber(interval.to)}`);
				}
			} )

			if ( intervalsTexts.length > 0 ) {
				label += t( "configuratorTarget.card.insuranceAmountLimit", { intervals: intervalsTexts.join('; ') } );
			}
			if ( availableInsuranceAmount.step ) {
				label += t( "configuratorTarget.card.insuranceAmountStep", { step: availableInsuranceAmount.step } );
			}

			validator = (amount) => {
				let intervalsPassed = true;
				let stepPassed = true;
				if ( availableInsuranceAmount.intervals.length > 0 ) {
					intervalsPassed = availableInsuranceAmount.intervals.some( ( interval ) => interval.from <= amount && amount <= interval.to );
				}
				if ( availableInsuranceAmount.step ) {
					stepPassed = (amount % availableInsuranceAmount.step === 0);
				}
				const positiveAmount = (amount > 0);
				return intervalsPassed && stepPassed && positiveAmount;
			}
		}

		return {label: label, validator: validator};
	}, [availableInsuranceAmount, t])

	const addressOrBuildingElement = useMemo( () => {
		if ( building && building.label ) {
			return (
				<div className={'font-weight-light'}>
					<em>{building.label}</em>
				</div>
			)
		}
		else if ( defaultPlaceOfInsuranceStreet && defaultPlaceOfInsuranceZip ) {
			return (
				<div className={'font-weight-light'}>
					<em>{defaultPlaceOfInsuranceStreet}, {defaultPlaceOfInsuranceZip.label}</em>
				</div>
			)
		}
		else {
			return undefined;
		}
	}, [building, defaultPlaceOfInsuranceStreet, defaultPlaceOfInsuranceZip])

	const currentlyAvailableNamedCriteria = useMemo( () => {
		return {
			queryName: "currentlyAvailable",
				params: {availableOn: policyValidFrom}
		}
	}, [policyValidFrom])

	const displayIndexHelperElement = useMemo( () => {
		const amountNotChaned = amount === previousAmount //amount has not changed
		const newPolicyIsValidAfterLastInvoicePeriod = moment(previousNextInvoicePeriodFrom) <= moment(policyValidFrom) //new policy version should be valid later then the current policy version is invoiced
		const newPolicyIsValidInNextYear = moment(policyValidFrom).year() === indexYear + 1 //new policy version should be valid in the next year (otherwise there is no reason to offer the index for the next year)

		return indexed //product is indexed
			&&  amountNotChaned
			&&  newPolicyIsValidAfterLastInvoicePeriod
			&&  newPolicyIsValidInNextYear
	}, [indexed, amount, previousAmount, previousNextInvoicePeriodFrom, policyValidFrom, indexYear])

	const indexingHelperElement = useMemo( () => {
		if ( displayIndexHelperElement ) {
			if ( amountIndexedForIndexYearPlus1 !== null ) {
				return (
					<Col>
						<Alert variant={ "info" } className={ "mb-3" }>
							<p>{
								t('createPolicy.detail.indexingHelperElement.indexExists',
									{
										indexYear: indexYear,
										amount: textService.formatNumber( amount ),
										validFrom: textService.formatDateTime( moment(policyValidFrom), { dateStyle: 'medium' } ),
										amountIndexedForIndexYearPlus1: textService.formatNumber(amountIndexedForIndexYearPlus1 )
									}
								)
							}</p>
							<Button variant={"primary"} size={"sm"} onClick={ () => {
								setAmount( amountIndexedForIndexYearPlus1 )
								onAmountChanged( targetKey, amountIndexedForIndexYearPlus1 )
							}}>{t('createPolicy.detail.indexingHelperElement.indexExists.button.label')}</Button>
						</Alert>
					</Col>
				)
			}
			else {
				return (
					<Col>
						<Alert variant={ "warning" } className={ "mb-3" }>
							{t('createPolicy.detail.indexingHelperElement.indexNotExists',
							{
								indexYear: indexYear,
								amount: textService.formatNumber( amount ),
								validFrom: textService.formatDateTime( moment(policyValidFrom), { dateStyle: 'medium' } ),
								indexYearPlus1: indexYear + 1
							}
							)}
						</Alert>
					</Col>
				)
			}
		}
	}, [displayIndexHelperElement, amountIndexedForIndexYearPlus1, indexYear, amount, policyValidFrom])

	const validity = configuratorService.useConfiguratorValidity( tariffValidFrom, tariffValidTo, false, policyValidFrom )

	return (
		<div>
			<Card style={{overflow: "inherit"}}>
				<ConfiguratorTargetHeader eventKey={targetKey} onClick={onConfiguratorTargetHeaderClick} >
					<Row>
						{ validity }
						<Col md={11}>
							<span className={'fw-bold'}>{target}</span><span> (<NumericFormat value={ amount } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/>)</span>
						</Col>
						<Col md={1} className={"d-flex flex-row-reverse"}>
							<CloseButton onClick={() => onClose(targetKey)}/>
						</Col>
					</Row>
					{addressOrBuildingElement && <Row>
						<Col md={12}>
							{addressOrBuildingElement}
						</Col>
					</Row>}
					{totalElement && <Row>
						<Col md={12}>
							{totalElement}
						</Col>
					</Row>}
				</ConfiguratorTargetHeader>
				<Accordion.Collapse eventKey={targetKey}>
					<Card.Body className={"tour-configurator-target-insurance-amount"} >
					<Row>
						<FormControl
							hidden={true}
							name={'targetId-'+targetKey}
							type="number"
							value={targetId}
						/>
						<FormControl type={"text"} name={'isManualTariff-' + targetKey} hidden={true} value={ displayManualTariff }/>
						<FormControl
							hidden={true}
							name={'text-'+targetKey}
							type="text"
							value={target}
						/>
						<FormControl
							hidden={true}
							name={'fee-'+targetKey}
							type="number"
							value={feeBrutto}
						/>
						<FormControl
							hidden={true}
							name={'useStampTax-'+targetKey}
							type={'number'}
							value={useStampTax}
						/>
						<FormControl
							hidden={true}
							name={'useLoeschfuenfer-'+targetKey}
							type={'number'}
							value={useLoeschfuenfer}
						/>
						<FormControl type={"number"} name={'amount-'+targetKey} value={amount} hidden={true}/>

						{indexingHelperElement}

						<Form.Group as={Col} md="12" controlId={'groupFee'} className={"mb-3"}>
							<Form.Label>{t(configuratorTargetType.enumType + "." + configuratorTargetType.name)} ({insuranceAmountProps.label})</Form.Label>
							<FormControl
								name={'amount-field-'+targetKey}
								type="number"
								thousandSeparator={true}
								value={amount}
								onChange={(value) => {
									if (amountTimeoutHandle) {
										clearTimeout(amountTimeoutHandle);
									}
									setAmountTimeoutHandle( setTimeout( function(value) {
										setAmount(value);
										onAmountChanged( targetKey, value );
									}, 300, value));
								}}
								validationMessages={{
									range: insuranceAmountProps.label,
								}}
								rules={{
									required: true,
									validate: {
										range: (v) => insuranceAmountProps.validator(v)
									}
								}}

							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId={'policyType'} className={"mb-3"}>
							<Form.Label>{t('createPolicy.detail.policyType.label')}</Form.Label>
							<TypeaheadControl
								name={'policyType-'+targetKey}
								options={policyTypes}
								value={policyType}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId={'avb'} className={"mb-3"}>
							<Form.Label>{t('createPolicy.detail.avb.label')}</Form.Label>
							<RestAsyncSelectControl
								noSelection={true}
								domainName={ "avb" }
								value={ avb && avb.id }
								label={ avb && avb.label }
								sort={ "avbDescription.description" }
								name={ 'avb-'+targetKey }
								rules={{
									required: true
								}}
								namedCriteria={ currentlyAvailableNamedCriteria }
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId={'firstLossRisk'} className={"mb-3"}>
							<Form.Label>{t('createPolicy.detail.firstLossRisk.label')}</Form.Label>
							<div className={"yes-no-switchbutton"}><SwitchButtonControl onstyle={"danger"} offlabel={t("default.no")} onlabel={t("default.yes")} name={'firstLossRisk-'+targetKey} value={defaultFirstLossRisk} /></div>
						</Form.Group>
					</Row>
					{discountSurchargeElement}
					{feeElement}
					{individualTextsElement}
					{participationElement}
				</Card.Body>
				</Accordion.Collapse>
			</Card>
		</div>
	);
}

export { ConfiguratorTarget };
