import {Dropdown} from "../_components/Dropdown";
import {Col, Form, Row} from "react-bootstrap";

function DiscountSurchargeDropdown( { idx, targetKey, name, labels, defaultValue, onDiscountSurchargeKeyChanged }) {
	return (
		<Row>
			<Form.Group as={Col} md="12" controlId={"discountSurcharge-" + targetKey + "-" + idx}>
				<Form.Label>{ name }</Form.Label>
				<Dropdown
					name={ "_discountSurcharge-" + targetKey + "-" + idx }
					options={ labels.map( (label, index) => {
						return {key: index, value: label}
					}) }
					keyPropName={ 'key' }
					valuePropName={ 'value' }
					onChange={ (event) => {
						onDiscountSurchargeKeyChanged( idx, parseInt(event.target.value) )
					} }
					defaultValue={ defaultValue }
					required={true}
				/>
			</Form.Group>
		</Row>
	)
}

export default DiscountSurchargeDropdown
