import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";
import CreateEditBankPayment from "./CreateEditBankToCustmoerNotification";
import ShowBankToCustomerNotification from "./ShowBankToCustomerNotification";
import PaymentStats from "./PaymentStats";

function BankToCustomerNotification({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			hidden: true,
		},
		{
			dataField: 'createdOn',
			text: t( 'default.createdOn' ),
			sortField: 'createdOn',
			formatter: (cellContent, row) => bootstrapTableService.formatterDate(cellContent),
			sort: true
		},
		{
			dataField: 'createdBy',
			text: t('default.createdBy'),
			sortField: 'createdBy',
			sort: true
		},
		{
			dataField: 'filename',
			text: t('bankToCustomerNotification.filename'),
			sortField: 'filename_sort',
			sort: true
		},
		{
			dataField: 'nrOfPairedPayments', //virtual name
			text: t('bankToCustomerNotification.paymentStatusesStats'),
			formatter: (cellContent, row) => {
				return (<PaymentStats nrOfPayments={row.nrOfPayments} nrOfPairedInvoices={row.nrOfPairedInvoices} paymentStatesStats={row.paymentStatesStats}/>)
			}
			// sortField: 'nrOfPayments',
			// sort: true
		},
	];

	return (
		<React.StrictMode>
			<Index
				path={match.path}
				domainName={ 'bankToCustomerNotification' }
				columns={ columns }
				toString={ (object) => object.filename }
				// formElements={ <FormElements/> }
				sortField={'createdOn'}
				sortOrder={'desc'}
				// allowEdits={true}
				allowShow={true}
				showElement={<ShowBankToCustomerNotification/>}
				createElement={<CreateEditBankPayment/>}
				// editElement={<CreateEditDamage/>}
				allowDeletions={true}
			/>
		</React.StrictMode>
	);
}

export { BankToCustomerNotification };
