import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {saveAs} from "file-saver";
import {Button, Figure} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faFile, faFileArchive,
    faFileAudio, faFileCode, faFileExcel,
    faFileImage,
    faFilePdf, faFilePowerpoint,
    faFileVideo, faFileWord,
    faTrashAlt,
    faUndoAlt
} from "@fortawesome/free-solid-svg-icons";
import {fileService} from "../_services/file.service";

function Attachment({index, attachment, onChange, showImages}) {
    const isImage = useMemo( () => {
        if ( attachment.mimeType ) {
            return attachment.mimeType.search('image') === 0;
        }
        else {
            return false;
        }
    }, [attachment] )
    const [deleted, setDeleted] = useState(false);
    const [imgUrl, setImgUrl] = useState(undefined);

    const handleDownload = useCallback( () => {
        fileService.downloadAttachment(attachment.id)
            .then(blob => saveAs(blob, attachment.filename))
    }, [attachment]);

    useEffect( () => {
        if ( isImage ) {
            fileService.downloadAttachment( attachment.id )
                .then( blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        setImgUrl(base64data);
                    };
                } )
        }
    }, [isImage, attachment.id])

    const handleDelete = () => {
        const newStatus = !deleted;
        setDeleted(newStatus);
        onChange((newStatus?'delete':'undo'), attachment.id);
    }

    const iconClasses = {
        // Media
        'image': faFileImage,
        'audio': faFileAudio,
        'video': faFileVideo,
        // Documents
        'application/pdf': faFilePdf,
        'application/msword': faFileWord,
        'application/vnd.ms-word': faFileWord,
        'application/vnd.oasis.opendocument.text': faFileWord,
        'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
        'application/vnd.ms-excel': faFileExcel,
        'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
        'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
        'application/vnd.ms-powerpoint': faFilePowerpoint,
        'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
        'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
        'text/plain': faFile,
        'text/html': faFileCode,
        'application/json': faFileCode,
        // Archives
        'application/gzip': faFileArchive,
        'application/zip': faFileArchive
    }

    const getIconClass = (mimeType) => {
        let result = faFile;
        if ( mimeType ) {
            for (let key in iconClasses) {
                if (iconClasses.hasOwnProperty(key) && mimeType.search(key) === 0) {
                    result = iconClasses[key];
                    break;
                }
            }
        }
        return result;
    }

    let icon = faTrashAlt;
    let variant = "danger";
    if ( deleted ) {
        icon = faUndoAlt;
        variant = "success";
    }

    const getImageAttachment = () => {
        return <>
                <Figure.Image alt={attachment.filename} src={imgUrl} />
            </>
    }

    const getOtherAttachment = () => {
        return (
            <>
                <FontAwesomeIcon icon={ getIconClass( attachment.mimeType ) } style={{ height: "30px" }} color={"#0d6efd"}/>
            </>
        )
    }


    return (
        <div className={ `attachment ${ deleted ? 'deleted' : '' }` } key={ index }>
            <div>
                <Figure>
                    { showImages && isImage ? getImageAttachment() : getOtherAttachment() }
                </Figure>
                <div className={'filename'}> { attachment.filename } </div>
            </div>
            <div className={'buttons'}>
                { onChange && <Button className={'me-2'} type={ 'button' } variant={ variant } size={ "sm" } onClick={ handleDelete }><FontAwesomeIcon size={ "sm" } icon={ icon }/></Button>}
                <Button type={ 'button' } variant={ 'primary' } size={ "sm" } onClick={ handleDownload }><FontAwesomeIcon size={ "sm" } icon={ faDownload }/></Button>
            </div>
        </div>
        )
}

export default Attachment;
