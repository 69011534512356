import React from 'react';
import PaymentStateBadge from "../payment/PaymentStateBadge";

function PaymentStatusesStats({paymentStatesStats}) {

    return (
        <div>
            {
                paymentStatesStats && paymentStatesStats.map((item, key) => {
                    return (
                        <div key={key}>
                            <PaymentStateBadge paymentState={item.paymentState} customText={item.count}/>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PaymentStatusesStats
