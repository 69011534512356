import React, {useMemo} from 'react';
import {Table} from "react-bootstrap";
import PropTypes from "prop-types";

function SimpleTable( {columns, rows} ) {

    const visibleColumns = useMemo( () => {
        return columns.filter( (column) => !column.hidden )
    }, [columns] )

    const table = useMemo( () => {
        const getRows = () => {
            const getRow = ( object, key ) => {
                return (
                    <tr key={ key }>
                        { visibleColumns.map( column => {
                            return <td className={(column.className && column.className instanceof Function) ? column.className(object) : column.className } key={ column.key }>{ column.formatter( object ) }</td>
                        } ) }
                    </tr>
                )
            }

            if ( rows && rows.length > 0 ) {
                return rows.map( ( object, index ) => getRow( object, index ) );
            }
            else {
                return <></>;
            }
        }

        return (
            <Table bordered>
                <thead>
                <tr key={'0'}>
                    {visibleColumns.map(column => {
                        return <th className={column.className} key={column.key}>{column.label}</th>
                    })}
                </tr>
                </thead>
                <tbody>
                { getRows() }
                </tbody>
            </Table>
        )
    }, [rows, visibleColumns] )

	return table
}

SimpleTable.propTypes = {
    columns: PropTypes.arrayOf( PropTypes.object ).isRequired,
    rows: PropTypes.arrayOf( PropTypes.object ).isRequired,
}

export default SimpleTable