import React, {useMemo} from 'react';
import PreviewSettlementBatch from "../_components/_settlementBatch/PreviewSettlementBatch";
import OtherLeadershipDiscountSettlementBatchFormElements from "./OtherLeadershipDiscountSettlementBatchFormElements";

function PreviewOtherLeadershipDiscountSettlementBatch( { id } ) {
	const hiddenFields = useMemo(() => ['policyNumber', 'detail'], [])

	return (
		<PreviewSettlementBatch
			className={'otherLeadershipDiscountSettlementBatch'}
			id={ parseInt(id) }
			settlementBatchFormElements={<OtherLeadershipDiscountSettlementBatchFormElements />}
			hiddenFields={hiddenFields}
		/>
	);
}

export default PreviewOtherLeadershipDiscountSettlementBatch
