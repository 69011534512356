import React from 'react'
import {useTranslation} from "react-i18next";
import appService from "../_services";
import {Alert} from "react-bootstrap";
import textService from "../_services/text.service";
import moment from "moment";

function Envers({domain}) {
	const {t} = useTranslation()

	return (
		<Alert variant={'info'} className={'ps-2 pt-1 pb-1 pe-2'}>
			<small>{t('envers.created')} {t('envers.by')} {appService.nullSafeGet(domain, 'createdBy')} {t('envers.on')} {textService.formatDateTime(moment(appService.nullSafeGet(domain, 'createdOn')))} / </small>
			<small>{t('envers.changed')} {t('envers.by')} {appService.nullSafeGet(domain, 'lastChangedBy')} {t('envers.on')} {textService.formatDateTime(moment(appService.nullSafeGet(domain, 'lastChangedOn')))}</small>
		</Alert>
	)
}

export default Envers