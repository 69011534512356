import React, {useMemo} from 'react';
import {Button, Form} from 'react-bootstrap'
import { alertService } from '../_services'
import {useTranslation} from "react-i18next";
import { withRouter } from 'react-router-dom';
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {formService} from "./form.service";
import SubmitButton from "../_components/SubmitButton";
import FormControl from "../_components/FormControl";

function AddEditForm( { domainName, instanceToString, history, children, beforeSubmit, routeParams } ) {
	const { t } = useTranslation();
	const _domainName = useMemo( () => ((routeParams && routeParams.domainName) ? routeParams.domainName : domainName), [routeParams, domainName] );
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [domainInstance] = restService.useDomainInstance( _domainName, id, true );
	const useFormObject = useForm();
	const isAddMode = !id;

	function goBack() {
		if ( history.length === 1 ) {
			window.close();
		}
		else {
			history.goBack();
		}
	}

	function onSubmit(data) {
		const flattenData = formService.flattenSubmitData(data);
		let overrides = {data: flattenData};

		if ( beforeSubmit ) {
			overrides = { overrides, ...beforeSubmit({...overrides}) };
		}

		if ( isAddMode ) {
			return create( _domainName, overrides.data );
		} else {
			return update( _domainName, id, overrides.data );
		}
	}

	function create(domainName, data) {
		return new Promise( (resolve, reject) => {
			restService.saveDomainInstance( domainName, data )
				.then( () => {
					alertService.success( t( 'default.created', { what: instanceToString( data ) } ), { keepAfterRouteChange: true } );
					goBack();
					resolve();
				} )
				.catch( ( error, signal ) => {
					console.log(error);
					restService.handleServerErrors( error, signal );
					resolve(); //if finishes formState.isSubmitting
				} );
		})
	}

	function update(domainName, id, data) {
		return new Promise( (resolve, reject) => {
		restService.updateDomainInstance( domainName, id, data )
			.then( () => {
				alertService.success( t( 'default.updated', { what: instanceToString( data ) } ), { keepAfterRouteChange: true } );
				goBack();
				resolve();
			})
			.catch( (error, signal ) => {
				console.log(error);
				restService.handleServerErrors( error, signal )
				resolve(); //if finishes formState.isSubmitting
			} );
		})
	}

	const childrenProps = useMemo( () => {
		return {
			domainInstance: domainInstance,
			isAddMode: isAddMode
		}
	}, [domainInstance, isAddMode])

	const handleCancel = () => {
		goBack();
	}

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<FormControl
						hidden={true}
						name="id"
						type={"number"}
						value={domainInstance && domainInstance.id}
					/>
                    <FormControl
                        hidden={true}
                        name="version"
                        type={"number"}
						value={domainInstance && domainInstance.version}
                    />
					{React.cloneElement(children, {...childrenProps})}
					<Form.Group>
						<SubmitButton isSubmitting={useFormObject.formState.isSubmitting} type="submit" className="btn btn-primary">{t('default.save')}</SubmitButton>&nbsp;
						<Button className="btn btn-secondary" onClick={handleCancel}>{t('default.cancel')}</Button>
					</Form.Group>
				</Form>
			</FormProvider>
		</div>
	);
}

AddEditForm.propTypes = {
	domainName: PropTypes.string.isRequired,
	instanceToString: PropTypes.func,
	history: PropTypes.object.isRequired,
	children: PropTypes.any.isRequired,
	beforeSubmit: PropTypes.func
};


export default withRouter(AddEditForm);
