import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import bootstrapTableService from "../_components/bootstrapTable.service";
import DomainTable from "../_components/DomainTable";
import PropTypes from "prop-types";
import DeleteConfirmDialog from "../_components/DeleteConfirmDialog";
import restService from "../_services/rest.service";
import {alertService} from "../_services";
import DiscountSurcharge from "../policy/DiscountSurcharge";

function DamagePaymentInfoDetails({namedCriteria, searchOptions, hiddenFields}) {
    const {t} = useTranslation();
    const domainTableRef = useRef()

    const handleDelete = () => {
        try {
            let row = rowToBeDeleted;
            restService.deleteDomainInstance( 'damagePaymentInfoDetail', row.id )
                .then( () => {
                    alertService.success(t("default.deleted", {what: t('damagePaymentInfoDetail.label')}), { keepAfterRouteChange: true });
                    domainTableRef.current.onRowDeleted(row)
                })
                .catch( (error, signal) => {
                    restService.handleServerErrors(error, signal);
                })

        }
        catch (e) {
            alertService.error(e);
        }
    }

    const [rowToBeDeleted] = useState({});
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);


    const columns = useMemo(() => {
        const _columns = [
            {
                dataField: 'id',
                text: t('damagePaymentInfoDetail.id'),
                hidden: true,
            },
            {
                dataField: 'damageDetail.label',
                text: t('damagePaymentInfoDetail.damageDetail'),
            },
            {
                dataField: 'discountSurcharge',
                text: t('damageDetail.discountSurcharge'),
                formatter: (cellContent, row) => {
                    if ( row.damageDetail.participationType ) {
                        return row.damageDetail.participationType.label
                    }
                    else if ( row.damageDetail.policyTarget && row.damageDetail.policyTarget.discountSurcharge ) {
                        return <DiscountSurcharge policyTarget={row.damageDetail.policyTarget} />
                    }
                }
            },
            {
                dataField: 'amount',
                text: t('damagePaymentInfoDetail.amount'),
                formatter: (cellContent, row) => bootstrapTableService.formatterAmount(cellContent),
            },
            {
                dataField: 'participation',
                text: t('damagePaymentInfoDetail.participation'),
                formatter: (cellContent, row) => bootstrapTableService.formatterBoolean(cellContent),
            },
        ];

        return _columns.filter( (column) => !hiddenFields.includes( column.dataField ) );
    }, [hiddenFields, t])

    return (
        <>
            <DomainTable
                ref={domainTableRef}
                domainName={'damagePaymentInfoDetail'}
                columns={columns}
                namedCriteria={namedCriteria}
                searchOptions={searchOptions}
                // rowClasses={rowClasses}
                // sortField={'billingFrom'}
                // sortOrder={'desc'}
            />
            <DeleteConfirmDialog
                show={showDeleteConfirm}
                onHide={handleCloseDeleteConfirm}
                bodyText={t('deleteConfirm.body', {name: t('damagePaymentInfoDetail.label')})}
                onDelete={handleDelete}
            />
        </>
    )
}


DamagePaymentInfoDetails.defaultProps = {
    hiddenFields: []
};

DamagePaymentInfoDetails.propTypes = {
    namedCriteria: PropTypes.object,
    hiddenFields: PropTypes.array
};

export default DamagePaymentInfoDetails
