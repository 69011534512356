import React from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import SubmitButton from "../../_components/SubmitButton";

function Navigation( props ) {
    const history = useHistory();
    const {t} = useTranslation()
    const handlePrev = (event) => {
        props.previousStep();
    }
    const handleCancel = (even) => {
        let id = props.damagePaymentInfoData && props.damagePaymentInfoData.baseData && props.damagePaymentInfoData.baseData.id
        let damageId = props.damagePaymentInfoData && props.damagePaymentInfoData.baseData && props.damagePaymentInfoData.baseData.damage.id
        if ( undefined !== id ) {
            history.push('/damagePaymentInfo/show/' + id)
        }
        else if ( undefined !== damageId ) {
            history.push('/damage/show/' + damageId)
        }
        else {
            history.push('/damage')
        }
    }

    return (
        <div className={"d-grid gap-2 d-md-flex mb-2"}>
            <Button variant="primary" className="" onClick={handlePrev} disabled={props.currentStep === 1}>
                {t('default.previous')}
            </Button>
            {props.currentStep !== props.totalSteps && <Button type="submit">
                {t('default.next')}
            </Button>}
            {props.currentStep === props.totalSteps &&
                <SubmitButton isSubmitting={props.isSubmitting} type={"submit"} className='btn btn-default me-1'>{t('default.save')}</SubmitButton>
            }
            <Button className='btn btn-secondary me-1' onClick={handleCancel}>{t('default.cancel')}</Button>
        </div>
    )
}

export {Navigation}
