import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";
import {GoogleMapLink} from "../_components/GoogleMapLink";

function Building({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('building.id.label'),
		hidden: true
	},
	{
		dataField: 'street',
		sortField: 'street_sort',
		text: t('building.street.label'),
		sort: true,
	},
	{
		dataField: 'zip',
		text: t('building.zip.label'),
		sort: true,
		sortField: 'zip.zip',
		formatter: (cellContent, row) => {
			return (
				<span><GoogleMapLink street={row.street} zipLabel={row.zip && row.zip.label}/><span className={"ml-2"}>{cellContent.label}</span> </span>
			)
		}
	},
	{
		dataField: 'description',
		text: t('building.description.label'),
		sortField: 'description_sort',
		sort: true
	}
	// , {
	// 	dataField: 'buildingType.label',
	// 	text: t('building.buildingType.label'),
	// 	sort: true
	// }, {
	// 	dataField: 'buildingClass.label',
	// 	text: t('building.buildingClass.label'),
	// 	sort: true
	// }, {
	// 	dataField: 'constructionType.label',
	// 	text: t('building.constructionType.label'),
	// 	sort: true
	// }, {
	// 	dataField: 'fireExtinguisher.label',
	// 	text: t('building.fireExtinguisher.label'),
	// 	sort: true
	// }, {
	// 	dataField: 'cubicCapacity',
	// 	text: t('building.cubicCapacity.label'),
	// 	sort: true
	// }, {
	// 	dataField: 'flatRoof',
	// 	text: t('building.flatRoof.label'),
	// 	sort: true
	// }
	];

	return (
		<Index
			path={match.path}
			domainName={'building'}
			columns={columns}
			toString={ (object) => object.description }
			formElements={<FormElements/>}
			sortField={'street_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { Building };
