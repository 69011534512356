import React from 'react'
import {Link} from "react-router-dom";

function PlainUserMessage(params) {
	const getLinks = () => {
		if ( params.links && params.links.length > 0 ) {
			const links = params.links.map( (link, idx) => {
				let linkParams = {
					to: link.href,
				}
				if ( link.newTab ) {
					linkParams.target = "_blank"
					linkParams.rel = "noopener noreferrer"
				}
				return <Link key={idx} className="btn btn-sm btn-primary" {...linkParams}>{ link.text }</Link>
			})

			return <>{links}</>
		}
		else {
			return null
		}
	}

	return (
		<>
			<p>{params.message}</p>
			{getLinks()}
		</>
	)
}

export default PlainUserMessage