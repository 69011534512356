import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { v4 as uuid } from 'uuid';
import {saveAs} from "file-saver";
import {
    ChonkyActions,
    FileBrowser,
    FileContextMenu,
    FileHelper,
    FileList,
    FileNavbar,
    FileToolbar,
} from 'chonky';
import {fileService} from "../_services/file.service";
import PropTypes from "prop-types";
import {Button, Form, Modal} from "react-bootstrap";
import FileUploader from "./FileUploader";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {createIntl, createIntlCache} from "react-intl";
import i18n from "i18next";

export const ChonkyFileBrowser = ({holderType, holderId}) => {
    const [currentFolderId, setCurrentFolderId] = useState();
    const [fileMap, setFileMap] = useState()
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [uploadModalReadyForSubmit, setUploadModalReadyForSubmit] = useState(false)
    const [deletedDirIds, setDeletedDirIds] = useState([])
    const [deletedFileIds, setDeletedFileIds] = useState([])
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false)
    const {t} = useTranslation()
    const useFormObject = useForm()
    const [resetLocalStorage, setResetLocalStorage] = useState( true )

    if ( resetLocalStorage ) {
        localStorage.removeItem('missingTranslations')
        setResetLocalStorage(false)
    }

    //Load attachments from the server
    useEffect(()=> {
        fileService.attachmentsInfo(holderType, holderId).then(
            data => {
                setCurrentFolderId(data.rootFolderId);
                setFileMap(data.fileMap);
            }
        )
    }, [holderType, holderId])

    const saveAttachmentsInfo = useCallback( () => {
        let data = {
            deletedDirIds: deletedDirIds,
            deletedFileIds: deletedFileIds,
            fileMap: fileMap
        }
        fileService.saveAttachmentsInfo(holderType, holderId, data).then(
            data => {
                setUnsavedChanges(false);
                setCurrentFolderId(data.rootFolderId);
                setDeletedDirIds([]);
                setDeletedFileIds([]);
                setFileMap(data.fileMap);
            }
        )
    },[fileMap, holderId, holderType, deletedDirIds, deletedFileIds])

    const files = useMemo( () => {
        let files;
        if ( currentFolderId && fileMap && Object.keys(fileMap).length > 0 ) {
            const currentFolder = fileMap[currentFolderId];
            files = currentFolder.childrenIds
                ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ?? null)
                : [];
        }
        return files;
    }, [fileMap, currentFolderId]);

    const handleDownload = useCallback( (file) => {
        fileService.downloadAttachment(file.id)
            .then(blob => saveAs(blob, file.name))
    }, [])

    const folderChain = useMemo( () => {
        let folderChain;
        if ( currentFolderId && fileMap && Object.keys(fileMap).length > 0 ) {
            const currentFolder = fileMap[currentFolderId];

            folderChain = [currentFolder];

            let parentId = currentFolder.parentId;
            while (parentId) {
                const parentFile = fileMap[parentId];
                if (parentFile) {
                    folderChain.unshift(parentFile);
                    parentId = parentFile.parentId;
                } else {
                    parentId = null;
                }
            }
        }
        return folderChain;
    }, [currentFolderId, fileMap])

    const getSaveButton = () => {
        return (
                <div className="d-grid">
                    <Button onClick={saveAttachmentsInfo} disabled={!hasUnsavedChanges} variant={"danger"} className={"mb-2"}>
                        {t('default.save')}
                    </Button>
                </div>
        )
    }

    const thumbnailGenerator = useCallback((file) => {
        if ( file.thumbnailUrl ) {
            return new Promise( (resolve, reject) => {
                return fileService.thumbnail(file.thumbnailUrl, file.id)
                    .then(blob => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            const base64data = reader.result;
                            resolve(base64data);
                        };
                    })
                    .catch(reject);
            });
        }
        else {
            return null
        }
    }, []);

    // Setup logic to listen to changes in current folder ID without having to update
    // `useCallback` hooks. Read more about it here:
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
    const currentFolderIdRef = useRef(currentFolderId);
    useEffect(() => {
        currentFolderIdRef.current = currentFolderId;
    }, [currentFolderId]);

    // Function that will be called when user creates a new folder using the toolbar
    // button. UUID is used for newly created folder's ID
    const createFolder = useCallback((folderName) => {
        setFileMap((currentFileMap) => {
            const newFileMap = { ...currentFileMap };

            // Create the new folder
            const newFolderId = uuid();
            newFileMap[newFolderId] = {
                id: newFolderId,
                name: folderName,
                isDir: true,
                modDate: new Date(),
                parentId: currentFolderIdRef.current,
                childrenIds: [],
                childrenCount: 0,
            };

            // Update parent folder to reference the new folder.
            const parent = newFileMap[currentFolderIdRef.current];
            newFileMap[currentFolderIdRef.current] = {
                ...parent,
                childrenCount: parent.childrenCount+1,
                childrenIds: [...parent.childrenIds, newFolderId],
            };
            setUnsavedChanges(true);
            return newFileMap;
        });
    }, []);

    const processChildrenWhenParentDelete = useCallback( (fileMap, parentId, deletedDirIds, deletedFileIds) => {
        const keysToDelete = new Set();
        for (const property in fileMap) {
            if (fileMap[property].parentId === parentId) {
                keysToDelete.add(fileMap[property].id)
            }
        }
        keysToDelete.forEach(key => {
            const actFile = fileMap[key]
            if (actFile.isDir === true) {
                processChildrenWhenParentDelete(fileMap, actFile.id, deletedDirIds, deletedFileIds)
                deletedDirIds.push(actFile.id)
            } else {
                deletedFileIds.push(actFile.id)
            }
            delete fileMap[key]
        })
    },[])

    // Function that will be called when user deletes files either using the toolbar
    // button or `Delete` key.
    const deleteFiles = useCallback((files) => {
        // We use the so-called "functional update" to set the new file map. This
        // lets us access the current file map value without having to track it
        // explicitly. Read more about it here:
        // https://reactjs.org/docs/hooks-reference.html#functional-updates
        setFileMap((currentFileMap) => {
            // Create a copy of the file map to make sure we don't mutate it.
            const newFileMap = { ...currentFileMap };
            const newDeletedDirIds = [];
            const newDeletedFileIds = [];
            files.forEach((file) => {
                // Delete file from the file map.
                processChildrenWhenParentDelete( newFileMap, file.id, newDeletedDirIds, newDeletedFileIds )
                delete newFileMap[file.id];
                if ( file.isDir ) {
                    newDeletedDirIds.push(file.id);
                }
                else {
                    newDeletedFileIds.push(file.id)
                }
                // Update the parent folder to make sure it doesn't try to load the
                // file we just deleted.
                if (file.parentId) {
                    const parent = newFileMap[file.parentId];
                    const newChildrenIds = parent.childrenIds.filter(
                        (id) => id !== file.id
                    );
                    newFileMap[file.parentId] = {
                        ...parent,
                        childrenIds: newChildrenIds,
                        childrenCount: newChildrenIds.length,
                    };
                }
            });
            if ( newDeletedDirIds.length > 0 ) {
                newDeletedDirIds.push(...deletedDirIds)
                setDeletedDirIds(newDeletedDirIds);
            }
            if ( newDeletedFileIds.length > 0 ) {
                newDeletedFileIds.push(...deletedFileIds)
                setDeletedFileIds(newDeletedFileIds)
            }
            setUnsavedChanges(true);
            return newFileMap;
        });
    }, [deletedDirIds, deletedFileIds, processChildrenWhenParentDelete]);

    /**
     * Function that will be called when files are added from dropzone or moved from one folder to another
     * using drag & drop.
     * If there is no source is not specified, files will be just added to destination folder. Otherwise, they will be
     * removed from source too.
     * @type {(function(files, source, destination): void)|*}
     */
    const addOrMoveFiles = useCallback( ( files, source, destination ) => {
        setFileMap((currentFileMap) => {
            const newFileMap = { ...currentFileMap };
            const moveFileIds = new Set(files.map((f) => f.id));

            if ( undefined !== source ) {
                // Delete files from their source folder.
                const newSourceChildrenIds = source.childrenIds.filter(
                    (id) => !moveFileIds.has(id)
                );
                newFileMap[source.id] = {
                    ...source,
                    childrenIds: newSourceChildrenIds,
                    childrenCount: newSourceChildrenIds.length,
                };
            }

            // Add the files to their destination folder.
            const newDestinationChildrenIds = [
                ...destination.childrenIds,
                ...files.map((f) => f.id),
            ];
            newFileMap[destination.id] = {
                ...destination,
                childrenIds: newDestinationChildrenIds,
                childrenCount: newDestinationChildrenIds.length,
            };

            // Finally, set new or update the parent folder ID on the files from source folder
            // ID to the destination folder ID.
            files.forEach((file) => {
                newFileMap[file.id] = {
                    ...file,
                    parentId: destination.id,
                };
            });
            setUnsavedChanges(true);
            return newFileMap;
        });
        }, []
    );

    const fileActionHandler = useCallback((data) => {
        // console.log('action', data.id);
        if (data.id === ChonkyActions.OpenFiles.id) {
            const { targetFile, files } = data.payload;
            const fileToOpen = targetFile ?? files[0];
            if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                setCurrentFolderId(fileToOpen.id);
                return;
            }
            else {
                handleDownload(fileToOpen);
                return;
            }
        }
        else if (data.id === ChonkyActions.DeleteFiles.id) {
            deleteFiles(data.state.selectedFilesForAction);
        }
        else if (data.id === ChonkyActions.MoveFiles.id) {
            addOrMoveFiles(
                data.payload.files,
                data.payload.source,
                data.payload.destination
            );
        }
        else if (data.id === ChonkyActions.CreateFolder.id) {
            const folderName = prompt(t('default.new.folder.name'));
            if (folderName) createFolder(folderName);
        }
        else if (data.id === ChonkyActions.UploadFiles.id) {
            setShowUploadModal(true);
        }
    }, [createFolder, setCurrentFolderId, deleteFiles, addOrMoveFiles, handleDownload, t]
    );

    const fileActions = useMemo(
        () => [
            ChonkyActions.CreateFolder,
            ChonkyActions.DeleteFiles,
            ChonkyActions.UploadFiles,
            ChonkyActions.MoveFiles,
        ],
        []
    );

    const uploadModal = () => {
        const handleReadyForSubmitChange = (ready) => {
            setUploadModalReadyForSubmit(ready);
        }
        return (
            <Modal
                show={showUploadModal}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <FormProvider {...useFormObject}>
                        <Form>
                            <FileUploader changesPropertyName={'fileChanges'} onReadyForSubmitChange={handleReadyForSubmitChange}/>
                        </Form>
                    </FormProvider>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" disabled={!uploadModalReadyForSubmit} onClick={() => {
                        let createdFiles = Object.values(useFormObject.getValues()['fileChanges'])
                        let files = []
                        if ( createdFiles ) {
                            files = createdFiles.map( (index) => index.fileInfo )
                        }
                        addOrMoveFiles(files, undefined, fileMap[currentFolderId]);
                        setShowUploadModal(false);
                    }}>{t('default.add')}</Button>
                    <Button variant="secondary" onClick={() => {
                        setShowUploadModal(false);
                    }}>{t('default.close')}</Button>
                </Modal.Footer>
            </Modal>
        )

    }

    const cache = createIntlCache()

    const deIntl = createIntl({
        locale: i18n.language,
        messages: {
            "chonky.toolbar.visibleFileCount": "{fileCount, plural,\n                =0 {# Einträge}\n                one {# Eintrag}\n                other {# Einträge}\n            }",
            "chonky.toolbar.selectedFileCount": "{fileCount, plural,\n                =0 {# ausgewählt}\n                one {# ausgewählt}\n                other {# ausgewählte}\n            }",
            "chonky.toolbar.hiddenFileCount": "{fileCount, plural,\n                =0 {}\n                other {# ausgeblendet}\n            }",
            "chonky.toolbar.searchPlaceholder": "Suche",
            "chonky.contextMenu.browserMenuShortcut": "Browser-Menü: {shortcut}",
            "chonky.actions.open_parent_folder.button.name": "Ein Ordner nach oben gehen",
            "chonky.actions.create_folder.button.name": "Ordner erstellen",
            "chonky.actions.create_folder.button.tooltip": "Einen Ordner erstellen",
            "chonky.actionGroups.Actions": "Aktionen",
            "chonky.actions.upload_files.button.name": "Dateien hochladen",
            "chonky.actions.upload_files.button.tooltip": "Dateien hochladen",
            "chonky.actions.enable_list_view.button.name": "Zur Listenansicht wechseln",
            "chonky.actions.enable_grid_view.button.name": "Zur Rasteransicht wechseln",
            "chonky.actionGroups.Options": "Optionen",
            "chonky.fileList.nothingToShow": "Nichts zu zeigen",
            "chonky.actions.delete_files.button.name": "Dateien löschen",
            "chonky.actions.open_selection.button.name": "Auswahl öffnen",
            "chonky.actions.select_all_files.button.name": "Alle Dateien auswählen",
            "chonky.actions.clear_selection.button.name": "Auswahl löschen",
            "chonky.actions.sort_files_by_name.button.name": "Nach Namen sortieren",
            "chonky.actions.sort_files_by_size.button.name": "Nach Größe sortieren",
            "chonky.actions.sort_files_by_date.button.name": "Nach Datum sortieren",
            "chonky.actions.toggle_hidden_files.button.name": "Ausgeblendete Dateien anzeigen",
            "chonky.actions.toggle_show_folders_first.button.name": "Ordner zuerst anzeigen",
        },
        // onError: (err) => {
        //     let missingTranslations = JSON.parse(localStorage.getItem('missingTranslations')) || {}
        //     missingTranslations = ( {...missingTranslations, [err.descriptor.id]: err.descriptor.defaultMessage} )
        //     localStorage.setItem('missingTranslations', JSON.stringify(missingTranslations));
        // }
    }, cache)

    return (
        <>
            {uploadModal()}
            {hasUnsavedChanges  && getSaveButton()}
            <div style={{ height: 400 }}>
                <FileBrowser
                    files={files}
                    folderChain={folderChain}
                    thumbnailGenerator={thumbnailGenerator}
                    fileActions={fileActions}
                    onFileAction={fileActionHandler}
                    i18n={i18n.resolvedLanguage == 'de' ? deIntl : undefined}
                    // disableDragAndDropProvider={true}
                    // disableDragAndDrop={true}
                    // disableDefaultFileActions={true}
                >
                    <FileNavbar />
                    <FileToolbar />
                    <FileList />
                    <FileContextMenu />
                </FileBrowser>
            </div>
        </>
    );
};

ChonkyFileBrowser.propTypes = {
    holderId: PropTypes.number.isRequired,
    holderType: PropTypes.oneOf(['damage', 'policy']).isRequired
};
