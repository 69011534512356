import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";

function FormElements({domainInstance, isAddMode}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className="mb-3">
				<Card.Header>{t('salutation.card.common')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="2" controlId="formGroupSalutationDescription">
							<Form.Label>{t('salutation.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value= {domainInstance.description}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="5" controlId="formGroupSalutationName">
							<Form.Label>{t('salutation.salutation')}</Form.Label>
							<FormControl
								name={"salutation"}
								type={"text"}
								value= {domainInstance.salutation}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
