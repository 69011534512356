import React, {useEffect, useMemo, useState} from 'react';
import {Button, ButtonGroup, Card, Col, Dropdown, Form, FormLabel, InputGroup, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as ZipFormElements} from "../zip/FormElements";
import {PartnerType} from "../_enum/enum";
import FormCheckControl from "../_components/FormCheckControl";
import EditableTableControl from "../form/EditableTableControl";
import {partnerService} from "../_services/partner.service";
import Attachments from "../_components/Attachments";
import FileUploader from "../_components/FileUploader";
import {GoogleMapLink} from "../_components/GoogleMapLink";
import {isValid as isValidIban} from 'iban';
import {useFormContext} from "react-hook-form";
import {damageService} from "../_services/damage.service";
import DropdownItemWithOptions from "../_components/DropdownItemWithOptions";
import restService from "../_services/rest.service";

function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();
	const [zipLabel, setZipLabel] = useState(domainInstance.zipLabel || '');
	const [streetName, setStreetName] = useState(domainInstance.streetName || '');
	const [streetNr, setStreetNr] = useState(domainInstance.streetNr || '');
	const [isAgent, setIsAgent] = useState(domainInstance.isAgent)
	const [isBroker, setIsBroker] = useState(domainInstance.isBroker)
	const [isInsuranceCompany, setIsInsuranceCompany] = useState(domainInstance.isInsuranceCompany)
	const brokerInterestRateNames = partnerService.useBrokerInterestRateNames()
	const [brokerInterestRatesData, setBrokerInterestRatesData] = useState( [] )
	const [attachmentsData, setAttachmentsData] = useState([])
	const { getValues } = useFormContext();
	const [tags] = restService.useDomainInstancesList('partnerTag', 1, 10000, undefined, undefined, undefined, undefined, "select");

	const partnerType = useMemo( () => domainInstance.partnerType || (defaultValues && defaultValues.partnerType), [domainInstance, defaultValues])

	const columns = [
		{id: "validFrom", label: t("brokerInterestRate.validFrom.label"), input: {tag: "input", type: "date", required: true}},
		{id: "interestRate", label: t("brokerInterestRate.interestRate.label"), input: {tag: "input", type: "text", required: true}},
		{id: "name", label: t("brokerInterestRate.name.label"), input: {tag: "typeahead", id: "brokerInterestRate", type: "text", options: brokerInterestRateNames, required: true}},
	]

	useEffect( () => {
		const getBrokerInterestRatesData = () => {
			if ( domainInstance && domainInstance.brokerInterestRates ) {
				return domainInstance.brokerInterestRates.map((interestRateData) => {
					return {
						id: interestRateData.id,
						validFrom: interestRateData.validFrom,
						interestRate: interestRateData.interestRate,
						name: interestRateData.name,
					}
				})
			}
			else {
				return []
			}
		}
		const getAttachmentsData = () => {
			if ( domainInstance && domainInstance.attachments ) {
				return domainInstance.attachments.map((attachmentsData) => {
					return attachmentsData
				})
			}
			else {
				return []
			}
		}
		setZipLabel(domainInstance.zipLabel || '')
		setStreetName(domainInstance.streetName || '');
		setStreetNr(domainInstance.streetNr || '');
		setIsAgent( domainInstance.isAgent );
		setIsBroker( domainInstance.isBroker || false );
		setIsInsuranceCompany( domainInstance.isInsuranceCompany );
		setBrokerInterestRatesData( getBrokerInterestRatesData() );
		setAttachmentsData( getAttachmentsData() );
	}, [domainInstance])

	const renderPhonePrivate = () => {
		if ( partnerType === PartnerType.PERSON ) {
			return (
				<Form.Group as={Col} md="4" controlId="formGroupNamePhonePrivate">
					<Form.Label>{t('partner.phonePrivate')}</Form.Label>
					<FormControl
						name={"phonePrivate"}
						type={"text"}
						value= {domainInstance.phonePrivate || (defaultValues && defaultValues.phonePrivate)}
					/>
				</Form.Group>
			)
		}
	}

	const renderBrokerTable = () => {
		if ( isBroker ) {
			return (
				<Row>
					<EditableTableControl name="brokerInterestRatesData" columns={columns} data={brokerInterestRatesData || []}/>
				</Row>
			)
		}
	}

	const renderEmailPrivate = () => {
		if ( partnerType === PartnerType.PERSON ) {
			return (
				<Form.Group as={Col} md="4" controlId="formGroupEmailPrivate">
					<Form.Label>{t('partner.emailPrivate')}</Form.Label>
					<FormControl
						name={"emailPrivate"}
						type={"email"}
						value={domainInstance.emailPrivate || (defaultValues && defaultValues.emailPrivate)}
					/>
				</Form.Group>
			)
		}
	}

	const renderName = () => {
		const renderName = (width) => {
			return (
				<Form.Group as={Col} md={width} controlId="formGroupPartnerName">
					<Form.Label>{t('partner.name')}</Form.Label>
					<FormControl
						name={"name"}
						type={"text"}
						value={domainInstance.name || (defaultValues && defaultValues.name)}
						rules={{
							required: true
						}}
						validationMessages={{
							required: t( 'partner.name.required' )
						}}
					/>
				</Form.Group>
			)
		}

		const renderFirstName = () => {
			return (
				<Form.Group as={Col} md="5" controlId="formGroupPartnerFirstName">
					<Form.Label>{t('partner.firstName')}</Form.Label>
					<FormControl
						name={"firstName"}
						type={"text"}
						value={domainInstance.firstName || (defaultValues && defaultValues.firstName)}
					/>
				</Form.Group>
			)
		}

		if ( partnerType === PartnerType.PERSON ) {
			return (
				<>
					{renderName(5)}
					{renderFirstName()}
				</>
			)
		}
		else {
			return (
				renderName(10)
			)
		}
	}

	const renderAdditionalInfo = () => {
		if ( partnerType === PartnerType.PERSON ) {
			return (
				<Card className={"mb-2"}>
					<Card.Header>
						{t('partner.card.additional')}
					</Card.Header>
					<Card.Body>
						<Row>
							<Form.Group as={Col} md="3" controlId="formGroupBirthDate">
								<Form.Label>{t('partner.birthDate')}</Form.Label>
								<FormControl
									name={"birthDate"}
									type={"date"}
									value= {domainInstance.birthDate || (defaultValues && defaultValues.birthDate)}
								/>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
			)
		}
	}

	const getPrintButtons = () => {
		if ( domainInstance.id > 0 ) {
			return (
				<Dropdown as={ ButtonGroup } size="sm" className={ "float-end" }>
					<Button>
						<DropdownItemWithOptions
							label={t("reports.envelopeC5.label")}
							options={[{name:'additionalText', control: 'input', placeholder: t('partner.deployInstruction.placeholder')}]}
							onClick={ (values) => {
								partnerService.printEnvelopeC5( domainInstance.id, values.additionalText ).then();
							}}
						/>
					</Button>
					<Dropdown.Toggle split variant="primary" id="dropdown-split-basic"/>

					<Dropdown.Menu>
						<Dropdown.Item onClick={ () => {
							damageService.printDamageRendement( {partnerId: domainInstance.id, detailed: false} ).then()
							}}>{t('reports.damageRendement.title')}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)
		}
	}

	const pkDebCred = useMemo( () => {
		let result = ""
		if ( domainInstance.pkDeb ) {
			result += ( result ? ', ' : '' ) + `PK Deb: ${ domainInstance.pkDeb }`
		}
		if ( domainInstance.pkCred) {
			result += ( result ? ', ' : '' ) + `PK Kred: ${domainInstance.pkCred}`
		}
		return result;
	}, [domainInstance])

	const tagsColumns = [
		{id: "partnerTag", label: '' , input: {tag: "typeahead", id: "partnerTag", options: tags, required: true }},
	]

	return (
		<>
			<Row className={"mb-2"}>
				<Col>
					{ getPrintButtons() }
				</Col>
			</Row>
			<Card className={"mb-2"}>
				<Card.Header><div className={"float-start"}>{t('partner.card.identification')}</div> <div className={"float-end"}>{pkDebCred}</div> </Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="2" controlId="formGroupSalutation">
							<Form.Label>{t('partner.salutation')}</Form.Label>
							<RestAsyncSelectControl
								domainName={ "salutation" }
								sort={ "description_sort" }
								value={domainInstance.salutationId}
								label={domainInstance.salutationLabel}
								name={ 'salutation' }
								rules={{
									required: true
								}}
							/>
						</Form.Group>

						{renderName()}
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="6" controlId="formGroupNameSuffix1">
							<Form.Label>{t('partner.nameSuffix1')}</Form.Label>
							<FormControl
								name={"nameSuffix1"}
								type={"text"}
								value= {domainInstance.nameSuffix1 || (defaultValues && defaultValues.nameSuffix1)}
							/>
						</Form.Group>
						<Form.Group as={Col} md="6" controlId="formGroupNameSuffix2">
							<Form.Label>{t('partner.nameSuffix2')}</Form.Label>
							<FormControl
								name={"nameSuffix2"}
								type={"text"}
								value= {domainInstance.nameSuffix2 || (defaultValues && defaultValues.nameSuffix2)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('partner.card.contact')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupStreetName">
							<Form.Label>{t('partner.streetName')}</Form.Label>
							<FormControl
								name={"streetName"}
								type={"text"}
								value= {domainInstance.streetName || (defaultValues && defaultValues.streetName)}
								onChange={(event) => setStreetName(event.target.value || '')}
							/>
						</Form.Group>

						<Form.Group as={Col} md="2" controlId="formGroupStreetNr">
							<Form.Label>{t('partner.streetNr')}</Form.Label>
							<FormControl
								name={"streetNr"}
								type={"text"}
								value= {domainInstance.streetNr || (defaultValues && defaultValues.streetNr)}
								onChange={(event) => setStreetNr(event.target.value || '')}
							/>
						</Form.Group>

						<Form.Group as={Col} md="6" controlId="formGroupZip">
							<Form.Label>{t('partner.zip')} <GoogleMapLink street={`${streetName} ${streetNr}`} zipLabel={zipLabel}/> </Form.Label>
							<RestAsyncSelectControl domainName={ "zip" }
							                        sort={ "zip" }
							                        value={domainInstance.zipId}
							                        label={domainInstance.zipLabel}
							                        name={ 'zip' }
							                        noSelection={{label:'Nothing', id: ""}}
							                        rules={{required: true}}
							                        createable={{formElements: <ZipFormElements/>, target:'place'}}
							                        onChange={(option) => setZipLabel(option.label || '')}
							/>
						</Form.Group>

					</Row>
					<Row className={"mb-3"}>
						{renderPhonePrivate()}
						<Form.Group as={Col} md="4" controlId="formGroupNamePhoneBusiness">
							<Form.Label>{t('partner.phoneBusiness')}</Form.Label>
							<FormControl
								name={"phoneBusiness"}
								type={"text"}
								value= {domainInstance.phoneBusiness || (defaultValues && defaultValues.phoneBusiness)}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="formGroupNamePhoneMobile">
							<Form.Label>{t('partner.phoneMobile')}</Form.Label>
							<FormControl
								name={"phoneMobile"}
								type={"text"}
								value= {domainInstance.phoneMobile || (defaultValues && defaultValues.phoneMobile)}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						{renderEmailPrivate()}
						<Form.Group as={Col} md="4" controlId="formGroupEmailBusiness">
							<Form.Label>{t('partner.emailBusiness')}</Form.Label>
							<FormControl
								name={"emailBusiness"}
								type={"email"}
								value= {domainInstance.emailBusiness || (defaultValues && defaultValues.emailBusiness)}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="formGroupHomepage">
							<Form.Label>{t('partner.homepage')}</Form.Label>
							<FormControl
								name={"homepage"}
								type={"text"}
								value= {domainInstance.homepage || (defaultValues && defaultValues.homepage)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
			{renderAdditionalInfo()}
			<Card className={"mb-2"}>
				<Card.Header>{t('partner.card.bank')}</Card.Header>
				<Card.Body>
					{ [1, 2].map( (nr) => {
						return (<Row key={nr} className={nr>1?"mt-3":""}>
							<Form.Group as={ Col } md="6" controlId={`formGroupIban${nr}Name`}>
								<Form.Label>{ t( "partner.ibanName" ) } {nr}</Form.Label>
								<FormControl
									name={ `iban${nr}name` }
									type={ "text" }
									value={ domainInstance[`iban${nr}name`] }
									required={false}
									validationMessages={{
										missingName: 'Name not entered',
									}}
									rules={{
										validate: {
											missingName: (v) => {
												const ibanEntered = !!getValues(`iban${nr}`);
												return !ibanEntered || !(ibanEntered && !v);
											}
										}
									}}
								/>
							</Form.Group>

							<Form.Group as={ Col } md="6" controlId={`formGroupIban${nr}`}>
								<Form.Label>{ t( "partner.iban" ) } {nr}</Form.Label>
								<FormControl
									name={ `iban${nr}` }
									type={ "text" }
									value={ domainInstance[`iban${nr}`] }
									validationMessages={{
										iban: 'False iban',
										missingIban: 'Iban is not entered'
									}}
									rules={{
										validate: {
											missingIban: (v) => {
												const ibanNameEntered = !!getValues(`iban${nr}name`);
												return !(ibanNameEntered && !v);
											},
											iban: (v) => !v || isValidIban(v)
										}
									}}
									required={false}
								/>
							</Form.Group>
						</Row>)
					})}
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('partner.business.relationship')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="6" controlId="formGroupInsuranceCompany">
							<FormLabel>{t('partner.isInsuranceCompany')}</FormLabel>
							<InputGroup className={"mb-3"}>
								<InputGroup.Checkbox
									name={"isInsuranceCompany"}
									type={"checkbox"}
									value= {domainInstance.isInsuranceCompany}
									onChange={ (e) => { setIsInsuranceCompany(e.target.checked)} }
								/>
								<InputGroup.Text>{t('partner.insurance.company.nr')}</InputGroup.Text>
								<FormControl
									name={"insuranceCompanyNr"}
									type={"text"}
									placeholder={t('partner.insurance.company.nr')}
									value= {domainInstance.insuranceCompanyNr}
									disabled={!isInsuranceCompany}
								/>
							</InputGroup>
						</Form.Group>
						<Form.Group as={Col} md="6" controlId="formGroupAgent">
							<FormLabel>{t('partner.isAgent')}</FormLabel>
							<InputGroup className={"mb-3"}>
								<InputGroup.Checkbox
										name={"isAgent"}
										type={"checkbox"}
										value= {domainInstance.isAgent}
										onChange={ (e) => { setIsAgent(e.target.checked)} }
								/>
								<InputGroup.Text>{t('partner.agent.nr')}</InputGroup.Text>
								<FormControl
									name={"agentNr"}
									type={"text"}
									placeholder={t('partner.agent.nr')}
									value={domainInstance.agentNr}
									className={"last-input"}
									disabled={!isAgent}
								/>
							</InputGroup>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="4" controlId="isBuildingManager">
							<FormCheckControl
								name={"isBuildingManager"}
								type={"checkbox"}
								value={domainInstance.isBuildingManager || false}
								label={t('partner.isBuildingManager')}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="4" controlId="isBroker">
							<FormCheckControl
								name={"isBroker"}
								type={"checkbox"}
								value={domainInstance.isBroker || false}
								onChange={ (e) => { setIsBroker(e.target.checked)} }
								label={t('partner.isBroker')}
							/>
						</Form.Group>
					</Row>
					{renderBrokerTable()}
				</Card.Body>
			</Card>
			<Card>
				<Card.Header>{t('partner.tags')}</Card.Header>
				<Card.Body>
					<Row>
						<EditableTableControl
							name={"tags"}
							columns={tagsColumns}
							data={domainInstance.tags}
						/>
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('partner.attachments')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Attachments attachments={attachmentsData} changesPropertyName={'fileChanges'}/>
					</Row>
					<Row>
						<Form.Group as={Col} md="12" controlId="groupFileChanges">
							<Form.Label>{t('uploadFiles.label')}</Form.Label>
							<FileUploader changesPropertyName={'fileChanges'}/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
