import React, {useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {v4} from "uuid";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import {useFormContext} from "react-hook-form";
import {useConfig} from "../_services/useConfig";
import {Alert} from "react-bootstrap";

/**
 *
 * @param changesPropertyName
 * @param maxFiles
 * @param additionalFileProperties
 * @param handleSubmit submit button will be rendered when this prop is provided
 * @param onReadyForSubmitChange callback which is executed when readyForSubmit is change. Sending readyForSubmit as parameter
 * @returns {JSX.Element}
 * @constructor
 */
export default function FileUploader({changesPropertyName, maxFiles, additionalFileProperties, handleSubmit, onReadyForSubmitChange}) {
    const {getValues, setValue} = useFormContext();
    const [uploadErrors, setUploadErrors] = useState([]);
    const {config: { uploadInfo }} = useConfig()
    const [readyForSubmit, setReadyForSubmit] = useState(false)
    const dropzoneClassNames = {
        dropzone: 'appvers-dropzone',
        inputLabel: 'appvers-inputLabel',
        preview: 'appvers-preview'
    }

    useEffect( () => {
        return () => {
            setValue(changesPropertyName, undefined)
        }
    }, [changesPropertyName, setValue])

    const getFileChanges = () => {
        let fileChanges = getValues(changesPropertyName);
        if ( !fileChanges ) {
            fileChanges = {};
        }
        return fileChanges;
    }

    const removeUploadError = (index) => {
        let errors = [...uploadErrors];
        errors.splice(index, 1);
        setUploadErrors( errors );
    }
    const showUploadErrors = () => {
        if ( uploadErrors.length > 0 ) {
            let result = []
            uploadErrors.forEach( (error, index) => {
                result.push(
                    <Alert key={index} variant={"danger"} onClose={() => removeUploadError(index)} dismissible>
                        {error}
                    </Alert>
                )
            })
            return result
        }
    }

    const getUploadParams = ({ file, meta }) => {
        const { Authorization } = headers();
        const uploadHeaders = { Authorization };//, 'Access-Control-Allow-Origin': 'http://localhost'};
        return {
            headers: uploadHeaders,
            url: `${SERVER_URL}/api/files/upload`,
            fields: {lastModifiedDate: meta.lastModifiedDate},
        }
    }

    const handleChangeStatus = (fileWithMeta, status, allFilesWithMeta) => {
        if ( status === 'done' || status === 'removed' ) {
            let serverResponse
            if ( fileWithMeta.xhr ) {
                serverResponse = JSON.parse(fileWithMeta.xhr.responseText);
            }
            let fileChanges = getFileChanges();
            if ( status === 'done' ) {
                if ( serverResponse && serverResponse.success ) {
                    let fileInfo = {...serverResponse.fileInfo};
                    fileChanges[serverResponse.id] = {...{action: 'create'}, ...{fileInfo: fileInfo}, ...{properties: (additionalFileProperties || {})}};
                }
                else {
                    if ( serverResponse && serverResponse.message ) {
                        let errorMessage = `Upload of file ${fileWithMeta.file.name} failed. Error message: ${serverResponse.message}`
                        setUploadErrors([...uploadErrors, errorMessage]);
                    }
                }
            }
            else if ( status === 'removed' && serverResponse ) {
                delete fileChanges[serverResponse.id];
                //TODO: delete temp file from the server?
            }
            setValue(changesPropertyName, fileChanges );
        }
        let doneCounter = 0;
        let removedCounter = 0;
        const doneStatuses = ['done', 'headers_received'];
        const removedStatus = 'removed';
        for ( let fileStatus of allFilesWithMeta.map( (file) => file.meta.status ) ) {
            if ( doneStatuses.includes( fileStatus ) ) {
                doneCounter++;
            }
            else if ( fileStatus === removedStatus ) {
                removedCounter++;
            }
        }
        let allDone = allFilesWithMeta.length === ( doneCounter + removedCounter );
        let somethingToSubmit = allDone && allFilesWithMeta.length > removedCounter;
        if ( somethingToSubmit !== readyForSubmit ) {
            setReadyForSubmit( somethingToSubmit );
            if ( onReadyForSubmitChange ) {
                onReadyForSubmitChange( somethingToSubmit );
            }
        }
        // console.log(status, fileWithMeta.meta);
    }

    return (
        <>
            { showUploadErrors() }
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                classNames={dropzoneClassNames}
                inputContent={<FontAwesomeIcon key={v4()} icon={ faUpload }/>}
                maxSizeBytes={uploadInfo.maxFileSize}
                inputLabelWithFiles={"Add another files"}
                maxFiles={ maxFiles === undefined ? 999 : maxFiles }
            />
        </>
    )
}
