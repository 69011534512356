import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import {Dropdown} from "./Dropdown";

function DropdownControl( { name, rules, validationMessages, value, placeholder, onChange, readOnly, className, optionsDomainName, keyPropName, valuePropName, nullable, options, size, disabled }) {
	const { reset, getValues, control } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);

	useEffect(() => {
		if ( getValues && reset ) {
			const newValue = defVal;
			setCurrentValue(newValue);
			const val = { ...getValues(), [name]: newValue };
			reset( val );
			console.log(`Value ${name} set to`, defVal)
		}
	}, [value, getValues, name, reset, defVal]);

	return (
		<>
			{/*<p>TypeaheadControl: {(defaultValue === undefined || defaultValue === null) ? '' : defaultValue}</p>*/}
		<Controller
			control={ control }
			name={ name }
			rules={ rules }
			value={ currentValue }
			render={ ( { field, fieldState } ) => {
				return ( <>
					{/*<p>field.value: {field.value===undefined?'undefined':(field.value===null?'null':field.value)}</p>*/}
					<Dropdown
						onChange={ ( e ) => {
							setCurrentValue(e.target.value);
							field.onChange( e );
							if ( onChange ) {
								onChange( e );
							}
						} }
						onBlur={ field.onBlur }
						value={ field.value }
						name={ field.name }
						dropdownRef={ field.ref }
						className={ ( className ? `${ className } ` : '' ) + ( !!fieldState.error ? 'is-invalid' : '' ) }
						placeholder={ placeholder }
						readOnly={ readOnly }
						optionsDomainName={ optionsDomainName }
						keyPropName={ keyPropName }
						valuePropName={ valuePropName }
						nullable={ nullable }
						options={ options }
						size={ size }
						disabled={ disabled }
					/>
					<Form.Control.Feedback type="invalid">
						{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
					</Form.Control.Feedback>
				</> )
			}
			}
		/>
		</>
	)
}

DropdownControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	optionsDomainName: PropTypes.string,
	keyPropName: PropTypes.string,
	valuePropName: PropTypes.string,
	nullable: PropTypes.bool,
	options: PropTypes.array,
	size: PropTypes.string
};

export default DropdownControl;
