import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function DeleteConfirmDialog({show, onHide, onDelete, bodyText}) {
    const {t} = useTranslation()

    return (
        <Modal show={show} onHide={onHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('deleteConfirm.heading')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyText}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {
                    onHide();
                    onDelete();
                }}>
                    {t('default.delete')}
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    {t('default.cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmDialog
