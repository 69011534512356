import React, {useCallback, useMemo, useState} from 'react';
import EditableTable from "../form/EditableTable";
import {useTranslation} from "react-i18next";
import {configuratorNodeOrderService} from "../_services/configuratorNodeOrder.service";
import restService from "../_services/rest.service";
import {Button, Form} from "react-bootstrap";
import { Prompt } from 'react-router'

function ConfiguratorNodeOrderList() {
	const {t} = useTranslation();
	const [configuratorNodeOrders, setConfiguratorNodeOrders] = configuratorNodeOrderService.useConfiguratorNodeOrders()
	const [submitDisabled, setSubmitDisabled] = useState(true);

	const onConfiguratorNodeOrdersChanged = useCallback( (data) => {
		setConfiguratorNodeOrders(data);
		setSubmitDisabled(false);
	}, [setConfiguratorNodeOrders, setSubmitDisabled])

	const handleSubmit = () => {
		configuratorNodeOrderService.saveConfiguratorNodeOrders(configuratorNodeOrders)
			.then( () => setSubmitDisabled(true))
			.catch( ( error ) => restService.handleServerErrors( error, undefined ) );
	}

	const columns = useMemo( () => [
		{id: "firstLevelLabel", label: t("configuratorNodeOrder.firstLevel.label"), input: {tag: "label", readonly:true}},
		{id: "secondLevelLabel", label: t("configuratorNodeOrder.secondLevel.label"), input: {tag: "label", readonly:true}},
	], [t] )

	const table = useMemo( () => {
		if ( configuratorNodeOrders.length > 0 ) {
			return <EditableTable columns={columns} data={configuratorNodeOrders} onChange={onConfiguratorNodeOrdersChanged} orderable={true} allowDeletions={false} allowAdditions={false}/>
		}
		else {
			return undefined;
		}
	}, [configuratorNodeOrders, columns, onConfiguratorNodeOrdersChanged])

	return (
		<>
			<Prompt
				when={!submitDisabled}
				message={t('default.unsavedChangesLeave.message')}
			/>
			<Form>
				{table}

			</Form>
			<div className="configurator-node-order-list-footer">
				<Button type="button" variant={"primary"} onClick={handleSubmit} style={{marginRight:'10px'}} disabled={submitDisabled}>{t('default.save')}</Button>
			</div>
		</>
	)
}

export default ConfiguratorNodeOrderList