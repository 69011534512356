import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Toast, ToastContainer} from "react-bootstrap";
import {messagePanelService} from "../_services/message.panel.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import TimeLeft from "./TimeLeft";
import moment from "moment";
import appService from "../_services";

function MessagePanel() {
	const ref = useRef();
	const [messages, setMessages] = useState([]);

	const update = useCallback((addMessages, removeMessages) => {
		/*
		 * Originally, I had two methods: push() and remove() here. But it showed up, that it is not possible to call
		 * them in one event. For example when I needed to remove some message and create a new one in one event. Thus
		 * just one method update() is here now, that manages all at once.
		 */

		//remove unwanted messages
		let newMessages = [...messages];
		if ( removeMessages ) {
			 newMessages = newMessages.filter( ( message ) => !removeMessages.includes( message.uuid ) );
		}

		//add new messages
		let ids = []
		if ( addMessages ) {
			const idsToUpdate = appService.intersect( addMessages.map( ( message ) => message.uuid ), newMessages.map( ( message ) => message.uuid ) )

			//update/add existing messages
			addMessages.forEach( ( message ) => {
				if ( idsToUpdate.includes( message.uuid ) ) {
					console.log( 'udpated', message.uuid )

					const existingMessage = newMessages.find( ( m ) => m.uuid === message.uuid );
					existingMessage.header = message.header;
					existingMessage.message = message.message;
					existingMessage.variant = message.variant;
				} else {
					console.log( 'added', message.uuid )
					newMessages.push( {
						uuid: message.uuid,
						createdOn: moment(),
						header: message.header,
						message: message.message,
						variant: message.variant
					} );
				}
			} )
		}

		setMessages( newMessages );
		return ids;
	}, [messages]);

	useEffect( () => {
		ref.current = {update: update}
		messagePanelService.register( ref );
	}, [update]);

	const handleOnClose = useCallback((uuid) => {
		const newMessages = messages.filter( (message) => message.uuid !== uuid )
		setMessages( [...newMessages] );
	}, [messages])

	const getVariantAttrs = (variant) => {
		switch(variant) {
			case 'DANGER':
				return {icon: <FontAwesomeIcon icon={faTriangleExclamation}/>, bg: 'danger'};
			case 'WARNING':
				return {icon: <FontAwesomeIcon icon={faTriangleExclamation}/>, bg: 'warning'};
			case 'INFO':
			default:
				return {icon: <FontAwesomeIcon icon={faCircleInfo}/>, bg: 'Light'};
		}
	}

	const toasts = useMemo( () => {
		return messages.map( ( message, i ) => {
			const variantAttrs = getVariantAttrs(message.variant)
			return (
				<Toast key={i} onClose={() => handleOnClose(message.uuid)} bg={variantAttrs.bg}>
					<Toast.Header>
						{variantAttrs.icon}
						<strong className="ms-1 me-auto">{message.header}</strong>
						<small className="text-muted"><TimeLeft key={message.uuid} start={message.createdOn}/></small>
					</Toast.Header>
					<Toast.Body>{ React.cloneElement( message.message, {uuid: message.uuid} ) }</Toast.Body>
				</Toast>
			)
		})
	}, [messages, handleOnClose]);

	return (
		<>
			<ToastContainer className={"position-fixed mb-3 me-3"} position={"bottom-end"} style={{zIndex:1070}}>
				{toasts}
			</ToastContainer>
		</>
	)
}

export default MessagePanel