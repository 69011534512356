import React, {useState, useCallback, useMemo} from 'react';
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ConfiguratorForm} from "./ConfiguratorForm";
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import {alertService} from "../_services";

function CreatePolicyAddProductConfigurator(props) {
	const useFormObject = useForm();
	const { t } = useTranslation();
	const stepValues = useMemo( () => ( props.values[props.stepName] ), [props.values, props.stepName] );
	const policyValues = useMemo( () => ( props.values['policy'] ), [props.values])
	const [values, setValues] = useState(stepValues.values);
	const stepBuildingValues = useMemo( () => (props.values['addProductBuilding']), [props.values] );
	const buildingId = useMemo( () => ( stepBuildingValues && stepBuildingValues.building ) ? stepBuildingValues.building.id : undefined, [stepBuildingValues] );
	const validFrom = useMemo( () => ( policyValues.validFrom ), [policyValues] );
	const [building] = restService.useDomainInstance('building', buildingId);
	const brokerId = useMemo( () => ( policyValues.broker && policyValues.brokerOrConsultant==='BROKER' && policyValues.broker.id ), [policyValues] );
	const [submitDisabled, setSubmitDisabled] = useState(false);

	const configuratorDefaultValues = useMemo( () => {
		return {
			buildingType: building ? building.buildingType : undefined,
			buildingClass: building ? building.buildingClass : undefined,
			constructionType: building ? building.constructionType : undefined,
			fireExtinguisher: building ? building.fireExtinguisher : undefined,
			heatingType: building ? building.heatingType : undefined,
			roofType: building ? building.roofType : undefined
		}
	}, [building])

	const handleClickPrevious = () => {
		props.previousStep();
	}

	const onSubmit = (data) => {
		//TODO: Here should be either address or building information which can identify County
		let buildingId = stepBuildingValues.building && stepBuildingValues.building.id
		let countyId
		if ( buildingId ) {
			countyId = stepBuildingValues.building.countyId
		}
		// else if ( stepBuildingValues.address. ) {
		//
		// }
		const params = {
			values: JSON.stringify(values),
			validFrom: validFrom,
			buildingId: buildingId,
			countyId: countyId,
			brokerId: brokerId,
		}
		const h = headers();

		setSubmitDisabled(true);
		fetch( `${ SERVER_URL }/api/configurator/getAvailableTargets?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
		} )
			.then( r => r.json() )
			.then( json => {
                setSubmitDisabled(false);
				props.handleUpdate(props.stepName, { targets: json.configuratorTargets.map( (target) => { target.targetId = target.id; return target }), values: values } );
				props.nextStep();
			} )
			.catch( error => {
                setSubmitDisabled(false);
				let errMsg = 'Error retrieving data: ' + error;
				alertService.error( errMsg );
			} );
	}

	const handleOnChange = useCallback( (values) => {
		setValues(values)
	}, [setValues] )

	return (
		<FormProvider {...useFormObject}>
			<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
				<ConfiguratorForm onChange={ handleOnChange } defaultValues={configuratorDefaultValues} buildingId={buildingId} validFrom={validFrom}/>
				<div className="create-policy-footer p-3">
					<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
					<Button type={"submit"} disabled={submitDisabled} className='btn btn-default'>{t('default.next')}</Button>
				</div>
			</Form>
		</FormProvider>
	);
}

export { CreatePolicyAddProductConfigurator };
