import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";

function FormCheckControl( { type, name, rules, validationMessages, value, label, onChange, onBlur, disabled, className }) {
    const { reset, getValues, control } = useFormContext();
    const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
    const [currentValue, setCurrentValue] = useState(defVal);

    useEffect(() => {
        if ( getValues && reset ) {
            const newValue = defVal;
            setCurrentValue(newValue);
            const val = { ...getValues(), [name]: newValue };
            reset( val );
        }
    }, [value, getValues, name, reset, defVal]);

    return (
        <>
            <Controller
                control={ control }
                name={ name }
                rules={ rules }
                defaultValue={ currentValue }
                render={ ( { field, fieldState } ) =>
                    <>
                        <Form.Check
                            onChange={ ( e ) => {
                                setCurrentValue(e.target.value)
                                field.onChange( e );
                                if ( onChange ) {
                                    onChange( e );
                                }
                            } }
                            onBlur={ ( e ) => {
                                field.onBlur(e);
                                if ( onBlur ) {
                                    onBlur( e );
                                }
                            }}
                            checked={field.value}
                            name={ field.name }
                            ref={ field.ref }
                            className={ (className ? `${className} ` : '') + (!!fieldState.error ? 'is-invalid' : '') }
                            type={ type }
                            label={ label }
                            disabled={ disabled }
                        />
                        <Form.Control.Feedback type="invalid">
                            { reactHookFormService.getValidationMessage(fieldState, validationMessages) }
                        </Form.Control.Feedback>
                    </>
                }
            />
        </>
    )
}

FormCheckControl.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    validationMessages: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string
};

export default FormCheckControl;
