import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import {Form} from "react-bootstrap";
import reactHookFormService from "./reactHookForm.service";
import {selectControlService} from "../_services/selectControl.service";
import Select from "react-select";

function SelectControl( {options, name, value, label, valuePropertyName, labelPropertyName, onChange, placeholder, rules, validationMessages, noSelection, disabled=false , isMulti=false} ) {
	const { reset, getValues, control } = useFormContext();
	const [ controlPlaceholder, setControlPlaceholder ] = useState(placeholder);
	const defVal = useMemo( () => {
			if ( value ) {
				if( isMulti ) {
					return value;
				}
				else {
					return { [valuePropertyName]: value, [labelPropertyName]: label };
				}
			} else {
				return '';
			}
	}, [value, label, labelPropertyName, valuePropertyName, isMulti])
	const [currentValue, setCurrentValue] = useState(defVal);

	useEffect(() => {
		if ( getValues && reset ) {
			setControlPlaceholder( placeholder )
			const newValue = defVal;
			setCurrentValue(newValue);
			const val = { ...getValues(), [name]: newValue };
			reset( val );
		}
	}, [value, getValues, name, reset, defVal, placeholder]);

	return (
		<>
			<Controller
				control = {control}
				name = {name}
				rules = { rules }
				value = { currentValue }
				render = {({ field, fieldState }) => {
						const opts = {
							onChange: ( val ) => {
								setCurrentValue( val );
								field.onChange( val );
								if ( onChange ) {
									onChange( val );
								}
							},
							onBlur: field.onBlur,
							value: field.value,
							name: field.name,
							selectRef: field.ref,
							styles: selectControlService.getCustomStyles(!!fieldState.error),
							className: !!fieldState.error ? 'is-invalid' : '',
							placeholder: controlPlaceholder,
							options: options,
							isDisabled: disabled,
							isMulti: isMulti,
							getOptionLabel: (option) => ( option[labelPropertyName] ),
							getOptionValue: (option) => ( option[valuePropertyName] )
						}
						return (
							<>
								<Select {...opts} />
								<Form.Control.Feedback type="invalid">
									{ reactHookFormService.getValidationMessage(fieldState, validationMessages) }
								</Form.Control.Feedback>
							</>
						)
					}
				}
			/>
		</>
	);
}

SelectControl.defaultProps = {
	valuePropertyName: 'id',
	labelPropertyName: 'label'
};

SelectControl.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	valuePropertyName: PropTypes.string,
	labelPropertyName: PropTypes.string,
	noSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	disabled: PropTypes.bool
};

export { SelectControl };
