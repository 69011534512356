import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function Avb({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('avb.id.label'),
		hidden: true
	}, {
		dataField: 'year',
		text: t('avb.year.label'),
		sort: true
	}, {
		dataField: 'avbDescription',
		text: t('avb.avbDescription.label'),
		sortField: 'avbDescription.description_sort',
		formatter: (cell, row) => cell['label'],
		sort: true
	}, {
		dataField: 'esAvbCode',
		sortField: 'esAvbCode_sort',
		text: t('avb.esAvbCode.label'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'avb'}
			columns={columns}
			toString={ (object) => ( object.description ? object.year + "-" + object.description : object.year ) }
			formElements={<FormElements/>}
			sortField={'avbDescription.description_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { Avb };
