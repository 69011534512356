import React, {useMemo} from 'react';
import {Image} from "react-bootstrap";
import logoAppvers from '../images/logo_appvers.svg';
import logoAppversGreyscale from '../images/logo_appvers_greyscale.svg';

function LogoAppvers( {width, greyscale} ) {

	const src = useMemo( () => {
		if ( greyscale ) {
			return logoAppversGreyscale
		}
		else {
			return logoAppvers
		}
	}, [greyscale])

	return (
		<Image src={src} width={width}/>
	)
}

export default LogoAppvers