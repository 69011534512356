import {useEffect} from "react";
import {addSteps, removeSteps} from "./tourSlice";
import store from "../store";
import {v4} from "uuid"

/**
 * Helper hook for managing addSteps and removeSteps.
 * @param steps
 * @param nextLevel
 */
export default function useTour( steps, nextLevel=false ) {
	useEffect( () => {
		const id = _addSteps(steps, nextLevel);
		return () => _removeSteps(id);
	}, [steps, nextLevel])
}

/**
 * Adds given steps to redux store. All steps in the store will be displayed in the tour.
 * @param steps
 * @param nextLevel - Only top level steps will be displayed in tour. Thus when you have a modal form,
 * you probably don't want to display tour steps from elements below the modal form. In such a case use
 * nextLevel=true.
 * @return {*|string} - returns UUID, that will be used in removeSteps() later.
 */
export function _addSteps(steps, nextLevel=false) {
	const id = v4();
	let level = 0;

	if ( nextLevel ) {
		for (const id in store.getState().tour.steps) {
			const _steps = store.getState().tour.steps[id];
			if ( _steps.level > level ) {
				level = _steps.level;
			}
		}
		level++;
	}

	store.dispatch(addSteps( {[id]: {steps: steps, level: level}} ) );

	return id
}

/**
 * Removes steps with given id from the redux store.
 * @param id
 */
export function _removeSteps(id) {
	store.dispatch(removeSteps(id));
}