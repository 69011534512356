import React from 'react';
import ShowBankPayment from "./ShowBankPayment";
import {ShowSimilarInvoices} from "../invoice/ShowSimilarInvoices";
import {Button} from "react-bootstrap";
import {t} from "i18next";
import {useHistory} from "react-router-dom";

function ShowSimilarToBankPayment( {id} ) {
    const history = useHistory();

    const handlePairManually = () => {
        history.push(`/manualPayment/bankPaymentCorrection/${id}`)
    }
    const handleCancel = () => {
        history.push(`/bankPayment/show/${id}`)
    }

    return (
        <>
            <ShowBankPayment id={id}/>
            <Button variant={"primary"} className={'mb-2 me-2'} size={'sm'} type="button" onClick={handlePairManually}>{t('pair.manually')}</Button>
            <Button variant={"secondary"} className={'mb-2'} size={'sm'} type="button" onClick={handleCancel}>{t('default.cancel')}</Button>
            <ShowSimilarInvoices bankPaymentId={id}/>
        </>
    )
}

export default ShowSimilarToBankPayment
