import React, {useMemo} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {useTranslation} from "react-i18next";
import {policyService} from "../_services/policy.service";

function ExpiringPolicyVersions() {
    const {t} = useTranslation();
    const useFormObject = useForm();

    const onSubmit = (data) => {
        policyService.printExpiringPolicyVersions( data.dateFrom, data.dateTo, data.partnerConsultantScopes, data.brokers )
    }


    const hsNamedCriteriaBroker = useMemo( () => {
        return {
            namedRestriction:
                {
                    queryName: "broker",
                    params: {}
                }
        }
    }, [])


	return 	(
        <>
            <h2>{t('reports.expiringPolicyVersions.title')}</h2>

            <FormProvider {...useFormObject}>
            <Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
                <Card>
                    <Card.Header>
                        {t('default.filter')}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Form.Group className="col-md-6" controlId="dateFrom">
                                <Form.Label>{t('expiringPolicyVersions.dateFrom.label')}</Form.Label>
                                <FormControl
                                    name={"dateFrom"}
                                    type={"date"}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6" controlId="dateTo">
                                <Form.Label>{t('expiringPolicyVersions.dateTo.label')}</Form.Label>
                                <FormControl
                                    name={"dateTo"}
                                    type={"date"}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mt-3"}>
                            <Form.Group className="col-md-12" controlId="consultants">
                                <Form.Label>{t('expiringPolicyVersions.consultants.label')}</Form.Label>
                                <RestAsyncSelectControl
                                    name={"partnerConsultantScopes"}
                                    domainName={"partnerConsultantScope"}
                                    isMulti={true}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mt-3"}>
                            <Form.Group className="col-md-12" controlId="consultants">
                                <Form.Label>{t('expiringPolicyVersions.brokers.label')}</Form.Label>
                                <RestAsyncSelectControl
                                    name={"brokers"}
                                    searchOptions={hsNamedCriteriaBroker}
                                    domainName={"partner"}
                                    isMulti={true}
                                />
                            </Form.Group>
                        </Row>

                    </Card.Body>
                </Card>
                <Row className={"mt-3"}>
                    <Col md={2}>
                        <Button type={"submit"}>{ t('default.export') }</Button>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    </>
)
}

export default ExpiringPolicyVersions