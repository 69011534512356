

function TariffContent({tariff, index, decInc=false}) {


    const formatCurrency = (value) => {
        return parseInt( value ).toLocaleString('en').replaceAll(',', '\'')
    }

    const getContent = () => {
        let result = "";
        const add = (str) => {
            if (str) {
                result += ((result===""?"":"; ") + str);
            }
        }
        add( (tariff.maxInsuranceAmount!==null?("[" + formatCurrency(tariff.minInsuranceAmount) + " - " + formatCurrency(tariff.maxInsuranceAmount) + "]"):"") );
        const sign = (decInc?(tariff.tariff>0?"+":""):"");
        add( (tariff.tariffUnit.name==="PERCENT"?(sign+Math.round(tariff.tariff*100000000)/1000000 + " %"):(tariff.tariff + " CHF")) );
        add( (tariff.minPrice!==null?("min. Prä.: " + formatCurrency(tariff.minPrice) + " CHF"):"") );
        return result;
    }

    return (
        getContent()
    )
}

export {TariffContent}
