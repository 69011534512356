import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";

function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('country.card.info')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="4" controlId="formGroupCountryCode">
							<Form.Label>{t('country.code')}</Form.Label>
							<FormControl
								name={"code"}
								type={"text"}
								// validationMessages={{
								// 	required: t('country.code.required'),
								// 	myRule: 'Text is not 'aa'
								// 	myAnotherRule: 'Text is not 'bb'
								// }}
								value={domainInstance.code || (defaultValues && defaultValues.code)}
								rules={ {
									required: true,
									// validate: {
									// 	myRule: (v) => v==='aa',
									//  myAnotherRule:(v) => v==='bb',
									// }
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupCountryName">
							<Form.Label>{t('country.name')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value={domainInstance.name || (defaultValues && defaultValues.name)}
								rules={ {required: true} }
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
