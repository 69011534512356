import React from 'react';
import {Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import PaymentStateBadge from "../payment/PaymentStateBadge";

function FormElementsInvoice({settlement, onRefreshSettlement}) {
	return (
		<>
			<Row>
				<ShowField label={'invoicePolicyVersion.dueDate'} object={settlement} property={'dueDate'} type={'date'} size={4}/>
				<ShowField label={'invoicePolicyVersion.paymentTotal'} object={settlement} property={'paymentTotal'} type={'number'} size={4}/>
				<ShowField label={'invoicePolicyVersion.lastPayment'} object={settlement} property={'lastPayment'} type={'date'} size={4}/>
			</Row>
			<Row>
				<ShowField label={'invoice.paymentState'} object={settlement}size={4}>
					<PaymentStateBadge paymentState={settlement.paymentState}/>
				</ShowField>
				<ShowField label={'settlement.replacedSettlement'} object={settlement} property={'replacedSettlement.label'} type={'text'} size={4}/>
				<ShowField label={'invoicePolicyVersion.refNr'} object={settlement} property={'refNr'} type={'text'} size={4}/>
				<ShowField label={'invoicePolicyVersion.commissionPercent'} object={settlement} property={'commissionPercent'} type={'number'} size={4} appendix={' %'}/>
			</Row>
		</>
	)
}

export default FormElementsInvoice