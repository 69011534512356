import React, {useEffect} from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import logoAppvers from '../images/logo_appvers_with_name.svg';
import {useTranslation} from "react-i18next";
import PageTour from "../tour/PageTour";
import store from "../store";
import settingService from "../_services/setting.service";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function TopMenuBar(props) {
	const {_header} = settingService.useSetting(['_header']);
	const {i18n, t} = useTranslation();

	const auth = store.getState().security.auth;
	const username = auth && auth.username;

	useEffect( () => {
		document.title = `${(_header && _header.title) || 'AVS150'}`;
	}, [_header])

	const changeLanguage = (value) => {
		i18n.changeLanguage(value)
	}

	return (
		<>
			<div style={_header && _header.css}>
				{ _header && _header.text }
			</div>
			<Navbar bg="light" expand="sm">
				<Container fluid>
					<Navbar.Brand href="/">
						<img src={logoAppvers} alt={"logo"}/>
					</Navbar.Brand>
					<PageTour/>
					<div className="ms-auto">
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
					</div>
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
						<Nav className="">
							<NavDropdown title={t("menuBar.settings.label")}>
								<NavDropdown.Item onClick={() => changeLanguage("en-CH")} >English</NavDropdown.Item>
								<NavDropdown.Item onClick={() => changeLanguage("de-CH")} >Deutsch</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link onClick={props.logoutHandler}>{t("menuBar.logout.label", {username: username})}</Nav.Link>
							<Nav.Link href="mailto:helpdesk@bsa-informatik.cz">{<FontAwesomeIcon icon={faEnvelope}/> }</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export { TopMenuBar };
