import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";

function BooleanEditControl( { defaultValue, save, controlRef }  ) {
    const [value, setValue] = useState(defaultValue);

	return (
        <Form.Check
            ref={controlRef}
            size={ "sm" }
            type={ "checkbox" }
            checked={ value === null ? undefined : value }
            onChange={ (e) => {
                let val = e.target.checked
                setValue( val );
                save(val);
            }}
        />
	)
}

BooleanEditControl.propTypes = {
    defaultValue: PropTypes.oneOfType( [PropTypes.string, PropTypes.number]),
    setValue: PropTypes.func,
    save: PropTypes.func,
}

export default BooleanEditControl