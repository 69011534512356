import React, {useMemo} from 'react';

import {useConfig} from "./_services/useConfig";
import textService from "./_services/text.service";
import {Badge} from "react-bootstrap";
import {Link} from "react-router-dom";

const Footer = () => {
    const { config: { versionInfo } } = useConfig()

    const timestamp = useMemo( () => {
        if( versionInfo.timestamp ) {
            return textService.formatDateTime(Date.parse(versionInfo.timestamp));
        }
        else {
            return ''
        }
    }, [versionInfo.timestamp])

    return (
       <Link to={"/changelog"}><Badge className={"float-end"} bg="primary">Version { versionInfo.tag } vom: { timestamp }</Badge></Link>
    )

};

export default Footer;
