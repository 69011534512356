import React, {useMemo} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import appService from "../_services";
import restService from "../_services/rest.service";
import {useTranslation} from "react-i18next";
import ShowField from "../form/ShowField";
import {BankPayments} from "../bankPayment/BankPayments";
import textService from "../_services/text.service";
import moment from "moment";
import PaymentStats from "./PaymentStats";
import store from "../store";
import {setQueryParams} from "./bankToCustomerNotificationSlice";

function ShowBankToCustomerNotification(props) {
    const [bankToCustomerNotification] = restService.useDomainInstance( 'bankToCustomerNotification', props.id, false );
    const { t } = useTranslation();

    store.dispatch( setQueryParams( window.location.search ) );

    const searchOptions = useMemo( () => {
        if ( bankToCustomerNotification && bankToCustomerNotification.id ) {
            return {
                queryStrings: [{field: 'bankToCustomerNotification.id', text: `${bankToCustomerNotification.id}`}]
            }
        }
    }, [bankToCustomerNotification] );

    return (
        <>
            <Card className='mb-2'>
                <Card.Header><h4>{t('bankToCustomerNotification.label') + ' ' + appService.nullSafeGet( bankToCustomerNotification, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <ShowField label={'default.createdOn'} object={bankToCustomerNotification} property={'createdOn'} type={'date'}/>
                        </Col>
                        <Col>
                            <ShowField label={'default.createdBy'} object={bankToCustomerNotification} property={'createdBy'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'bankToCustomerNotification.filename'} object={bankToCustomerNotification} property={'filename'} type={'text'}/>
                        </Col>
                        <Col>
                            <ShowField label={'bankToCustomerNotification.sha256'} object={bankToCustomerNotification} property={'sha256'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'bankToCustomerNotification.fileCreationDateTime'} object={bankToCustomerNotification} property={'fileCreationDateTime'} type={'datetime'}/>
                        </Col>
                        <Col>
                            <ShowField label={'bankToCustomerNotification.paymentsIncluded'} object={bankToCustomerNotification}>
                                {textService.formatDateTime(moment(bankToCustomerNotification.paymentsFromDateTime))} - {textService.formatDateTime(moment(bankToCustomerNotification.paymentsToDateTime))}
                            </ShowField>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'bankToCustomerNotification.paymentStatusesStats'} object={bankToCustomerNotification}>
                                <PaymentStats nrOfPayments={bankToCustomerNotification.nrOfPayments} nrOfPairedInvoices={bankToCustomerNotification.nrOfPairedInvoices} paymentStatesStats={bankToCustomerNotification.paymentStatesStats}/>
                            </ShowField>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {searchOptions && <BankPayments searchOptions={searchOptions}/>}
        </>
    )


}

export default ShowBankToCustomerNotification
