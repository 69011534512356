import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import {Link} from "react-router-dom";
import textService from "../_services/text.service";
import CreateSettlementBatch from "../_components/_settlementBatch/CreateSettlementBatch";
import OtherLeadershipDiscountSettlementBatchFilter from "./OtherLeadershipDiscountSettlementBatchFilter";
import SimpleTable from "../_components/SimpleTable";
import moment from "moment/moment";

function CreateOtherLeadershipDiscountSettlementBatch( { routeParams } ) {
	const { t } = useTranslation();
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [otherLeadershipDiscountSettlementBatch] = restService.useDomainInstance('otherLeadershipDiscountSettlementBatch', id, true)

	const columns = useMemo( () => [
		{ key: 'policyNumber', label: t('policyVersion.policyNumber.label'), formatter: (policyVersion) => <Link to={ `/policy/show/${policyVersion.id}` } target={"_blank"} rel={"noopener noreferrer"}>{ policyVersion.policyNumber }</Link> },
		{ key: 'policyHolder', label: t('policyHolder.label'), formatter: (policyVersion) => policyVersion.policyHolder},
		{ key: 'dueDate', label: t('createOtherLeadershipDiscountSettlementBatch.dueDate'), formatter: (policyVersion) => textService.format(moment(policyVersion.dueDate), 'date') },
		{ key: 'nettoFee', label: t('createOtherLeadershipDiscountSettlementBatch.nettoFee'), formatter: (policyVersion) => textService.format(policyVersion.nettoFee, 'number') },
		{ key: 'participation', label: t('createOtherLeadershipDiscountSettlementBatch.participation'), formatter: (policyVersion) => textService.format(policyVersion.participation, 'number') + ' %' },
		{ key: 'appversFee', label: t('createOtherLeadershipDiscountSettlementBatch.appversFee'), formatter: (policyVersion) => textService.format(policyVersion.appversFee, 'number') },
		{ key: 'refund', label: t('createOtherLeadershipDiscountSettlementBatch.refund'), formatter: (policyVersion) => textService.format(policyVersion.refund, 'number') },
	], [t]);

	const getTable = useCallback( (rows) => {
		return {
			element: <SimpleTable columns={columns} rows={rows}/>,
			size: rows.length,
		}
	}, [columns])

	return (
		<CreateSettlementBatch
			settlementBatch={otherLeadershipDiscountSettlementBatch}
			filter={<OtherLeadershipDiscountSettlementBatchFilter />}
			getInvoicesTable={getTable}
		/>
	);
}

export default CreateOtherLeadershipDiscountSettlementBatch