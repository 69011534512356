import React from 'react';
import serviceService from "../_services/service.service";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";
import SimpleTable from "../_components/SimpleTable";
import {useTranslation} from "react-i18next";

function PoliciesVersionsWithIncompleteRV() {
    const policiesVersionsWithIncompleteRV = serviceService.usePoliciesVersionsWithIncompleteRV()
    const {t} = useTranslation()

    const columns = [
        {
            key: "policyVersion",
            label: t( 'policyVersion.policyNumber.label' ),
            formatter: (cellContent) => <Link to={ restService.getTo( cellContent.policyVersion, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
                { cellContent.policyVersion.label }
            </Link>,
        },
        {
            key: 'missing',
            label: t( 'policiesVersionsWithIncompleteRV.missing.label' ),
            formatter: (cellContent) => cellContent.missing
        },
    ]

    return (
        <SimpleTable columns={columns} rows={policiesVersionsWithIncompleteRV}/>
    )
}

export default PoliciesVersionsWithIncompleteRV