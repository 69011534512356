import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";

function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('avbDescription.card.common.header')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupAvbDescription">
							<Form.Label>{t('avbDescription.description.label')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value= {domainInstance.description || (defaultValues && defaultValues.description)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
