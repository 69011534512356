import React, {useMemo, useState} from 'react'
import {youTrackService} from "../_services/youTrack.service";
import ChangelogItem from "./ChangelogItem";
import {Col, Container, Form, FormControl, Row, Table} from "react-bootstrap";
import {useConfig} from "../_services/useConfig";

function Changelog() {
	const { issues, sortedVersions } = youTrackService.useChangelogIssues();
	const { config: { versionInfo } } = useConfig()
	const [assignee, setAssignee] = useState('');

	const items = useMemo( () => {
		if ( sortedVersions ) {
			const tableContent = sortedVersions.map( version => {
				const issuesRows = issues[version].filter((issue) => {
					return !assignee || !issue.assignee || (issue.assignee.toLowerCase().includes(assignee.toLowerCase()))
				}).map( ( issue ) => {
					return <ChangelogItem key={ issue.id } issue={ issue }/>;
				} );

				return (
					issuesRows.length > 0 ?
						<>
							<tr colspan={ 2 }>
								<strong>{ version }</strong>
							</tr>
							{ issuesRows }
							<tr style={{height:'2em'}}></tr>
						</>
						:
						<></>
				)
			} )

			return (
				<Table>
					<tbody>
						{tableContent}
					</tbody>
				</Table>
			)
		}
		else {
			return <p>Loading...</p>
		}
	}, [issues, sortedVersions, assignee])

	return (
		<Container>
			<Row>
				<Col>
					<h3 className={"mb-3"}>Erledigte Punkte im YouTrack</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					<h5 className={"mb-5"}>Aktuelle Version ist {versionInfo.tag}</h5>
				</Col>
			</Row>
			<Row>
				<Col className={"ms-3 mb-4"} md={4}>
					<Form.Group as={Row} md="2" controlId="assignee">
						<FormControl
							placeholder={"Assignee"}
							name={"assignee"}
							type={"text"}
							value= {assignee}
							onChange={ (event) => setAssignee(event.target.value) }
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					{items}
				</Col>
			</Row>
		</Container>
	)
}

export default Changelog