import {useEffect, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";

export const configuratorNodeOrderService = {
	useConfiguratorNodeOrders,
	saveConfiguratorNodeOrders
}

function useConfiguratorNodeOrders() {
	const [ configuratorNodeOrders, setConfiguratorNodeOrders ] = useState([])

	useEffect( () => {
		const controller = new AbortController();
		const h = headers();
		fetch( `${ SERVER_URL }/api/configuratorNode/getConfiguratorNodeOrders`, {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => {
				return setConfiguratorNodeOrders( json.configuratorNodeOrders )
			} )
			.catch( (error) => restService.handleServerErrors( error, controller.signal ) );

		return function cleanup() {
			controller.abort()
		}
	}, [])

	return [ configuratorNodeOrders, setConfiguratorNodeOrders ];
}

function saveConfiguratorNodeOrders( configuratorNodeOrders ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const params = { data: JSON.stringify( configuratorNodeOrders ) }
		fetch( `${ SERVER_URL }/api/configuratorNode/saveConfiguratorNodeOrders`, {
			method: 'POST',
			headers: h,
			body: JSON.stringify( params )
		} )
			.then( r => restService.handleServerResponse( r.clone() ) )
			.then( resolve )
			.catch( reject );
	});
}