import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import bootstrapTableService from "../_components/bootstrapTable.service";
import DomainTable from "../_components/DomainTable";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faEye, faWrench} from "@fortawesome/free-solid-svg-icons";
import PaymentStateBadge from "../payment/PaymentStateBadge";
import {getEnumType, PaymentState} from "../_enum/enum";
import {Button, ButtonGroup} from "react-bootstrap";
import store from "../store";
import {setQueryParams} from "../bankToCustomerNotification/bankToCustomerNotificationSlice";

function BankPayments({namedCriteria, searchOptions}) {
	const { t } = useTranslation();
	const domainTableRef = useRef()
	const [originalSearchOptions] = useState(searchOptions)
	const storeQueryParams = store.getState().bankToCustomerNotification.queryParams
	const queryParams = new URLSearchParams(storeQueryParams || window.location.search)
	const location = useLocation();

	if ( window.location.search && !storeQueryParams ) {
		store.dispatch( setQueryParams( window.location.search ) );
	}

	const [paymentStateFilter, setPaymentStateFilter] = useState( () => {
		const paymentStateFilterParam = queryParams.get("paymentStateFilter")
		console.log("paymentStateFilter=" + paymentStateFilterParam)
		return paymentStateFilterParam
	})

	const _searchOptions = useMemo( () => {
		if ( paymentStateFilter === 'UNPAIRED' ) {
			const newQueryStrings = [...originalSearchOptions.queryStrings, {
				field: 'invoice',
				predicate: 'EXISTS',
				innerPredicate: 'MUST_NOT'
			}]
			return { ...originalSearchOptions, ...{ queryStrings: newQueryStrings } }
		}
		else {
			if (paymentStateFilter) {
				const newQueryStrings = [...originalSearchOptions.queryStrings, {
					field: 'invoice.paymentState',
					text: paymentStateFilter
				}]
				return { ...originalSearchOptions, ...{ queryStrings: newQueryStrings } }
			}
			else {
				return {...originalSearchOptions}
			}
		}
	}, [paymentStateFilter, originalSearchOptions])

	const [page, setPage] = useState( () => {
		const pageParam = parseInt( queryParams.get("page") )
		if ( isNaN(pageParam) ) {
			return 1
		}
		else {
			return pageParam
		}
	});
	const [sizePerPage, setSizePerPage] = useState( () => {
		const sizePerPage = parseInt( queryParams.get("sizePerPage") )
		if ( isNaN(sizePerPage) ) {
			return 10
		}
		else {
			return sizePerPage
		}
	});
	const [sortField, setSortField] = useState( () => {
		const sortFieldParam = queryParams.get("sortField")
		return sortFieldParam
	});
	const [sortOrder, setSortOrder] = useState( () => {
		const sortOrderParam = queryParams.get("sortOrder")
		return sortOrderParam
	});
	const [searchText, setSearchText] = useState( () => {
		return queryParams.get("searchText")
	});

	const handleTableStateChanged = (newState) => {
		let params = {};
		if ( newState.page ) {
			params['page'] = newState.page;
			setPage(newState.page)
		}
		if ( newState.sortField ) {
			params['sortField'] = newState.sortField;
			setSortField(newState.sortField)
		}
		if ( newState.sortOrder ) {
			params['sortOrder'] = newState.sortOrder;
			setSortOrder(newState.sortOrder)
		}
		if ( newState.searchText ) {
			params['searchText'] = newState.searchText;
			setSearchText(newState.searchText)
		}
		if ( newState.sizePerPage ) {
			params['sizePerPage'] = newState.sizePerPage;
			setSizePerPage(newState.sizePerPage)
		}
		const psf = newState.paymentStateFilter || paymentStateFilter
		if ( psf ) {
			params['paymentStateFilter'] = psf;
		}
		const urlSearchParams = (new URLSearchParams( params )).toString();
		store.dispatch( setQueryParams( urlSearchParams ) );
		window.history.pushState(null, null, `${location.pathname}?${urlSearchParams}`);
	}

	const getFixAction = (rowData) => {
		let result
		if ( rowData.pairedInvoice ) {
			if ( PaymentState.PAID !== getEnumType("appvers.enums.PaymentState", rowData.pairedInvoice.paymentState).name ) {
				result = (
					<Link className={`btn btn-sm btn-warning`} to={`/manualPayment/bankPaymentCorrection/${rowData.id}` }>
						<FontAwesomeIcon icon={faWrench}/>
					</Link>
				)
			}
		}
		else {
			result = (
				<Link className={`btn btn-sm btn-warning ms-1`} to={`/bankPayment/showSimilar/${rowData.id}`}>
					<FontAwesomeIcon icon={faWrench}/>
				</Link>
			)
		}
		return result
	}

	const columns = [
		{
			dataField: 'id',
			hidden: true,
		},
		{
			dataField: 'amountDate',
			text: t( 'payment.amountDate' ),

			formatter:  bootstrapTableService.formatterDate,
			sortField: 'amountDate',
			sort: true
		},
		{
			dataField: 'refNr',
			text: t('payment.refNr'),
			headerStyle: (column, colIndex) => {
				return { width: '16em' };
			},
			formatter: (cellContent, row) => {
				if ( row.pairedInvoice && row.refNr !== row.pairedInvoice.refNr ) {
					return (
						<>
							<div className={'text-danger'}>{cellContent}</div>
							<div className={'text-center'}><FontAwesomeIcon icon={faAngleDoubleDown}/></div>
							<div className={'text-success'}>{row.pairedInvoice.refNr}</div>
						</>
					)
				}
				else {
					return cellContent
				}
			},
			sortField: 'refNr',
			sort: true
		},
		{
			dataField: 'amount',
			text: t('payment.amount'),
			formatter: bootstrapTableService.formatterAmount,
			classes: (cell, row, rowIndex, colIndex) => 'text-end',
			sortField: 'amount',
			sort: true
		},
		{
			dataField: 'pairedInvoice.total',
			sort: true,
			text: t('bankPayment.invoice.total'),
			formatter: (cellContent, row) => {
				if ( row.pairedInvoice ) {
					return bootstrapTableService.formatterAmount( cellContent )
				}
			},
			classes: (cell, row, rowIndex, colIndex) => 'text-end'
		},
		{
			dataField: 'pairedInvoice.paymentState',
			text: t('bankPayment.invoice.paymentState'),
			formatter: (cellContent, row) => {
				return (
					<>
						{getFixAction(row)}<br/>
						{row.pairedInvoice && <PaymentStateBadge key={'badge'} paymentState={row.pairedInvoice.paymentState}/>}
					</>
				)
			},
			classes: (cell, row, rowIndex, colIndex) => 'text-center',
			sortField: 'pairedInvoice.paymentState',
			sort: true,
		},
		{
			dataField: 'pairedInvoice.invoiceNr',
			text: t('payment.invoice'),
			sort: true,
			classes: (cell, row, rowIndex, colIndex) => 'text-end',
			formatter: (cellContent, row) => {
				if ( row.pairedInvoice ) {
					return <Link to={`/settlement/show/${row.pairedInvoice.id}`}>{cellContent}</Link>
				}
			},
			// sortField: 'filename',
			// sort: true
		},
		{
			dataField: '',
			text: '',
			sort: false,
			classes: (cell, row, rowIndex, colIndex) => 'text-end',
			formatter: (cellContent, row) => {
				return (
					<>
						<Link className={`btn btn-sm btn-primary ms-1`} to={`/bankPayment/show/${row.id}`}>
							<FontAwesomeIcon icon={faEye}/>
						</Link>
					</>
				)
			},
			headerStyle: (column, colIndex) => {
				return { width: '10%' };
			},
		}
	];

	const onPaymentStateFilterChange = (newState) => {
		setPaymentStateFilter( newState )

		const paramsState = {
			page: page,
			sortField: sortField,
			sortOrder: sortOrder,
			searchText: searchText,
			sizePerPage: sizePerPage,
			paymentStateFilter: newState,
		}
		console.log(paramsState)
		handleTableStateChanged( paramsState )
	}

	const filterButtons = () => {
		const getButtonVariant = (name, isActive) => {
			if ( isActive ) {
				return name
			}
			else {
				return `outline-${name}`
			}
		}
		return (
			<ButtonGroup>
				<Button size={"sm"} active={paymentStateFilter === null}
						onClick={() => onPaymentStateFilterChange(null)}
						variant={getButtonVariant('primary', paymentStateFilter === null)}>{t('default.all')}</Button>
				<Button size={"sm"} active={paymentStateFilter === PaymentState.PAID}
						onClick={() => onPaymentStateFilterChange(t(`appvers.enums.PaymentState.${PaymentState.PAID}`))}
						variant={getButtonVariant('success', paymentStateFilter === PaymentState.PAID)}>{t('appvers.enums.PaymentState.PAID')}</Button>
				<Button size={"sm"} active={paymentStateFilter === PaymentState.PAID_PARTIALLY}
						onClick={() => onPaymentStateFilterChange(t(`appvers.enums.PaymentState.${PaymentState.PAID_PARTIALLY}`))}
						variant={getButtonVariant('warning', paymentStateFilter === PaymentState.PAID_PARTIALLY)}>{t('appvers.enums.PaymentState.PAID_PARTIALLY')}</Button>
				<Button size={"sm"} active={paymentStateFilter === PaymentState.OVERPAID}
						onClick={() => onPaymentStateFilterChange(t(`appvers.enums.PaymentState.${PaymentState.OVERPAID}`))}
						variant={getButtonVariant('danger', paymentStateFilter === PaymentState.OVERPAID)}>{t('appvers.enums.PaymentState.OVERPAID')}</Button>
				<Button size={"sm"} active={paymentStateFilter === 'UNPAIRED'}
						onClick={() => onPaymentStateFilterChange('UNPAIRED')}
						variant={getButtonVariant('warning', paymentStateFilter === 'UNPAIRED')}>{t("bankPayment.filter.unpaired.label")}</Button>
			</ButtonGroup>
		)
	}

	return (
		<>
			{filterButtons()}
			<DomainTable
				onTableStateChanged={handleTableStateChanged}
				ref={domainTableRef}
				domainName={'bankPayment'}
				columns={columns}
				namedCriteria={namedCriteria}
				searchOptions={_searchOptions}
				sortField={sortField} sortOrder={sortOrder} searchText={searchText} sizePerPage={sizePerPage}
				page={page}
				// rowClasses={rowClasses}
				// sortField={'billingFrom'}
				// sortOrder={'desc'}
			/>
		</>
	);
}

export { BankPayments };
