import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {Button, Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export default function ConfiguratorNodeTypeDialog(props) {
	const [show, setShow] = useState(props.show);
	const [result, setResult] = useState(props.defaultValue);
	const {t} = useTranslation();

	useEffect(() => {
		setShow( props.show );
	}, [props.show]);

	const handleCancelEdit = (event) => {
		if(event) {
			event.stopPropagation();
		}
		props.handleCancel();
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		props.handleSubmit( result );
	}

	const handleOnChange = (event) => {
		setResult(event.target.id);
	}

	const getRadioOptions = () => {
		return props.availableOptions.map( (option) => {
			return <Form.Check
				key={option.id}
				type={'radio'}
				id={option.id}
				label={t(option.label)}
				name={'result'}
				onChange={handleOnChange}
				checked={option.id === result}
			/>
		})
	}

	return (
		<>
			<Modal
				show={show}
				size="sm"
				animation={false}
				onHide={handleCancelEdit}
				centered
				backdrop="static"
			>
				<Form noValidate onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>{t('configuratorNodeTypeDialog.modal.header')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div key={`default-radio`} className="mb-3">
							{getRadioOptions()}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" type="submit">
							{t('default.create')}
						</Button>
						<Button variant="secondary" onClick={handleCancelEdit}>
							{t('default.close')}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

ConfiguratorNodeTypeDialog.propTypes = {
	show: PropTypes.bool,
	availableOptions: PropTypes.array,
	handleCancel: PropTypes.func,
	handleSubmit: PropTypes.func,
	defaultValue: PropTypes.string
};
