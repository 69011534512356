import React from 'react';
import {PulseLoader} from "react-spinners";
import PropTypes from "prop-types";

function DefaultSpinner({loading, css, size}) {
	return (
        <PulseLoader color={"#343a40"} loading={loading} css={css} size={size} />
	)
}

DefaultSpinner.propTypes = {
	loading: PropTypes.bool,
	css: PropTypes.string,
	size: PropTypes.number,
}

DefaultSpinner.defaultProps = {
	loading: false,
	css: '',
	size: 10,
};
export default DefaultSpinner