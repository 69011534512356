import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function ProfitParticipationSettlementBatchFilter( {settlementBatch, setData, clearData} ) {
    const { t } = useTranslation();
    const [year, setYear] = useState('');

    const _year = useMemo(() => (settlementBatch && settlementBatch.year) || '', [settlementBatch]);

    useEffect( () => {
        setYear(_year);
    }, [_year, setYear]);

    const handleUpdateTable = () => {
        if ( year ) {
            setData( {
                year: year,
            } );
        }
    }

	return (
        <>
            <p>{ t( 'participationSettlementBatchFilter.message' ) }</p>
            <Row>
                <Form.Group as={ Col } md="6" controlId="formGroupDateTo">
                    <Form.Control
                        name={ "year" }
                        type={ "number" }
                        value={ year }
                        onChange={ ( event ) => {
                            setYear( event.target.value );
                            clearData();
                        }}
                        required={ true }
                    />
                </Form.Group>
            </Row>
            <Row className={"mt-3"}>
                <Col>
                    <Button type={"button"} onClick={handleUpdateTable}>{t('default.update')}</Button>
                </Col>
            </Row>
        </>
	)
}

export default ProfitParticipationSettlementBatchFilter