import React from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {invoiceService} from "../../_services/invoice.service";
import {messagePanelService} from "../../_services/message.panel.service";

function SettlementPrintedMessageCommon( params) {
	const {t} = useTranslation();

	const handleOnClick = () => {
		switch ( params.settlementClassName ) {
			case 'settlement':
				invoiceService.bookSettlement(params.id)
				break;
			case 'settlementBatch':
				invoiceService.bookSettlementBatch(params.id)
				break
			default:
				throw new Error(`Unknown className ${params.className}`);
		}
		messagePanelService.update( undefined, [params.uuid] );
	}

	return (
		<>
			<p>{params.message}</p>
			<Button variant={'primary'} onClick={handleOnClick}>{t('settlementBatch.book')}</Button>
		</>
	)
}

export default SettlementPrintedMessageCommon
