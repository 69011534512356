import React, {useEffect, useState} from 'react';
import {Card, Col, Form, InputGroup, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as ZipFormElements} from "../zip/FormElements";
import {GoogleMapLink} from "../_components/GoogleMapLink";

function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();
	const [street, setStreet] = useState(domainInstance.street || (defaultValues && defaultValues.street));
	const [zipLabel, setZipLabel] = useState(domainInstance && domainInstance.zip && domainInstance.zip.label);

	useEffect( () => {
		setStreet(domainInstance.street || (defaultValues && defaultValues.street));
		setZipLabel(domainInstance && domainInstance.zip && domainInstance.zip.label);
	}, [domainInstance, defaultValues])

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Body>
					<Card className={"mb-2"}>
						<Card.Header>{t('building.description.label')}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="12" controlId="formGroupDescription">
									<FormControl
										name={"description"}
										type={"text"}
										value= {domainInstance.description}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Card className={"mb-2"}>
						<Card.Header>{t('building.card.address.header')}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="6" controlId="formGroupStreet">
									<Form.Label>{t('building.street.label')} <GoogleMapLink street={street} zipLabel={zipLabel}/></Form.Label>
									<FormControl
										name={"street"}
										type={"text"}
										value= {street}
										rules={{
											required: true
										}}
										onChange={ (event) => setStreet(event.target.value) }
									/>
								</Form.Group>
								<Form.Group as={Col} md="6" controlId="groupZip">
									<Form.Label>{t('building.zip.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"zip"}
										sort={"place_sort"}
										value={domainInstance && domainInstance.zip && domainInstance.zip.id}
										label={domainInstance && domainInstance.zip && domainInstance.zip.label}
										name={'zip'}
										createable={{formElements: <ZipFormElements/>}}
										rules={{
											required: true
										}}
										onChange={ (event) => setZipLabel(event.target.value ? event.target.value.label : '') }
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Card className={"mb-2"}>
						<Card.Header>{t('building.card.properties.header')}</Card.Header>
						<Card.Body>
							<Row className={"mb-3"}>
								<Form.Group as={Col} md="4" controlId="groupBuildingType">
									<Form.Label>{t('building.buildingType.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"buildingType"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.buildingType && domainInstance.buildingType.id}
										label={domainInstance && domainInstance.buildingType && domainInstance.buildingType.label}
										name={'buildingType'}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
								<Form.Group as={Col} md="4" controlId="groupBuildingClass">
									<Form.Label>{t('building.buildingClass.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"buildingClass"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.buildingClass && domainInstance.buildingClass.id}
										label={domainInstance && domainInstance.buildingClass && domainInstance.buildingClass.label}
										name={'buildingClass'}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
								<Form.Group as={Col} md="4" controlId="groupConstructionType">
									<Form.Label>{t('building.constructionType.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"constructionType"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.constructionType && domainInstance.constructionType.id}
										label={domainInstance && domainInstance.constructionType && domainInstance.constructionType.label}
										name={'constructionType'}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Row>
							<Row className={"mb-3"}>
								<Form.Group as={Col} md="4" controlId="groupFireExtinguisher">
									<Form.Label>{t('building.fireExtinguisher.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"fireExtinguisher"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.fireExtinguisher && domainInstance.fireExtinguisher.id}
										label={domainInstance && domainInstance.fireExtinguisher && domainInstance.fireExtinguisher.label}
										name={'fireExtinguisher'}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
								<Form.Group as={Col} md="4" controlId="groupHeatingType">
									<Form.Label>{t('building.heatingType.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"heatingType"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.heatingType && domainInstance.heatingType.id}
										label={domainInstance && domainInstance.heatingType && domainInstance.heatingType.label}
										name={'heatingType'}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
								<Form.Group as={Col} md="4" controlId="groupRoofType">
									<Form.Label>{t('building.roofType.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"roofType"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.roofType && domainInstance.roofType.id}
										label={domainInstance && domainInstance.roofType && domainInstance.roofType.label}
										name={'roofType'}
										rules={{
											required: true
										}}
									/>
								</Form.Group>

							</Row>
							<Row>
								<Form.Group as={Col} md="4" controlId="groupBranch">
									<Form.Label>{t('building.branch.label')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"branch"}
										sort={"name_sort"}
										value={domainInstance && domainInstance.branch && domainInstance.branch.id}
										label={domainInstance && domainInstance.branch && domainInstance.branch.label}
										name={'branch'}
									/>
								</Form.Group>

								<Form.Group as={Col} md="4" controlId="formCubicCapacity">
									<Form.Label>{t('building.cubicCapacity.label')}</Form.Label>
									<InputGroup>
										<FormControl
											name={"cubicCapacity"}
											type={"number"}
											value= {domainInstance.cubicCapacity}
										/>
										<InputGroup.Text>&#13221;</InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Card.Body>
			</Card>
		</>
	);
}


// FireExtinguisher fireExtinguisher
// Long cubicCapacity
// Boolean flatRoof

export { FormElements };
