import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import PreviewPolicyVersionPolicyVersionSettlements from "./PreviewPolicyVersionPolicyVersionSettlements";
import PreviewPolicyVersionParticipationSettlements from "./PreviewPolicyVersionParticipationSettlements";
import {useTranslation} from "react-i18next";
import PreviewPolicyVersionProfitParticipationSettlements from "./PreviewPolicyVersionProfitParticipationSettlements";

function PreviewPolicyVersionSettlements({policyVersion}) {
	const {t} = useTranslation();

	return (
		<div className={"mt-3"}>
			<Tabs>
				<Tab eventKey="invoices" title={t('previewPolicyVersion.tab.invoices')} mountOnEnter={true}>
					<PreviewPolicyVersionPolicyVersionSettlements policyVersion={policyVersion}/>
				</Tab>
				<Tab eventKey="participationInvoices" title={t('previewPolicyVersion.tab.participationInvoices')} mountOnEnter={true}>
					<PreviewPolicyVersionParticipationSettlements policyVersion={policyVersion}/>
				</Tab>
				<Tab eventKey="profitParticipationCreditNotes" title={t('previewPolicyVersion.tab.profitParticipationCreditNotes')} mountOnEnter={true}>
					<PreviewPolicyVersionProfitParticipationSettlements policy={policyVersion.policy}/>
				</Tab>
			</Tabs>
		</div>
	)
}

export default PreviewPolicyVersionSettlements