import headers from "../security/headers";
import {SERVER_URL} from "../config";
import {fileService} from "./file.service";
import restService from "./rest.service";
import {messageBoxService} from "./messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";
import {useEffect, useState} from "react";

export const damageService = {
	printDamagePaymentInfo,
	printDamageStatement,
	printDamageDossier,
	downloadInvolvedInsuranceInvoices,
	markPaymentInfoAsBooked,
	markDamageAsResolved,
	getReserveAmounts,
	printDamageRendement,
	printDamageReserves,
	partlyResolveDamage,
	useDamagesForInterimBilling
}

function getReserveAmounts( damagePaymentInfoId ) {
	return new Promise( (resolve, reject) => {
		const h = headers();

		fetch( `${ SERVER_URL }/api/damagePaymentInfo/getReserveAmounts/${damagePaymentInfoId}`, {
			method: 'GET',
			headers: h,
		} )
			.then( r => r.json() )
			.then( json => {
				resolve(json);
			} )
			.catch( error => {
				reject(error);
			} );
	});
}

function useDamagesForInterimBilling() {
	const [damagesForInterimBilling, setDamagesForInterimBilling] = useState( [] )

	useEffect( () => {
		const controller = new AbortController();
		const h = headers();

		fetch( `${ SERVER_URL }/api/damage/getDamagesForInterimBilling`, {
			method: 'GET',
			headers: h,
		} )
			.then( r => r.json() )
			.then( json => {
				setDamagesForInterimBilling( json.damagesForInterimBilling )
			} )
			.catch( error => {
				restService.handleServerErrors( error, controller.signal );
			} );
	}, [] );

	return damagesForInterimBilling
}

function printDamagePaymentInfo( id ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/damagePaymentInfo/printDamagePaymentInfo/${ id }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printDamageStatement( id ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/damage/printDamageStatement/${ id }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printDamageDossier( id ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/damage/printDamageDossier/${ id }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printDamageRendement( params ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/damage/printDamageRendement/?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printDamageReserves() {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/damage/printDamageReserves/`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}


function downloadInvolvedInsuranceInvoices( id ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/damage/downloadInvolvedInsuranceInvoices/${ id }`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}


function markPaymentInfoAsBooked( id, newReserveAmounts ) {
	const h = headers();
	const controller = new AbortController()
	const params = {
		id: id,
		newReserveAmounts: JSON.stringify(newReserveAmounts)
	};
	fetch( `${ SERVER_URL }/api/damagePaymentInfo/book?` + new URLSearchParams( params ), {
		method: 'GET',
		headers: h,
		signal: controller.signal
	} )
		.then( response => restService.handleServerResponse( response ) )
		.then( () => { /*do nothing*/ } )
		.catch( ( error ) => {
			restService.handleServerErrors( error, controller.signal );
		} );
}

//TODO:
function markDamageAsResolved( id ) {
	messageBoxService.display( i18n.t( 'damage.markAsResolved.question' ), i18n.t( 'damage.markAsResolved' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
		.then( ( button ) => {
			if ( button === MessageBoxButtons.YES ) {
				const h = headers();
				const controller = new AbortController()
				fetch( `${ SERVER_URL }/api/damage/resolve/${ id }`, {
					method: 'GET',
					headers: h,
					signal: controller.signal
				} )
					.then( response => restService.handleServerResponse( response ) )
					.then( () => { /*do nothing*/ } )
					.catch( ( error ) => {
						restService.handleServerErrors( error, controller.signal );
					} );
			}
		})
}

function partlyResolveDamage( id ) {
	return new Promise( (resolve, reject) => {
		messageBoxService.display( i18n.t( 'damage.partlyResolveDamage.question' ), i18n.t( 'damage.partlyResolveDamage' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
			.then( ( button ) => {
				if ( button === MessageBoxButtons.YES ) {
					const h = headers();
					const controller = new AbortController()
					fetch( `${ SERVER_URL }/api/damage/partlyResolve/${ id }`, {
						method: 'GET',
						headers: h,
						signal: controller.signal
					} )
						.then( response => restService.handleServerResponse( response ) )
						.then( () => {
							resolve();
						} )
						.catch( ( error ) => {
							restService.handleServerErrors( error, controller.signal );
							reject();
						} );
				}
			})
	})
}
