import {useEffect, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "../_services/rest.service";

function usePolicyTypes( ) {
	const [policyTypes, setPolicyTypes] = useState( [] );

	useEffect(() => {
		const controller = new AbortController()
		const h = headers();
		fetch( `${ SERVER_URL }/api/configurator/getPolicyTypes`, {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( r => r.json() )
			.then( data => {
				setPolicyTypes( data.policyTypes );
			} )
			.catch( error =>  restService.handleServerErrors( error, controller.signal ) );
		return function cleanup() {
			controller.abort();
		}
	}, []);

	return [policyTypes];
}

export default usePolicyTypes
