import React, {useState} from 'react';
import {Form, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useForm, FormProvider} from "react-hook-form";
import {FormElements} from "./FormElements";
import restService from "../_services/rest.service";
import {alertService} from "../_services";
import {useHistory} from "react-router-dom";
import headers from "../security/headers";
import {SERVER_URL} from "../config";

function CreateEditBankToCustomerNotification() {
    const {t} = useTranslation();
    const [bankPaymentData] = useState({})
    const methods = useForm();
    const history = useHistory();
    const [controlDisabled, setControlDisabled] = useState(false);

    const handleFormSubmit = (data) => {
        save(data)
            .then( (id) => {
                setControlDisabled(false);
                handleSuccess(id);
            } )
            .catch( error => {
                setControlDisabled(false);
                alertService.error(error);
            })
    }

    const save = (data) => {
        setControlDisabled(true);
        return new Promise( (resolve, reject) => {
            const h = headers();
            delete h['Content-Type'];
            let formData = new FormData()
            formData.append('file', data.file[0])
            fetch( `${ SERVER_URL }/api/bankToCustomerNotification`, {
                method: 'POST',
                headers: h,
                body: formData
            } )
                .then( r => restService.handleServerResponse( r.clone() ) )
                .then( json => resolve(json.id) )
                .catch( reject );
        });
    }

    const handleSuccess = (id) => {
        history.push(`/bankToCustomerNotification/show/${id}`)
    }
    const handleCancel = () => {
        history.push('/bankToCustomerNotification')
    }

    return (
        <FormProvider {...methods}>
            <Form disabled={controlDisabled} method="post" encType={'multipart/form-data'} onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <fieldset disabled={controlDisabled}>
                    <FormElements domainInstance={ bankPaymentData }/>
                    <div className={"d-grid gap-2 d-md-flex mb-2"}>
                        <Button variant="primary" type="submit" >
                            {t('default.create')}
                        </Button>
                        <Button variant="secondary" onClick={handleCancel}>
                            {t('default.cancel')}
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </FormProvider>
    )
}
export default CreateEditBankToCustomerNotification
