import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";

function DecimalEditControl( { defaultValue, save, controlRef } ) {
    const [rawValue, setRawValue] = useState(defaultValue);

	return (
        <Form.Control
            ref={controlRef}
            size={ "sm" }
            type={ "text" }
            value={ rawValue === null ? undefined : rawValue }
            onChange={ (e) => {
                let val = e.target.value
                const regexp = new RegExp(/^\d*(\.|,)?\d*$/);
                if ( regexp.test(val) ) {
                    setRawValue( e.target.value );
                }
            }}
            onBlur={ () => save( parseFloat(rawValue.replaceAll(",", ".")) ) }
            className={"text-end"}
        />
	)
}

DecimalEditControl.propTypes = {
    defaultValue: PropTypes.oneOfType( [PropTypes.string, PropTypes.number]),
    setValue: PropTypes.func,
    save: PropTypes.func,
}

export default DecimalEditControl