import React, {useCallback, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import {Button, Card, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import appService from "../_services";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";
import ShowDamagePaymentInfoDetails from "./ShowDamagePaymentInfoDetails";
import {Link} from "react-router-dom";
import websocketService from "../_services/websocket.service";
import {damageService} from "../_services/damage.service";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DefaultSpinner from "../_components/DefaultSpinner";
import textService from "../_services/text.service";

function ShowDamagePaymentInfo(props) {
    const [damagePaymentInfo, setDamagePaymentInfo] = restService.useDomainInstance( 'damagePaymentInfo', props.id, false );
    const [showMarkAsBooked, setShowMarkAsBooked] = useState(false);
    const [reserveAmounts, setReserveAmounts] = useState(null);
    const {t} = useTranslation();
    const linkToDamage = useMemo( () => {
        if ( damagePaymentInfo && damagePaymentInfo.damage ) {
            return `/damage/show/${damagePaymentInfo.damage.id}`
        }
    },[damagePaymentInfo])

    const handlePrintDamagePaymentInfo = (id) => {
        damageService.printDamagePaymentInfo(id);
    }

    const refreshDamagePaymentInfo = useCallback( (controller) => {
        const signal = controller && controller.signal;
        restService.getDomainInstance( 'damagePaymentInfo', props.id, signal )
            .then( (result) => setDamagePaymentInfo(result) )
            .catch( error => restService.handleServerErrors( error, signal ) )
    }, [props.id, setDamagePaymentInfo])

    const afterUpdateDamagePaymentInfoCallback = useCallback( (changes) => {
        if ( changes.payload.updatedProperties.includes( 'booked' ) ) {
            refreshDamagePaymentInfo();
        }
    }, [refreshDamagePaymentInfo]);

    const ids = useMemo( () => [parseInt(props.id)], [props.id] );
    websocketService.useAfterUpdateSubscription('damagePaymentInfo', ids, afterUpdateDamagePaymentInfoCallback);

    const showBaseData = () => {
        return (
            <Card className='mb-2'>
                <Card.Header><h4>{appService.nullSafeGet( damagePaymentInfo, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <ShowField label={'damage.label'} object={damagePaymentInfo} property={'damage.label'} type={'text'} link={{to: linkToDamage}} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.partner'} object={damagePaymentInfo} property={'partner.label'} type={'text'} />
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfo.description'} object={damagePaymentInfo} property={'description'} type={'text'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.paymentInfoDate'} object={damagePaymentInfo} property={'paymentInfoDate'} type={'date'} />
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfo.amount'} object={damagePaymentInfo} property={'amount'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.booked'} object={damagePaymentInfo} property={'booked'} type={'boolean'} />
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfo.bankAccountNr'} object={damagePaymentInfo} property={'bankAccountNr'} type={'text'} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    const handleShowMarkAsBooked = useCallback( () => {
        setShowMarkAsBooked(true);
        damageService.getReserveAmounts(props.id)
            .then( (result) => {
                setReserveAmounts(result)
            })
            .catch( ( error ) => restService.handleServerErrors( error ) )
    }, [setShowMarkAsBooked, setReserveAmounts, props.id])

    const handleSubmitMarkAsBooked = useCallback( (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowMarkAsBooked(false);

        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData.entries())

        let ras = []
        for( const rb in data ) {
            const damageDetailId = rb.match(/newReserveAmount-(\d+)/)[1];
            ras.push( {damageDetailId: damageDetailId, newReserveAmount: data[rb]} );
        }

        damageService.markPaymentInfoAsBooked( damagePaymentInfo.id, ras )
    }, [setShowMarkAsBooked, damagePaymentInfo.id])

    const showButtons = () => {
        if ( !damagePaymentInfo.booked ) {
            return (
                <>
                    <Link to={ `/damagePaymentInfo/edit/${props.id}` } className="btn btn-sm btn-primary mb-2 me-2">{t('default.edit')}</Link>
                    <Button className={"btn-sm mb-2"} variant={"warning"} onClick={ handleShowMarkAsBooked }>{t('damagePaymentInfo.markAsBooked')}</Button>
                </>
            )
        }
        else {
            return (
                <>
                    <Button variant={"primary"} size={"sm"} onClick={ () => handlePrintDamagePaymentInfo(props.id) }>
                        <FontAwesomeIcon icon={faFilePdf}/>
                    </Button>
                </>
            )
        }
    }

    return (
        <>
            <Modal show={showMarkAsBooked} onHide={ () => setShowMarkAsBooked(false) } centered backdrop="static" animation={false}>
                <Form onSubmit={ handleSubmitMarkAsBooked }>
                    <Modal.Header closeButton>
                        <Modal.Title>{t( 'damagePaymentInfo.markAsBooked')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={"mb-3"}>
                            <Col>
                                {t('damagePaymentInfo.markAsBooked.question')}
                            </Col>
                        </Row>
                        { reserveAmounts != null ?
                            reserveAmounts.map( (reserveAmount) => {
                                return (
                                    <Form.Group key={reserveAmount.damageDetailId} className={"mt-3"} as={Col} controlId="newReserveAmount">
                                        <Form.Label>{ t( 'damagePaymentInfo.markAsBooked.newReserveAmount', {damageDetailDescription: reserveAmount.damageDetailDescription, currentAmount: textService.formatNumber(reserveAmount.currentAmount)} ) }</Form.Label>
                                        <Form.Control
                                            name={ `newReserveAmount-${reserveAmount.damageDetailId}` }
                                            type={ "number" }
                                            defaultValue={ reserveAmount.supposedAmount }
                                            required={ true }
                                        />
                                    </Form.Group>
                                )
                            })
                            :
                            <Row>
                                <DefaultSpinner loading={true}/>
                            </Row>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type={"submit"}>
                            {t('default.yes')}
                        </Button>
                        <Button variant="secondary" onClick={ () => setShowMarkAsBooked(false) }>
                            {t('default.no')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {showBaseData()}
            <Row>
                <Col>
                    {showButtons()}
                </Col>
            </Row>
            <Tabs defaultActiveKey="damagePaymentInfoDetails" id="damagePaymentInfo-tabs">
                <Tab eventKey="damagePaymentInfoDetails" title={t('damagePaymentInfo.tab.paymentInfoDetails')}>
                    <div className={"border-left border-right border-bottom rounded-bottom p-3"}>
                        <ShowDamagePaymentInfoDetails damagePaymentInfo={damagePaymentInfo}/>
                    </div>
                </Tab>
            </Tabs>
        </>
    )
}

export default ShowDamagePaymentInfo
