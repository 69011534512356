import React, {useCallback, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import {Button, Col, Modal} from "react-bootstrap";
import {Dropdown} from "./Dropdown";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {partnerService} from "../_services/partner.service";
import websocketService from "../_services/websocket.service";

function ChangeBankAccount({defaultBankAccountNr, onBankAccountChanged, partner}) {
    const {t} = useTranslation();
    const partnerId = partner && partner.id

    const [bankAccounts, setBankAccounts] = partnerService.useBankAccounts(partnerId);

    const handleAfterUpdatePartner = useCallback( () => {
        partnerService.getBankAccounts(partnerId)
            .then( (bankAccounts) => setBankAccounts( bankAccounts ) )
    }, [setBankAccounts, partnerId])
    const observePartnerIds = useMemo( () => [partnerId], [partnerId] )
    websocketService.useAfterUpdateSubscription( 'partner', observePartnerIds, handleAfterUpdatePartner );

    const [showChangeBankAccount, setShowChangeBankAccount] = useState(false);
    const [newBankAccount, setNewBankAccount] = useState(undefined);

    const handleChangeBankAccountOnClick = () => {
        setNewBankAccount(defaultBankAccountNr);
        setShowChangeBankAccount(true);
    }

    const handlePerformChangeBankAccount = () => {
        onBankAccountChanged( newBankAccount );
        setShowChangeBankAccount(false);
    }

	return (
        <>
            <Modal show={showChangeBankAccount} onHide={ () => setShowChangeBankAccount(false) } >
                <Modal.Header closeButton>
                    <p>{t('changeBankAccount.changeBankAccount.header')}</p>
                </Modal.Header>

                <Modal.Body>
                    <p>{t('changeBankAccount.noChildAllowed.message')}</p>
                    <Dropdown
                        nullable={true}
                        name={ "bankAccountNr" }
                        options={bankAccounts}
                        keyPropName={ 'id' }
                        valuePropName={ 'label' }
                        value={ newBankAccount }
                        required={true}
                        onChange={event => {
                            setNewBankAccount(event.target.value)
                        }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => handlePerformChangeBankAccount()}>{t('default.change')}</Button>
                    <Link className="btn btn-warning" to={restService.getTo( partner, 'edit' ) || ''} target={"_blank"} rel={"noopener noreferrer"}>{t('default.open')} {t('partner.label')}</Link>
                </Modal.Footer>
            </Modal>

            <Col>
                <Button size={'sm'} variant={ 'primary' } onClick={ handleChangeBankAccountOnClick }>{ t( 'changeBankAccount.bankAccountNr' ) } { t( 'default.change' ) }</Button>
            </Col>
        </>
	)
}

export default ChangeBankAccount