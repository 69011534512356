import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function ParticipationSettlementBatchFilter( {settlementBatch, setData, clearData} ) {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const _dateFrom = useMemo(() => (settlementBatch && settlementBatch.dateFrom) || '', [settlementBatch]);
    const _dateTo = useMemo(() => (settlementBatch && settlementBatch.dateTo) || '', [settlementBatch]);

    useEffect( () => {
        setDateFrom(_dateFrom);
        setDateTo(_dateTo);
    }, [_dateFrom, _dateTo, setDateFrom, setDateTo]);

    const handleUpdateTable = () => {
        if ( dateFrom && dateTo && dateFrom <= dateTo ) {
            setData( {
                dateFrom: dateFrom,
                dateTo: dateTo,
            } );
        }
    }

	return (
        <>
            <Alert variant={"danger"}>
                <strong>Nach der Hauptfakturierung 1.1.2024 diese Funktion ZUERST TESTEN.</strong>
            </Alert>
            <p>{ t( 'participationSettlementBatchFilter.message' ) }</p>
            <Row>
                <Form.Group as={ Col } md="6" controlId="formGroupDateFrom">
                    <Form.Control
                        name={ "dateFrom" }
                        type={ "date" }
                        value={ dateFrom }
                        onChange={ ( event ) => {
                            setDateFrom( event.target.value );
                            clearData();
                        }}
                        required={ true }
                    />
                </Form.Group>

                <Form.Group as={ Col } md="6" controlId="formGroupDateTo">
                    <Form.Control
                        name={ "dateTo" }
                        type={ "date" }
                        value={ dateTo }
                        onChange={ ( event ) => {
                            setDateTo( event.target.value );
                            clearData();
                        }}
                        required={ true }
                    />
                </Form.Group>
            </Row>
            <Row className={"mt-3"}>
                <Col>
                    <Button type={"button"} onClick={handleUpdateTable}>{t('default.update')}</Button>
                </Col>
            </Row>
        </>
	)
}

export default ParticipationSettlementBatchFilter