import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFileSignature, faFileInvoiceDollar, faHouseDamage, faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";

function StandardText({match}) {
	const { t } = useTranslation();

	const symbols = useMemo( () => {
		return {
			'police': faFileSignature,
			'invoicePolicyVersion': faFileInvoiceDollar,
			'damage': faHouseDamage,
			'brokerInterest': faHandHoldingUsd
		}
	}, [])

	const columns = useMemo( () => {
		return [{
			dataField: 'id',
			text: t( 'standardText.id.label' ),
			hidden: true
	},
	{
			dataField: 'label',
			text: t( 'standardText.label.label' ),
		sortField: 'label_sort',
			sort: true
	},
	{
			dataField: 'theme',
			text: t( 'standardText.theme.label' ),
		sortField: 'theme_sort',
			sort: true
	},
	{
			dataField: 'flags',
			text: t( 'standardText.flags.label' ),
			headerAlign: 'center',
			align: 'center',
			headerStyle: ( column, colIndex ) => {
				return { width: '110px' };
			},
			formatter: ( value ) => {
				return Object.keys( symbols ).map( ( property, key ) => {
					if ( value[property] ) {
						return <FontAwesomeIcon key={ key } icon={ symbols[property] } className={ "me-1" }
						                        title={ t( `standardText.${ property }.label` ) }/>
					} else {
						return undefined;
					}
				} )
			}
	},
	{
			dataField: 'active',
			text: t( 'standardText.active.label' ),
			sort: true,
			headerAlign: 'center',
			align: 'center',
			headerStyle: ( column, colIndex ) => {
				return { width: '60px' };
			},
			formatter: bootstrapTableService.formatterBoolean,
		}];
	}, [t, symbols]);


	return (
		<Index
			path={match.path}
			domainName={'standardText'}
			columns={columns}
			toString={['theme']}
			formElements={<FormElements symbols={symbols}/>}
			sortField={'theme_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { StandardText };
