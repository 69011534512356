import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUmbrella, faIndustry, faUser} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";

function Partner({ match }) {
	const { t } = useTranslation();

	const columns = [{
			dataField: 'id',
			text: t( 'partner.id' ),
			hidden: true
		},
		{
			dataField: 'fullName',
			text: t( 'partner.name' ),
			sortField: 'fullName_sort',
			sort: true,
			formatter: (cell, row) => {
				let icon;
				switch(row.className) {
					case 'person':
						icon = <FontAwesomeIcon className={'text-secondary'} icon={faUser}/>;
						break;
					case 'company':
						icon = <FontAwesomeIcon icon={faIndustry}/>;
						break;
					default:
						throw new Error(`Unknown className ${row.className}`);
				}
				return (<>
					<span style={{display: 'inline-block', width:'25px'}}>
						{icon}
					</span>
						{row.fullName}
					<div>
						{ row.tags.map( tag => <Badge className={"me-1"} key={tag.id} bg="primary">{ tag.partnerTag.name }</Badge> ) }
					</div>
				</>);
			},
		}, {
			dataField: 'zipLabel',
			text: t( 'partner.zipLabel' ),
			sortField: 'zip.label',
			sort: true
		}, {
			dataField: 'streetFullName',
			text: t( 'partner.streetFullName' ),
		}, {
			dataField: 'consultantOrBroker',
			text: t( 'default.consultantOrBroker' ),
			formatter: ( cell, row ) => {
				return row.partnerConsultantsAndBrokers.map( cob => cob.label ).join(', ');
			}
		}
	];

	const getAddButtons = () => {
		return (
			<>
				<Link to={ `partner/person/create` } className="btn btn-sm btn-success mb-2">{ t( 'default.addWhat', { what: t( `person.label` ) } ) }</Link>&nbsp;
				<Link to={ `partner/company/create` } className="btn btn-sm btn-success mb-2">{ t( 'default.addWhat', { what: t( `company.label` ) } ) }</Link>
			</>
		)
	}

	const getEditUrlParams = useCallback( (row) => {
		return `/${row.className}`;
	}, [])

	const getAdditionalButtons = (row) => {
		return <Link to={ `policy?partnerId=${row.id}` } className="btn btn-sm btn-primary ms-1" target={"_blank"} rel={"noopener noreferrer"}>
			<FontAwesomeIcon icon={faUmbrella}/>
		</Link>
	}

	return (
		<Index
			path={match.path}
			domainName={ 'partner' }
			columns={ columns }
			toString={ ['name', 'surname'] }
			formElements={ <FormElements/> }
			sortField={'fullName'}
			allowEdits={true}
			allowDeletions={true}
			addButtons={getAddButtons()}
			getEditUrlParams={getEditUrlParams}
			additionalButtons={getAdditionalButtons}
		/>
	);
}

export { Partner };
