import React, {useMemo} from "react";
import {Damages} from "../damage/Damages";

function PreviewPolicyVersionDamages({policyVersion}) {
    const searchOptions = useMemo( () => ( {queryStrings: [{field: 'policyVersion.id', text: `${policyVersion.id}`}]} ), [policyVersion] );

    return (
        <>
            <div>
                {(policyVersion && policyVersion.id) ? <Damages searchOptions={searchOptions} hiddenFields={['policyNumber']}/> : null}
            </div>
        </>
    )
}

export default PreviewPolicyVersionDamages
