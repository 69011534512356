import React from "react";
import {useState} from "react";
import {FormControl} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function TableSearch(props){
	const {t} = useTranslation();
	const [input, setInput] = useState('');
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.onSearch( input );
		}
	};
	return (
		<FormControl className='w-25 float-end' size={"sm"} type='text' name='table-search' defaultValue={props.searchText} placeholder={t('default.search')} autoComplete="on" onChange={ (event) => setInput(event.target.value)} onKeyDown={handleKeyDown}/>
	);
}

export default TableSearch