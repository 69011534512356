import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react'
import {messageBoxService} from "../_services/messageBox.service";
import {Button, Container, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export const MessageBoxButtons = Object.freeze({
	YES:'YES',
	NO:'NO',
	OK:'OK',
});

function MessageBox() {
	const ref = useRef();
	const {t} = useTranslation();
	const [content, setContent] = useState('');
	const [title, setTitle] = useState('');
	const [buttons, setButtons] = useState([]);
	const [show, setShow] = useState(false);
	const [callback, setCallback] = useState(undefined);
	const [headerClassName, setHeaderClassName] = useState('');

	const display = useCallback( (content, buttons, title, headerClassName, callback ) => {
		setContent(content);
		setTitle(title)
		setButtons(buttons);
		setCallback( () => callback );
		setShow(true);
		setHeaderClassName(headerClassName);
	}, [setContent, setButtons, setShow, setCallback])

	const handleButton = useCallback((btn) => {
		setShow(false);
		callback(btn);
		setTitle('');
		setContent('');
		setButtons([]);
		setCallback(undefined);
	}, [callback])

	useEffect( () => {
		ref.current = {display: display}
		messageBoxService.register( ref );
	}, [display]);

	const buttonsElement = useMemo( () => {
		return (
			buttons.map( (button) => {
				let variant = ''
				switch(button) {
					case MessageBoxButtons.YES:
					case MessageBoxButtons.OK:
						variant = "primary";
						break;
					default:
						variant = "secondary"
				}
				return <Button variant={variant} key={button} className={"me-2"} onClick={() => handleButton(button)}>{t(`default.${button.toLowerCase()}`)}</Button>
			})
		)
	}, [buttons, handleButton, t])


	return (
		<Modal show={show} centered={true}>
			<Modal.Header className={headerClassName}>{ title }</Modal.Header>
			<Modal.Body>
				<Container>
					<Row className={"mb-4"}>
						{ content }
					</Row>
				</Container>
				{ buttonsElement }
			</Modal.Body>
		</Modal>
	)
}

export default MessageBox
