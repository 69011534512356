import pjson from './../package.json';

export const isProduction = process.env.NODE_ENV === 'production';

console.log(`Loading ${process.env.NODE_ENV} config...`);

export const SERVER_URL = isProduction ? '' : 'http://localhost:8080';

export const CLIENT_VERSION = pjson.version;
export const REACT_VERSION = pjson.dependencies.react;

export const DEFAULT_CONFIG = {
    uploadInfo : {
        maxFileSize: 1024*1024*10 //10 MB
    },
    versionInfo : {
        timestamp: null,
        tag: ''
    }
}

export const CONFIG_URL = "/api/application/getConfiguration";
