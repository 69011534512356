import { createSlice } from '@reduxjs/toolkit'

export const bankToCustomerNotificationSlice = createSlice({
	name: 'bankToCustomerNotification',
	initialState: {
		queryParams: undefined,
	},
	reducers: {
		setQueryParams: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			if ( action.payload === "" ) {
				state.queryParams = undefined;
			}
			else {
				state.queryParams = action.payload;
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const { setQueryParams } = bankToCustomerNotificationSlice.actions

export default bankToCustomerNotificationSlice.reducer
