import React, {useMemo} from 'react';
import {Settlements} from "../../settlement/Settlements";

function SettlementBatchReady( {settlementBatch, hiddenFields} ) {
	const searchOptions = useMemo( () => ( {queryStrings: [{field: 'settlementBatch.id', text: `${settlementBatch.id}`}]} ), [settlementBatch] );

	return (
		(settlementBatch && settlementBatch.id) ? <Settlements searchOptions={searchOptions} hiddenFields={hiddenFields}/> : null
	)
}

export default SettlementBatchReady
