export const dropdownService = {
	getOptionClassName,
}
function getOptionClassName(optionClassName, option) {
	if ( optionClassName && option ) {
		if ( typeof optionClassName === 'function' ) {
			return optionClassName(option);
		}
		else if ( typeof optionClassName === 'string' ) {
			return optionClassName;
		}
		else {
			console.warn(`Unsupported optionClassName of type ${typeof optionClassName}: ${optionClassName}`);
		}
	}
	else {
		return '';
	}
}