import React, {useRef} from 'react';
import {useTranslation} from "react-i18next";
import bootstrapTableService from "../_components/bootstrapTable.service";
import DomainTable from "../_components/DomainTable";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {invoiceService} from "../_services/invoice.service";
import textService from "../_services/text.service";

function Payments({namedCriteria, searchOptions}) {
	const { t } = useTranslation();
	const domainTableRef = useRef()

	const getPaymentType = (value, obj) => {
		return textService.objectToString( obj, obj.className, undefined, true )
	}

	const columns = [
		{
			dataField: 'id',
			hidden: true,
		},
		{
			dataField: 'amountDate',
			text: t( 'payment.amountDate' ),

			formatter:  bootstrapTableService.formatterDate,
			sortField: 'amountDate',
			sort: true
		},
		{
			dataField: 'paymentType',
			text: t( 'payment.paymentType' ),
			formatter:  getPaymentType,
		},
		{
			dataField: 'refNr',
			text: t('payment.refNr'),
			headerStyle: (column, colIndex) => {
				return { width: '16em' };
			},
			sortField: 'refNr',
			sort: true
		},
		{
			dataField: 'amount',
			text: t('payment.amount'),
			formatter: bootstrapTableService.formatterAmount,
			classes: (cell, row, rowIndex, colIndex) => 'text-end',
			sortField: 'amount',
			sort: true
		},
		{
			dataField: 'invoice.total',
			sort: true,
			text: t('bankPayment.invoice.total'),
			formatter: (cellContent, row) => {
				if ( row.invoice ) {
					return bootstrapTableService.formatterAmount( cellContent )
				}
			},
			classes: (cell, row, rowIndex, colIndex) => 'text-end'
		},
		{
			dataField: 'note',
			text: t('manualPayment.note'),
			sortField: 'note_sort',
			sort: true
		},
		// {
		// 	dataField: 'invoice.paymentState',
		// 	text: t('bankPayment.invoice.paymentState'),
		// 	formatter: (cellContent, row) => {
		// 		if ( row.invoice ) {
		// 			return <PaymentStateBadge paymentState={row.invoice.paymentState}/>
		// 		}
		// 	},
		// 	classes: (cell, row, rowIndex, colIndex) => 'text-end',
		// 	sortField: 'invoice.paymentState',
		// 	sort: true,
		// },
		// {
		// 	dataField: 'invoice.invoiceNr',
		// 	text: t('payment.invoice'),
		// 	sort: true,
		// 	classes: (cell, row, rowIndex, colIndex) => 'text-end',
		// 	formatter: (cellContent, row) => {
		// 		if ( row.invoice ) {
		// 			return <Link to={`/settlement/show/${row.invoice.id}`}>{cellContent}</Link>
		// 		}
		// 	},
		// 	// sortField: 'filename',
		// 	// sort: true
		// },
		{
			dataField: '',
			text: '',
			sort: false,
			classes: (cell, row, rowIndex, colIndex) => 'text-end',
			formatter: (cellContent, row) => {
				return (
					<>
						<Link className={`btn btn-sm btn-primary ms-1`} to={`${invoiceService.getPaymentLink(row.className, row.id)}`}>
							<FontAwesomeIcon icon={faEye}/>
						</Link>
					</>
				)
			},
			headerStyle: (column, colIndex) => {
				return { width: '10%' };
			},
		}
	];

	return (
		<DomainTable
			ref={domainTableRef}
			domainName={'payment'}
			columns={columns}
			namedCriteria={namedCriteria}
			searchOptions={searchOptions}
			// rowClasses={rowClasses}
			// sortField={'billingFrom'}
			// sortOrder={'desc'}
		/>
	);
}

export { Payments };
