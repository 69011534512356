import React, {useMemo} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import {policyService} from "../_services/policy.service";
import {useTranslation} from "react-i18next";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";

function CommissionsExport() {
	const {t} = useTranslation();
	const useFormObject = useForm();

	const onSubmit = (formData) => {
		policyService.exportCommissions(formData.dateFrom, formData.dateTo, formData.partners)
	};

	const hsNamedCriteriaPartnerConsultantScopeOrBroker = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "namedCriteriaPartnerConsultantScopeOrBroker",
					params: {}
				}
		}
	}, [])

	return (
		<>
			<h2>{t('reports.commissionsExport.title')}</h2>

			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Card>
						<Card.Header>
							{t('default.filter')}
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="col-md-6" controlId="dateFrom">
									<Form.Label>{t('reports.commissionsExport.dateFrom.label')}</Form.Label>
									<FormControl
										name={"dateFrom"}
										type={"date"}
									/>
								</Form.Group>

								<Form.Group className="col-md-6" controlId="dateTo">
									<Form.Label>{t('reports.commissionsExport.dateTo.label')}</Form.Label>
									<FormControl
										name={"dateTo"}
										type={"date"}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group as={Col} md="12" controlId="groupPartner">
									<Form.Label>{t('reports.commissionsExport.partners')}</Form.Label>
									<RestAsyncSelectControl
										domainName={"partner"}
										sort={"fullName_sort"}
										name={'partners'}
										searchOptions={hsNamedCriteriaPartnerConsultantScopeOrBroker}
										isMulti={true}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Row className={"mt-3"}>
						<Col md={2}>
							<Button type={"submit"}>{ t('default.export') }</Button>
						</Col>
					</Row>
				</Form>
			</FormProvider>
		</>
	)
}

export default CommissionsExport