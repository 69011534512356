import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";
import CreateProfitParticipationSettlementBatch from "./CreateProfitParticipationSettlementBatch";
import PreviewProfitParticipationSettlementBatch from "./PreviewProfitParticipationSettlementBatch";

function ProfitParticipationSettlementBatch({match}) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('default.id'),
		hidden: true
	}, {
		dataField: 'year',
		text: t('profitParticipationSettlementBatch.year'),
		sort: true,
		sortType: 'Integer',
	}, {
		dataField: 'createdOn',
		text: t('settlementBatch.createdOn.label'),
		sort: true,
		formatter: (cellContent, row) => bootstrapTableService.formatterDateTime(cellContent)
	}, {
		dataField: 'createdBy',
		text: t( 'settlementBatch.createdBy.label' ),
		sort: true
	}, {
		dataField: 'settlementsCount',
		text: t( 'settlementBatch.settlementsCount.label'),
		sort: true
	},
	];

	return (
		<Index
			path={match.path}
			domainName={'profitParticipationSettlementBatch'}
			columns={columns}
			toString={['name']}
			createElement={<CreateProfitParticipationSettlementBatch/>}
			showElement={<PreviewProfitParticipationSettlementBatch/>}
			sortField={'year'}
			sortOrder={'desc'}
			allowEdits={false}
			allowDeletions={false}
			allowShow={true}
		/>
	);
}

export default ProfitParticipationSettlementBatch;
