import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import restService from "../_services/rest.service";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import UnderConstruction from "../_components/UnderConstruction";

function SplitFileByModal({settlementBatch, show, onShowChanged}) {
	const [splitFileBy, setSplitFileBy] = useState(settlementBatch.splitFileBy || []);
	const {t} = useTranslation()

	useEffect( () => {
		setSplitFileBy(settlementBatch.splitFileBy || []);
	}, [settlementBatch.splitFileBy]);

	// const columns = [
	// 	{id: "partner", label: t('splitFileByModal.broker') , input: {tag: "select", values: [], required: true, nullable:true }},
	// ]

	const save = () => {
		restService.updateDomainInstance('settlementBatch', settlementBatch.id, {version: settlementBatch.version, splitFileBy: splitFileBy.filter( (partner) => !partner.deleted )})
			.then( () => {
				onShowChanged(false);
			})
	}

	return (
		<Modal show={show}>
			<Modal.Body>
				<Alert variant={"info"}>{t('splitFileByModal.tooltip')}</Alert>
				<UnderConstruction />

				{/*vdusa 19.12.2022:*/}
				{/*The functionality here was made for brokers. But later it was changed to all partners.*/}
				{/*The problem is, that our EditableTable doesn't support async select, thus I couldn't implement*/}
				{/*it quickly. */}
				{/*To do: 1) make EditableTable able to use async select*/}
				{/*       2) use this new element for partner column*/}

				{/*<EditableTable*/}
				{/*	columns={columns}*/}
				{/*	data={splitFileBy}*/}
				{/*	onChange={(value) => setSplitFileBy(value)}*/}
				{/*/>*/}
			</Modal.Body>
			<Modal.Footer>
				<Button variant={"primary"} onClick={ () => save() }>{t('default.save')}</Button>
				<Button variant={"secondary"} onClick={ () => onShowChanged(false) }>{t('default.cancel')}</Button>
			</Modal.Footer>
		</Modal>
	)
}

SplitFileByModal.propTypes = {
	splitFileBy: PropTypes.object,
};

export default SplitFileByModal