import headers from "../security/headers";
import {SERVER_URL} from "../config";
import {originalWarn} from "../index";

export * from './alert.service';

const appService = {
	isMobile,
	compareObjectsByValue,
	sortArrayOfObjects,
	nullSafeGet,
	fixFileName,
	groupBy,
	capitalize,
	serverLogger,
	intersect,
}

function groupBy(array,func) {
	return array.reduce((a,b,i)=>((a[func(b,i,array)]||=[]).push(b),a),{});
}

function intersect(array1, array2) {
	if ( array1 && array2 ) {
		return array1.filter(value => array2.includes(value));
	}
	else {
		return []
	}
}

function capitalize(word) {
	return word.charAt(0).toUpperCase()	+ word.slice(1);
}

function isMobile()
{
	return "ontouchstart" in window;
}

function fixFileName(fileName) {
	const umlautMap = {
		'\u00dc': 'UE',
		'\u00c4': 'AE',
		'\u00d6': 'OE',
		'\u00fc': 'ue',
		'\u00e4': 'ae',
		'\u00f6': 'oe',
		'\u00df': 'ss',
	}

	function replaceUmlaute(str) {
		return str
			.replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
				const big = umlautMap[a.slice(0, 1)];
				return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
			})
			.replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
				(a) => umlautMap[a]
			);
	}

	const deAccentedFileName = replaceUmlaute(fileName).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	return deAccentedFileName.replace(/([^a-z0-9\-\s.]+)/gi, '');
}

function compareObjectsByValue(obj1, obj2) {
	if ( !obj1 || !obj2 ) {
		if ( !obj1 && !obj2 ) {
			return true
		}
		else {
			return false
		}
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if ( keys1.length !== keys2.length ) {
		return false
	}

	const existsKeyWhereObjectsDiffer = keys1.some( (key) => {
		return !( obj2.hasOwnProperty( key ) && obj1[key] === obj2[key] )
	});

	return !existsKeyWhereObjectsDiffer;
}

function sortArrayOfObjects(array, crit) {
	const cmp = (a,b) => {
		const getValue = (obj, prop) => {
			const props = prop.split('.');
			let val = obj;
			props.forEach( (p) => {
				val = val[p];
			})
			return val;
		}

		const compare = (a,b,order) => {
			console.assert(order === 'asc' || order === 'desc', `Incorrect order criteria '${order}'. Only 'asc' or 'desc' allowed.`)
			const revertOrder = (order==='desc'?-1:1);
			if (a < b) {
				return -1*revertOrder;
			}
			if (a > b) {
				return 1*revertOrder;
			}
			return 0;
		}

		let result = 0;
		for( const critIdx in crit ) {
			const c = crit[critIdx].split(' ');
			console.assert( c.length === 1 || c.length === 2, `Incorrect sort criteria '${crit[critIdx]}'` );
			const cProp = c[0];
			const cOrder = c.length === 2 ? c[1] : 'asc';
			result = compare(getValue(a, cProp), getValue(b, cProp), cOrder);
			if ( result ) {
				break;
			}
		};
		return result
	}

	array.sort(cmp);
}

function nullSafeGet(object, property) {
	const props = property.split('.');
	let result = object;
	let i = 0;
	while(result && i < props.length) {
		result = result[props[i++]];
	}
	return result;
}

function serverLogger(level, message) {
	const h = headers();
	const controller = new AbortController();

	if ( !['error', 'warn', 'info'].includes(level) ) {
		originalWarn(`serverLogger() called with unknown level '${level}'.`)
	}

	const body = {
		level: level,
		message: message,
	}
	fetch( `${ SERVER_URL }/api/logger`, {
		method: 'POST',
		headers: h,
		signal: controller.signal,
		body: JSON.stringify( body )
	} )
		.then()
		.catch( error => {
			console.log(`serverLogger(): ${error}`)
		} );

}
export default appService
