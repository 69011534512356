import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";
import ShowAccountingTafFile from "./ShowAccountingTafFile";
import bootstrapTableService from "../_components/bootstrapTable.service";

function AccountingTafFile({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('accountingTafFile.id.label'),
		hidden: true
	}, {
		dataField: 'createdOn',
		text: t('accountingTafFile.createdOn.label'),
		formatter: (cellContent, row) => bootstrapTableService.formatterDateTime(cellContent),
		sort: true
	}, {
		dataField: 'createdBy',
		text: t('accountingTafFile.createdBy.label'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'accountingTafFile'}
			columns={columns}
			toString={ (object) => t('accountingTafFile.label') }
			formElements={<FormElements/>}
			sortField={'createdOn'}
			sortOrder={'desc'}
			allowShow={true}
			showElement={<ShowAccountingTafFile/>}
		/>
	);
}


export { AccountingTafFile };
