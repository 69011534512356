import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import appService from "../_services";
import ShowField from "../form/ShowField";
import restService from "../_services/rest.service";
import {useTranslation} from "react-i18next";
import PaymentStateBadge from "../payment/PaymentStateBadge";
import FormattedText from "../_components/FormatedText";

function ShowBankPayment(props ) {
    const [bankPayment] = restService.useDomainInstance( 'bankPayment', props.id, false );
    const { t } = useTranslation();

    return (
        <Card className='mb-2'>
            <Card.Header><h4>{t('bankPayment.label') + ' ' + appService.nullSafeGet( bankPayment, 'id')}</h4></Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <ShowField label={'payment.amountDate'} object={bankPayment} property={'amountDate'} type={'date'}/>
                    </Col>
                    <Col>
                        <ShowField label={'payment.refNr'} object={bankPayment} property={'refNr'} type={'text'}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'payment.amount'} object={bankPayment} property={'amount'} type={'text'}/>
                    </Col>
                    <Col>
                        <ShowField label={'bankPayment.invoice.total'} object={bankPayment} property={'pairedInvoice.total'} type={'text'}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'bankPayment.debtor'} object={bankPayment} property={'debtor'}>
                            <FormattedText text={bankPayment.debtor}/>
                        </ShowField>
                    </Col>
                    <Col>
                        <ShowField label={'bankPayment.ultimateDebtor'} object={bankPayment} property={'ultimateDebtor'}>
                            <FormattedText text={bankPayment.ultimateDebtor}/>
                        </ShowField>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'bankPayment.debtorIBAN'} object={bankPayment} property={'debtorIBAN'} type={'text'}/>
                    </Col>
                    <Col>
                        <ShowField label={'bankToCustomerNotification.label'} object={bankPayment} property={'bankToCustomerNotification.id'} type={'text'}
                                   link={bankPayment.bankToCustomerNotification && {to: `/bankToCustomerNotification/show/${bankPayment.bankToCustomerNotification.id}`}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'payment.invoice'} object={bankPayment} property={'pairedInvoice.invoiceNr'} type={'text'} link={bankPayment.pairedInvoice && {to: `/settlement/show/${bankPayment.pairedInvoice.id}`}}/>
                    </Col>
                    <Col>
                        <ShowField label={'bankPayment.invoice.paymentState'} object={bankPayment} property={'pairedInvoice.paymentState'} type={'text'}>
                            {bankPayment.pairedInvoice && <PaymentStateBadge paymentState={bankPayment.pairedInvoice.paymentState}/>}
                        </ShowField>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ShowBankPayment
