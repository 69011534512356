import React, {useMemo} from 'react';
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import appService from "../_services";
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";
import {DamageShares} from "../damageShare/DamageShares";

function ShowDamageDetail(props) {
    const [damageDetail] = restService.useDomainInstance( 'damageDetail', props.id, false );
    const searchOptions = useMemo( () => {
        return {
            queryStrings: [
                {
                    field: "damageDetail.id",
                    text: `${props.id}`
                }
            ]
        }
    }, [props.id])
    const { t } = useTranslation();

    const showBaseData = () => {
        return (
            <Card className='mb-2'>
                <Card.Header><h4>{appService.nullSafeGet( damageDetail, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <ShowField label={'damageDetail.productAndBuilding'} object={damageDetail} property={'productAndBuilding'} type={'text'} />
                        </Col>
                        <Col>
                            <ShowField label={'damageDetail.reserveAmount'} object={damageDetail} property={'reserveAmount'} type={'text'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damageDetail.participationType'} object={damageDetail} property={'participationType.label'} type={'text'} />
                        </Col>
                        <Col>
                            <ShowField label={'damageDetail.participationAmount'} object={damageDetail} property={'participationAmount'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damageDetail.description'} object={damageDetail} property={'description'} type={'text'}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            {showBaseData()}
            <Tabs defaultActiveKey="participations" id="damageDetails-tabs">
                <Tab eventKey="participations" title={t('damageDetail.tab.damageShare')}>
                    <div className={"border-left border-right border-bottom rounded-bottom p-3"}>
                        <DamageShares searchOptions={searchOptions} />
                    </div>
                </Tab>
            </Tabs>
        </>
    )
}

export default ShowDamageDetail
