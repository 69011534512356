import React, {useEffect, useMemo, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function PolicyVersionSettlementBatchFilter( {settlementBatch, setData} ) {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const _dateFrom = useMemo(() => (settlementBatch && settlementBatch.dateFrom) || '', [settlementBatch]);
    const _dateTo = useMemo(() => (settlementBatch && settlementBatch.dateTo) || '', [settlementBatch]);

    useEffect( () => {
        setDateFrom(_dateFrom)
        setDateTo(_dateTo)
    }, [_dateFrom, _dateTo]);

    const splitFileBy = useMemo( () => settlementBatch && settlementBatch.splitFileBy, [settlementBatch]);

    useEffect( () => {
        setData( {
            dateFrom: dateFrom,
            dateTo: dateTo,
            splitFileBy: splitFileBy
        });
    }, [dateFrom, dateTo, splitFileBy, setData, settlementBatch]);

	return (
        <>
            <p>{ t( 'policyVersionSettlementBatch.fromToQuestion.message' ) }</p>
            <Row>
                <Form.Group as={ Col } md="6" controlId="formGroupDateFrom">
                    <Form.Label>{ t( 'policyVersionSettlementBatch.fromToQuestion.dateFrom' ) }</Form.Label>
                    <Form.Control
                        name={ "dateFrom" }
                        type={ "date" }
                        value={ dateFrom }
                        onChange={ ( event ) => setDateFrom( event.target.value ) }
                        required={ true }
                    />
                </Form.Group>
                <Form.Group as={ Col } md="6" controlId="formGroupDateTo">
                    <Form.Label>{ t( 'policyVersionSettlementBatch.fromToQuestion.dateTo' ) }</Form.Label>
                    <Form.Control
                        name={ "dateTo" }
                        type={ "date" }
                        value={ dateTo }
                        onChange={ ( event ) => setDateTo( event.target.value ) }
                        required={ true }
                    />
                </Form.Group>
            </Row>
        </>
	)
}

export default PolicyVersionSettlementBatchFilter