import React from 'react';
import PropTypes from "prop-types";
import {Settlements} from "../settlement/Settlements";

function DamageSettlements( {namedCriteria, searchOptions, hiddenFields}) {
	return (
		<>
			<Settlements searchOptions={searchOptions}/>
		</>
	)
}


DamageSettlements.defaultProps = {
	hiddenFields: []
};

DamageSettlements.propTypes = {
	namedCriteria: PropTypes.object,
	searchOptions: PropTypes.object,
	hiddenFields: PropTypes.array
};

export { DamageSettlements };
