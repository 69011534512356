import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import textService from "../_services/text.service";
import moment from "moment";
import restService from "../_services/rest.service";
import {Link, withRouter} from "react-router-dom";
import CreateSettlementBatch from "../_components/_settlementBatch/CreateSettlementBatch";
import PolicyVersionSettlementBatchFilter from "./PolicyVersionSettlementBatchFilter";
import SimpleTable from "../_components/SimpleTable";

function CreatePolicyVersionSettlementBatch( { routeParams } ) {
	const { t } = useTranslation();
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [policyVersionSettlementBatch] = restService.useDomainInstance('policyVersionSettlementBatch', id, true)

	const columns = useMemo( () => {
			return [
				{
					key: 'policyNumber',
					label: t( 'policyVersion.policyNumber.label' ),
					formatter: ( duePolicyVersion ) => <Link to={ `/policy/show/${ duePolicyVersion.id }` }
					                                         target={ "_blank" }
					                                         rel={ "noopener noreferrer" }>{ duePolicyVersion.policyNumber }</Link>
				},
				{
					key: 'policyHolder',
					label: t( 'policyHolder.label' ),
					formatter: ( duePolicyVersion ) => duePolicyVersion.policyHolder
				},
				{
					key: 'nextInvoicePeriodFrom',
					label: t( 'policyVersion.nextInvoicePeriodFrom.label' ),
					formatter: ( duePolicyVersion ) => textService.formatDateTime( moment( duePolicyVersion.nextInvoicePeriodFrom ), { dateStyle: 'medium' } )
				},
				{
					key: 'nextInvoicePeriodTo',
					label: t( 'policyVersion.nextInvoicePeriodTo.label' ),
					formatter: ( duePolicyVersion ) => textService.formatDateTime( moment( duePolicyVersion.nextInvoicePeriodTo ), { dateStyle: 'medium' } )
				}
			]
		}, [t])


	const getTable = useCallback( (rows) => {
		return {
			element: <SimpleTable columns={columns} rows={rows}/>,
			size: rows.length,
		}
	}, [columns])

	return (
		<CreateSettlementBatch
			settlementBatch={policyVersionSettlementBatch}
			filter={<PolicyVersionSettlementBatchFilter />}
			getInvoicesTable={getTable}
		/>
	);
}

export default withRouter(CreatePolicyVersionSettlementBatch);
