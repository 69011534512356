import React, {useEffect, useMemo, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function OtherLeadershipDiscountSettlementBatchFilter( {settlementBatch, setData} ) {
    const { t } = useTranslation();
    const [year, setYear] = useState('');
    const [percent, setPercent] = useState('');

    const _year = useMemo(() => (settlementBatch && settlementBatch.year) || '', [settlementBatch]);
    const _percent = useMemo(() => (settlementBatch && settlementBatch.percent) || '', [settlementBatch]);

    useEffect( () => {
        setYear(_year);
        setPercent(_percent);
    }, [_year, setYear, _percent, setPercent]);

    useEffect( () => {
        setData( {
            year: year,
            percent: percent,
        });
    }, [setData, year, percent]);

	return (
        <>
            <p>{ t( 'otherLeadershipDiscountSettlementBatchFilter.message' ) }</p>
            <Row>
                <Form.Group as={ Col } md="6" controlId="yearGroup">
                    <Form.Control
                        name={ "year" }
                        type={ "number" }
                        value={ year }
                        onChange={ ( event ) => setYear( event.target.value ) }
                        required={ true }
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className={'mt-3'} as={ Col } md="6" controlId="percentGroup">
                    <Form.Label>{t("otherLeadershipDiscountSettlementBatch.percent.label")}</Form.Label>
                    <Form.Control
                        name={ "percent" }
                        type={ "number" }
                        value={ percent }
                        onChange={ ( event ) => setPercent( event.target.value ) }
                        required={ true }
                        validationMessages={{
                            range: t('default.validationMessage.range', {from:"0", to:"100"}),
                        }}
                        rules={{
                            validate: {
                                range: (v) => 0<=v && v<=100
                            }
                        }}
                    />
                </Form.Group>
            </Row>
        </>
	)
}

export default OtherLeadershipDiscountSettlementBatchFilter