import React, {useEffect} from 'react'
import {ProgressBar} from "react-bootstrap";
import {messagePanelService} from "../../_services/message.panel.service";

function ProgressUserMessage(params) {

	useEffect( () => {
		if ( params.progress === -1 ) { //suicide
			console.log(`suicide of ${params.uuid}`)
			messagePanelService.update( undefined, [params.uuid] );
		}
	}, [params.progress] )

	return (
		<>
			<p className={"centered"}>{ params.message }</p>
			<ProgressBar max={ 100 }
			             now={ params.progress }
			             label={ `${params.progress} %` }/>
		</>
	)
}

export default ProgressUserMessage