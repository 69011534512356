import React, {useCallback, useRef, useState} from 'react';

function EditControl( { policyVersionId, fieldName, onSave, editControl } ) {
    const [lastSavedValue, setLastSavedValue] = useState(undefined)

    const isDirty = useCallback( (value) => {
        return ( lastSavedValue !== value );
    }, [ lastSavedValue ]);

    const save = useCallback( (value) => {
        if ( isDirty(value) ) {
            onSave(policyVersionId, {[fieldName]: value})
                .then( () => {
                    setLastSavedValue(value);
                })
        }
    }, [setLastSavedValue, isDirty, fieldName, onSave, policyVersionId])

    const nodeRef = useRef(null);

	return React.cloneElement(editControl, {controlRef:nodeRef, save: save})
}

export default EditControl