import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import {Link} from "react-router-dom";
import textService from "../_services/text.service";
import CreateSettlementBatch from "../_components/_settlementBatch/CreateSettlementBatch";
import CommissionSettlementBatchFilter from "./CommissionSettlementBatchFilter";
import SimpleTable from "../_components/SimpleTable";
import appService from "../_services";
import {Accordion} from "react-bootstrap";

function CreateCommissionSettlementBatch( { routeParams } ) {
	const { t } = useTranslation();
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [commissionSettlementBatch] = restService.useDomainInstance('commissionSettlementBatch', id, true)

	const columns = useMemo( () => [
		{ key: 'policyNumber', label: t('policyVersion.policyNumber.label'), formatter: (commission) => <Link to={ `/policy/show/${commission.policyVersionId}` } target={"_blank"} rel={"noopener noreferrer"}>{ commission.policyNumber }</Link> },
		{ key: 'policyHolder', label: t('policyHolder.label'), formatter: (commission) => commission.policyHolder},
		{ key: 'amount', label: t('commission.amount'), formatter: (commission) => textService.formatNumber(commission.amount) }
	], [t]);

	const getPartnerTable = useCallback( (rows) => {
		const partnerId = rows[0].partnerId
		const partner = rows[0].partner
		return <Accordion.Item eventKey={partnerId}>
				<Accordion.Header>
					<div className={"w-100"}>
						<div className={"float-start"}>{partner}</div>
						<div className={"float-end"}>CHF {textService.formatNumber(rows.reduce((accumulator, row) => accumulator + row.amount, 0))}</div>
					</div>
				</Accordion.Header>
				<Accordion.Body>
					<SimpleTable key={partnerId} columns={columns} rows={rows}/>
				</Accordion.Body>
			</Accordion.Item>
	}, [columns])

	const getTable = useCallback( (rows) => {
		const groupedRowsByPartnerId = appService.groupBy( rows, (row) => row.partnerId )
		const accordionItems = Object.keys(groupedRowsByPartnerId).sort( (a,b) => {
			const partnerA = groupedRowsByPartnerId[a][0].partner;
			const partnerB = groupedRowsByPartnerId[b][0].partner;

			return (partnerA < partnerB) ? -1 : ((partnerB < partnerA) ? 1 : 0)
		}).map( (partnerId) => {
			return getPartnerTable(groupedRowsByPartnerId[partnerId])
		})

		return {
			element: <Accordion> { accordionItems } </Accordion>,
			size: Object.keys(groupedRowsByPartnerId).length,
		}
	}, [getPartnerTable])

	return (
		<CreateSettlementBatch
			settlementBatch={commissionSettlementBatch}
			filter={<CommissionSettlementBatchFilter />}
			getInvoicesTable={getTable}
		/>
	);
}

export default CreateCommissionSettlementBatch