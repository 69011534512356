import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function Country({match}) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('country.id'),
		hidden: true
	}, {
		dataField: 'code',
		text: t('country.code'),
		sort: true
	}, {
		dataField: 'name',
		text: t('country.name'),
		sortField: 'name_sort',
		sort: true
	},
	];

	return (
		<Index
			path={match.path}
			domainName={'country'}
			columns={columns}
			toString={['name']}
			formElements={<FormElements/>}
			sortField={'code'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { Country };
