

function damageBaseDataReducer (state, action) {
    switch (action.type) {
        case 'BASE_DATA_CHANGE':
            return { ...state, ...action.data}
        default:
            return state;
    }
}

function damageDetailsReducer (state, action) {
    switch (action.type) {
        case 'DETAILS_CHANGE':
            return JSON.parse(JSON.stringify(action.data));
        default:
            return state;
    }
}

function damageAttachmentsReducer (state, action) {
    switch (action.type) {
        case 'ATTACHMENTS_CHANGE':
            return { ...state, ...action.data.fileChanges };
        default:
            return state;
    }
}

export default function damageReducer (state, action) {
    return {
        baseData: damageBaseDataReducer( state.baseData, action ),
        details: damageDetailsReducer( state.details, action ),
        fileChanges: damageAttachmentsReducer( state.fileChanges, action ),
    }
}
