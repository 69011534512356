import React from 'react'
import { Partner } from './partner/Index';
import { Country } from './country/Index';
import { County } from "./county/Index";
import { Zip } from "./zip/Index";
import { Avb } from "./avb/Index";
import { AvbDescription } from "./avbDescription/Index";
import { Salutation } from './salutation/Index';
import {Alert, TopMenuBar} from './_components';
import {Route, Switch, Redirect} from 'react-router-dom';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {ConfiguratorEdit} from "./configurator/Editor";
import Home from "./Home";
import {Setting} from "./setting/Index";
import {BuildingType} from "./buildingType/Index";
import {BuildingClass} from "./buildingClass/Index";
import {ConstructionType} from "./constructionType/Index";
import {FireExtinguisher} from "./fireExtinguischer/Index";
import {Building} from "./building/Index";
import {RoofType} from "./roofType/Index";
import {HeatingType} from "./heatingType/Index";
import {Policy} from "./policy/Index";
import {IndividualText} from "./individualText/Index";
import {StandardText} from "./standardText/Index";
import {ProductType} from "./productType/Index";
import {Product} from "./product/Index";
import {Tariff} from "./tariff/Index";
import {Branch} from "./branch/Index";
import {PolicyCancellationReason} from "./policyCancellationReason/Index";
import {PartnerConsultantScope} from "./partnerConsultantScope/Index";
import PolicyVersionSettlementBatch from "./policyVersionSettlementBatch/Index";
import CommissionSettlementBatch from "./commissionSettlementBatch/Index"
import OtherLeadershipDiscountSettlementBatch from "./otherLeadershipDiscountSettlementBatch/Index"
import ParticipationSettlementBatch from "./participationSettlementBatch/Index"
import ProfitParticipationSettlementBatch from "./profitParticipationSettlementBatch/Index"
import Footer from "./Footer";
import {Trans, useTranslation} from "react-i18next";
import {securityService} from "./_services/security.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AccountingTafFile} from "./accountingTafFile/Index";
import OtherLeadershipCheck from "./otherLeadershipCheck/Index"
import PoliciesExport from "./reports/PoliciesExport";
import InventoryExport from "./reports/InventoryExport";
import DamagePayments from "./reports/DamagePayments";
import InterimBillingDamages from "./reports/InterimBillingDamages"
import InvoicedAppversPart from "./reports/InvoicedAppversPart"
import BillingForPartners from "./reports/BillingForPartners"
import {ReportMessage} from "./reportMessage/Index"
import PoliciesVersionsWithIncompleteRV from "./service/PoliciesVersionsWithIncompleteRV"
import CommissionsExport from "./reports/CommissionsExport";

import {
	faAddressCard,
	faBuilding,
	faDiagramProject,
	faArrowUpAZ,
	faFolderClosed,
	faFolderOpen,
	faSliders,
	faUmbrella,
	faPeopleGroup,
	faRectangleList,
	faScrewdriverWrench,
	faFileInvoiceDollar,
	faUsers,
	faHouseChimneyCrack,
	faBurst,
	faMoneyBillWave, faBuildingColumns, faFileInvoice,
	faFileLines, faBook, faFileExport, faHandHoldingDollar, faListCheck, faChartPie, faMoneyBillTransfer, faLanguage,
	faSuitcaseMedical, faRotate
} from "@fortawesome/free-solid-svg-icons"
import MenuItems from "./_components/MenuItems";
import ConfiguratorNodeOrderList from "./configuratorNodeOrder/ConfiguratorNodeOrderList";
import PreviewSettlement from "./settlement/PreviewSettlement";
import {User} from "./user/Index";
import {Damage} from "./damage/Index";
import {DamageType} from "./damageType/Index";
import {DamageEvent} from "./damageEvent/Index";
import {ElementalDamageCause} from "./elementalDamageCause/Index";
import ShowDamageDetail from "./damageDetail/ShowDamageDetail";
import Changelog from "./youtrack/Changelog";
import {BankToCustomerNotification} from "./bankToCustomerNotification/Index";
import {Settlements} from "./settlement/Settlements";
import ShowBankPayment from "./bankPayment/ShowBankPayment";
import Reports from "./reports/Reports";
import DebtorsList from "./reports/DebtorsList";
import DamageRendement from "./reports/DamageRendement";
import FeePerYear from "./reports/FeePerYear";
import ExpiringPolicyVersions from "./reports/ExpiringPolicyVersions";
import ShowDamagePaymentInfo from "./damagePaymentInfo/ShowDamagePaymentInfo";
import settingService from "./_services/setting.service";
import ShowDamagePaymentInfoDetail from "./damagePaymentInfoDetail/ShowDamagePaymentInfoDetail";
import CreateEditDamagePaymentInfo from "./damagePaymentInfo/CreateEditDamagePaymentInfo";
import CreateEditCommission from "./commission/CreateEditCommission";
import {faPercent} from "@fortawesome/free-solid-svg-icons/faPercent";
import ProfitParticipationSummary from "./reports/ProfitParticipationSummary"
import {ManualPayment} from "./manualPayment/Index";
import ShowSimilarToBankPayment from "./bankPayment/ShowSimilarToBankPayment";
import CreateManualPayment from "./manualPayment/CreateManualPayment";
import {InnerPredicateEnum} from "./_enum/enum";
import moment from "moment/moment";
import ReindexAll from "./service/ReindexAll";

function AppRouter() {
	const {t} = useTranslation();
	const {_leftMenu} = settingService.useSetting(['_leftMenu']);

	const logoutHandler = () => {
		securityService.logOut();
	};

	function SettlementsComponent( {routerProps} ) {
		let searchOptions = null
		const searchParams = new URLSearchParams(routerProps.location.search)
		if ( searchParams ) {
			let queryStrings = []
			for (const p of searchParams) {
				if ( p[0].charAt(0) === '_' ) {
					routerProps[p[0].substring(1)] = p[1]
				}
				else {
					let queryString = { field: p[0] }
					let isPredicate = false
					Object.values( InnerPredicateEnum ).forEach( predicate => {
						const match = p[1].match( new RegExp( `${predicate}\\((.*)\\)` ) )
						if ( match && match.length === 2 ) {
							queryString['text'] = match[1]
							queryString['innerPredicate'] = predicate
							isPredicate = true
						}
					} )
					if ( !isPredicate ) {
						queryString['text'] = p[1]
					}
					queryStrings.push( queryString )
				}
			}
			searchOptions = {
				queryStrings: queryStrings, targetGroup: "AllSettlements"
			}
		}

		return <Settlements {...routerProps} hiddenFields={['paymentState']} searchOptions={searchOptions}/>
	}

	//!!!!!!!!!!!IMPORTANT!!!!!!!!!!!
	//Always add "path" value to Grails application.groovy -> appvers.reactRoutes config variable
	//direct url links will not work properly otherwise
	const routes = [
		{
			path: "/",
			exact: true,
			component: (routerProps) => <Home {...routerProps} />,
		},
		{
			path: "/commission/edit/:id?",
			exact: true,
			component: (routerProps) => {
				return <CreateEditCommission id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/commission/create/:policyVersionId?",
			exact: true,
			component: (routerProps) => {
				return <CreateEditCommission policyVersionId={ parseInt(routerProps.match.params.policyVersionId) } />
			},
		},
		{
			path: "/settlement/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <PreviewSettlement id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/bankPayment/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowBankPayment id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/bankPayment/showSimilar/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowSimilarToBankPayment id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damageDetail/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowDamageDetail id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damagePaymentInfo/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowDamagePaymentInfo id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damagePaymentInfoDetail/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowDamagePaymentInfoDetail id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damagePaymentInfo/edit/:id?",
			exact: true,
			component: (routerProps) => {
				return <CreateEditDamagePaymentInfo id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damage/:damageId/damagePaymentInfo/create",
			exact: true,
			component: (routerProps) => {
				return <CreateEditDamagePaymentInfo damageId={ parseInt(routerProps.match.params.damageId) } />
			},
		},
		{
			path: "/partner/:domainName?",
			link: "/partner",
			sidebar: () => t("menuBar.partners.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faPeopleGroup}/>,
			component: (routerProps) => <Partner {...routerProps} />
		},
		{
			path: "/policy",
			sidebar: () => t("menuBar.policies.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faUmbrella}/>,
			component: (routerProps) => <Policy {...routerProps} />
		},
		{
			path: "/damage",
			sidebar: () => t("menuBar.damages.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faHouseChimneyCrack}/>,
			component: (routerProps) => <Damage {...routerProps} />
		},
		{
			sidebar: () => t("menuBar.billing.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faMoneyBillWave}/>,
			routes: [
				{
					path: "/invoicePolicyVersion",
					sidebar: () => t("menuBar.invoices.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faFileInvoice}/>,
					component: (routerProps) => <SettlementsComponent routerProps={routerProps}/>,
					abbrev: () => t("menuBar.invoices.abbrev.label"),
				},
				{
					path: `/claim`,
					component: (routerProps) => <SettlementsComponent routerProps={routerProps}/>,
				},
				{
					path: `/claim?_showPrint=true&paymentState=${t('appvers.enums.PaymentState.UNPAID')}&dueDate=LESS_THAN(${moment().format("D.M.yyyy")})`,
					sidebar: () => t("menuBar.claims.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faFileInvoice}/>,
					abbrev: () => t("menuBar.claims.abbrev.label"),
				},
				{
					sidebar: () => t( "menuBar.settlementBatch.label" ),
					icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faMoneyBillWave }/>,
					routes: [
						{
							path: "/policyVersionSettlementBatch",
							sidebar: () => t( "menuBar.policyVersionSettlementBatch.label" ),
							icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faFileInvoiceDollar }/>,
							component: ( routerProps ) => <PolicyVersionSettlementBatch { ...routerProps }/>,
							abbrev: () => t( "menuBar.policyVersionSettlementBatch.abbrev.label" ),
						},
						{
							path: "/commissionSettlementBatch",
							sidebar: () => t("menuBar.commissionSettlementBatch.label"),
							icon: (classes) => <FontAwesomeIcon className={classes} icon={faHandHoldingDollar}/>,
							component: (routerProps) => <CommissionSettlementBatch {...routerProps}/>,
							abbrev: () => t("menuBar.commissionSettlementBatch.abbrev.label"),
						},
						{
							path: "/participationSettlementBatch",
							sidebar: () => t("menuBar.participationSettlementBatch.label"),
							icon: (classes) => <FontAwesomeIcon className={classes} icon={faChartPie}/>,
							component: (routerProps) => <ParticipationSettlementBatch {...routerProps}/>,
							abbrev: () => t("menuBar.participationSettlementBatch.abbrev.label"),
						},
						{
							path: "/otherLeadershipDiscountSettlementBatch",
							sidebar: () => t("menuBar.otherLeadershipDiscountSettlementBatch.label"),
							icon: (classes) => <FontAwesomeIcon className={classes} icon={faPercent}/>,
							component: (routerProps) => <OtherLeadershipDiscountSettlementBatch {...routerProps}/>,
							abbrev: () => t("menuBar.otherLeadershipDiscountSettlementBatch.abbrev.label"),
						},
						{
							path: "/profitParticipationSettlementBatch",
							sidebar: () => t("menuBar.profitParticipationSettlementBatch.label"),
							icon: (classes) => <FontAwesomeIcon className={classes} icon={faHandHoldingDollar}/>,
							component: (routerProps) => <ProfitParticipationSettlementBatch {...routerProps}/>,
							abbrev: () => t("menuBar.profitParticipationSettlementBatch.abbrev.label"),
						},

					]
				},
				{
					path: "/bankToCustomerNotification",
					sidebar: () => t("menuBar.bankToCustomerNotification.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faBuildingColumns}/>,
					component: (routerProps) => <BankToCustomerNotification {...routerProps} />,
					abbrev: () => t("menuBar.bankToCustomerNotification.abbrev.label"),
				},
				{
					path: "/manualPayment/bankPaymentCorrection/:id?/:invoiceId?",
					exact: true,
					component: (routerProps) => {
						return <CreateManualPayment bankPaymentId={ parseInt(routerProps.match.params.id) } invoiceId={routerProps.match.params.invoiceId ? parseInt(routerProps.match.params.invoiceId) : undefined} />
					},
				},
				{
					path: "/manualPayment",
					sidebar: () => <Trans
						i18nKey={'menuBar.manualPayment.label'}
						components={{ ul: <ul/>, li: <li/> }}/>,
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faMoneyBillTransfer}/>,
					component: (routerProps) => <ManualPayment {...routerProps} />,
				},
				{
					path: "/otherLeadershipCheck",
					sidebar: () => t("menuBar.otherLeadershipCheck.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faListCheck}/>,
					component: (routerProps) => <OtherLeadershipCheck {...routerProps} />,
					abbrev: () => t("menuBar.otherLeadershipCheck.abbrev.label"),
				},
			]
		},
		{
			path: "/reports",
			sidebar: () => t("menuBar.reports.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faFileLines}/>,
			component: (routerProps) => <Reports {...routerProps} />
		},
		{
			path: "/debtorsList",
			component: (routerProps) => <DebtorsList {...routerProps} />
		},
		{
			path: "/damageRendement",
			component: (routerProps) => <DamageRendement {...routerProps} />
		},
		{
			path: "/feePerYear",
			component: (routerProps) => <FeePerYear {...routerProps} />
		},
		{
			path: "/expiringPolicyVersions",
			component: (routerProps) => <ExpiringPolicyVersions {...routerProps} />
		},
		{
			path: "/profitParticipationSummary",
			component: (routerProps) => <ProfitParticipationSummary {...routerProps} />
		},
		{
			path: "/policiesExport",
			component: (routerProps) => <PoliciesExport {...routerProps} />
		},
		{
			path: "/inventoryExport",
			component: (routerProps) => <InventoryExport {...routerProps} />
		},
		{
			path: "/damagePayments",
			component: (routerProps) => <DamagePayments {...routerProps} />
		},
		{
			path: "/interimBillingDamages",
			component: (routerProps) => <InterimBillingDamages {...routerProps} />
		},
		{
			path: "/invoicedAppversPart",
			component: (routerProps) => <InvoicedAppversPart {...routerProps} />
		},
		{
			path: "/commissionsExport",
			component: (routerProps) => <CommissionsExport {...routerProps} />
		},
		{
			path: "/billingForPartners",
			component: (routerProps) => <BillingForPartners {...routerProps} />
		},
		{
			sidebar: () => t("menuBar.accounting.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faBook}/>,
			routes: [
				{
					path: "/accountingTafFile",
					sidebar: () => t("menuBar.accountingTafFile.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faFileExport}/>,
					component: (routerProps) => <AccountingTafFile {...routerProps} />
				}
			]
		},
		{
			sidebar: () => t("menuBar.baseData.label"),
			icon: (classes, active) => {
				if ( !active ) {
					return <FontAwesomeIcon className={classes} icon={faFolderClosed}/>
				}
				else {
					return <FontAwesomeIcon className={classes} icon={faFolderOpen}/>
				}
			},
			routes: [
				{
					sidebar: () => t("menuBar.persons.and.addresses.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faAddressCard}/>,
					routes: [
						{
							path: "/salutation",
							sidebar: () => t("menuBar.salutations.label"),
							component: (routerProps) => <Salutation {...routerProps} />,
							abbrev: () => t("menuBar.salutations.abbrev.label"),
						},
						{
							path: "/country",
							sidebar: () => t("menuBar.countries.label"),
							component: (routerProps) => <Country {...routerProps} />,
							abbrev: () => t("menuBar.countries.abbrev.label"),
						},
						{
							path: "/county",
							sidebar: () => t("menuBar.counties.label"),
							component: (routerProps) => <County {...routerProps} />,
							abbrev: () => t("menuBar.counties.abbrev.label"),
						},
						{
							path: "/zip",
							sidebar: () => t("menuBar.zips.label"),
							component: (routerProps) => <Zip {...routerProps} />,
							abbrev: () => t("menuBar.zips.abbrev.label"),
						},
						{
							path: "/partnerConsultantScope",
							sidebar: () => t("menuBar.partnerConsultantScope.label"),
							component: (routerProps) => <PartnerConsultantScope {...routerProps} />,
							abbrev: () => t("menuBar.partnerConsultantScope.abbrev.label"),
						},
					]
				},
				{
					sidebar: () => t("menuBar.object.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faBuilding}/>,
					routes: [
						{
							path: "/building",
							sidebar: () => t("menuBar.buildings.label"),
							component: (routerProps) => <Building {...routerProps} />,
							abbrev: () => t("menuBar.buildings.abbrev.label"),
						},
						{
							path: "/buildingType",
							sidebar: () => t("menuBar.buildingType.label"),
							component: (routerProps) => <BuildingType {...routerProps} />,
							abbrev: () => t("menuBar.buildingType.abbrev.label"),
						},
						{
							path: "/buildingClass",
							sidebar: () => t("menuBar.buildingClass.label"),
							component: (routerProps) => <BuildingClass {...routerProps} />,
							abbrev: () => t("menuBar.buildingClass.abbrev.label"),
						},
						{
							path: "/constructionType",
							sidebar: () => t("menuBar.constructionType.label"),
							component: (routerProps) => <ConstructionType {...routerProps} />,
							abbrev: () => t("menuBar.constructionType.abbrev.label"),
						},
						{
							path: "/heatingType",
							sidebar: () => t("menuBar.heatingType.label"),
							component: (routerProps) => <HeatingType {...routerProps} />,
							abbrev: () => t("menuBar.heatingType.abbrev.label"),
						},
						{
							path: "/roofType",
							sidebar: () => t("menuBar.roofType.label"),
							component: (routerProps) => <RoofType {...routerProps} />,
							abbrev: () => t("menuBar.roofType.abbrev.label"),
						},
						{
							path: "/fireExtinguisher",
							sidebar: () => t("menuBar.fireExtinguisher.label"),
							component: (routerProps) => <FireExtinguisher {...routerProps} />,
							abbrev: () => t("menuBar.fireExtinguisher.abbrev.label"),
						},
					]
				},
				{
					sidebar: () => t("menuBar.policies.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faRectangleList}/>,
					routes: [
						{
							path: "/avbDescription",
							sidebar: () => t("menuBar.avbDescriptions.label"),
							component: (routerProps) => <AvbDescription {...routerProps} />,
							abbrev: () => t("menuBar.avbDescriptions.abbrev.label"),
						},
						{
							path: "/avb",
							sidebar: () => t("menuBar.avbs.label"),
							component: (routerProps) => <Avb {...routerProps} />,
							abbrev: () => t("menuBar.avbs.abbrev.label"),
						},
						{
							path: "/individualText",
							sidebar: () => t("menuBar.individualTexts.label"),
							component: (routerProps) => <IndividualText {...routerProps} />,
							abbrev: () => t("menuBar.individualTexts.abbrev.label"),
						},
						{
							path: "/standardText",
							sidebar: () => t("menuBar.standardTexts.label"),
							component: (routerProps) => <StandardText {...routerProps} />,
							abbrev: () => t("menuBar.standardTexts.abbrev.label"),
						},
						{
							path: "/policyCancellationReason",
							sidebar: () => t("menuBar.policyCancellationReason.label"),
							component: (routerProps) => <PolicyCancellationReason {...routerProps} />,
							abbrev: () => t("menuBar.policyCancellationReason.abbrev.label"),
						},
					]
				},
				{
					sidebar: () => t("menuBar.damages.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faBurst}/>,
					routes: [
						{
							path: "/damageType",
							sidebar: () => t("menuBar.damageType.label"),
							component: (routerProps) => <DamageType {...routerProps} />,
							abbrev: () => t("menuBar.damageType.abbrev.label"),
						},
						{
							path: "/damageEvent",
							sidebar: () => t("menuBar.damageEvent.label"),
							component: (routerProps) => <DamageEvent {...routerProps} />,
							abbrev: () => t("menuBar.damageEvent.abbrev.label"),
						},
						{
							path: "/edCause",
							sidebar: () => t("menuBar.elementalDamageCause.label"),
							component: (routerProps) => <ElementalDamageCause {...routerProps} />,
							abbrev: () => t("menuBar.elementalDamageCause.abbrev.label"),
						},
					]
				},
				{
					sidebar: () => t("menuBar.insuranceProduct.label"),
					icon: (classes) => <FontAwesomeIcon className={classes} icon={faDiagramProject}/>,
					routes: [
						{
							path: "/configurator-editor",
							sidebar: () => t("menuBar.configuratorEditor.label"),
							component: (routerProps) => <ConfiguratorEdit {...routerProps} />,
							abbrev: () => t("menuBar.configuratorEditor.abbrev.label"),
						},
						{
							path: "/branch",
							sidebar: () => t("menuBar.branch.label"),
							component: (routerProps) => <Branch {...routerProps} />,
							abbrev: () => t("menuBar.branch.abbrev.label"),
						},
						{
							path: "/product",
							sidebar: () => t("menuBar.products.label"),
							component: (routerProps) => <Product {...routerProps} />,
							abbrev: () => t("menuBar.products.abbrev.label"),
						},
						{
							path: "/productType",
							sidebar: () => t("menuBar.productTypes.label"),
							component: (routerProps) => <ProductType {...routerProps} />,
							abbrev: () => t("menuBar.productTypes.abbrev.label"),
						},
						{
							path: "/tariff",
							sidebar: () => t("menuBar.tariffs.label"),
							component: (routerProps) => <Tariff {...routerProps} />,
							abbrev: () => t("menuBar.standardTexts.abbrev.label"),
						},
					]
				},
			]
		},
		{
			sidebar: () => t("menuBar.settingEditor.label"),
			icon: (classes) => <FontAwesomeIcon className={classes} icon={faScrewdriverWrench}/>,
			routes: [
				{
					path: "/setting",
					sidebar: () => t( "menuBar.defaultValues.label" ),
					icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faSliders }/>,
					component: ( routerProps ) => <Setting { ...routerProps } />
				},
				{
					path: "/configuratorNodeOrder",
					sidebar: () => t( "menuBar.configuratorNodeOrderList.label" ),
					icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faArrowUpAZ }/>,
					component: ( routerProps ) => <ConfiguratorNodeOrderList { ...routerProps } />
				},
				{
					path: "/user",
					sidebar: () => t( "menuBar.users.label" ),
					icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faUsers }/>,
					component: ( routerProps ) => <User { ...routerProps } />
				},
				{
					path: "/reportMessage",
					sidebar: () => t( "menuBar.reportMessage.label" ),
					icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faLanguage }/>,
					component: ( routerProps ) => <ReportMessage { ...routerProps } />
				},
				{
					sidebar: () => t("menuBar.service.label"),
					icon: (classes) => <FontAwesomeIcon className={ classes } icon={ faSuitcaseMedical }/>,
					routes: [
						{
							path: "/policiesVersionsWithIncompleteRV",
							sidebar: () => t( "menuBar.policiesVersionsWithIncompleteRV.label" ),
							icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faUmbrella }/>,
							component: ( routerProps ) => <PoliciesVersionsWithIncompleteRV { ...routerProps } />
						},
						{
							path: "/reindexAll",
							sidebar: () => t( "menuBar.reindexAll.label" ),
							icon: ( classes ) => <FontAwesomeIcon className={ classes } icon={ faRotate }/>,
							component: ( routerProps ) => <ReindexAll { ...routerProps } />
						},
					]
				},
				{
					path: "/changelog",
					component: ( routerProps ) => <Changelog { ...routerProps } />
				},
			]
		},
		/*!!!!!!!!!!!IMPORTANT!!!!!!!!!!!*/
		/*Always add "path" value to Grails application.groovy -> appvers.reactRoutes config variable*/
		/*direct url links will not work properly otherwise*/
	]

	const getAllRoutes = ( routes ) => {
		let result = [];
		routes.forEach((route) => {
			if ( route.routes ) {
				result = result.concat(getAllRoutes( route.routes ));
			}
			else {
				result.push( route );
			}
		})
		return result;
	}

	return (
		<>
			<div className="bg-light">
				<TopMenuBar logoutHandler={logoutHandler}/>
			</div>

			<div className="d-flex flex-grow-1">
				<div className={`left-menu-bar ${(_leftMenu && _leftMenu.bg) ? `bg-${_leftMenu.bg}` : 'bg-light'}`}>
					<div className="px-3 pt-2 text-nowrap sticky">
						<MenuItems routes={routes}/>
					</div>
				</div>
				<div className="py-3 ps-3 flex-grow-1">
					<Alert/>
					<Switch>
						{getAllRoutes(routes).map((route, index) => (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								render={routerProps => route.component(routerProps)}/>
						))}
						<Redirect from="*" to="/" />
					</Switch>
				</div>
			</div>

			<div className="bg-light p-2">
				<Footer/>
			</div>
		</>
	)
}

export default AppRouter;
