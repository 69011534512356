import React, {useMemo} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import moment from "moment";
import PaymentStateBadge from "../payment/PaymentStateBadge";
import {useFormContext} from "react-hook-form";
import textService from "../_services/text.service";

function FormElements({domainInstance}) {
	const { t } = useTranslation();
	const {getValues, reset} = useFormContext()
	const pairedWithBankPayment = domainInstance && domainInstance.bankPayment && domainInstance.bankPayment.id
	const showInvoiceLabel = (option) => {
		return (
			<span>
				{option.refNr ? `${option.refNr}, ` : ''} {option.invoiceNr}, {option.paymentTotal}/{option.total} <PaymentStateBadge paymentState={option.paymentState}/>
			</span>
		)
	}

	const showBankPaymentLabel = (option) => {
		return (
			<span>
				{option.refNr}, {option.id}, {textService.formatDateTime(Date.parse(option.amountDate),{dateStyle: 'medium'})}, {option.amount}
			</span>
		)
	}

	const handleInvoiceChange = (value) => {
		let calculatedAmount = value.total - value.paymentTotal
		const val = { ...getValues(), 'amount': calculatedAmount.toFixed(2) };
		reset( val );
	}

	const amountDate = useMemo( () => {
		if ( domainInstance && domainInstance.amountDate ) {
			return moment(domainInstance.amountDate).format('YYYY-MM-DD')
		}
		else {
			return moment().format('YYYY-MM-DD')
		}
	}, [domainInstance] )

	return (
		<>
			{ domainInstance && <Card className={"mb-2"}>
				<Card.Header>{t('manualPayment.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupAmountDate">
							<Form.Label>{t('payment.amountDate')}</Form.Label>
							<FormControl
								name={"amountDate"}
								type={"date"}
								value={amountDate}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupNote">
							<Form.Label>{t('manualPayment.note')}</Form.Label>
							<FormControl
								name={"note"}
								type={"text"}
								value={domainInstance.note}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					{ pairedWithBankPayment && <Row className={"mb-3"}>
						<Form.Group as={Col} md="" controlId="bankPaymentInfo">
							<Form.Label>{t('manualPayment.bankPayment')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"bankPayment"}
								value={domainInstance && domainInstance.bankPayment}
								name={'bankPayment'}
								onGetOptionLabel={showBankPaymentLabel}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row> }
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="9" controlId="formGroupRefNr">
							<Form.Label>{t('payment.invoice')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"invoice"}
								// sort={"[Long]selectionSort"}
								value={domainInstance && domainInstance.invoice}
								name={'invoice'}
								onGetOptionLabel={showInvoiceLabel}
								onChange={handleInvoiceChange}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="3" controlId="formGroupAmount">
							<Form.Label>{t('payment.amount')}</Form.Label>
							<FormControl
								name={"amount"}
								type={"text"}
								value={domainInstance.amount}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>}
		</>
	);
}

export { FormElements };
