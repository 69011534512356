import {useEffect, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";

const reportMessageService = {
	useComposedReportMessage,
	saveComposedReportMessage
};

function useComposedReportMessage( code ) {
	const [composedReportMessage, setComposedReportMessage] = useState( [] )

	useEffect( () => {
		const controller = new AbortController();
		const h = headers();
		const params = {
			code: code
		}

		fetch( `${ SERVER_URL }/api/reportMessage/getComposedReportMessage?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
		} )
			.then( r => r.json() )
			.then( json => {
				setComposedReportMessage( json )
			} )
			.catch( error => {
				restService.handleServerErrors( error, controller.signal );
			} );
	}, [code] );

	return composedReportMessage
}

function saveComposedReportMessage(composedReportMessage) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		const h = headers();
		const params = {
			composedReportMessage: JSON.stringify(composedReportMessage)
		}

		fetch( `${ SERVER_URL }/api/reportMessage/saveComposedReportMessage?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( r => r.json() )
			.then( json => {
				resolve();
			} )
			.catch( error => {
				reject()
			} );
	})
}

export default reportMessageService;