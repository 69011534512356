import React, {useEffect, useRef, useState} from 'react';
import EditableRowCell from "./EditableRowCell";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import editableTableService from "./editableTable.service";
import {ACTION_CREATE, ACTION_DELETE, ACTION_UPDATE} from "./EditableTable";

function EditableTableRow(props) {
	const rowData = props.rowData
	const [deleted, setDeleted] = useState( false );
	const isEmptyRow = props.isEmptyRow

	const refreshCallbackRefs = useRef({});

	useEffect( () => {
		if ( props.onRef ) {
			props.onRef( ( columnId, rawValue ) => {
				refreshCallbackRefs.current[columnId](rawValue);
			} );
		}
	}, [props]);

	const handleCellChange = (value, columnId) => {
		let action
		if ( rowData.action ) {
			action = rowData.action
		}
		else {
			if ( isEmptyRow ) {
				action = ACTION_CREATE
			}
			else {
				action = ACTION_UPDATE
			}
		}

		const newRowData = { ...rowData };
		newRowData["action"] = action;
		newRowData[columnId] = value;

		if ( isEmptyRow ) {
			for ( var columnIdx in props.columns ) {
				const column = props.columns[columnIdx];
				if ( !newRowData.hasOwnProperty(column.id) && column.input.defaultValue ) {
					const value = editableTableService.getValue( column, column.input.defaultValue );
					if ( value.error ) {
						throw new Error("Bad definition of 'default value' in column '" + column.id + "'");
					}
					else {
						newRowData[column.id] = value.value;
					}
				}
			}
		}

		props.onChange( columnId, newRowData, props.rowId );
	}

	const handleMoveUp = () => {
		props.moveUp( props.rowId );
	}

	const handleMoveDown = () => {
		props.moveDown( props.rowId );
	}

	const getRow = () => {
		if ( !deleted ) {
			return (
				<tr>
					{getRowCells()}
					{ !isEmptyRow && props.allowDeletions && <td><Button type={'button'} variant={"danger"} size={"sm"} onClick={deleteRow}><FontAwesomeIcon size={"sm"} icon={ faTrashAlt }/></Button></td> }
					{ props.orderable &&
					<td className={"align-middle"}>
						<div style={{width:"2em"}}>
							<Button type="button" className={"float-start link-button"} onClick={handleMoveUp}><FontAwesomeIcon size={"sm"} icon={ faArrowUp }/></Button>
							<Button type="button" className={"float-start link-button"} onClick={handleMoveDown}><FontAwesomeIcon size={"sm"} icon={ faArrowDown }/></Button>
						</div>
					</td> }
				</tr>
			)
		}
	}

	const getRowCells = () => {
		return (
			props.columns.map( (column) => {
				return <EditableRowCell key={column.id} onRef={(callback) => {
					refreshCallbackRefs.current[column.id] = callback
				}} column={column} beforeChange={props.beforeChange} onChange={handleCellChange} rowData={rowData}
										validationError={(props.validationErrors || {})[column.id]}/>;
			})
		)
	}

	const deleteRow = () => {
		if ( !props.beforeDelete || props.beforeDelete(rowData) ) {
			let action
			if ( rowData.action === ACTION_CREATE ) {
				action = ""
			} else {
				action = ACTION_DELETE
			}
			const newRowData = { ...rowData, action: action, deleted: true };
			setDeleted( true );
			props.onChange( undefined, newRowData, props.rowId );
		}
	}

	return (
		<>
			{getRow()}
		</>
	);
}

export default EditableTableRow;
