import React from 'react';
import {Card, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();
	const {register} = useFormContext();

	return (
		<>
			{domainInstance && <Card className={"mb-2"}>
				<Card.Header>{t('bankToCustomerNotification.card.info')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group md="4" controlId="file">
							<Form.Label className={'pe-2'}>{t('bankToCustomerNotification.file')}</Form.Label>
							<input
								{...register('file')}
								type={"file"}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>}
		</>
	);
}

export { FormElements };
