import React, {useEffect} from 'react';
import websocketService from "../_services/websocket.service";
import {messagePanelService} from "../_services/message.panel.service";
import PropTypes from "prop-types";
import SettlementPrintedMessageCommon from "../_components/_userMessage/SettlementPrintedMessageCommon";
import SettlementPrintedPrintWithSettlementBatchMessage	from "../_components/_userMessage/SettlementPrintedPrintWithSettlementBatchMessage";

function SettlementPrintedMessageListener( {settlementId} ) {
    const [settlementPrintedMessage] = websocketService.useWebSocketSubscription(`/topic/settlement-${settlementId}/printed`, {})

    useEffect( () => {
        if ( settlementPrintedMessage.header ) {
			let msg = null
			switch ( settlementPrintedMessage.classSimpleName ) {
				case 'SettlementPrintedMessageCommon':
					msg = {
						uuid: settlementPrintedMessage.uuid,
						header: settlementPrintedMessage.header,
						message: <SettlementPrintedMessageCommon { ...settlementPrintedMessage }/>,
						variant: settlementPrintedMessage.variant
					}
					break
				case 'SettlementPrintedPrintWithSettlementBatchMessage':
					msg = {
						uuid: settlementPrintedMessage.uuid,
						header: settlementPrintedMessage.header,
						message: <SettlementPrintedPrintWithSettlementBatchMessage { ...settlementPrintedMessage }/>,
						variant: settlementPrintedMessage.variant
					}
					break
			}

            messagePanelService.update( [msg], undefined )
        }
    },[settlementPrintedMessage])

	return (
		<></>
	)
}

SettlementPrintedMessageListener.propTypes = {
	settlementId: PropTypes.number,
}
export default SettlementPrintedMessageListener