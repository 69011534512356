import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('elementalDamageCause.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="6" controlId="formGroupElementalDamageCauseCauseCode">
							<Form.Label>{t('elementalDamageCause.causeCode')}</Form.Label>
							<FormControl
								name={"causeCode"}
								type={"text"}
								value={domainInstance.causeCode || (defaultValues && defaultValues.causeCode)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="6" controlId="formGroupElementalDamageCauseDescription">
							<Form.Label>{t('elementalDamageCause.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value={domainInstance.description || (defaultValues && defaultValues.description)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
