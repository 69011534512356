import React, {useMemo} from 'react';
import {DamageDetails} from "../damageDetail/DamageDetails";

function ShowDamageDamageDetails({damage}) {
    const searchOptions = useMemo( () => ( {queryStrings: [{field: 'damage.id', text: `${damage.id}`}]} ), [damage.id] );

    return (
        <>
            <div>
                {(damage && damage.id) ? <DamageDetails searchOptions={searchOptions}/> : null}
            </div>
        </>
    )
}

export default ShowDamageDamageDetails
