import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function GoogleMapLink( { street, zipLabel }) {

	return (
		<a className="pe-1" href={'https://maps.google.com/?q=' + street + ', ' + zipLabel} rel={"noopener noreferrer"} target={"_blank"}><FontAwesomeIcon icon={ faMapMarkerAlt }/></a>
	)
}

GoogleMapLink.propTypes = {
	street: PropTypes.string,
	zipLabel: PropTypes.string
}

export { GoogleMapLink };
