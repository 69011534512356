import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import {fileService} from "./file.service";

const reportService = {
	debtorsList,
	printDamagePayments,
	printActivePartner,
};

function debtorsList(criteria) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		const params = { criteria: JSON.stringify( criteria ) }
		fetch( `${ SERVER_URL }/api/report/debtorsList`, {
			method: 'POST',
			headers: h,
			body: JSON.stringify( params ),
			responseType: 'blob',
			signal: controller.signal,
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printDamagePayments( dateFrom, dateTo, groupData ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		const params = {
			dateFrom: dateFrom,
			dateTo: dateTo,
			groupData: groupData
		}
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/report/printDamagePayments`, {
			method: 'POST',
			headers: h,
			body: JSON.stringify( params ),
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printActivePartner( ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		fetch( `${ SERVER_URL }/api/report/printActivePartner`, {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

export default reportService;