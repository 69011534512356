import i18n from "i18next";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareCheck, faSquareXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const textService = {
	formatDateTime,
	formatNumber,
	format,
	objectToString,
	numberToString,
}

function formatDateTime(dateTime, options = { dateStyle: 'short', timeStyle: 'short' }) {
	if ( dateTime ) {
		const lang = i18n.language
		try {
			return new Intl.DateTimeFormat( lang, options ).format( dateTime )
		}
		catch(e) {
			console.log (e);
		}
	}
	else {
		return dateTime
	}
}

function formatNumber(number, options = {thousandSeparator: true, minimumFractionDigits: 2}) {
	if ( number === -0 ){
		//Negative zero can occur in JavsScript, which produces output -0.00. It wasn't nice.
		number = +0
	}
	let nf = new Intl.NumberFormat( i18n.language, options );
	return nf.format(number);
}

function format(value, type) {
	let result = value;

	switch ( type ) {
		case 'text':
			result = value;
			break;
		case 'date':
			result = textService.formatDateTime(moment(value),{dateStyle: 'medium'});
			break;
		case 'datetime':
			result = textService.formatDateTime(moment(value));
			break;
		case 'number':
			result = textService.formatNumber(value);
			break;
		case 'boolean':
			result = value ? <FontAwesomeIcon size={"1x"} className={'text-success'} icon={faSquareCheck}/> : <FontAwesomeIcon size={"1x"} className={'text-danger'} icon={faSquareXmark}/> //value === true ? i18n.t('default.yes') : i18n.t('default.no');
			break;
		default:
			if ( 'appvers.enums.' === type.substring(0,14) ) {
				result = i18n.t(type + '.' + value);
			}
			else {
				throw new Error('ShowField: Uknown type: ' + type);
			}
	}

	return result
}

/**
 *
 * @param object to be used
 * @param domainName String representing domain to be translated
 * @param toString Text representation
 * @param withLabel
 * @returns {string}
 */
function objectToString( object, domainName, toString, withLabel = false ) {
	let result = "";
	const concat = ( string1, string2, delimiter ) => string1 + ( ( string1 === "" ? "" : delimiter ) + string2 );

	if ( withLabel ) {
		result = concat( result, i18n.t(domainName + ".label"), " ");
	}

	switch ( typeof(toString) ) {
		case "object":
			if ( toString.constructor === Array ) {
				for ( let propIdx in toString ) {
					const propName = toString[propIdx];

					//read dot notation properly (for example if object = { property: { nestedProperty: 'ahoj' }}, and propName='property.nestedProperty', then we would like to get 'ahoj' as value)
					var value = propName.split('.').reduce(function(a, b) {
						let result
						if ( a ) {
							result = a[b];
						}
						return result;
					}, object);

					if ( value ) {
						result = concat( result, value, " " );
					}
				}
			}
			break;
		case "function":
			result = concat( result, toString(object), " " );
			break;
		default:
			console.debug( "objectToString is called without toString defined" )
	}

	return result;
};

function numberToString( number ) {
	let result = number.toFixed(10)

	if ( result.search('.') >= 0 ) {
		while ( result.charAt(result.length - 1) === '0' ) {
			result = result.substring(0, result.length - 1)
		}
	}

	if ( result.charAt(result.length - 1) === '.' ) {
		result = result.substring(0, result.length - 1)
	}

	return result
}

export default textService
