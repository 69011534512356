import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";
import ShowManualPayment from "./ShowManualPayment";
import {FormElements} from "./FormElements";

function ManualPayment({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			hidden: true,
		},
		{
			dataField: 'amountDate',
			text: t( 'payment.amountDate' ),
			formatter:  bootstrapTableService.formatterDate,
			sortField: 'amountDate',
			sort: true
		},
		{
			dataField: 'note',
			text: t( 'manualPayment.note' ),
			sort: true,
			sortField: 'note_sort',
		},
		{
			dataField: 'invoice.id',
			text: t('payment.invoice'),
			headerStyle: (column, colIndex) => {
				return { width: '16em' };
			},
			sortField: 'invoice.id',
			sort: true
		},
		{
			dataField: 'refNr',
			text: t('payment.refNr'),
			headerStyle: (column, colIndex) => {
				return { width: '16em' };
			},
			sortField: 'refNr',
			sort: true
		},
		{
			dataField: 'amount',
			text: t('payment.amount'),
			formatter: bootstrapTableService.formatterAmount,
			classes: (cell, row, rowIndex, colIndex) => 'text-end',
			sortField: 'amount',
			sort: true
		},
		{
			dataField: 'invoice.total',
			sort: true,
			text: t('bankPayment.invoice.total'),
			formatter: (cellContent, row) => {
				if ( row.invoice ) {
					return bootstrapTableService.formatterAmount( cellContent )
				}
			},
			classes: (cell, row, rowIndex, colIndex) => 'text-end'
		},
	];


	return (
		<React.StrictMode>
			<Index
				path={match.path}
				domainName={ 'manualPayment' }
				columns={ columns }
				toString={ (object) => t('manualPayment.label') }
				formElements={ <FormElements/> }
				sortField={'amountDate'}
				sortOrder={'desc'}
				allowEdits={true}
				allowShow={true}
				showElement={<ShowManualPayment/>}
				allowDeletions={true}
			/>
		</React.StrictMode>
	);
}

export { ManualPayment };
