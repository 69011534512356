import React, {useMemo} from 'react';
import PropTypes from "prop-types";
import DomainTable from "../_components/DomainTable";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import bootstrapTableService from "../_components/bootstrapTable.service";
import DiscountSurcharge from "../policy/DiscountSurcharge";

function DamageDetails( {namedCriteria, searchOptions, hiddenFields}) {
    const {t} = useTranslation();

    const columns = useMemo(() => {
        const _columns = [
            {
                dataField: 'id',
                text: t('damageDetail.id'),
                hidden: true,
            },
            {
                dataField: 'productAndBuilding',
                text: t('damageDetail.productAndBuilding'),
            },
            {
                dataField: 'description',
                text: t('damageDetail.description'),
            },
            {
                dataField: 'discountSurcharge',
                text: t('damageDetail.discountSurcharge'),
                formatter: (cellContent, row) => {
                    if ( row.participationType ) {
                        return row.participationType.label
                    }
                    else if ( row.policyTarget && row.policyTarget.discountSurcharge ) {
                        return <DiscountSurcharge policyTarget={row.policyTarget} />
                    }
                }
            },
            {
                dataField: 'participationAmount',
                text: t('damageDetail.participationAmount'),
                formatter: (cellContent, row) => bootstrapTableService.formatterAmount(cellContent),
            },
            {
                dataField: 'reserveAmount',
                text: t('damageDetail.reserveAmount'),
                formatter: (cellContent, row) => bootstrapTableService.formatterAmount(cellContent),
            },
            {
                dataField: 'buttons',
                text: '',
                sort: false,
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                formatter: (cellContent, row) => {
                    return (
                        <>
                            {/*<Button variant={'primary'} size={'sm'} onClick={ () => handlePrintSettlement(row) }>*/}
                            {/*    <FontAwesomeIcon icon={faFilePdf}/>*/}
                            {/*</Button>*/}
                            <Link className={`btn btn-sm btn-primary ms-1`} to={`/damageDetail/show/${row.id}`} target={"_blank"} rel={"noopener noreferrer"}>
                                <FontAwesomeIcon icon={faEye}/>
                            </Link>
                            {/*{ row.cancelledWhy === SettlementCancelReason.NOT_CANCELLED &&*/}
                            {/*    <Button className={ "ms-1" } variant={ 'danger' } size={ 'sm' }*/}
                            {/*            onClick={ () => handleCancelSettlementQuestion( row ) }>*/}
                            {/*        <FontAwesomeIcon icon={ faFileCircleXmark }/>*/}
                            {/*    </Button>*/}
                            {/*}*/}
                        </>
                    )
                },
                headerStyle: (column, colIndex) => {
                    return { width: '10%' };
                },
            }
        ];

        return _columns.filter( (column) => !hiddenFields.includes( column.dataField ) );
    }, [hiddenFields, t])

    return (
        <>
            <DomainTable
                domainName={'damageDetail'}
                columns={columns}
                namedCriteria={namedCriteria}
                searchOptions={searchOptions}
                // rowClasses={rowClasses}
                // sortField={'billingFrom'}
                // sortOrder={'desc'}
            />
        </>
    )
}


DamageDetails.defaultProps = {
    hiddenFields: []
};

DamageDetails.propTypes = {
    namedCriteria: PropTypes.object,
    hiddenFields: PropTypes.array
};

export { DamageDetails };
