export const formService = {
	flattenSubmitData
};

function flattenSubmitData(data) {
	let flattenData = {};

	//data contains inherited structures, that aren't properly accepted by server,
	//thus we need to adjust data first:
	for (const [key, value] of Object.entries(data)) {
		flattenData[key] = value;
		if ( value !== null && typeof value === 'object' ) {
			if ( value.hasOwnProperty('id') ) {
				//pass only the id in case of object
				flattenData[key] =  {id: value.id === undefined ? null : value.id };
			}
			else if ( value.hasOwnProperty('value') ) {
				//pass the 'value' as 'id' in case of object (it comes from RestAsyncSelect
				flattenData[key] =  { id: value.value === undefined ? null : value.value };
			}
		}
	}

	return flattenData;
}
