import React from "react";
import {Link} from "react-router-dom";
import restService from "./rest.service";
import {invoiceService} from "./invoice.service";
import textService from "./text.service";
import i18n from "i18next";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import {fileService} from "./file.service";

export const profitParticipationService = {
	getDetailsColumns,
	getSettlementColumns,
	printRvSummary,
	printSummary,
}

function getDetailsColumns(){
	const className = (row) => {
		console.log("row.settlement.classSimpleName", row.settlement.classSimpleName);
		const bg = (row.year % 2 === 0 ) ? ' bg-body-secondary ' : '';
		const fg = row.settlement.classSimpleName === 'creditNoteDamagePaymentNotification' ? ' text-danger ' : '';
		console.log("bg + fg", bg + fg);
		return bg + fg;
	}

	return [
		{ key: 'year', label: i18n.t( 'default.year' ), className: className, formatter: ( row ) => row.year },
		{
			key: 'number',
			label: i18n.t( 'default.nr' ),
			className: className,
			formatter: ( row ) => <Link to={ restService.getTo( row.settlement, 'show' ) } target={ "_blank" }
			                            rel={ "noopener noreferrer" }>{ row.settlement.number }</Link>
		},
		{
			key: 'type',
			label: i18n.t( 'settlement.type' ),
			className: className,
			formatter: ( row ) => row.settlement.type
		},
		{
			key: 'partner',
			label: i18n.t( 'settlement.partner' ),
			className: className,
			formatter: ( row ) => row.settlement.partner.label
		},
		{
			key: 'detail',
			label: i18n.t( 'settlement.detail' ),
			className: className,
			formatter: ( row ) => invoiceService.getDetailAsText( row.settlement.detail )
		},
		{
			key: 'total',
			label: i18n.t( 'settlement.total' ),
			className: (row) => `${ className(row) } text-end`,
			formatter: ( row ) => {
				let amount;
				if ( row.settlement.classSimpleName === 'invoicePolicyVersion' || row.settlement.classSimpleName === 'creditNotePolicyVersion') {
					amount = row.settlement.netAmountAfterRabatt;
				}
				else {
					amount = row.settlement.total;
				}
				return textService.formatNumber( amount )
			}
		},
	]
}

function getSettlementColumns(forParticipant = false, showPolicy = false) {
	let result = []

	if( showPolicy ) {
		result.push(
			{key: 'test', className:"text-end", label: i18n.t('settlementItemProfitParticipation.participationPercent'), formatter: (row) => row.policy.label, xlsxFormatter: (row) => row.policy.label, xlsxTotal: false}
		)
	}

	result.push(
		{key: 'year', className:"text-end", label: i18n.t('settlementItemProfitParticipation.year'), formatter: (row) => row.year, xlsxFormatter: (row) => row.year, xlsxTotal: false }
	)
	result.push(
		{key: 'feeTotal', className:"text-end", label: i18n.t('settlementItemProfitParticipation.feeTotal'), formatter: (row) => textService.formatNumber(row.feeTotal), xlsxFormatter: (row) => row.feeTotal, xlsxTotal: true }
	)
	result.push(
		{key: 'administrationFee', className:"text-end", label: i18n.t('settlementItemProfitParticipation.administrationFee'), formatter: (row) => textService.formatNumber(row.administrationFee), xlsxFormatter: (row) => row.administrationFee, xlsxTotal: true }
	)
	result.push(
		{key: 'damagesTotal', className:"text-end", label: i18n.t('settlementItemProfitParticipation.damagesTotal'), formatter: (row) => textService.formatNumber(row.damagesTotal), xlsxFormatter: (row) => row.damagesTotal, xlsxTotal: true }
	)
	result.push(
		{key: 'profitParticipationPercent', className:"text-end", label: i18n.t('settlementItemProfitParticipation.profitParticipationPercent'), formatter: (row) => textService.formatNumber(row.profitParticipationPercent, {thousandSeparator: true, minimumFractionDigits: 0}) + ' %', xlsxFormatter: (row) => row.profitParticipationPercent, xlsxTotal: false }
	)

	if( forParticipant ) {
		result.push(
			{key: 'participationPercent', className:"text-end", label: i18n.t('settlementItemProfitParticipation.participationPercent'), formatter: (row) => textService.formatNumber(row.participationPercent, {thousandSeparator: true, minimumFractionDigits: 0}) + ' %', xlsxFormatter: (row) => row.participationPercent, xlsxTotal: false }
		)
	}

	result.push(
		{key: 'amount', className:"text-end", label: i18n.t('settlementItemProfitParticipation.amount'), formatter: (row) => textService.formatNumber(row.amount), xlsxFormatter: (row) => row.amount, xlsxTotal: true }
	)

	return result
}

function printRvSummary(settlementBatchId, detailed) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
		const controller = new AbortController();

		const params = {
			settlementBatchId: settlementBatchId,
			detailed: detailed,
		}

		fetch( `${ SERVER_URL }/api/profitParticipationSettlementBatch/printRvSummary?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal,
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}

function printSummary(from, to, dataValidOn) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
		const controller = new AbortController();

		const params = {
			from: from,
			to: to,
			dataValidOn: dataValidOn,
		}

		fetch( `${ SERVER_URL }/api/profitParticipationSettlementBatch/printSummary?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal,
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}