import React, {useEffect, useMemo, useCallback} from 'react';
import restService from "../../_services/rest.service";
import {Button, Card, Col, Row, Dropdown, ButtonGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {invoiceService} from "../../_services/invoice.service";
import websocketService from "../../_services/websocket.service";
import SettlementPrintedMessageCommon from "../_userMessage/SettlementPrintedMessageCommon";
import {messagePanelService} from "../../_services/message.panel.service";
import DropdownItemWithOptions from "../DropdownItemWithOptions";
import PropTypes from "prop-types";
import SettlementBatchProgress from "./SettlementBatchProgress";
import SettlementBatchReady from "./SettlementBatchReady";

function PreviewSettlementBatch( { id, className, settlementBatchFormElements, additionalPrintDropdowns, hiddenFields} ) {
	const [ settlementBatch, setSettlementBatch ] = restService.useDomainInstance(className, id);

	const [settlementBatchCreateState] = websocketService.useWebSocketSubscription(`/topic/settlementBatch-${ settlementBatch.id }/create`, {})
	const [settlementBatchUpdateState] = websocketService.useWebSocketSubscription(`/topic/settlementBatch-${ settlementBatch.id }/update`, {})
	const [settlementBatchPrintedMessage] = websocketService.useWebSocketSubscription(`/topic/settlementBatch-${ settlementBatch.id }/printed`, {})

	useEffect( () => {
		if ( settlementBatchPrintedMessage.header ) {
			const msg = {
				uuid: settlementBatchPrintedMessage.uuid,
				header: settlementBatchPrintedMessage.header,
				message: <SettlementPrintedMessageCommon { ...settlementBatchPrintedMessage }/>,
				variant: settlementBatchPrintedMessage.variant
			}
			messagePanelService.update( [msg], undefined )
		}
	},[settlementBatchPrintedMessage])

	const {t} = useTranslation()

	const refreshSettlementBatch = useCallback( (controller) => {
		const signal = controller && controller.signal;
		restService.getDomainInstance( className, id, signal )
			.then( (result) => setSettlementBatch(result) )
			.catch( error => restService.handleServerErrors( error, signal ) )
	}, [id, setSettlementBatch, className])

	useEffect( () => {
		if ( settlementBatchCreateState.finished || settlementBatchUpdateState.finished ) {
			const controller = new AbortController();
			refreshSettlementBatch(controller)
			return function cleanup() {
				controller.abort()
			}
		}
	}, [settlementBatchCreateState.finished, settlementBatchUpdateState.finished, id, setSettlementBatch, refreshSettlementBatch])

	const afterUpdateSettlementBatchCallback = useCallback( (changes) => {
		if ( changes.payload.updatedProperties.some( (property) => ['booked', 'exception'].includes(property) ) ) {
			refreshSettlementBatch();
		}
	}, [refreshSettlementBatch]);
	const ids = useMemo( () => [parseInt(id)], [id] );
	websocketService.useAfterUpdateSubscription('settlementBatch', ids, afterUpdateSettlementBatchCallback);

	const handleRestartSettlementBatches = () => {
		console.log("jojo");
		invoiceService.restartSettlementBatches();
	}

	const settlements = useMemo( () => {
		if ( settlementBatch && settlementBatch.id ) {
			if ( settlementBatch.objectsIsNull ) {
				return <SettlementBatchReady settlementBatch={settlementBatch} hiddenFields={hiddenFields}/>;
			}
			else {
				return (
					<div>
						<SettlementBatchProgress settlementBatch={settlementBatch} settlementBatchUpdateState={settlementBatchCreateState} restartSettlementBatches={handleRestartSettlementBatches}/>
					</div>
				);
			}
		}

		return null
	}, [ settlementBatch, settlementBatchCreateState, hiddenFields ])

	const handlePrintSettlementBatch = useCallback( (withLogo = true, ppstamp = false) => {
		invoiceService.printSettlementBatch( settlementBatch.id, settlementBatch.className, settlementBatch.dateFrom, settlementBatch.dateTo, withLogo, ppstamp )
	}, [settlementBatch]);

	const handleBookSettlements = useCallback( () => {
		invoiceService.bookSettlementBatch( settlementBatch.id )
	}, [settlementBatch])

	const settlementsCount = `${settlementBatch && settlementBatch.settlementsCount}${ (settlementBatch && settlementBatch.printWithSettlementBatchInvoicesCount) ? ('+' + settlementBatch.printWithSettlementBatchInvoicesCount ) : ''}`
	const printButtonLabel = t('previewSettlementBatch.printAllInvoices.label', {count: settlementsCount});

	return (
		<>
			<Card>
				<Card.Header>
					{t('settlementBatch.label')}
				</Card.Header>
				<Card.Body>
					{/* information about settlement batch */}
					{React.cloneElement(settlementBatchFormElements, {...{ settlementBatch: settlementBatch }})}


					{/* buttons */}
					<Row className={"mt-3"}>
						<Col lg={6} md={9} sm={12}>
							{/* print settlement batch */}
							<Dropdown as={ButtonGroup}>
								<Button variant={"primary"} onClick={handlePrintSettlementBatch}
										disabled={ !(settlementBatch && settlementBatch.objectsIsNull) }>
									{printButtonLabel}
								</Button>
								<Dropdown.Toggle split variant={"primary"} disabled={ !(settlementBatch && settlementBatch.objectsIsNull) }/>
								<Dropdown.Menu disabled={ !(settlementBatch && settlementBatch.objectsIsNull) }>
									<DropdownItemWithOptions
										label={printButtonLabel}
										options={[{name:'withLogo', checked:true, control: 'switchbutton'}, {name:'ppstamp', checked:true, control: 'switchbutton'}]}
										onClick={ (values) => {
											handlePrintSettlementBatch( values.withLogo, values.ppstamp );
										}}
									/>
									{ additionalPrintDropdowns &&
										React.cloneElement(additionalPrintDropdowns, {...{ settlementBatch: settlementBatch }})
									}
								</Dropdown.Menu>
							</Dropdown>

							{/* book settlement batch */}
							{!settlementBatch.booked && <Button className={"ms-2"} variant={"warning"} onClick={handleBookSettlements} disabled={ !(settlementBatch && settlementBatch.objectsIsNull) }>{t('settlementBatch.book')}</Button>}
						</Col>
					</Row>
				</Card.Body>
			</Card>


			{/* list of settlements / creating progress */}
			<Card className={"mt-3"}>
				<Card.Header>
					{t('invoicePolicyVersion.label.plural')}
				</Card.Header>
				<Card.Body>
					<Row>
						{settlements}
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

PreviewSettlementBatch.propTypes = {
	id: PropTypes.number.isRequired,
	className: PropTypes.string.isRequired,
	settlementBatchFormElements: PropTypes.element.isRequired,
	additionalPrintDropdowns: PropTypes.element,
	hiddenFields: PropTypes.arrayOf( PropTypes.string )
}

export default PreviewSettlementBatch
