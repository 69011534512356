import React, {useMemo, useCallback} from 'react'
import PropTypes from "prop-types";
import {policyService} from "../_services/policy.service";
import {PaymentState} from "../_enum/enum";
import textService from "../_services/text.service";
import {Card, Form} from "react-bootstrap";
import moment from "moment";
import {useTranslation} from "react-i18next";
import DefaultSpinner from "../_components/DefaultSpinner";

function ActivateDeactivateLastSettlement({policyVersionId, validTo, cancelLastInvoice, setCancelLastInvoice}) {
	const lastSettlement = policyService.useLastSettlement(policyVersionId)
	const {t} = useTranslation();

	const paidInvoice = useCallback( () => {
		return <p>{t('activateDeactivateLastSettlement.lastInvoiceIsPaid.message', {
			billingFrom: textService.formatDateTime(Date.parse(lastSettlement.billingFrom),{dateStyle: 'medium'}),
			billingTo: textService.formatDateTime(Date.parse(lastSettlement.billingTo),{dateStyle: 'medium'}),
			invoiceNr: lastSettlement.invoiceNr
		})}</p>
	}, [lastSettlement, t]);

	const notPaidInvoice = useCallback( () => {
		return <>
				<p>{t('activateDeactivateLastSettlement.lastInvoiceIsNotPaid.message', {
					invoiceNr: lastSettlement.invoiceNr,
					billingFrom: textService.formatDateTime(Date.parse(lastSettlement.billingFrom),{dateStyle: 'medium'}),
					billingTo: textService.formatDateTime(Date.parse(lastSettlement.billingTo),{dateStyle: 'medium'}),
				})}</p>
				<Form.Check
					id={"cancelInvoice"}
					name={"cancelInvoice"}
					type={"checkbox"}
					label={t('activateDeactivateLastSettlement.cancelInvoice.label')}
					value={cancelLastInvoice}
					onChange={ (event) => {
						setCancelLastInvoice(event.target.checked)
					} }
				/>
				{ moment(Date.parse(lastSettlement.billingFrom)) < validTo && cancelLastInvoice &&
					<div className={"mt-3"}>
						{t('activateDeactivateLastSettlement.invoicePeriod.message', {
							periodFrom: textService.formatDateTime(Date.parse(lastSettlement.billingFrom),{dateStyle: 'medium'}),
							periodTo: textService.formatDateTime(Date.parse(validTo),{dateStyle: 'medium'}),
						})}
					</div>
				}
			</>
	}, [lastSettlement, validTo, setCancelLastInvoice, cancelLastInvoice, t])

	const creditNotePolicyVersion = useCallback( () => {
		return <p>{t('activateDeactivateLastSettlement.lastCreditNote.message', {
			billingFrom: textService.formatDateTime(Date.parse(lastSettlement.billingFrom),{dateStyle: 'medium'}),
			billingTo: textService.formatDateTime(Date.parse(lastSettlement.billingTo),{dateStyle: 'medium'}),
			creditNoteNr: lastSettlement.creditNoteNr,
		})}</p>
	}, [lastSettlement, t])

	const lastSettlementElement = useMemo( () => {
		switch ( lastSettlement.className ) {
			case 'appvers.InvoicePolicyVersion':
				if ( lastSettlement.paymentState === PaymentState.UNPAID ) {
					return notPaidInvoice();
				}
				else {
					return paidInvoice();
				}
			case 'appvers.CreditNotePolicyVersion':
				return creditNotePolicyVersion();
			default:
				if ( lastSettlement.id === null ) {
					return <p>{t('activateDeactivateLastSettlement.noLastSettlement')}</p>
				}
				else {
					return <DefaultSpinner loading={ true }/>
				}
		}
	}, [lastSettlement, creditNotePolicyVersion, notPaidInvoice, paidInvoice, t])

	return (
		<Card className={ "mt-3" }>
			<Card.Body>
				<Card.Subtitle className="mb-2 text-muted">{t('activateDeactivateLastSettlement.lastSettlement.label')}</Card.Subtitle>
				<Card.Text>
					{ lastSettlementElement }
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

ActivateDeactivateLastSettlement.propTypes = {
	policyVersionId: PropTypes.number,
	validTo: PropTypes.object,
	cancelLastInvoice: PropTypes.bool,
	setCancelLastInvoice: PropTypes.func,
}

export default ActivateDeactivateLastSettlement
