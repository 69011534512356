import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form";
import {Navigation} from "./Navigation";
import {useTranslation} from "react-i18next";
import EditableTableControl from "../../form/EditableTableControl";
import restService from "../../_services/rest.service";
import {Card, Form} from "react-bootstrap";
import headers from "../../security/headers";
import {SERVER_URL} from "../../config";
import {alertService} from "../../_services";
import {useHistory} from "react-router-dom";
import DiscountSurcharge from "../../policy/DiscountSurcharge";

function Details({damageData, dispatch, ...props}) {
    const {t} = useTranslation();
    const history = useHistory();
    const methods = useForm();
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const namedCriteria = useMemo( () => ( {queryName: "policyVersion", params: {id: damageData.baseData.policyVersion.id}} ), [damageData.baseData.policyVersion.id] );

    const loadMe = useCallback(() => {
        return damageData.baseData.policyVersion.id !== undefined
    }, [damageData.baseData.policyVersion.id]);

    const [policyTargets] = restService.useDomainInstancesList('policyTarget', 1, 100, undefined, undefined, undefined, namedCriteria, "select", loadMe)

    const handleFormSubmit = (data) => {
        setIsSubmitting(true);
        dispatch({type: 'DETAILS_CHANGE', data: data.damageDetails});
    }

    useEffect(() => {
        if ( isSubmitting ) {
            save()
                .then( (id) => {
                    redirectToShow(id)
                    setIsSubmitting(false)
                } )
                .catch( error => {
                    restService.handleServerErrors( error )
                    setIsSubmitting(false)
                } )
        }
    }, [isSubmitting]);

    const save = () => {
        return new Promise( (resolve, reject) => {
            const h = headers();
            let data = damageData.baseData;
            data['damageDetails'] = damageData.details;
            data['fileChanges'] = damageData.fileChanges;
            const params = { data: JSON.stringify( data ) }
            fetch( `${ SERVER_URL }/api/damage/saveOrUpdateDamage`, {
                method: 'POST',
                headers: h,
                body: JSON.stringify( params )
            } )
                .then( r => restService.handleServerResponse( r.clone() ) )
                .then( json => resolve(json.damageId) )
                .catch( reject );
        });
    }

    const redirectToShow = (id) => {
        history.push('/damage/show/'+id)
        alertService.success( t( 'default.updated', { what: t( 'damage.label' ) } ), { keepAfterRouteChange: true } );
    }

    const columns = [
        {
            id: "policyTarget",
            label: t("damageDetail.productAndBuilding"),
            input: {tag: "select", values: policyTargets, required: true, nullable: true},
            validator: (value,data,rowIndex) => {
                if ( !value ) {
                    return t('default.validationMessage.required')
                }
                else {
                    const found = policyTargets.find( ( pt ) => pt.id === parseInt( value.id ) )
                    return ( !found ) ? t('default.validationMessage.required') : undefined
                }
            }
        },
        {id: "description", label: t("damageDetail.description"), input: {tag: "input", type: "text"}},
        {id: "discountSurcharges", label: t("damageDetail.discountSurcharge"), input: {tag: "label"}, readonly:true,
            formatter: (cellContent, row) => {
                if ( row.participationType ) {
                    return row.participationType.label
                }
                else if ( row.policyTarget ) {
                    const policyTarget = policyTargets.find( (pt) => pt.id === parseInt(row.policyTarget.id) )
                    return <DiscountSurcharge policyTarget={policyTarget} />
                }
            }
        },
        {id: "participationAmount", label: t("damageDetail.participationAmount"), input: {tag: "numericFormat"}},
        {id: "reserveAmount", label: t("damageDetail.reserveAmount"), input: {tag: "numericFormat"}},
    ]

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <Card className={"mb-2"}>
                    <Card.Header>{t('damageDetail.card.info')}</Card.Header>
                    <Card.Body>
                        <EditableTableControl
                            name="damageDetails"
                            columns={columns}
                            data={damageData.details}
                        />
                        <Navigation isSubmitting={isSubmitting} damageData={damageData} {...props} />
                    </Card.Body>
                </Card>
            </Form>
        </FormProvider>
    )
}

export {Details}
