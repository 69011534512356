import React from 'react';
import {Button, ButtonGroup, Col, Dropdown, Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";
import {profitParticipationService} from "../_services/profitParticipation.service";

function ProfitParticipationSettlementBatchFormElements( {settlementBatch} ) {
    const {t} = useTranslation();

    const handlePrintRvSummary = () => {
        profitParticipationService.printRvSummary(settlementBatch.id, false)
    }

    const handlePrintRvSummaryDetailed = () => {
        profitParticipationService.printRvSummary(settlementBatch.id, true)
    }

	return (
        <>
            <Row>
                <ShowField label={'profitParticipationSettlementBatch.year'} object={settlementBatch} property={'year'} type={'text'} size={6}/>
            </Row>
            <Row>
                <Col sm={12}>
                    <Dropdown as={ ButtonGroup } size="sm" className={ "float-end" }>
                        <Button variant={'primary'} onClick={handlePrintRvSummary}>{t('profitParticipationSettlementBatchFormElements.printRvSummary.label')}</Button>

                        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic"/>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick = { handlePrintRvSummaryDetailed }>
                                {t('profitParticipationSettlementBatchFormElements.printRvSummary.detailed.label')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </>
    )
}

export default ProfitParticipationSettlementBatchFormElements