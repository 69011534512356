import React from 'react';
import {Row} from "react-bootstrap";
import ShowField from "../form/ShowField";

function FormElementsCreditNoteProfitParticipation( {settlement, onRefreshSettlement}) {
	return (
		<>
	        <Row>
		        <ShowField label={'creditNoteProfitParticipation.policy'} object={settlement} property={'policy.label'} type={'text'} link={{domain: 'policy', action:'view', newTab:true}}/>
	        </Row>
	        <Row>
	            <ShowField label={'creditNoteProfitParticipation.billingFrom'} object={settlement} property={'billingFrom'} type={'date'} size={4}/>
	            <ShowField label={'creditNoteProfitParticipation.billingTo'} object={settlement} property={'billingTo'} type={'date'} size={4}/>
	        </Row>
		</>
	)
}

export default FormElementsCreditNoteProfitParticipation