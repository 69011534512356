import React, {useEffect, useRef} from 'react';
import {Card} from "react-bootstrap";
import PropTypes from "prop-types";
import {configuratorService} from "../_services/configurator.service";

function ConfiguratorTargetHeader({ children, eventKey, onClick }) {
	const ref = useRef(null);

	useEffect( () => {
		configuratorService.registerConfiguratorHeader(eventKey, ref);
		return () => {
			configuratorService.unregisterConfiguratorHeader(eventKey);
		}
	}, [ref, eventKey]);

	return (
		<Card.Header
			className={"bg-success bg-opacity-10 cursor-pointer"}
			onClick={ () => {
				onClick(eventKey);
			} }
			ref={ref}
		>
			{children}
		</Card.Header>
	);
}

ConfiguratorTargetHeader.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element,PropTypes.array]),
	eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClick: PropTypes.func,
}

export { ConfiguratorTargetHeader };
