import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";
import bootstrapTableService from "../_components/bootstrapTable.service";

function DamageType({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			text: t('damageType.id'),
			hidden: true
		},
		{
			dataField: 'name',
			text: t('damageType.name'),
			sortField: 'name_sort',
			sort: true
		},
		{
			dataField: 'isElementalDamage',
			text: t('damageType.isElementalDamage'),
			formatter: bootstrapTableService.formatterBoolean,
			sort: true
		},
		{
			dataField: 'edCause.label',
			text: t( 'damageType.edCause' ),
			sortField: 'edCause.description_sort',
			sort: true
		},
	];

	return (
		<Index
			path={match.path}
			domainName={ 'damageType' }
			columns={ columns }
			toString={ (object) => object.name }
			formElements={ <FormElements/> }
			sortField={'name_sort'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { DamageType };
