import i18n from "i18next";
import store from "../store";

const headers = () => {
	const auth = store.getState().security.auth;
	return {
		'Authorization': 'Bearer ' + (auth ? auth.access_token : ""),
		'Accept-Language': i18n.language,
		'Content-Type': 'application/json',
		'Accept': 'application/json, text/plain, */*',
	}
}
export default headers
