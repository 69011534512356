import React, {useMemo, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup, Card, Col, Form, ToggleButton} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as BuildingFormElements} from "../building/FormElements";
import FormControl from "../_components/FormControl";
import Address from "./Address";

function CreatePolicyAddProductBuilding( props) {
	const { t } = useTranslation()
	const useFormObject = useForm();
	const stepValues = useMemo( () => ( props.values[props.stepName] ), [props.values, props.stepName] );
	const stepConfiguratorValues = useMemo( () => (props.values['addProductConfigurator']), [props.values] );
    const addressOrBuilding = useMemo( () => stepValues['addressOrBuilding'] || 'BUILDING', [stepValues] );
	const handleClickPrevious = () => {
		props.previousStep();
	}

	const onSubmit = (data) => {
		switch ( addressOrBuilding ) {
			case 'BUILDING':
				data.placeOfInsuranceZip = undefined;
				data.placeOfInsuranceStreet = undefined;
				break;
			case 'ADDRESS':
				data.building = undefined;
				break;
			default:
		}

		props.handleUpdate('addProductConfigurator', {...stepConfiguratorValues, ...{values: {}}})
		props.handleUpdate(props.stepName, data );
		props.nextStep();
	}

	const _handleUpdate = props.handleUpdate;
	const _stepName = props.stepName;

	const handleToggleButtonChange = useCallback( (e) => {
		_handleUpdate(_stepName, { ...stepValues, ...{addressOrBuilding: e.target.value} } );
	}, [_handleUpdate, _stepName, stepValues]);

	const addressOrBuildingElement = useMemo( () => {
		switch ( addressOrBuilding ) {
			case 'BUILDING':
				return <>
					<Form.Group as={Col} md="12" controlId="groupAddressOrBuilding">
						<Form.Label>
							Objekt
						</Form.Label>
						<RestAsyncSelectControl
							noSelection={true}
							domainName={"building"}
							sort={"street_sort"}
							name={'building'}
							createable={{formElements: <BuildingFormElements/>, target:'street'}}
						/>
					</Form.Group>
				</>
			case 'ADDRESS':
				return <>
					<Address namePrefix={'placeOfInsurance'}/>
				</>
			default:
				return undefined;
		}
	}, [addressOrBuilding])

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Card className={"mb-2"}>
						<Card.Header>
							<FormControl type={"text"} name={'addressOrBuilding'} hidden={true} value={addressOrBuilding}/>
							<ButtonGroup>
								{ [
									{value: 'BUILDING', label: t('createPolicy.building.building.label')},
									{value: 'ADDRESS', label: t('createPolicy.building.address.label')}
								].map( (option, idx) => {
									return (
										<ToggleButton
											size={"sm"}
											key = { idx }
											id={`radio-${option.value}-${idx}`}
											type = "radio"
											variant = { addressOrBuilding === option.value ? "primary" : "outline-primary" }
											name = { "addressOrBuilding" }
											value = { option.value }
											checked = { addressOrBuilding === option.value }
											onChange = { handleToggleButtonChange }
										>
											{option.label}
										</ToggleButton>
									)
								})}
							</ButtonGroup>
						</Card.Header>
						<Card.Body>
							{ addressOrBuildingElement }
						</Card.Body>
					</Card>
					<div className="create-policy-footer p-3">
						<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
						<Button type={"submit"} className='btn btn-default'>{t('default.next')}</Button>
					</div>
				</Form>
			</FormProvider>
		</div>
	);
}

export { CreatePolicyAddProductBuilding };
