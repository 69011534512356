import React, {useEffect, useMemo, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function CommissionSettlementBatchFilter( {settlementBatch, setData} ) {
    const { t } = useTranslation();
    const [year, setYear] = useState('');

    const _year = useMemo(() => (settlementBatch && settlementBatch.year) || '', [settlementBatch]);

    useEffect( () => {
        setYear(_year)
    }, [_year, setYear]);

    useEffect( () => {
        setData( {
            year: year,
        });
    }, [setData, year]);

	return (
        <>
            <p>{ t( 'commissionSettlementBatchFilter.message' ) }</p>
            <Row>
                <Form.Group as={ Col } md="6" controlId="formGroupYear">
                    <Form.Control
                        name={ "year" }
                        type={ "number" }
                        value={ year }
                        onChange={ ( event ) => setYear( event.target.value ) }
                        required={ true }
                    />
                </Form.Group>
            </Row>
        </>
	)
}

export default CommissionSettlementBatchFilter