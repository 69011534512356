import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


// the translations
// (tip move them in a JSON file and import them)
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';


// the translations
const resources = {
	en: {
		translation: translationEN
	},
	de: {
		translation: translationDE
	}
};

i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: "en", // use en if detected lng is not available
		load: 'languageOnly',
		keySeparator: false, // we do not use keys in form messages.welcome
		debug:true,
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
