import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import restService from "./_services/rest.service";

function LinkToDomain( {domain, text, action } ) {
	return (
        <>
            <Link to={restService.getTo( domain, action )} target={"_blank"} rel={"noopener noreferrer"}>{text === undefined ? domain.label : text}</Link>
            <br/>
        </>
	)
}

LinkToDomain.defaultProps = {
    action: 'show'
}

LinkToDomain.propTypes = {
    domain: PropTypes.object.isRequired,
    text: PropTypes.string
}

export default LinkToDomain