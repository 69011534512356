import React, {useEffect, useMemo} from 'react';
import AppRouter from './AppRouter';
import Login from './Login';
import {Container, Row, Col} from 'react-bootstrap';
import Spinner from "./_components/Spinner";
import {securityService} from "./_services/security.service";
import { useIdleTimer } from 'react-idle-timer'
import {useSelector} from "react-redux";
import restService from "./_services/rest.service";
import {useConfig} from "./_services/useConfig";
import {WebsocketProvider} from "./_components/WebsocketProvider";
import MessagePanel from "./_components/MessagePanel";
import UserMessagesListener from "./_components/UserMessagesListener";
import MessageBox from "./_components/MessageBox";
import {useTranslation} from "react-i18next";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import store from "./store";
import {isProduction} from "./config";
import settingService from "./_services/setting.service";

function App() {
    const [loggedIn, serverChecked] = securityService.useLoggedIn();
    const {setConfig} = useConfig();
    const {i18n} = useTranslation();

    useEffect( () => {
        securityService.resetRefreshTimer();
    }, [])

    useEffect(()=> {
        if ( loggedIn ) {
            if ( isProduction ) {
                LogRocket.init('wqwj2k/appvers');
                const auth = store.getState().security.auth;
                const username = auth && auth.username;
                LogRocket.identify(username, {
                    name: username,
                })
                setupLogRocketReact(LogRocket);
            }
            const controller = new AbortController();

            if ( i18n.resolvedLanguage === 'de' && i18n.language !== 'de-CH' ) {
                i18n.changeLanguage( 'de-CH' )
            }
            else if ( i18n.resolvedLanguage === 'en' && i18n.language !== 'en-CH' ) {
                i18n.changeLanguage( 'en-CH' )
            }

            restService.getApplicationConfig(
                controller,
                (configData) => {
                    setConfig(configData);
                }
            );
            return function cleanup() {
                controller.abort();
            }
        }
    }, [setConfig, loggedIn, i18n])

    const expiresIn = useSelector((state) => {
        let expiresIn = undefined;
        if ( state && state.security && state.security.auth ) {
            expiresIn = state.security.auth.expires_in;
        }
        return expiresIn;
    })

    const timerOpts = useMemo(() => {
        let result = {};
        if (expiresIn) {
            const timeout = expiresIn ? (expiresIn * 1000) : 0;
            result = {
                timeout: timeout,
                onIdle: () => {
                    console.log("You have been logged out after " + timerOpts.timeout/1000 + " seconds of inactivity.");
                    securityService.logOut();
                },
                debounce: 500,
                crossTab: {
                    emitOnAllTabs: true
                }
            }
        }
        return result;
    }, [expiresIn]);

    useIdleTimer( timerOpts );

    const login = ( loginUserDetails ) => {
        securityService.authenticateUser( loginUserDetails );
    };

    const logoutHandler = () => {
        securityService.logOut();
    };

    const contentForRoute = () => {
        if ( serverChecked ) {
            if ( loggedIn ) {
                return (
                    <Col className="d-flex page-content flex-column">
                        <WebsocketProvider>
                            <MessagePanel/>
                            <UserMessagesListener/>
                            <MessageBox/>
                            <AppRouter logoutHandler={logoutHandler}/>
                        </WebsocketProvider>
                    </Col>
                )
            } else {
                return (
                    <Col className="d-flex justify-content-center align-items-center bg-light">
                        <Col sm="7" md="5" lg="4" xl="3">
                            <Login callback={ login }/>
                        </Col>
                    </Col>
                )
            }
        }
        else {
            return <p>Loading&hellip;</p>
        }
    };

    return (
        <Container fluid>
            <Row>
                {contentForRoute()}
            </Row>
            <Spinner/>
        </Container>
    );
}

export default App;
