import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function AddButtons({path, label, addButtons, domainName}) {
    const { t } = useTranslation();

    const getAddButtons = () => {
        if ( addButtons ) {
            return addButtons
        }
        else {
            return (
                <>
                    {domainName &&
                        <Link to={ `${ path }/create` } className="btn btn-sm btn-success mt-3 mb-2">{ label ? label : t( 'default.addWhat', { what: t( `${ domainName }.label` ) } ) }</Link>
                    }
                </>
            )
        }
    }

    return (
        getAddButtons()
    )
}
export default AddButtons
