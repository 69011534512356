export const ConfiguratorTargetMethod = Object.freeze({
	INTERVALS:'INTERVALS',
	GRADUAL:'GRADUAL',
});

export const ConfiguratorTargetType = Object.freeze({
	VALUE:'VALUE',
	COUNT:'COUNT',
});

export const TariffUnit = Object.freeze({
	PERCENT:'PERCENT',
	CURRENCY:'CURRENCY',
});

export const PartnerType = Object.freeze({
	PERSON:'PERSON',
	COMPANY:'COMPANY',
});

export const InvoiceCycle = Object.freeze({
	YEARLY:'YEARLY',
	HALF_YEARLY:'HALF_YEARLY',
	QUARTERLY:'QUARTERLY',
	MONTHLY:'MONTHLY'
});

export const BaseTargetNameType = Object.freeze({
	DEFAULT:'DEFAULT',
	MANUAL:'MANUAL',
	BUILDING_NAME:'BUILDING_NAME'
});

export const PolicyVersionStatus = Object.freeze({
	ACTIVE:'ACTIVE',
	INACTIVE:'INACTIVE',
	REPLACEMENT:'REPLACED',
	IN_PROCESS:'IN_PROCESS',
	IN_PROCESS_CANCELLED:'IN_PROCESS_CANCELLED',
	OTHER: 'OTHER'
});

export const SettlementType = Object.freeze( {
	INVOICE_PERIODIC: 'INVOICE_PERIODIC',
	INVOICE_PRO_RATA: 'INVOICE_PRO_RATA',
	CREDIT_NOTE_POLICY_VERSION: 'CREDIT_NOTE_POLICY_VERSION',
	INVOICE_DAMAGE_PARTICIPATION_POLICY_HOLDER: 'INVOICE_DAMAGE_PARTICIPATION_POLICY_HOLDER',
	INVOICE_DAMAGE_PARTICIPATION_INVOLVED_INSURANCE: 'INVOICE_DAMAGE_PARTICIPATION_INVOLVED_INSURANCE',
	CREDIT_NOTE_DAMAGE_PAYMENT_NOTIFICATION: 'CREDIT_NOTE_DAMAGE_PAYMENT_NOTIFICATION',
	INVOICE_PARTICIPATION: 'INVOICE_PARTICIPATION',
	CREDIT_NOTE_PARTICIPATION: 'CREDIT_NOTE_PARTICIPATION',
	INVOICE_PROFIT_PARTICIPATION: 'INVOICE_PROFIT_PARTICIPATION',
	CREDIT_NOTE_PROFIT_PARTICIPATION: 'CREDIT_NOTE_PROFIT_PARTICIPATION',
	CREDIT_NOTE_COMMISSION: 'CREDIT_NOTE_COMMISSION',
	CREDIT_NOTE_OTHER_LEADERSHIP_DISCOUNT: 'CREDIT_NOTE_OTHER_LEADERSHIP_DISCOUNT'
});

export const SettlementCancelReason = Object.freeze( {
	NOT_CANCELLED: 'NOT_CANCELLED',
	OTHER: 'OTHER'
});

export const UserAttachmentType = Object.freeze( {
	SIGNATURE: 'SIGNATURE',
	OTHER: 'OTHER'
});

export const PaymentState = Object.freeze( {
	UNPAID: 'UNPAID',
	PAID_PARTIALLY: 'PAID_PARTIALLY',
	PAID: 'PAID',
	OVERPAID: 'OVERPAID',
	PAYMENT_NOT_NECESSARY: 'PAYMENT_NOT_NECESSARY',
});

export const AccountingDocumentEvent = Object.freeze( {
	BOOK: 'BOOK',
	CANCEL: 'CANCEL',
});

export const AccountingDocumentOrigin = Object.freeze( {
	FINANCE: 'FINANCE',
	DEBTORS: 'DEBTORS',
	CREDITORS: 'CREDITORS',
	OTHER: 'OTHER',
});

export const InnerPredicateEnum = Object.freeze( {
	MUST: 'MUST',
	MUST_NOT: 'MUST_NOT',
	AT_LEAST: 'AT_LEAST',
	GREATER_THAN: 'GREATER_THAN',
	AT_MOST: 'AT_MOST',
	LESS_THAN: 'LESS_THAN'
});

export function getEnumType(enumClassName, value) {
	return { enumType: enumClassName, name: value }
}
