import React, {useCallback, useMemo} from 'react'
import PropTypes from "prop-types";
import restService from "../_services/rest.service";
import {Button, ButtonGroup, Card, Dropdown as ReactDropdown, Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import PreviewSettlementItems from "./PreviewSettlementItems";
import websocketService from "../_services/websocket.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {policyService} from "../_services/policy.service";
import {invoiceService} from "../_services/invoice.service";
import SettlementPrintedMessageListener from "./SettlementPrintedMessageListener";
import PreviewAccounting from "../accounting/PreviewAccounting";
import PrintInvoicePolicyVersionWithSettlementBatch from "./FormElementsInvoicePolicyVersion";

function PreviewSettlement({id}) {
	const {t} = useTranslation()
	const [settlement, setSettlement] = restService.useDomainInstance('settlement', id)

	const nr = (settlement && settlement.className) === 'appvers.InvoicePolicyVersion' ? 'invoiceNr' : 'creditNoteNr'

	const refreshSettlement = useCallback( () => {
		console.log('refreshSettlement called')
		restService.getDomainInstance( 'settlement', id )
			.then( (result) => setSettlement(result) )
			.catch( error => restService.handleServerErrors( error ) )
	}, [id, setSettlement])

	const afterUpdateSettlementCallback = useCallback( (changes) => {
		console.log('changes.payload.updatedProperties', changes.payload.updatedProperties)
		if ( changes.payload.updatedProperties.includes( 'booked' ) || changes.payload.updatedProperties.includes( 'total' ) || changes.payload.updatedProperties.includes( 'printWithSettlementBatch' ) ) {
			refreshSettlement();
		}
	}, [refreshSettlement]);
	const ids = useMemo( () => [id], [id] );
	websocketService.useAfterUpdateSubscription('settlement', ids, afterUpdateSettlementCallback);



	const handleBookInvoice = useCallback( () => {
		invoiceService.bookSettlement(settlement.id)
	}, [settlement])


	const handlePrintSettlement = useCallback( (withLogo = true) => {
		policyService.printSettlement( settlement.id, settlement.number, settlement.policyNumber, settlement.partner.label, settlement.className, withLogo )
			.then( () => { /*invoicePolicyVersion printed*/} )
	}, [settlement]);

	const formElements = useMemo( () => {
		const formElements = invoiceService.getSettlementFormElements(settlement.className)
		let i = 0
		let result = {formElements: [], tabs: []}
		result.formElements = formElements.formElements.map( (element) => {
			const TagName = element
			return <TagName key={++i} settlement={settlement} onRefreshSettlement={refreshSettlement}/>
		})

		result.tabs.push(
			<Tab eventKey="settlementItems" title={t('settlement.settlementItems.label')} mountOnEnter={true}>
				<PreviewSettlementItems settlement={settlement} settlementClassName={settlement.className} refreshSettlement={refreshSettlement}/>
			</Tab>
		)

		result.tabs.push(
			<Tab eventKey="acccounting" title={t('settlement.accounting.label')} mountOnEnter={true}>
				<PreviewAccounting accountableObject={settlement}/>
			</Tab>
		)

		formElements.tabs.forEach( (tab) => {
			const TagName = tab.content
			const positionIdx = ( tab.positionIdx === undefined ? result.tabs.length : tab.positionIdx )
			console.log(tab.eventKey)
			result.tabs.splice(positionIdx, 0, <Tab key={++i} eventKey={tab.eventKey} title={tab.title} settlement={settlement} mountOnEnter={true}>
				<TagName settlement={settlement}/>
			</Tab> )
		})
		return result;
	}, [settlement, refreshSettlement, t])

	return (
		<>
			<SettlementPrintedMessageListener settlementId={id}/>
			<Card>
				<Card.Header>
					<h4 className={"float-start"}>{settlement && settlement.type} {settlement && settlement[nr]}</h4>
					{settlement.className === 'appvers.InvoicePolicyVersion' && <span className={"ms-1 float-end"}><PrintInvoicePolicyVersionWithSettlementBatch settlement={settlement} onRefreshSettlement={refreshSettlement}/></span>}
					{!settlement.booked && <Button size={'sm'}  className={"ms-1 float-end"} variant={"warning"} onClick={handleBookInvoice}>{t('settlementBatch.book')}</Button>}
					<ReactDropdown as={ButtonGroup} size="sm" className={"float-end"}>
						<Button variant={"primary"} onClick={ () => handlePrintSettlement(true) }>
							<FontAwesomeIcon icon={faFilePdf}/>
						</Button>
						<ReactDropdown.Toggle split variant={"primary"}/>
						<ReactDropdown.Menu>
							<ReactDropdown.Item onClick={ ( event ) => {
								handlePrintSettlement(false);
							}}>{t('default.withoutLogo.asPdf.label')}</ReactDropdown.Item>
						</ReactDropdown.Menu>
					</ReactDropdown>
				</Card.Header>

				<Card.Body>
					{ formElements.formElements }
					<Tabs defaultActiveKey="settlementItems" id="damage-tabs" className={'mt-3'}>
						{ formElements.tabs }
					</Tabs>
				</Card.Body>
			</Card>
		</>

	)
}

export default PreviewSettlement

PreviewSettlement.propTypes = {
	id: PropTypes.number,
	className: PropTypes.string
};
