import React, {useMemo} from 'react';
import {Payments} from "../payment/Payments";

function TabPayments({settlement}) {
	const searchOptions = useMemo( () => ( {queryStrings: [{field: 'invoice.id', text: `${settlement.id}`}]} ), [settlement.id] );

	return (
		<Payments searchOptions={searchOptions} mountOnEnter={true} />
	)
}

export default TabPayments