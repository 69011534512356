import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, ButtonGroup, Card, Col, Form, InputGroup, Row, ToggleButton} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import FormControl from "../_components/FormControl";
import {useTranslation} from "react-i18next";
import moment from 'moment'
import 'react-dropzone-uploader/dist/styles.css'
import SwitchButtonControl from "../_components/SwitchButtonControl";
import DropdownControl from "../_components/DropdownControl";
import {getEnumType, InvoiceCycle} from "../_enum/enum";
import {partnerService} from "../_services/partner.service";
import {reactUtilsService} from "../_services/reactUtils.service";
import {policyService} from "../_services/policy.service";

function CreatePolicyPolicy(props) {
	const { t } = useTranslation()
	const useFormObject = useForm();
	const stepValues = useMemo( () => (props.values[props.stepName]), [props.values, props.stepName] );
	const detailValues = useMemo( () => (props.values['detail']), [props.values] );
	const [policyHolder] = useState(stepValues.policyHolder);
	const [attachments] = useState(stepValues.attachments);
	const [dueMonth, setDueMonth] = useState(stepValues.dueMonth);
	const [insured, setInsured] = useState(stepValues.insured);
	const [invoiceTo, setInvoiceTo] = useState(stepValues.invoiceTo);
	const [buildingManager] = useState(stepValues.buildingManager);
	const [validFrom, setValidFrom] = useState(stepValues.validFrom);
	const [contractValidityPeriod, setContractValidityPeriod] = useState(stepValues.contractValidityPeriod);
	const [otherLeadership, setOtherLeadership] = useState(stepValues.otherLeadership);
	const [collectivePolicy, setCollectivePolicy] = useState(stepValues.collectivePolicy);
	const [leadingCompany, setLeadingCompany] = useState(stepValues.leadingCompany);
	const [profitParticipation, setProfitParticipation] = useState(stepValues.profitParticipation);
	const isFirstPolicyVersion = useMemo( () => stepValues.isFirstPolicyVersion, [stepValues] );
	const [brokerOrConsultant, setBrokerOrConsultant] = useState(stepValues.broker ? 'BROKER' : 'CONSULTANT');
	const [broker, setBroker] = useState(stepValues.broker);
	const [brokerInterestRate, setBrokerInterestRate] = useState(stepValues.brokerInterestRate);
	const brokerInterestRates = partnerService.useBrokerInterestRates(broker && broker.id, validFrom);
	const brokerHasChanged = reactUtilsService.useHasChanged(broker);
	const brokerInterestRatesHasChanged = reactUtilsService.useHasChanged(brokerInterestRates);

	const validToCalculated = policyService.useValidToCalculated( validFrom, contractValidityPeriod, dueMonth );

	const onSubmit = (data) => {
		data.invoiceCycle = getEnumType( 'appvers.enums.InvoiceCycle', data.invoiceCycle )
		props.handleUpdate(props.stepName, data);
		props.nextStep();
	}

	const handlePolicyholderOnChange = (value) => {
		if ( !insured ) {
			setInsured(value);
		}
		if ( !invoiceTo ) {
			setInvoiceTo(value);
		}
	}

	const isCollectivePolicyWithOwnLeadership = (otherLeadership, collectivePolicy) => {
		return (collectivePolicy && !otherLeadership)
	}

	const isProfitParticipation = (profitParticipation) => {
		return profitParticipation
	}

	const isOnlyJanuaryDuePossible = (otherLeadership, collectivePolicy, profitParticipation) => {
		return  isCollectivePolicyWithOwnLeadership(otherLeadership, collectivePolicy) || isProfitParticipation(profitParticipation)
	};

	const getDueDateValidationMessage = (otherLeadership, collectivePolicy, profitParticipation) => {
		let messages = []
		if ( isOnlyJanuaryDuePossible(otherLeadership, collectivePolicy) ) {
			messages.push( t('createPolicy.policy.isCollectivePolicyWithOwnLeadership') );
		}
		if ( isProfitParticipation(profitParticipation) ) {
			messages.push( t('createPolicy.policy.isProfitParticipation') );
		}

		return messages.join(' ')
	}

	const refreshDueMonth = useCallback((validFrom) => {
		if ( isFirstPolicyVersion ) {
			const mmnt = moment( validFrom );
			if ( mmnt && mmnt.isValid() ) {
				let month = mmnt.month() + 1 //moment.month() is indexed from 0
				if ( mmnt.date() > 1 ) {
					month = ( month === 12 ? 1 : ( month + 1 ) )
				}

				setDueMonth( month );
			}
		}
	},[isFirstPolicyVersion])

	useEffect( () => {
		if ( validFrom && !dueMonth) {
			refreshDueMonth( validFrom );
		}

		if (brokerHasChanged) {
			//console.log(`brokerInterestRates ${JSON.stringify(brokerInterestRates)}`)
			setBrokerInterestRate(null);
		}
		if ( brokerInterestRatesHasChanged ) {
			if ( brokerInterestRates && Object.keys(brokerInterestRates).length > 0 ) {
				setBrokerInterestRate( brokerInterestRates[Object.keys(brokerInterestRates)[0]] );
			}
		}
	}, [validFrom, contractValidityPeriod, stepValues.contractValidityPeriod, brokerHasChanged, brokerInterestRatesHasChanged, brokerInterestRates, dueMonth, attachments, refreshDueMonth])

	const handlecontractValidityPeriodChanged = (event) => {
		setContractValidityPeriod(event.target.value);
	}

	const handleValidFromBlur = (event) => {
		setValidFrom(event.target.value);
		refreshDueMonth(event.target.value);
	}

	const insurancesHsNamedRestriction = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "insurance",
					params: {}
				}
		}
	}, [])

	const hsNamedCriteriaBroker = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "broker",
					params: {}
				}
		}
	}, [])

	const hsNamedCriteriaBuildingManager = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "buildingManager",
					params: {}
				}
		}
	}, [])

	const brokerOrConsultantElement = useMemo( () => {
		switch( brokerOrConsultant ) {
			case 'CONSULTANT':
				return (
					<Form.Group as={Col} md="12" controlId="groupBroker">
						<Form.Label>{t('createPolicy.version.consultant.label')}</Form.Label>
						<RestAsyncSelectControl
							noSelection={true}
							domainName={ "partnerConsultantScope" }
							sort={ "name_sort" }
							value={ stepValues.partnerConsultantScope ? stepValues.partnerConsultantScope.id : null }
							label={ stepValues.partnerConsultantScope ? stepValues.partnerConsultantScope.label : null }
							name={ 'partnerConsultantScope' }
							rules={{
								required: true
							}}
						/>
					</Form.Group>
				)
			case 'BROKER':
				return (
					<Row>
						<Form.Group as={Col} md="8" controlId="groupBroker">
							<Form.Label>{t('createPolicy.version.broker.label')}</Form.Label>
							<RestAsyncSelectControl
								noSelection={true}
								domainName={ "partner" }
								sort={ "fullName_sort" }
								value={ broker ? broker.id : null }
								label={ broker ? broker.label : null }
								name={ 'broker' }
								searchOptions={ hsNamedCriteriaBroker }
								onChange={ (value)=>setBroker(value) }
								validationMessages={{
									brokerProducts: t('createPolicy.brokerProducts.warning'),
								}}
								rules={{
									required: true,
									validate: {
										brokerProducts: (v) => {
											const targets = detailValues.targets ? detailValues.targets : [];
											const vId = v === null ? null : v.id;
											const productWithAnotherBroker = targets.some( (target) => target.broker && target.broker.id !== vId )
											return !productWithAnotherBroker;
										}
									},
								}}
							/>
						</Form.Group>
						{ broker && broker.id &&
							<Form.Group as={ Col } md="4" controlId="groupBrokerInterestRate">
								<Form.Label>{ t( 'createPolicy.version.brokerInterestRate.label' ) }</Form.Label>
								<InputGroup>
									{ brokerInterestRates && Object.keys(brokerInterestRates).length > 0 &&
										<DropdownControl
											name={ 'brokerInterestRateText' }
											options={ Object.keys( brokerInterestRates ).map( ( key ) => ( {
												value: brokerInterestRates[key],
												label: key
											} ) ) }
											keyPropName={ 'value' }
											valuePropName={ 'label' }
											value={ brokerInterestRate }
											onChange={ ( event ) => setBrokerInterestRate( event.target.value ) }
										/>
									}
									<FormControl
										name={ "brokerInterestRate" }
										type={ "number" }
										value={ brokerInterestRate }
										validationMessages={{
											range: t('default.validationMessage.range', {from:"0", to:"100"}),
										}}
										rules={{
											validate: {
												range: (v) => 0<=v && v<=100
											}
										}}
										onChange={ (event) => setBrokerInterestRate(event.target.value) }
									/>
									<InputGroup.Text>&#37;</InputGroup.Text>
								</InputGroup>
							</Form.Group>
						}
					</Row>)
			default:
				throw new Error(`Unknown broker or consultant value: ${brokerOrConsultant}`);
		}
	}, [broker, hsNamedCriteriaBroker, stepValues.partnerConsultantScope, t, detailValues.targets, brokerInterestRate, brokerInterestRates, brokerOrConsultant])

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<FormControl hidden={true}
						name={'id'}
						type="number"
						value={stepValues.id}
					/>
					<FormControl hidden={true}
					             name={'policyVersionId'}
					             type="number"
					             value={stepValues.policyVersionId}
					/>
					<FormControl hidden={true}
								 name={'versionPolicy'}
								 type="number"
								 value={stepValues.versionPolicy}
					/>
					<FormControl hidden={true}
								 name={'versionPolicyVersion'}
								 type="number"
								 value={stepValues.versionPolicyVersion}
					/>
					<FormControl hidden={true}
					             name={'memo'}
					             type="text"
					             value={stepValues.memo}
					/>
					<FormControl hidden={true}
					             name={'additionalText'}
					             type="text"
					             value={stepValues.additionalText}
					/>
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.policy.baseData.header')}</Card.Header>
					<Card.Body>
						<Row className={"mb-3"}>
							<Form.Group as={Col} md="12" controlId="groupPolicyholder">
								<Form.Label>{t('createPolicy.version.policyHolder.label')}</Form.Label>
								<RestAsyncSelectControl
									domainName={"partner"}
									sort={"fullName_sort"}
									value={policyHolder ? policyHolder.id : null}
									label={policyHolder ? policyHolder.label : null}
									name={'policyHolder'}
									onChange={handlePolicyholderOnChange}
									rules={{
										required: true
									}}
								/>
							</Form.Group>
						</Row>
						<Row className={"mb-3"}>
							<Form.Group as={Col} md="12" controlId="groupInsured">
								<Form.Label>{t('createPolicy.version.insured.label')}</Form.Label>
								<RestAsyncSelectControl
									domainName={"partner"}
									sort={"fullName_sort"}
									value={insured ? insured.id : null}
									label={insured ? insured.label : null}
									name={'insured'}
									rules={{
										required: true
									}}
								/>
							</Form.Group>
						</Row>
						<Row className={"mb-3"}>
							<Form.Group as={Col} md="12" controlId="groupInvoiceTo">
								<Form.Label>{t('createPolicy.version.invoiceTo.label')}</Form.Label>
								<RestAsyncSelectControl
									domainName={"partner"}
									sort={"fullName_sort"}
									value={invoiceTo ? invoiceTo.id : null}
									label={invoiceTo ? invoiceTo.label : null}
									name={'invoiceTo'}
									rules={{
										required: true
									}}
								/>
							</Form.Group>
						</Row>
						<Row className={"mb-3"}>
							<Form.Group as={Col} md="12" controlId="groupBuildingManager">
								<Form.Label>{t('createPolicy.version.buildingManager.label')}</Form.Label>
								<RestAsyncSelectControl
									domainName={"partner"}
									sort={"fullName_sort"}
									value={buildingManager ? buildingManager.id : null}
									label={buildingManager ? buildingManager.label : null}
									name={'buildingManager'}
									searchOptions={ hsNamedCriteriaBuildingManager }
								/>
							</Form.Group>
						</Row>
						<Row className={"mb-3"}>
							<Form.Group as={Col} md="4" controlId="groupValidFrom">
								<Form.Label>{t('createPolicy.version.validFrom')}</Form.Label>
								<FormControl
									name={"validFrom"}
									type={"date"}
									value= {stepValues.validFrom}
									onBlur={handleValidFromBlur}
									validationMessages={{
										range: t('default.validationMessage'),
									}}
									rules={{
										required: true,
										validate: {
											range: (v) => {
												const min = moment( [1900] );
												const max = moment( [2100] );
												const val = moment(v);
												return val.isValid() && min <= val && val <= max;
											}
										}
									}}
								/>
							</Form.Group>
							<Form.Group as={Col} md="4" controlId="groupContractValidityPeriod">
								<Form.Label>{t('createPolicy.version.contractValidityPeriod.label')}</Form.Label>
								<InputGroup>
									<FormControl
										name={"contractValidityPeriod"}
										type={"number"}
										value= {stepValues.contractValidityPeriod}
										onChange = {handlecontractValidityPeriodChanged}
										validationMessages={{
											range: t('default.validationMessage.range', {from:"1", to:"100"}),
										}}
										rules={{
											required: true,
											validate: {
												range: (v) => 1<=v && v<=100
											}
										}}
									/>
									<InputGroup.Text>{t('default.year' + (contractValidityPeriod==="1"?'':'s'))}</InputGroup.Text>
								</InputGroup>
							</Form.Group>
							<Form.Group as={Col} md="4" controlId="groupValidTo">
								<Form.Label>{t('createPolicy.version.validTo')}</Form.Label>
								<FormControl
									disabled={true}
									name={"validTo"}
									type={"date"}
									value= {validToCalculated}
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md="4" controlId="groupOfferValidTo">
								<Form.Label>{t('createPolicy.version.offerValidTo.label')}</Form.Label>
								<FormControl
									name={"offerValidTo"}
									type={"date"}
									value= {stepValues.offerValidTo}
									validationMessages={{
										range: t('default.validationMessage'),
									}}
									rules={{
										required: true,
										validate: {
											range: (v) => {
												const min = moment( [1900] );
												const max = moment( [2100] );
												const val = moment(v);
												return val.isValid() && min <= val && val <= max;
											}
										}
									}}
								/>
							</Form.Group>
							<Form.Group as={Col} md="4" controlId="groupDueMonth">
								<Form.Label>{t('createPolicy.version.dueMonth.label')}</Form.Label>
								<DropdownControl
									name={'dueMonth'}
									options={[1,2,3,4,5,6,7,8,9,10,11,12].map((month) => ({id:month, label:'1.'+month+'.'}))}
									keyPropName={'id'}
									valuePropName={'label'}
									value={dueMonth}
									onChange={(e) => setDueMonth(e.target.value)}
									validationMessages={{
										isOnlyJanuaryDuePossible: getDueDateValidationMessage(otherLeadership, collectivePolicy, profitParticipation)
									}}
									rules={{
										required: true,
										validate: {
											isOnlyJanuaryDuePossible: (v) => {
												return !isOnlyJanuaryDuePossible(otherLeadership, collectivePolicy, profitParticipation) || parseInt(v) === 1;
											}
										}
									}}
								/>
							</Form.Group>

							<Form.Group as={Col} md="4" controlId="groupInvoiceCycle">
								<Form.Label>{t('createPolicy.version.invoiceCycle.label')}</Form.Label>
								<DropdownControl
									name={'invoiceCycle'}
									options={Object.values(InvoiceCycle).map((item) => ({id:item, label: t('appvers.enums.InvoiceCycle.' + item)}))}
									keyPropName={'id'}
									valuePropName={'label'}
									value={stepValues.invoiceCycle ? stepValues.invoiceCycle.name : InvoiceCycle.YEARLY}
									rules={{
										required: true
									}}
								/>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>

				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.policy.leadingData.header')}</Card.Header>
					<Card.Body>
						<Row className={"mb-3"}>
							<Col as={Col} md="4">
								<SwitchButtonControl
									value={otherLeadership}
									offlabel={t("createPolicy.version.otherLeadership.offlabel")}
									onlabel={t("createPolicy.version.otherLeadership.onlabel")}
									name={"otherLeadership"}
									onChange={ ( value)=>setOtherLeadership(value) }
								/>
							</Col>
							<Col as={Col} md="4">
								<SwitchButtonControl
									value={collectivePolicy}
									offlabel={t("createPolicy.version.collectivePolicy.offlabel")}
									onlabel={t("createPolicy.version.collectivePolicy.onlabel")}
									name={"collectivePolicy"}
									onChange={ ( value)=>setCollectivePolicy(value) }
								/>
							</Col>

						</Row>
						{ otherLeadership &&
							<Row>
								<Form.Group as={ Col } md="8" controlId="groupLeadingCompany">
									<Form.Label>{ t( 'createPolicy.version.leadingCompany.label' ) }</Form.Label>
									<RestAsyncSelectControl
										noSelection={true}
										domainName={ "partner" }
										sort={ "fullName_sort" }
										value={ leadingCompany ? leadingCompany.id : null }
										label={ leadingCompany ? leadingCompany.label : null }
										name={ 'leadingCompany' }
										onChange={(value) => setLeadingCompany(value)}
										searchOptions={ insurancesHsNamedRestriction }
										validationMessages={{
											emptyLeadingCompany: t('default.validationMessage.required'),
										}}
										rules={{
											validate: {
												emptyLeadingCompany: (v) => !(otherLeadership && !v)
											}
										}}
									/>
								</Form.Group>
								<Form.Group as={Col} md="4" controlId="groupCollectivePolicyNr">
									<Form.Label>{t('createPolicy.version.collectivePolicyNr.label')}</Form.Label>
									<FormControl
										name={"collectivePolicyNr"}
										type={"text"}
										value= {stepValues.collectivePolicyNr}
										validationMessages={{
											emptyPolicyNr: t('default.validationMessage.required'),
										}}
										rules={{
											validate: {
												emptyPolicyNr: (v) => !(leadingCompany && !v)
											}
										}}
									/>
								</Form.Group>
							</Row>
						}
					</Card.Body>
				</Card>
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.version.profitParticipation.header')}</Card.Header>
					<Card.Body>
						<Row>
							<Form.Group as={Col} md="5" controlId="groupLeadingSwithButtons">
								<SwitchButtonControl
									value={profitParticipation}
									offlabel={t("createPolicy.version.profitParticipation.offlabel")}
									onlabel={t("createPolicy.version.profitParticipation.onlabel")}
									name={"profitParticipation"}
									onChange={ ( value)=>setProfitParticipation(value) }
								/>
							</Form.Group>
						</Row>
						{ profitParticipation &&
							<Row>
								<Form.Group as={ Col } md="6" controlId="groupProfitParticipationAnsatz">
									<Form.Label>{ t( 'createPolicy.version.groupProfitParticipationAnsatz.label' ) }</Form.Label>
									<InputGroup>
										<FormControl
											name={ "profitParticipationAnsatz" }
											type={ "number" }
											value={ stepValues.profitParticipationAnsatz }
										/>
										<InputGroup.Text>&#37;</InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Row>
						}
					</Card.Body>
				</Card>

				<Card className={"mb-2"}>
					<Card.Header>
						<FormControl type={"text"} name={'brokerOrConsultant'} hidden={true} value={brokerOrConsultant}/>
						<ButtonGroup>
							{ [
								{value: 'CONSULTANT', label: t('createPolicy.version.consultant.label')},
								{value: 'BROKER', label: t('createPolicy.version.broker.label')},
							].map( (option, idx) => {
								return (
									<ToggleButton
										size={"sm"}
										key = { idx }
										id={`radio-${option.value}-${idx}`}
										type = "radio"
										variant = { brokerOrConsultant === option.value ? "primary" : "outline-primary" }
										name = { "addressOrBuilding" }
										value = { option.value }
										checked = { brokerOrConsultant === option.value }
										onChange = { (e) => setBrokerOrConsultant(e.target.value) }
									>
										{option.label}
									</ToggleButton>
								)
							})}
						</ButtonGroup>
					</Card.Header>
					<Card.Body>
						{ brokerOrConsultantElement }
					</Card.Body>
				</Card>
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.version.assistance.header')}</Card.Header>
					<Card.Body>
						<Row>
							<Form.Group as={Col} md="4" controlId="groupAssistancePolicyNr">
								<Form.Label>{t('createPolicy.version.assistancePolicyNr.label')}</Form.Label>
								<FormControl
									name={"assistancePolicyNr"}
									type={"text"}
									value={stepValues.assistancePolicyNr}
								/>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
				<div className="create-policy-footer p-3">
					<Button type={"submit"} className='btn btn-default'>{t('default.next')}</Button>
				</div>
			</Form>
			</FormProvider>
		</div>
	);
}

export { CreatePolicyPolicy };
