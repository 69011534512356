import React from 'react';
import textService from "../_services/text.service";
import {useTranslation} from "react-i18next";
import {Table} from "react-bootstrap";

function AccountingRecord( {accountingRecord} ) {
	const {t} = useTranslation()

	const getAccountingOpenPost = () => {
		if ( accountingRecord.accountingOpenPost ) {
			const op = accountingRecord.accountingOpenPost

			return (
				<tr>
					<td colSpan={ 6 }>
						<Table className={ "ms-3 accounting-openpost-table" }>
							<thead>
							<tr>
								<th>{ t( 'accountingOpenPost.opId.label' ) }</th>
								<th>{ t( 'accountingOpenPost.pkKey.label' ) }</th>
								<th>{ t( 'accountingOpenPost.partner.label' ) }</th>
								<th>{ t( 'accountingOpenPost.amount.label' ) }</th>
								<th>{ t( 'accountingOpenPost.text.label' ) }</th>
								<th>{ t( 'accountingOpenPost.dateO.label' ) }</th>
								{ op.classSimpleName === 'AccountingOpenPostDeb' ? <th>{ t( 'accountingOpenPostDeb.dateD.label' ) }</th> : undefined }
								{ op.classSimpleName === 'AccountingOpenPostDeb' ? <th>{ t( 'accountingOpenPostDeb.ref.label' ) }</th> : undefined }
							</tr>
							</thead>
							<tbody>
								<tr>
									<td>{ textService.format( op.opId, 'text' ) }</td>
									<td>{ textService.format( op.pkKey, 'text' ) }</td>
									<td>{ textService.format( op.partner.label, 'text' ) }</td>
									<td>{ textService.format( op.amount, 'number' ) }</td>
									<td>{ textService.format( op.text, 'text' ) }</td>
									<td>{ textService.format( op.dateO, 'date' ) }</td>
									{ ( op.classSimpleName === 'AccountingOpenPostDeb' && op.dateD ) ? <td>{  textService.format( op.dateD, 'date' ) }</td> : undefined }
									{ ( op.classSimpleName === 'AccountingOpenPostDeb' && op.ref )? <td>{  textService.format( op.ref, 'text' ) }</td> : undefined }
								</tr>
							</tbody>
						</Table>
					</td>
				</tr>
			)
		}
		else {
			return undefined
		}
	}

	return (
		<>
			<tr>
				<td>{ textService.format( accountingRecord.date, 'date' ) }</td>
				<td>{ accountingRecord.account }</td>
				<td>{ textService.format( accountingRecord.type, 'appvers.enums.BookingType') }</td>
				<td>{ textService.format( accountingRecord.amount, 'number') }</td>
				<td>{ textService.format( accountingRecord.text, 'text') }</td>
			</tr>
			{getAccountingOpenPost()}
		</>
	)
}

export default AccountingRecord