import {useEffect, useState} from "react";
import restService from "./rest.service";
import headers from "../security/headers";
import {SERVER_URL} from "../config";

export const youTrackService = {
	useChangelogIssues
}

function useChangelogIssues() {
	const [result, setResult] = useState( {} );

	useEffect(() => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/application/getResolvedYouTrackIssues`, {
			method: 'GET',
			headers: h,
			signal: controller.signal,
		} )
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => {
				let issuesByVersion = {}
				json.forEach( issue => {
					const versions = issue.customFields.find(field => field.id==="110-225").value
					versions.forEach( version => {
						if ( !issuesByVersion[version.name] ) {
							issuesByVersion[version.name] = [];
						}
						const assigneeFieldValue = issue.customFields.find(field => field.id === "111-24").value;
						const stateFieldValue = issue.customFields.find(field => field.id === "110-226").value
						issuesByVersion[version.name].push( {
							id: issue.idReadable,
							summary: issue.summary,
							assignee: assigneeFieldValue && assigneeFieldValue.name,
							state: stateFieldValue && stateFieldValue.name,
						});
					})
				})

				function comparePartials(a, b) {
					if (a === b) {
						return 0;
					}
					let splitA = a.split('.');
					let splitB = b.split('.');
					const length = Math.max(splitA.length, splitB.length);
					for (let i = 0; i < length; i++) {
						if (parseInt(splitA[i]) > parseInt(splitB[i]) ||
							((splitA[i] === splitB[i]) && isNaN(splitB[i + 1]))) {
							return 1;
						}
						if (parseInt(splitA[i]) < parseInt(splitB[i]) ||
							((splitA[i] === splitB[i]) && isNaN(splitA[i + 1]))) {
							return -1;
						}
					}
				}

				setResult( {
					sortedVersions: Object.keys(issuesByVersion).sort(comparePartials).reverse(),
					issues: issuesByVersion
				} );
			} )
			.catch( (error) => restService.handleServerErrors( error, controller.signal ) );

		return function cleanup() {
			controller.abort()
		}
	}, []);

	return result;
}