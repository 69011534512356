import React from 'react';
import PreviewSettlementBatch from "../_components/_settlementBatch/PreviewSettlementBatch";
import PolicyVersionSettlementBatchFormElements from "./PolicyVersionSettlementBatchFormElements";
import PolicyVersionSettlementBatchAdditionalPrintDropdowns
	from "./PolicyVersionSettlementBatchAdditionalPrintDropdowns";

function PreviewPolicyVersionSettlementBatch( { id } ) {
	return (
		<PreviewSettlementBatch
			className={'policyVersionSettlementBatch'}
			id={ parseInt(id) }
			settlementBatchFormElements={<PolicyVersionSettlementBatchFormElements />}
			additionalPrintDropdowns={<PolicyVersionSettlementBatchAdditionalPrintDropdowns />}
		/>
	);
}

export default PreviewPolicyVersionSettlementBatch
