

function damagePaymentInfoBaseDataReducer (state, action) {
    switch (action.type) {
        case 'BASE_DATA_CHANGE':
            return { ...state, ...action.data}
        default:
            return state;
    }
}

function damagePaymentInfoDetailsReducer (state, action) {
    switch (action.type) {
        case 'DETAILS_CHANGE':
            return JSON.parse(JSON.stringify(action.data));
        default:
            return state;
    }
}

export default function damagePaymentInfoReducer (state, action) {
    return {
        baseData: damagePaymentInfoBaseDataReducer( state.baseData, action ),
        details: damagePaymentInfoDetailsReducer( state.details, action ),
    }
}
