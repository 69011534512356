import React from 'react';
import {Form, FormControl, Button, Card, InputGroup} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logoAppvers from './images/logo_appvers_with_name.svg';
import {Alert} from "./_components";
import {faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Login = ({callback}) => {
	const { t } = useTranslation()

	function handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		if ( form.checkValidity() === true ) {
			const formData = new FormData(event.target)
			const data = Object.fromEntries(formData.entries())

			callback( data );
		}
	}

	return (
		<Card className="my-5">
			<Card.Body>
				<div className="text-center">
					<img src={logoAppvers} alt={"logo"} className="my-3"/>
				</div>

				<Alert />
			<Form className="login-form" onSubmit={handleSubmit}>
				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
					<FormControl type='text' name='username' required placeholder={t('login.username.label')} autoComplete="on" />
				</InputGroup>

				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faKey}/></InputGroup.Text>
					<FormControl type='password' name='password' required placeholder={t('login.password.label')} autoComplete="off"/>
				</InputGroup>

				<Button className="px-5 mb-4 w-100" variant="success" type="submit">{t('login.login.label')}</Button>
			</Form>
			</Card.Body>
		</Card>
	);
};

export default Login;

