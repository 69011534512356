import {useEffect, useState} from "react";
import store from "../store";
import {setAuth} from "../security/securitySlice";
import restService from "./rest.service";
import {alertService} from "./alert.service";
import headers from "../security/headers";
import qs from "qs";
import i18n from "i18next";
import moment from "moment";
import {SERVER_URL} from "../config";
import ReactDOM from "react-dom";

export const securityService = {
	useLoggedIn,
	logOut,
	authenticateUser,
	refreshToken,
	loggedIn,
	resetRefreshTimer
}

function useLoggedIn() {
	const [loggedIn, setLoggedInValue] = useState( false );
	const [serverChecked, setServerChecked] = useState( false );

	const setLoggedIn = (value) => {
		ReactDOM.unstable_batchedUpdates(() => {
			setServerChecked(true)
			setLoggedInValue(value);
		})
	}

	useEffect(() => {
		const controller = new AbortController();
		securityService.loggedIn( controller.signal )
			.then( (value) => setLoggedIn(value) )
			.catch( (error) => setLoggedIn(false) )
		return function cleanup() {
			controller.abort()
		}
	});

	return [loggedIn, serverChecked];
}


let refreshTokenTimerId = undefined;

function _getAuth() {
	let auth = store.getState().security.auth;
	if ( auth && moment(auth.expireAt) < moment() ) {
		auth = undefined;
		_setAuth( "" );
	}
	return auth
}

function resetRefreshTimer(auth) {
	if ( undefined === auth ) {
		auth = _getAuth();
	}

	if ( auth && auth.expireAt ) {
		const advanceSeconds = 5;

		let millisToExpiration = moment(auth.expireAt).diff( moment() ) - advanceSeconds * 1000;
		if ( millisToExpiration < 1000 ) {
			millisToExpiration = 1;
		}
		if (refreshTokenTimerId) {
			clearTimeout(refreshTokenTimerId);
		}
		refreshTokenTimerId = setTimeout(securityService.refreshToken, millisToExpiration, auth.refresh_token);

		let getTime = (milli) => {
			let time = new Date(milli);
			let hours = time.getUTCHours();
			let minutes = time.getUTCMinutes();
			let seconds = time.getUTCSeconds();
			let milliseconds = time.getUTCMilliseconds();
			return (hours?(hours + "h"):"") + ((hours || minutes)?(minutes + "m"):"") + ((hours || minutes || seconds)?(seconds + "." + milliseconds + "s"):"");
		}

		console.log( `Token with id ${refreshTokenTimerId} will be refreshed in ${getTime(millisToExpiration)}`)
	}
}

function _setAuth(auth) {
	if( auth && auth.expires_in ) {
		auth.expireAt = moment().add(auth.expires_in, 'second').format();
		resetRefreshTimer(auth);
	}
	store.dispatch( setAuth( JSON.stringify(auth) ) );
}

function logOut() {
	if ( refreshTokenTimerId ) {
		clearTimeout(refreshTokenTimerId);
		//allTokenTimerIds = allTokenTimerIds.filter( id => id !== refreshTokenTimerId )
		//console.log( "Automatic token refresh cancelled.");
	}
	_setAuth( "" );
}

function authenticateUser(loginUserDetails) {
	fetch(`${SERVER_URL}/api/login`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify( loginUserDetails )
	})
		.then((response) => restService.handleServerResponse(response))
		.then(response => {
			_setAuth(response);
		})
		.catch(error => {
			let messageCode = 'default.error.message'
			if ( error.constructor === Object ) {
				if ( error.code ) {
					switch ( error.code ) {
						//custom translation
						case 401:
							messageCode = i18n.t('login.error.unauthorized')
							break;
						default:
							messageCode = error.messageCode
					}
				}
			}
			else {
				messageCode = error
			}
			alertService.error( messageCode )
		});
}

function refreshToken(refresh_token) {
	let h = {};
	h['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

	return fetch(
		`${SERVER_URL}/oauth/access_token`,
		{
			method: 'POST',
			headers: h,
			body: qs.stringify({
				grant_type: 'refresh_token',
				refresh_token: refresh_token
			})
		})
		.then((response) => restService.handleServerResponse(response))
		.then((a) => {
			console.log("Token refreshed");
			_setAuth(a);
		})
		.catch((error) => {
			alertService.error(error);
			securityService.logOut();
		})
}

function loggedIn( signal ) {
	return new Promise ((resolve, reject) => {
		if ( !_getAuth() ) {
			resolve(false);
		}
		else {
			fetch(
				`${ SERVER_URL }/api/security/loggedIn`,
				{
					method: 'GET',
					headers: headers(),
					signal: signal
				})
				.then( (response) => restService.handleServerResponse(response) )
				.then( (data) => resolve( data.loggedIn ) )
				.catch( () => resolve( false ) );
		}
	});
}
