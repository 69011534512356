import React from 'react';
import DamagePaymentInfos from "../damagePaymentInfo/DamagePaymentInfos";

function ShowDamagePaymentsInfos({damage}) {
    return (
        <>
            <div>
                {(damage && damage.id) ? <DamagePaymentInfos damage={damage} /> : null}
            </div>
        </>
    )

}

export default ShowDamagePaymentsInfos
