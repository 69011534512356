import {useEffect, useMemo, useState} from 'react'
import moment from "moment";
import {useTranslation} from "react-i18next";

function TimeLeft({start}) {
	const {t} = useTranslation()
	const [minutes, setMinutes] = useState(0)

	useEffect( () => {
		const refreshMinutes = () => {
			const now = moment(); //todays date
			const duration = moment.duration(now.diff(start));

			setMinutes( Math.floor(duration.asMinutes()) );
		}
		refreshMinutes();
		const intervalHandle = setInterval( refreshMinutes, 60000)

		return () => clearInterval(intervalHandle);
	}, [start, setMinutes] )

	const timeout = useMemo( () => {
		if ( minutes === 0 ) {
			return t('timeLeft.justNow', {minutes: minutes})
		}
		else if ( minutes <= 60 ) {
			return t('timeLeft.lessThanOneHour', {minutes: minutes})
		}
		if ( minutes > 60 ) {
			return t('timeLeft.overOneHour', {hours: Math.floor( minutes / 60 )})
		}
	}, [minutes, t])

	return timeout
}

export default TimeLeft