import React, {useMemo} from 'react';
import {Commissions} from "../commission/Commissions";

function PreviewCommissions( {policyVersion}) {
	const searchOptions = useMemo( () => ( {queryStrings: [{field: 'policyVersion.policy.id', text: `${policyVersion.policy.id}`}]} ), [policyVersion] );

	return (
		<>
			<div>
				{(policyVersion && policyVersion.policy.id) ? <Commissions searchOptions={searchOptions} hiddenFields={['policyId']} policyVersionId={policyVersion.id}/> : null}
			</div>
		</>
	)
}

export default PreviewCommissions