import {useEffect, useState} from "react";
import headers from "../security/headers";
import {SERVER_URL} from "../config";
import restService from "./rest.service";
import {fileService} from "./file.service";

export const partnerService = {
	useBrokerInterestRates,
	useBrokerInterestRateNames,
	useBankAccounts,
	getBankAccounts,
	printEnvelopeC5
}

function useBrokerInterestRates(partnerId, validOn) {
	const [ brokerInterestRates, setBrokerInterestRates ] = useState([])

	useEffect( () => {
		const controller = new AbortController();
		if( partnerId && validOn ) {
			const h = headers();
			const params = {
				partnerId: partnerId,
				validOn: validOn
			}
			fetch( `${ SERVER_URL }/api/partner/getBrokerInterestRates?` + new URLSearchParams( params ), {
				method: 'GET',
				headers: h,
				signal: controller.signal
			} )
				.then( ( response ) => ( restService.handleServerResponse( response ) ) )
				.then( ( json ) => setBrokerInterestRates( json ) )
				.catch( (error) => restService.handleServerErrors( error, controller.signal ) );
		}

		return function cleanup() {
			controller.abort()
		}
	}, [partnerId, validOn])

	return brokerInterestRates;
}

function useBrokerInterestRateNames() {
	const [ brokerInterestRateNames, setBrokerInterestRateNames ] = useState([])

	useEffect( () => {
		const controller = new AbortController();
		const h = headers();
		fetch( `${ SERVER_URL }/api/partner/getBrokerInterestRateNames`, {
			method: 'GET',
			headers: h,
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => setBrokerInterestRateNames( json ) )
			.catch( (error) => restService.handleServerErrors( error, controller.signal ) );

		return function cleanup() {
			controller.abort()
		}
	}, [])

	return brokerInterestRateNames;
}

function useBankAccounts(partnerId) {
	const [ bankAccounts, setBankAccounts ] = useState([])

	useEffect( () => {
		const controller = new AbortController();

		getBankAccounts(partnerId, controller.signal)
			.then( (bankAccounts) => setBankAccounts(bankAccounts) )
			.catch( (error) => restService.handleServerErrors( error, controller.signal ) );

		return function cleanup() {
			controller.abort()
		}
	}, [partnerId])

	return [bankAccounts, setBankAccounts];
}

function getBankAccounts(partnerId, signal) {
	return new Promise( (resolve, reject) => {
		const h = headers();

		fetch( `${ SERVER_URL }/api/partner/getBankAccounts/${partnerId}`, {
			method: 'GET',
			headers: h,
			signal: signal
		})
			.then( ( response ) => ( restService.handleServerResponse( response ) ) )
			.then( ( json ) => {
				const accounts = []
				for ( let nr=1; nr<=2; nr++) {
					if ( json[`iban${nr}`] !== '' ) {
						console.log( json[`iban${nr}`] )
						accounts.push( {
							id: json[`iban${nr}`],
							label: json[`iban${nr}name`] ? json[`iban${nr}name`] + ' (' + json[`iban${nr}`] + ')' : json[`iban${nr}`]
						} )
					}
				}
				resolve(accounts);
			} )
			.catch( reject );
	})
}

function printEnvelopeC5( partnerId, additionalText ) {
	return new Promise( (resolve, reject) => {
		const h = headers();
		h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		const params = {}
		if (additionalText) {
			params.additionalText = additionalText
		}
		fetch( `${ SERVER_URL }/api/partner/printEnvelopeC5/${partnerId}?` + new URLSearchParams( params ), {
			method: 'GET',
			headers: h,
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrors( error, controller.signal );
				reject();
			} );
	});
}
