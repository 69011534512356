let refMessagePanel = null;

export const messagePanelService = {
	register,
	update
}

function register(ref) {
	refMessagePanel = ref;
}

/**
 * Popups a new message
 * @param addMessages array of objects having following attributes: header, message, variant ('info', 'warning', 'danger')
 * @param removeMessages array of ids to be removed
 * @return ids of newly displayed  messages
 */
function update(addMessages, removeMessages) {
	return refMessagePanel.current.update(addMessages, removeMessages);
}