import React, {useMemo} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import {policyService} from "../_services/policy.service";
import {useTranslation} from "react-i18next";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import restService from "../_services/rest.service";

function PoliciesExport() {
    const {t} = useTranslation();
    const useFormObject = useForm();


    const withoutIVS3000 = useMemo( () => {
        return { queryStrings: [
                {field: 'id', text: '1020', innerPredicate: 'MUST_NOT'},
            ]}
    }, [])

    const [allProducts] = restService.useDomainInstancesList('product', undefined, undefined, "name_sort", undefined, withoutIVS3000, undefined, "select")

    const onSubmit = (formData) => {
        policyService.policiesExport(formData.validFrom, formData.validTo, formData.dataValidOn, formData.products)
    };

	return (
        <>
            <h2>{t('reports.policiesExport.title')}</h2>

            <FormProvider {...useFormObject}>
                <Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
                    <Card>
                        <Card.Header>
                            {t('default.filter')}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Group className="col-md-6" controlId="validFrom">
                                    <Form.Label>{t('policiesExport.validFrom.label')}</Form.Label>
                                    <FormControl
                                        name={"validFrom"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-6" controlId="validTo">
                                    <Form.Label>{t('policiesExport.validTo.label')}</Form.Label>
                                    <FormControl
                                        name={"validTo"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group className="col-md-6" controlId="dataValidOn">
                                    <Form.Label>{t('policiesExport.dataValidOn.label')}</Form.Label>
                                    <FormControl
                                        name={"dataValidOn"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group className="col-md-12" controlId="products">
                                    <Form.Label>{t('policiesExport.products.label')}</Form.Label>
                                    <RestAsyncSelectControl
                                        name={"products"}
                                        domainName={"product"}
                                        sort={"name_sort"}
                                        isMulti={true}
                                        value={allProducts}
                                    />
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className={"mt-3"}>
                        <Col md={2}>
                            <Button type={"submit"}>{ t('default.export') }</Button>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </>
	)
}

export default PoliciesExport