import { useState, useEffect } from 'react';
import headers from "../security/headers";
import {CONFIG_URL, SERVER_URL} from "../config";
import {alertService} from "./alert.service";
import appService from "./index";

//todo: create partner causes infinite loop

const restService = {
	getDomainInstance,
	useDomainInstance,
	getDomainInstancesList,
	useDomainInstancesList,
	saveDomainInstance,
	updateDomainInstance,
	deleteDomainInstance,
	handleServerResponse,
	handleServerErrors,
	getApplicationConfig,
	addSearchTextProperties,
	getTo,
	prepareSearchParams,
};

const defaultSearchTextProperties = {
	avb: ['avbDescription.description', 'year', 'esAvbCode'],
	avbDescription: ['description'],
	bankPayment: ['refNr', 'amountDate', 'amount', 'invoice.paymentState', 'invoice.invoiceNr', 'manuallyPairedInvoice.refNr'],
	payment: ['refNr', 'amountDate', 'amount', 'invoice.paymentState', 'invoice.invoiceNr'],
	manualPayment: ['refNr', 'amountDate', 'amount', 'note', 'invoice.paymentState', 'invoice.invoiceNr'],
	bankToCustomerNotification: ['createdOn', 'createdBy', 'filename'],
	branch: ['name'],
	building: ['label', 'zip.place'],
	buildingClass: ['name'],
	buildingType: ['name'],
	commission: ['commissionDate', 'amount', 'percent', 'year', 'partner.label'],
	commissionSettlementBatch: ['year', 'createdOn', 'createdBy' ],
	constructionType: ['name'],
	country: ['code', 'name'],
	county: ['code', 'name'],
	damage: [
		'id',
		'description',
		'note',
		'policyVersion.policyNumber',
		'damageType.name',
		'dateOfDamage',
		'damageEvent.eventName',
		'policyVersion.policyHolder.label'
	],
	damageDetail: ['description', 'participationAmount', 'reserveAmount'],
	damageEvent: ['eventDateTime', 'eventName', 'description', 'label'],
	damagePaymentInfo: ['paymentInfoDate', 'amount'],
	damageShare: ['company.label', 'percentShare'],
	damageType: ['name', 'edCause.description'],
	elementalDamageCause: ['causeCode', 'description'],
	fireExtinguisher: ['name'],
	heatingType: ['name'],
	individualText: ['text'],
	invoice: ['refNr', 'invoiceNr', 'total', 'paymentState'],
	invoiceDamagePayment: [	'refNr', 'invoiceNr', 'partner.label', 'number', 'type', 'total', 'createdOn', 'paymentState'],
	participationSettlementBatch: ['dateFrom', 'dateTo', 'createdOn', 'createdBy' ],
	partner: ['fullName', 'zip.place', 'zip.zip', 'streetName', 'streetNr', 'salutation.description', 'tags.partnerTag.name'],
	partnerConsultantScope: ['name', 'user.username'],
	policy: ['id', 'lastPolicyHolder', 'lastPolicyType'],
	policyCancellationReason: ['name'],
	policyVersion: ['policyNumber', 'policyHolder.label', 'policyType'],
	policyVersionSettlementBatch: ['dateFrom', 'dateTo', 'createdOn', 'createdBy' ],
	product: ['name'],
	productType: ['name'],
	roofType: ['name'],
	salutation: ['description'],
	setting: ['name'],
	settlement: ['partner.label', 'number', 'type', 'total', 'createdOn', 'paymentState'],
	standardText: ['label', 'theme', 'text'],
	tariff: ['name'],
	user: ['username', 'nameOfUser'],
	zip: ['zip', 'place', 'country.name', 'county.name', 'county.code'],
	reportMessage: ['code', 'message'],
}

function getDomainInstance( domainName, id, signal, callCreateIfIdIsNull = false, templateSuffix = undefined ) {
	return new Promise ((resolve, reject) => {
		const h = headers();
		let params = {}
		if ( templateSuffix ) {
			params.templateSuffix = templateSuffix;
		}
		if ( id ) {
			fetch( `${ SERVER_URL }/api/${ domainName }/${ id }?` + new URLSearchParams( params ), {
				method: 'GET',
				headers: h,
				signal: signal
			} )
				.then( r => r.json() )
				.then( domainInstance => {
					resolve( domainInstance );
				} )
				.catch( reject );
		} else {
			if ( callCreateIfIdIsNull ) {
				fetch( `${ SERVER_URL }/api/${ domainName }/create`, {
					method: 'GET',
					headers: h,
					signal: signal
				} )
					.then( r => r.json() )
					.then( domainInstance => {
						resolve( domainInstance );
					} )
					.catch( reject );
			}
			else {
				resolve( null );
			}
		}
	})
}

function useDomainInstance( domainName, id, callCreateIfIdIsNull = false, templateSuffix = undefined ) {
	const [domainInstance, setDomainInstance] = useState( {} );

	useEffect(() => {
		const controller = new AbortController();
		getDomainInstance( domainName, id, controller.signal, callCreateIfIdIsNull, templateSuffix )
			.then( (result) => {
				if ( result ) {
					setDomainInstance( result );
				}
			} )
			.catch( error => handleServerErrors( error, controller.signal ) )

		return function cleanup() {
			controller.abort()
		}
	}, [domainName, id, callCreateIfIdIsNull, templateSuffix]);

	return [domainInstance, setDomainInstance];
}

/**
 * Adds tp searchOptions searchTextProperties if it doesn't exist yet
 * @param searchOptions
 */
function addSearchTextProperties( domainName, searchOptions ) {
	if ( !searchOptions.searchTextProperties ) {
		if (defaultSearchTextProperties[domainName]) {
			searchOptions.searchTextProperties = defaultSearchTextProperties[domainName]
		}
	}
}

function prepareSearchParams( domainName, searchText, searchOptions, namedCriteria ) {
	searchOptions = searchOptions || {}
	addSearchTextProperties( domainName, searchOptions )
	let params = {
		searchOptions: JSON.stringify(searchOptions)
	}
	if ( searchText ) {
		params.searchText = searchText;
	}
	if ( namedCriteria ) {
		params.namedCriteria = JSON.stringify(namedCriteria);
	}
	return params
}

function getDomainInstancesList( domainName, page, sizePerPage, sort, searchText, searchOptions, namedCriteria, templateSuffix, signal ) {
	return new Promise ((resolve, reject) => {
		if ( domainName ) {
			const h = headers();
			let params = prepareSearchParams( domainName, searchText, searchOptions, namedCriteria );
			if ( templateSuffix ) {
				params.templateSuffix = templateSuffix;
			}
			if ( page && sizePerPage ) {
				params.offset = (page - 1) * sizePerPage
			}
			if ( sizePerPage && sizePerPage > 0 ) {
				params.max = sizePerPage
			}
			if ( sort ) {
				params.sort = sort;
			}

			fetch( `${ SERVER_URL }/api/${ domainName }?` + new URLSearchParams( params ), {
				method: 'GET',
				headers: h,
				signal: signal
			} )
				.then( r => r.json() )
				.then( json => {
					//debugger;
					resolve( {totalSize: json.count, data: json.data } );
				} )
				.catch( reject )
		}
	});
}

function useDomainInstancesList( domainName, page, sizePerPage, sort, searchText, searchOptions, namedCriteria, templateSuffix, condition ) {
	const [data, setData] = useState([]);
	const [totalSize, setTotalSize] = useState(0);

	useEffect(() => {
		if ( !condition || (condition && condition()) ) { //forms are loaded with empty domain first, then again with a domain - first call can be avoided by this condition
			const controller = new AbortController();
			getDomainInstancesList( domainName, page, sizePerPage, sort, searchText, searchOptions, namedCriteria, templateSuffix, controller.signal )
				.then( ( result ) => {
					setTotalSize( result.totalSize );
					setData( result.data );
				} )
				.catch( ( error ) => restService.handleServerErrors( error, controller.signal ) );
			return function cleanup() {
				controller.abort();
			}
		}
	}, [domainName, page, sizePerPage, sort, searchText, searchOptions, namedCriteria, templateSuffix, condition]);

	return [data, setData, totalSize, setTotalSize];
}

/**
 * For given response it returns either JSON with data from server or JSON with error message
 * @param response
 */
function handleServerResponse(response) {
	return new Promise((resolveHandle, rejectHandle) => {
		if (response.ok) {
			switch (response.status) {
				case 200:
				case 201:
					const contentType = response.headers.get("content-type");
					if (contentType && contentType.indexOf("application/json") !== -1) {
						resolveHandle( response.json() );
					}
					else if (contentType && (contentType.match("text/plain") || contentType.match("application/pdf.*") || contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1 || (contentType.indexOf("application/zip") !== -1))) {
						resolveHandle( response.blob() );
					}
					else {
						resolveHandle( response.text() );
					}
					break;
				default:
					resolveHandle( null );
					break;
			}
		} else if ( response.body.constructor === ReadableStream && ( null == response.headers.get("Content-Length") || response.headers.get("Content-Length") > 0 ) ) {
			const reader = response.body.getReader();
			new Promise((resolve, reject) => {
				var stream = new ReadableStream({
					start(controller) {
						// The following function handles each data chunk
						function push() {
							// "done" is a Boolean and value a "Uint8Array"
							reader.read()
								.then(({done, value}) => {
									// If there is no more data to read
									if (done) {
										controller.close();
										resolve(stream);
										return;
									}
									// Get the data and send it to the browser via the controller
									controller.enqueue(value);
									push();
								})
								.catch( e => reject(e))
						}
						push();
					}
				})
			})
				.then(stream => {
					return new Response(stream, {headers: {"Content-Type": "text/html"}}).json();
				})
				.then(result => {
					rejectHandle(result);
				})
				.catch(rejectHandle);
		}
		else {
			let errorMessage = {
				code: response.status,
				messageCode: 'default.error.message'
			}
			switch ( response.status ) {
				//default translations
				case 400:
					errorMessage.messageCode = 'error.response.bad.request'
					break;
				case 401:
					errorMessage.messageCode = 'error.response.unauthorized'
					break;
				case 403:
					errorMessage.messageCode = 'error.response.forbidden'
					break;
				default:
					errorMessage.messageCode = 'error.response.default'
			}
			rejectHandle( errorMessage )
		}
	})
}

function handleServerErrors( error, signal, alertOptions ) {
	if ( undefined === signal || !signal.aborted ) {

		//make the validation messages unique
		if ( error !== null && typeof error === 'object' && error._embedded && error._embedded.errors ) {
			error._embedded.errors = error._embedded.errors.filter((v, i, a) => a.findIndex( (item) => item['message'] === v['message'] ) === i);
			error.total = error._embedded.errors.length;
		}

		alertService.error( error, alertOptions )
	}
}

function saveDomainInstance(domainName, data) {
	return new Promise((resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/${ domainName }`, {
			method: 'POST',
			headers: h,
			signal: controller.signal,
			body: JSON.stringify( data )
		} )
			.then( ( response ) => {
				debugger;
				return handleServerResponse( response )
			} )
			.then( successJSON => resolve( successJSON ) )
			.catch( error => reject( error, controller.signal ) );
	})
}



function updateDomainInstance(domainName, id, data) {
	return new Promise((resolve, reject) => {
		if ( isNaN(Number(data.version)) ) {
			console.warn(`Missing 'version' property when calling updateDomainInstance(${domainName}, ${id}, ${JSON.stringify(data)})`)
		}
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/${ domainName }/${ id }`, {
			method: 'PUT',
			headers: h,
			signal: controller.signal,
			body: JSON.stringify( data )
		} )
			.then( ( response ) => handleServerResponse( response ) )
			.then( successJSON => resolve( successJSON ) )
			.catch( error => {
				reject( error, controller.signal )
				console.warn(`updateDomainInstance ${JSON.stringify(error)}`)
			} );
	});
}

function deleteDomainInstance(domainName, id) {
	return new Promise((resolve, reject) => {
		const h = headers();
		const controller = new AbortController();
		fetch( `${ SERVER_URL }/api/${ domainName }/${ id }`, {
			method: 'DELETE',
			headers: h,
			signal: controller.signal,
		} )
			.then( ( response ) => handleServerResponse( response ) )
			.then( successJSON => resolve( successJSON ) )
			.catch( error => reject( error, controller.signal ) );
	});
}

function getApplicationConfig(controller, successHandler, errorHandler) {
	const h = headers();
	fetch( `${ SERVER_URL }${CONFIG_URL}`, {
		method: 'GET',
		headers: h,
		signal: controller.signal,
	} )
		.then((response) => handleServerResponse(response))
		.then(successJSON => successHandler(successJSON))
		.catch(error => {
			handleServerErrors(error, controller.signal)
			if ( undefined !== errorHandler ) {
				errorHandler(error)
			}
		});
}

function getTo(object, action) {
	const classSimpleName = appService.nullSafeGet( object, 'classSimpleName' )
	const id = appService.nullSafeGet( object, 'id' )

	var link = undefined;

	if ( classSimpleName && id ) {
		switch( classSimpleName ) {
			case 'company':
			case 'person':
				link = `/partner/${classSimpleName}`;
				break;
			case 'policyVersion':
				link = `/policy`;
				break;
			case 'creditNote':
			case 'creditNoteDamagePayment':
			case 'creditNotePolicyVersion':
			case 'creditNoteCommission':
			case 'creditNoteOtherLeadershipDiscount':
			case 'creditNoteDamagePaymentNotification':
			case 'invoice':
			case 'invoiceDamagePayment':
			case 'invoiceDamagePaymentInvolvedInsurance':
			case 'invoiceDamagePaymentPolicyHolder':
			case 'invoicePolicyVersion':
				link = `/settlement`;
				break;
			default:
				link = `/${classSimpleName}`;
		}
	}

	var to = undefined;
	if ( link ) {
		to = `${link}/${action}/${id}`;
	}

	return to
}
export default restService;
