import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";

function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('individualText.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="12" controlId="formGroupText">
							<Form.Label>{t('individualText.text')}</Form.Label>
							<FormControl
								name={"text"}
								type={"text"}
								as="textarea"
								rows={3}
								value={domainInstance.text || (defaultValues && defaultValues.text)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="4" controlId="formGroupActive">
							<SwitchButtonControl
								value={!!(domainInstance.active || (defaultValues && defaultValues.active))}
								offlabel={t("individualText.active.offlabel")} onlabel={t("individualText.active.onlabel")} name={"active"}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
