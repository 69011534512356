import React from 'react';

import {Trans as ReactI18nextTrans} from "react-i18next"

function Trans({i18nKey, values}) {
    return (
        <ReactI18nextTrans
            i18nKey={i18nKey}
            components={{ ul: <ul/>, li: <li/>, b: <b/>, i: <i/>, a: <a/> }}
            values={values}
        />
    )
}

export default Trans