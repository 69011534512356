import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function SwitchButtonControl( { name, rules, validationMessages, value, onChange, disabled, onlabel, onstyle, offlabel, offstyle, size }) {
	const { reset, getValues, control } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);

	useEffect(() => {
		if ( getValues && reset ) {
			const newValue = defVal;
			setCurrentValue(newValue);
			const val = { ...getValues(), [name]: newValue };
			reset( val );
		}
	}, [value, getValues, name, reset, defVal]);

	return (
		<>
			{/*<p>{defaultValue===undefined?'undefined':(defaultValue===null?'null':defaultValue)}</p>*/}
		<Controller
			control={ control }
			name={ name }
			rules={ rules }
			defaultValue={ currentValue }
			render={ ( { field, fieldState } ) => {
					const opts = {
						onChange: ( val ) => {
							setCurrentValue(val)
							field.onChange( val );
							if ( onChange ) {
								onChange( val );
							}
						},
						onBlur: field.onBlur,
						checked: field.value,
						name: field.name,
						ref: field.ref,
						disabled: disabled,
						onlabel: onlabel,
						onstyle: onstyle,
						offlabel: offlabel,
						offstyle: offstyle,
						style: "w-100",
						size: size
					}
					return ( <>
						<BootstrapSwitchButton
							{ ...opts }
						/>
						<Form.Control.Feedback type="invalid">
							{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
						</Form.Control.Feedback>
					</> )
				}
			}
		/>
		</>
	)
}

SwitchButtonControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	onlabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
	onstyle: PropTypes.string,
	offlabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
	offstyle: PropTypes.string,
	size: PropTypes.string
};

export default SwitchButtonControl;
