import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import DefaultSpinner from "./DefaultSpinner";

function Spinner() {
	const {promiseInProgress} = usePromiseTracker();

	return (
		<DefaultSpinner loading={promiseInProgress} css={"position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);"} size={10}/>
	);
}

export default Spinner
