import React, {useMemo} from 'react';
import PreviewSettlementBatch from "../_components/_settlementBatch/PreviewSettlementBatch";
import ParticipationSettlementBatchFormElements from "./ParticipationSettlementBatchFormElements";

function PreviewParticipationSettlementBatch( { id } ) {
	const hiddenFields = useMemo(() => ['policyNumber', 'detail'], [])

	return (
		<PreviewSettlementBatch
			className={'participationSettlementBatch'}
			id={ parseInt(id) }
			settlementBatchFormElements={<ParticipationSettlementBatchFormElements />}
			hiddenFields={hiddenFields}
		/>
	);
}

export default PreviewParticipationSettlementBatch
