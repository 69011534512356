import React from 'react';
import {Row} from "react-bootstrap";
import ShowField from "../form/ShowField";

function CommissionSettlementBatchFormElements( {settlementBatch} ) {
	return (
        <>
            <Row>
                <ShowField label={'commissionSettlementBatch.year.label'} object={settlementBatch} property={'year'} type={'number'} size={4}/>
            </Row>
        </>
    )
}

export default CommissionSettlementBatchFormElements