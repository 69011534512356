import React, {useState} from 'react';
import {Dropdown} from "react-bootstrap";
import SplitFileByModal from "./SplitFileByModal";
import {useTranslation} from "react-i18next";

function PolicyVersionSettlementBatchAdditionalPrintDropdowns({settlementBatch}) {
    const {t} = useTranslation();
    const [ showSplitFileByModal, setShowSplitFileByModal ] = useState(false);

	return (
        <>
            <Dropdown.Item onClick={ ( event ) => {
                setShowSplitFileByModal(true)
            }}>{t('previewSettlementBatch.showSplitFileByModal')}</Dropdown.Item>
            <SplitFileByModal settlementBatch={settlementBatch} show={showSplitFileByModal} onShowChanged={ (value) => setShowSplitFileByModal(value) }/>
        </>
    )
}

export default PolicyVersionSettlementBatchAdditionalPrintDropdowns