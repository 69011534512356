import React, {useEffect, useState, useMemo} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import EditableTableControl from "../form/EditableTableControl";

function FormElements({domainInstance}) {
	const { t } = useTranslation();
	const [separateReinsurance, setSeparateReinsurance] = useState(domainInstance && domainInstance.separateReinsurance);

	const columns = useMemo( () => [
		{id: "from", label: t("default.from"), input: {tag: "input", type: "integer"}, validator: (value,data,rowIndex) => { return (value > data[rowIndex].to)?"'To' must be greater or equal 'From'.":undefined}},
		{id: "to", label: t("default.to"), input: {tag: "input", type: "integer"}, validator: (value,data,rowIndex) => { return (value < data[rowIndex].from)?"'From' must be smaller or equal 'To'.":undefined}},
		{id: "percent", label: t("default.percent"), input: {tag: "input", type: "double", required: true}},
	], [t] )

	useEffect( () => {
		setSeparateReinsurance(domainInstance.separateReinsurance);
	}, [setSeparateReinsurance, domainInstance.separateReinsurance, t])

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('productType.card.common.header')}</Card.Header>
				<Card.Body>
					<Card className={"mb-2"}>
						<Card.Header>{t('productType.name.label')}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="12" controlId="formGroupName">
									<FormControl
										name={"name"}
										type={"text"}
										value= {domainInstance.name}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<Card className={"mb-2"}>
						<Card.Header>{t('productType.card.reinsurance.label')}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="12" controlId="formGroupSeparateReinsurance">
									<SwitchButtonControl
										name={"separateReinsurance"}
										value={separateReinsurance}
										offlabel={t("productType.separateReinsurance.offlabel")}
										onlabel={t("productType.separateReinsurance.onlabel")}
										onChange={(value) => setSeparateReinsurance(value)}
									/>
								</Form.Group>
							</Row>
							{ separateReinsurance &&
							<Card className={"mt-3"}>
								<Card.Header>{t('productType.reinsuranceRates.label')}</Card.Header>
								<Card.Body>
									<EditableTableControl
										name={'reinsuranceRates'}
										orderable={false}
										columns={columns}
										allowDeletions={true}
										allowAdditions={true}
										data={domainInstance.reinsuranceRates}
										normalizeData={true}
									/>
								</Card.Body>
							</Card>
							}
						</Card.Body>
					</Card>
					<Card className={"mb-2"}>
						<Card.Header>{t('productType.loeschfuenferRelevant.label')}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="12" controlId="formGroupLoeschfuenferRelevant">
									<SwitchButtonControl
										name={"loeschfuenferRelevant"}
										value={domainInstance.loeschfuenferRelevant}
										offlabel={t("productType.loeschfuenferRelevant.offlabel")}
										onlabel={t("productType.loeschfuenferRelevant.onlabel")}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
