import React, {useMemo} from 'react'
import {Button, Tooltip} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import textService from "../_services/text.service";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import SimpleTable from "../_components/SimpleTable";
import restService from "../_services/rest.service";
import {Link} from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {profitParticipationService} from "../_services/profitParticipation.service";
import xlsxService from "../_services/xlsx.service";
import moment from "moment";

function PreviewSettlementItems({settlement, settlementClassName, refreshSettlement}) {
	const {t} = useTranslation();

	const settlementItems = useMemo( () => {
		return settlement && settlement.settlementItems
	}, [settlement])

	const exportToExcel = () => {
		const data = []
		settlementItems.forEach( (i) => {
			let row = {}
			columns.forEach( (column) => {
				row[column.label] = column.xlsxFormatter(i)
			})
			data.push( row )
		})

		const totalColumns = columns.filter( (column) => column.xlsxTotal ).map( (column) => column.label )
		xlsxService.createSheet(data, `${settlement.type} ${settlement.number}`, totalColumns )
	}

	const invoiceRabattTooltip = (props) => {
        return (
            <Tooltip id="myTooltip" {...props}>
                {t(`newSettlementUserMessage.warningMessage.${settlementClassName==='appvers.InvoicePolicyVersion'?'invoicePolicyVersion':'creditNotePolicyVersion'}`)}
            </Tooltip>
        );
	}

	const columns = useMemo( () => {
		const handleToggleIgnoreInvoiceRabatt = (settlementItemId, version, checked) => {
			restService.updateDomainInstance('settlementItemPolicyVersion', settlementItemId, {version: version, ignoreInvoiceRabatt: checked})
				.catch( error => {
					restService.handleServerErrors( error )
					refreshSettlement()
				})
		}

		switch( settlementClassName ) {
			case 'appvers.InvoicePolicyVersion':
			case 'appvers.CreditNotePolicyVersion':
				let result = [
					{key: 'policyTargetName', label: t('settlementItem.policyTargetName.label'), formatter: (row) => row.policyTargetName, xlsxFormatter: (row) => row.policyTargetName, xlsxTotal: false },
					{key: 'periodFrom', className:"text-center", label: t('settlementItem.periodFrom.label'), formatter: (row) => textService.format(row.periodFrom, 'date'), xlsxFormatter: (row) => moment(row.periodFrom).toDate(), xlsxTotal: false },
					{key: 'periodTo', className:"text-center",label: t('settlementItem.periodTo.label'), formatter: (row) => textService.format(row.periodTo, 'date'), xlsxFormatter: (row) => moment(row.periodTo).toDate(), xlsxTotal: false },
					{key: 'amount', className:"text-end", label: t('settlementItem.amount.label'), formatter: (row) => textService.formatNumber(row.amount), xlsxFormatter: (row) => row.amount, xlsxTotal: true },
					{key: 'loeschfuenferValue', className:"text-end", label: t('settlementItem.loeschfuenferValue.label'), formatter: (row) => textService.formatNumber(row.loeschfuenferValue), xlsxFormatter: (row) => row.loeschfuenferValue, xlsxTotal: true },
					{key: 'stampTaxPercent', className:"text-end", label: t('settlementItem.stampTaxPercent.label'), formatter: (row) => textService.formatNumber(row.stampTaxPercent), xlsxFormatter: (row) => row.stampTaxPercent, xlsxTotal: true },
					{key: 'stampTaxValue', className:"text-end", label: t('settlementItem.stampTaxValue.label'), formatter: (row) => textService.formatNumber(row.stampTaxValue), xlsxFormatter: (row) => row.stampTaxValue, xlsxTotal: true },
					{key: 'total', className:"text-end", label: t('settlementItem.amount.total'), formatter: (row) => textService.formatNumber(row.total), xlsxFormatter: (row) => row.total, xlsxTotal: true },
				]
				if ( settlementItems && settlementItems.find(si => si.invoiceRabatt !== 0) ) {
					result.splice(1, 0, {
						key: 'ignoreInvoiceRabatt',
						className: "text-center",
						label: <>{t( 'settlementItem.ignoreInvoiceRabatt.label' )}&nbsp;{<OverlayTrigger
                                delay={{ show: 250, hide: 1000 }}
                                overlay={ invoiceRabattTooltip }
                            >
	                            <span><FontAwesomeIcon icon={faCircleInfo}/></span>
                            </OverlayTrigger>}
						</>,
						formatter: ( row ) => {
							return row.invoiceRabatt !== 0 ?
								<>
								<BootstrapSwitchButton
									width={100}
									onstyle={'danger'}
									onlabel={ "0 %" }
									offlabel={ `${row.invoiceRabatt * 100} %` }
									checked={ row.ignoreInvoiceRabatt }
									size={ 'xs' }
									onChange={ ( value ) => handleToggleIgnoreInvoiceRabatt( row.id, row.version, value ) }
									disabled={settlement.booked}
								/>
								</>
							: undefined;
						},
						xlsxFormatter: (row) => row.invoiceRabatt,
                        xlsxTotal: false,
					})
				}
				return result
			case 'appvers.CreditNoteCommission':
				return [
					{key: 'policyVersionNumber', label: t('policy.label'), formatter: (row) => <Link to={restService.getTo( row.commission.policyVersion, 'show' )} target={"_blank"} rel={"noopener noreferrer"}>{row.commission.policyNumber}</Link>, xlsxFormatter: (row) => row.commission.policyNumber, xlsxTotal: false },
					{key: 'policyHolder', label: t('policyVersion.policyHolder.label'), formatter: (row) => row.commission.policyHolder.label, xlsxFormatter: (row) => row.commission.policyHolder.label, xlsxTotal: false },
					{key: 'percent', className:"text-end", label: t('commission.percent'), formatter: (row) => `${textService.formatNumber(row.commission.percent)} %`, xlsxFormatter: (row) => row.commission.percent, xlsxTotal: false },
					{key: 'amount', className:"text-end", label: t('commission.amount'), formatter: (row) => textService.formatNumber(row.amount), xlsxFormatter: (row) => row.amount , xlsxTotal: true},
				]
			case 'appvers.InvoiceDamagePaymentInvolvedInsurance':
				return [
					{key: 'damagePaymentInfoId', label: t('settlementItemDamagePaymentInvolvedInsurance.damagePaymentInfoId'), formatter: (row) => row.damageSharePaymentInfo.damagePaymentInfo.id, xlsxFormatter: (row) => row.damageSharePaymentInfo.damagePaymentInfo.id, xlsxTotal: false },
					{key: 'policyHolder', label: t('damageSharePaymentInfo.receiver'), formatter: (row) => row.damageSharePaymentInfo.policyHolder.label, xlsxFormatter: (row) => row.damageSharePaymentInfo.policyHolder.label, xlsxTotal: false },
					{key: 'percentShare', className:"text-end", label: t('damageShare.percentShare'), formatter: (row) => `${textService.formatNumber(row.damageSharePaymentInfo.percentShare)} %`, xlsxFormatter: (row) => row.damageSharePaymentInfo.percentShare, xlsxTotal: false },
					{key: 'calculatedAmountShare', className:"text-end", label: t('commission.amount'), formatter: (row) => textService.formatNumber(row.damageSharePaymentInfo.calculatedAmountShare), xlsxFormatter: (row) => row.damageSharePaymentInfo.calculatedAmountShare, xlsxTotal: true },
				]
			case 'appvers.InvoiceDamagePaymentPolicyHolder':
				return [
					{key: 'description', label: t('damagePaymentInfo.description'), formatter: (row) => row.damagePaymentInfoDetail.damagePaymentInfo.description, xlsxFormatter: (row) => row.damagePaymentInfoDetail.damagePaymentInfo.description, xlsxTotal: false },
					{key: 'amount', className:"text-end", label: t('damagePaymentInfo.amount'), formatter: (row) => textService.formatNumber(-row.amount), xlsxFormatter: (row) => -row.amount, xlsxTotal: true },
				]
			case 'appvers.CreditNoteDamagePaymentNotification':
				return [
					{key: 'description', label: t('damagePaymentInfo.description'), formatter: (row) => row.damagePaymentInfoDetail.damageDetail.label, xlsxFormatter: (row) => row.damagePaymentInfoDetail.damageDetail.label, xlsxTotal: false },
					{key: 'amount', className:"text-end", label: t('damagePaymentInfo.amount'), formatter: (row) => textService.formatNumber(row.amount), xlsxFormatter: (row) => row.amount, xlsxTotal: true },
				]
			case 'appvers.CreditNoteParticipation':
			case 'appvers.InvoiceParticipation':
				return [
					{key: 'policy', label: t('policy.label'), formatter: (row) => row.policy.label, xlsxFormatter: (row) => row.policy.label, xlsxTotal: false },
					{key: 'percent', className:"text-end", label: t('settlementItemParticipation.percent'), formatter: (row) => `${textService.formatNumber(row.percent)} %`, xlsxFormatter: (row) => row.percent, xlsxTotal: false },
					{key: 'fee', className:"text-end", label: t('settlementItemParticipation.fee'), formatter: (row) => textService.formatNumber(row.fee), xlsxFormatter: (row) => row.fee, xlsxTotal: true },
					{key: 'loeschfuenfer', className:"text-end", label: t('settlementItemParticipation.loeschfuenfer'), formatter: (row) => textService.formatNumber(row.loeschfuenfer), xlsxFormatter: (row) => row.loeschfuenfer, xlsxTotal: true },
					{key: 'stampTax', className:"text-end", label: t('settlementItemParticipation.stampTax'), formatter: (row) => textService.formatNumber(row.stampTax), xlsxFormatter: (row) => row.stampTax, xlsxTotal: true },
					{key: 'commissionAmount', className:"text-end", label: t('settlementItemParticipation.commissionAmount'), formatter: (row) => textService.formatNumber(row.commissionAmount), xlsxFormatter: (row) => row.commissionAmount, xlsxTotal: true },
					{key: 'costFee', className:"text-end", label: t('settlementItemParticipation.costFee'), formatter: (row) => textService.formatNumber(row.costFee), xlsxFormatter: (row) => row.costFee, xlsxTotal: true },
					{key: 'riskFee', className:"text-end", label: t('settlementItemParticipation.riskFee'), formatter: (row) => textService.formatNumber(row.riskFee), xlsxFormatter: (row) => row.riskFee, xlsxTotal: true },
					{key: 'participationFee', className:"text-end", label: t('settlementItemParticipation.participationFee'), formatter: (row) => textService.formatNumber(row.participationFee), xlsxFormatter: (row) => row.participationFee, xlsxTotal: true },
					{key: 'amount', className:"text-end", label: t('settlementItemParticipation.amount'), formatter: (row) => textService.formatNumber(row.amount), xlsxFormatter: (row) => row.amount, xlsxTotal: true },
				]
			case 'appvers.CreditNoteOtherLeadershipDiscount':
				return [
					{key: 'nettoFee', label: t('settlementItemOtherLeadershipDiscount.nettoFee'), formatter: (row) => textService.formatNumber(row.nettoFee), xlsxFormatter: (row) => row.nettoFee, xlsxTotal: true },
					{key: 'participation', label: t('settlementItemOtherLeadershipDiscount.participation'), formatter: (row) => `${textService.formatNumber(row.participation)} %`, xlsxFormatter: (row) => row.participation, xlsxTotal: false },
					{key: 'appversFee', label: t('settlementItemOtherLeadershipDiscount.nettoFee'), formatter: (row) => textService.formatNumber(row.appversFee), xlsxFormatter: (row) => row.appversFee, xlsxTotal: true },
					{key: 'amount', className:"text-end", label: t('settlementItemOtherLeadershipDiscount.amount'), formatter: (row) => textService.formatNumber(row.amount), xlsxFormatter: (row) => row.amount, xlsxTotal: true },
				]
			case 'appvers.CreditNoteProfitParticipation':
				return profitParticipationService.getSettlementColumns()
			case 'appvers.InvoiceProfitParticipation':
				return profitParticipationService.getSettlementColumns(true, true)
			default:
				console.log(`Update PreviewSettlementItems.jsx to accept also settlementClassName: ${settlementClassName}`)
		}
	}, [settlementClassName, t, settlementItems, settlement.booked])

	return (
		settlementItems ?
			<>
				<SimpleTable columns={ columns } rows={ settlementItems }/>
				<Button className={ "float-end btn-light" } size={ "xs" } onClick={ exportToExcel }><FontAwesomeIcon
					icon={ faFileExcel }/></Button>
			</>
			:
			<></>
	)

}

export default PreviewSettlementItems