import { createSlice } from '@reduxjs/toolkit'

export const tourSlice = createSlice({
	name: 'tour',
	initialState: {
		steps: {},
	},
	reducers: {
		addSteps: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			const payload = action.payload;
			state.steps = {...state.steps, ...payload};
		},
		removeSteps: (state, action) => {
			delete state.steps[action.payload]
		},
	},
})

// Action creators are generated for each case reducer function
export const { clear, addSteps, removeSteps } = tourSlice.actions

export default tourSlice.reducer