import React from 'react';
import ReactDOM from 'react-dom';
import './i18n'
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {trackPromise} from "react-promise-tracker";
import store, {persistor} from './store'
import { Provider } from 'react-redux'
import {PersistGate} from "redux-persist/integration/react";
import ConfigProvider from "./_services/useConfig";
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import appService from "./_services";

const originalFetch = window.fetch
window.fetch = (input, init) => trackPromise ( originalFetch(input, init) )

let isLoggingWarn = false;
export const originalWarn = console.warn;
console.warn = function(message, ...args) {
	if ( !isLoggingWarn ) {
		isLoggingWarn = true
		try {
			// Forward the warning to the original console.warn function
			originalWarn.apply(console, [message, ...args]);

			// Optionally, log it to the server
			appService.serverLogger('warn', message );
		} finally {
			isLoggingWarn = false
		}
	}
};

let isLoggingError = false;
window.addEventListener('error', function(event) {
	if ( !isLoggingError ) {
		isLoggingError = true
		try {
			appService.serverLogger( 'error', event.message);
		} finally {
			isLoggingError = false
		}
	}
});

setChonkyDefaults({ iconComponent: ChonkyIconFA });

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate load={ null } persistor={ persistor }>
				<ConfigProvider>
					<App />
				</ConfigProvider>
			</PersistGate>
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);
