import React from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {PartnerType} from "../_enum/enum";
import {useTranslation} from "react-i18next";

function CreatePersonQuestionModal({show, onClose}) {
    const {t} = useTranslation();

	return (
        <Modal show={show} centered={true}>
            <Modal.Header> {t('createPersonQuestionModal.header')} </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4} className={"text-center"}>
                        <Button type={"button"} variant={"primary"} onClick={() => onClose(PartnerType.PERSON)}>{ t('default.createWhat', {what: t('person.label')}) }</Button>
                    </Col>
                    <Col md={4} className={"text-center"}>
                        <Button type={"button"} variant={"primary"} onClick={() => onClose(PartnerType.COMPANY)}>{ t('default.createWhat', {what: t('company.label')}) }</Button>
                    </Col>
                    <Col md={4} className={"text-center"}>
                        <Button type={"button"} variant={"secondary"} onClick={() => onClose(null)}>{t('createPersonQuestionModal.iHaveChangedMyMind')}</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
	)
}

export default CreatePersonQuestionModal