import React, {useCallback, useMemo} from "react";
import {Settlements} from "../settlement/Settlements";
import {SERVER_URL} from "../config";
import headers from "../security/headers";

function PreviewPolicyVersionProfitParticipationSettlements( {policy}) {
	const policyId = useMemo( () => policy ? policy.id : null, [policy] )

	const getData = useCallback((page, sizePerPage, sort, searchText, searchOptions, signal) => {
		return new Promise( (resolve, reject) => {
			const h = headers();
			const params = {
				policyId: policyId,
				page: page,
				sizePerPage: sizePerPage,
				sort: sort,
				searchText: searchText,
				searchOptions: searchOptions,
			}
			fetch( `${ SERVER_URL }/api/settlement/getCreditNoteProfitParticipations?${ new URLSearchParams( params ) }`, {
				method: 'GET',
				headers: h,
				signal: signal
			} )
				.then( r => r.json() )
				.then( json => {
					//debugger;
					resolve( {totalSize: json.count, data: json.data } );
				} )
				.catch( reject )
		})
	}, [policyId] );

	return (
		<>
			<div className={'mt-3'}>
				{policyId ? <Settlements getData={getData} hiddenFields={['policyNumber']}/> : null}
			</div>
		</>

	)
}

export default PreviewPolicyVersionProfitParticipationSettlements
