import React from 'react';
import {useState} from "react";
import {NavLink} from "react-router-dom";
import MenuItems from "./MenuItems";
import {Collapse, Nav} from "react-bootstrap";

export default function MenuItem ({level, index, route}) {
    const [open, setOpen] = useState(false);

    const renderRoute = ( index, route ) => {
        if ( route.routes ) {
            return (
                <>
                    <Nav.Link className="px-0 align-middle text-secondary" onClick={()=>setOpen(!open)}>
                        {route.icon && route.icon(undefined, open)}
                        <span className="ms-1 d-none d-sm-inline">{route.sidebar()}</span>
                    </Nav.Link>
                    <Collapse in={open}>
                        <div>
                            <MenuItems level={level+1} routes={route.routes}/>
                        </div>
                    </Collapse>
                </>
            )
        }
        else {
            return (
                route.sidebar && <li key={index}>
                    <NavLink to={route.link? route.link : route.path} activeClassName="active-menu-item" className="nav-link px-0 align-middle text-secondary">
                        {route.icon && route.icon()}
                        {route.abbrev && <span className="d-block d-sm-none">{route.abbrev()}</span>}
                        <span className="ms-1 d-none d-sm-inline">{route.sidebar()}</span>
                    </NavLink>
                </li>
            )
        }
    }

    return (
        <>
            {renderRoute(index, route)}
        </>

    );
}

