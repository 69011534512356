import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";

function DiscountSurchargeFeeElementTr( { name, listBoxLabel, rate, unit, value } ) {
	const {t} = useTranslation()

	const ds = useMemo( () => {
		let result;

		switch( unit ) {
			case '%':
				result = `${ Math.round( Math.abs( rate * 10000 ) ) / 100} %`;
				break;
			case 'CHF':
				result = `${ Math.round( Math.abs( rate * 100 ) ) / 100} CHF`;
				break;
			default:
				throw new Error("Uknown unit")
		}

		return result;
	}, [unit, rate])

	return (
		<tr>
			<td>
				{ name } { listBoxLabel } ({ rate > 0 ? t( 'default.surcharge' ) : t( 'default.discount' ) } { ds })
			</td>
			<td className="text-right"><NumericFormat value={ value }
													  displayType={ 'text' }
													  thousandSeparator={ t('thousandSeparator') }/> CHF
			</td>
		</tr>
	)
}

export default DiscountSurchargeFeeElementTr
