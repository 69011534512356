import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";
import CreateParticipationSettlementBatch from "./CreateParticipationSettlementBatch";
import PreviewParticipationSettlementBatch from "./PreviewParticipationSettlementBatch";

function ParticipationSettlementBatch({match}) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('default.id'),
		hidden: true
	}, {
		dataField: 'dateFrom',
		text: t('participationSettlementBatch.dateFrom'),
		formatter: bootstrapTableService.formatterDate,
		sort: true,
		sortType: 'Date',
	}, {
		dataField: 'dateTo',
		text: t('participationSettlementBatch.dateTo'),
		formatter: bootstrapTableService.formatterDate,
		sort: true,
		sortType: 'Date',
	}, {
		dataField: 'createdOn',
		text: t('settlementBatch.createdOn.label'),
		sort: true,
		formatter: (cellContent, row) => bootstrapTableService.formatterDateTime(cellContent)
	}, {
		dataField: 'createdBy',
		text: t( 'settlementBatch.createdBy.label' ),
		sort: true
	}, {
		dataField: 'settlementsCount',
		text: t( 'settlementBatch.settlementsCount.label'),
		sort: true
	},
	];

	return (
		<Index
			path={match.path}
			domainName={'participationSettlementBatch'}
			columns={columns}
			toString={['name']}
			createElement={<CreateParticipationSettlementBatch/>}
			showElement={<PreviewParticipationSettlementBatch/>}
			sortField={'dateFrom'}
			sortOrder={'desc'}
			allowEdits={false}
			allowDeletions={false}
			allowShow={true}
		/>
	);
}

export default ParticipationSettlementBatch;
