import React from 'react';
import {getEnumType, PaymentState} from "../_enum/enum";
import {Badge} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function PaymentStateBadge( {paymentState, customText} ) {
    const {t} = useTranslation()

    const paymentStateEnum = getEnumType( "appvers.enums.PaymentState", paymentState )

    let bgClass = 'info'
    if ( paymentStateEnum.name === PaymentState.PAID ) {
        bgClass = 'success'
    }
    else if ( paymentStateEnum.name === PaymentState.OVERPAID ) {
        bgClass = 'danger'
    }
    else if ( paymentStateEnum.name === PaymentState.PAID_PARTIALLY ) {
        bgClass = 'warning'
    }
    else if ( paymentStateEnum.name === PaymentState.PAYMENT_NOT_NECESSARY ) {
        bgClass = 'secondary'
    }

    const paymentStateText = t(paymentStateEnum.enumType + "." + paymentStateEnum.name)

    const getInFrontBadgeText = () => {
        if ( customText !== undefined ) {
            return paymentStateText
        }
    }

    const getBadgeText = () => {
        if ( customText === undefined ) {
            return paymentStateText
        }
        else {
            return customText
        }
    }

    return (
        <>
            {getInFrontBadgeText() && <span className={"pe-2 align-middle"}>{getInFrontBadgeText()}</span>}<Badge className={"me-2"} bg={bgClass}>
                {getBadgeText()}
            </Badge>
        </>
    )
}

export default PaymentStateBadge
