import React, {useCallback, useMemo} from 'react';
import PropTypes from "prop-types";
import SimpleTable from "../_components/SimpleTable";
import {policyService} from "../_services/policy.service";
import {useTranslation} from "react-i18next";
import textService from "../_services/text.service";
import {Card, Col, Row} from "react-bootstrap";
import appService from "../_services";
import EditControl from "./EditControl";
import DecimalEditControl from "./DecimalEditControl";
import BooleanEditControl from "./BooleanEditControl";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";

function OtherLeadershipChecks( {year}) {
	const {t} = useTranslation();
	const [rows, setRows] = policyService.useOtherLeadershipChecks(year);

	const handleSave = useCallback( (policyVersionId, data) => {
		return new Promise( (resolve, reject) => {
			policyService.saveOrUpdateOtherLeadershipCheck(year, policyVersionId, data)
				.then( (row) => {
					let updatedRows = [...rows];
					const idx = rows.findIndex( (row) => row.policyVersion.id === policyVersionId )
					if ( idx < 0 ) {
						throw new Error(`row for policyVersionId: ${policyVersionId} not found`);
					}
					else {
						updatedRows[idx] = row
					}

					setRows(updatedRows)
					resolve();
				})
				.catch( (error) => reject(error) )
		})
	}, [rows, setRows, year]);

	const columns = useMemo( () => [
		{ key: 'id', hidden: true, formatter: (row) => `${year}-${row.policyVersion.id}`},
		{ key: 'collectivePolicyNr', className:"text-end", label: t('createOrEditOtherLeadershipCheck.collectivePolicyNr.label'), formatter: (row) => row.collectivePolicyNr },
		{ key: 'policyNumber', className:"text-end", label: t('createOrEditOtherLeadershipCheck.policyNumber.label'), formatter: (row) =>  <Link to={ restService.getTo(row.policyVersion, 'show') } target={"_blank"} rel={"noopener noreferrer"}>{ row.policyNumber }</Link> },
		{ key: 'dueMonth', className:"text-center", label: t('createOrEditOtherLeadershipCheck.dueMonth.label'), formatter: (row) => `1.${row.dueMonth}` },
		{ key: 'policyHolder', label: t('createOrEditOtherLeadershipCheck.policyHolder.label'), formatter: (row) => <Link to={ restService.getTo(row.policyHolder, 'edit') } target={"_blank"} rel={"noopener noreferrer"}>{ row.policyHolder.label }</Link> },
		{ key: 'expectedFee', className:"text-end", label: t('createOrEditOtherLeadershipCheck.expectedFee.label'), formatter: (row) => textService.formatNumber( policyService.round20(row.expectedFee) ) },
		{ key: 'fee', label: t('createOrEditOtherLeadershipCheck.fee.label'), formatter: (row) => <EditControl policyVersionId={row.policyVersion.id} onSave={handleSave} fieldName={'fee'} editControl={<DecimalEditControl key={`fee-${row.id}`} defaultValue={row.fee}/>} /> },
		{ key: 'difference', className:"text-end", label: t('createOrEditOtherLeadershipCheck.difference.label'), formatter: (row) => textService.formatNumber( policyService.round20(row.expectedFee - row.fee)) },
		{ key: 'checked', label: t('createOrEditOtherLeadershipCheck.checked.label'), formatter: (row) => <EditControl policyVersionId={row.policyVersion.id} onSave={handleSave} fieldName={'checked'} editControl={<BooleanEditControl key={`checked-${row.id}`} defaultValue={row.checked} />} /> },
		{ key: 'creditNoteOtherLeadershipDiscounts', label: t('createOrEditOtherLeadershipCheck.creditNoteOtherLeadershipDiscounts.label'), formatter: (row) => {
			const label = t('creditNoteOtherLeadershipDiscount.label') + ' ' + t('default.nr');
			return row.creditNoteOtherLeadershipDiscounts.map( (creditNote) => {
				return <Link key={creditNote.id} to={ restService.getTo(creditNote, 'show') } target={"_blank"} rel={"noopener noreferrer"}>{label} { creditNote.number }</Link>
			} )
		} },
	], [year, handleSave, t]);

	const leadingCompanyElement = useCallback( (key, companyName, otherLeadershipChecks ) => {
		return <Card key={key} className={"mb-3"}>
			<Card.Body>
				<Card.Title className={'text-primary'}>{ companyName }</Card.Title>
				<Row>
					<Col>
						<SimpleTable columns={ columns } rows={ otherLeadershipChecks } />
					</Col>
				</Row>
			</Card.Body>
		</Card>
	}, [columns])

	const elements = useMemo( () => {
		const otherLeadershipChecksGroupedByLeadingCompany = appService.groupBy( rows, (row) => row.leadingCompany.id )
		return Object.keys(otherLeadershipChecksGroupedByLeadingCompany).map( (leadingCompanyId) => {
			const companyName = otherLeadershipChecksGroupedByLeadingCompany[leadingCompanyId][0].leadingCompany.label
			const otherLeadershipChecks = otherLeadershipChecksGroupedByLeadingCompany[leadingCompanyId]
			return leadingCompanyElement( leadingCompanyId, companyName, otherLeadershipChecks )
		});
	}, [rows, leadingCompanyElement])

	return elements
}

OtherLeadershipChecks.propTypes = {
	year: PropTypes.number
}

export default OtherLeadershipChecks